import { Button, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { DragOption } from "../../@types/interfaces/api/drag-fill.interface";
import { IScrambledAnswerPost } from "../../@types/interfaces/api/scrambled-sentence.interface";
import { useAppDispatch } from "../../hooks/redux";
import { useAudio } from "../../hooks/useAudio";
import {
  setAnswerScrambled,
  setWrongScrambled,
} from "../../stores/ducks/scrambled-sentence/actions";
import { audioCorrectAnswer } from "../../utils/Utility";
import RegularButton from "../global/RegularButton";
import Subtitle from "../global/text/Subtitle";
import Text32px from "../global/text/Text32px";
import DragDropContextWrap from "./extra/DragDropContextWrap";
import DragOptionsBox from "./extra/DragOptionsBox";
import { assetsBaseUrl } from "../../utils/assets-helper";
import { useLanguageContext } from "../../providers/LanguageProvider";
import { useAccesibiltyContext } from "../../providers/AccessibilityController";

interface Props {
  onNext: () => void;
  words: DragOption[];
  wordsShuffled: DragOption[];
  title: string;
  idSentence: number;
  wrongAnswer: number;
  setAnswer: (data: IScrambledAnswerPost[]) => void;
  currentProgram?: "JR" | "LF" | "VM" | null;
  audios: {
    incomplete: string;
    intro: string;
    wrong: string;
  };
}

function ScrambledSentence({
  onNext,
  words,
  wordsShuffled,
  title,
  idSentence,
  wrongAnswer,
  setAnswer,
  audios,
  currentProgram,
}: Props) {
  const { language } = useLanguageContext();

  const dispatch = useAppDispatch();
  const { setAudio } = useAudio(language, audios.intro);

  type State = {
    items: DragOption[];
    sentence: DragOption[];
  };
  const initialState: State = {
    items: wordsShuffled,
    sentence: [],
  };
  const [prevState, setPrevState] = useState<State>(initialState);
  const [state, setState] = useState<State>(initialState);
  const [inactiveDrag, setInactiveDrag] = useState(false);
  const { onPlayAudio } = useAccesibiltyContext();

  useEffect(() => {
    if (words) {
      setState(initialState);
      setPrevState(initialState);
    }
  }, [words]);

  async function handleConfirm() {
    const response = state.sentence.map((word) => word.text);

    const arrayAnswer = state.sentence.map((w) => w.text);
    const data: IScrambledAnswerPost[] = [
      {
        sentence: idSentence,
        history: 0,
        answer: arrayAnswer.join(" "),
      },
    ];

    setAnswer(data);

    if (response.length !== words.length) {
      setAudio(audios.incomplete);
      return;
    }

    let isCorrect = true;
    for (let i = 0; i < words.length; i++) {
      const selected = response[i];
      const correctAnswer = words[i].text;
      if (selected.toLowerCase() !== correctAnswer.toLowerCase()) {
        isCorrect = false;
        break;
      }
    }

    if (!isCorrect) {
      setAudio(audios.wrong);
      dispatch(setWrongScrambled());
      if (wrongAnswer >= 2) {
        onNext();
      }
      return;
    }

    setInactiveDrag(true);
    onNext();
    await onPlayAudio(audioCorrectAnswer());
  }

  const emptyField = state.sentence.some((text) => text.text);

  return (
    <Flex flexDir="column" alignItems={"center"}>
      <Subtitle>unscramble the sentence</Subtitle>
      <Text32px>{title}</Text32px>

      <Flex mt="32px" gap="24px">
        <RegularButton
          onMouseDown={() => {
            setPrevState(state);
            setState({ items: [], sentence: words });
          }}
          onMouseUp={() => {
            setState(prevState);
          }}
          onMouseLeave={() => {
            setState(prevState);
          }}
        >
          Review
        </RegularButton>
      </Flex>

      <DragDropContextWrap
        alternatives={words}
        state={state}
        setState={setState}
        currentProgram={currentProgram}
      >
        <Flex flexDir={"column"} gap={3} mt="32px">
          <Droppable droppableId="sentence" direction="horizontal">
            {(provided) => (
              <Flex
                ref={provided.innerRef}
                minW={["300px", "625px"]}
                minH={"70px"}
                bg="white"
                p="8px 16px"
                borderRadius="8px"
                border="1.5px solid #CBD5E0"
                flexWrap="wrap"
                alignItems="flex-start"
              >
                {state.sentence.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={String(item.id)}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <Flex
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        p="8px 8px"
                        borderRadius="8px"
                        backgroundColor={snapshot.isDragging ? "#ff5e5e" : ""}
                        minWidth="fit-content"
                      >
                        <Text32px fontSize="20px" color="primary">
                          {item.text}
                        </Text32px>
                      </Flex>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Flex>
            )}
          </Droppable>
        </Flex>

        <DragOptionsBox
          alternatives={state.items}
          droppableId="items"
          inactiveDrag={inactiveDrag}
        />
      </DragDropContextWrap>

      <RegularButton mt="60px" onClick={handleConfirm} disabled={!emptyField}>
        Confirm (ok)
      </RegularButton>
    </Flex>
  );
}

export default ScrambledSentence;
