import {
  AspectRatio,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";

interface Props {
  url: string;
  onCloseModal?: () => void;
}
const ModalOpened: FC<Props> = ({ url, onCloseModal }) => {
  const { isOpen, onClose } = useDisclosure({
    defaultIsOpen: true,
    onClose: onCloseModal,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl" isCentered>
      <ModalOverlay />
      <ModalContent
        borderRadius="16px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        p="10px"
      >
        <video
          width="100%"
          style={{ borderRadius: "16px" }}
          controls
          autoPlay
          src={url}
          // onError={onClose}
        />

        <ModalCloseButton
          color="#fff"
          p="20px"
          bg="#2020203f"
          borderRadius="50%"
        />
      </ModalContent>
    </Modal>
  );
};

export default ModalOpened;
