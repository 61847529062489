import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Flex, Image, Text } from "@chakra-ui/react";
import ufo from "../../assets/image/game/ovini.png";
import bug from "../../assets/image/game/bug1.gif";
import net from "../../assets/image/game/net.png";
import { useAppDispatch } from "../../hooks/redux";
import {
  missShot,
  onNext,
  targetsHit,
} from "../../stores/ducks/minigame/action";
import { AudioMedia } from "../../hooks/AudioMedia";
import crossBowl from "../../assets/audios/CROSSBOW_SHOT.mp3";
import spark from "../../assets/image/game/spark.gif";
import laser from "../../assets/audios/Laser-Plasma-Shot.mp3";

interface FlyingElementProps {
  initialX: number;
  initialY: number;
  velocityX?: number;
  velocityY?: number;
  index: number;
  playPause: boolean;
  option?: string;
  optionVm?: {
    id: number;
    word: string;
  };
  sentence: string | undefined;
  answer: string | number | undefined;
  handleNext: () => void;
  missCount: () => void;
}

const BugElement: React.FC<FlyingElementProps> = ({
  initialX,
  initialY,
  velocityX = 6,
  velocityY = 7,
  answer,
  index,
  playPause,
  option,
  sentence,
  optionVm,
  handleNext,
  missCount,
}) => {
  const dispatch = useAppDispatch();
  const [position, setPosition] = useState({
    x: initialX,
    y: initialY,
  });
  const [velocity, setVelocity] = useState({
    x: (Math.random() - 0.5) * velocityX,
    y: (Math.random() - 0.5) * velocityY,
  });
  const [clicked, setClicked] = useState(false);
  const [shot, setShot] = useState(false);
  const [showImageMiss, setShowImageMiss] = useState(false);

  const verifyResponse = () => {
    if (option) {
      return sentence?.replace(/_+/, option ?? "");
    }
    if (optionVm) {
      return optionVm.id;
    }
  };

  const handleClick = () => {
    if (verifyResponse() === answer) {
      setClicked(true);
      setShot(true);
      dispatch(targetsHit());
      AudioMedia.playSequence("ENG", crossBowl);
      setTimeout(() => {
        setShot(false);
        handleNext();
        setClicked(false);
      }, 800);
    } else {
      AudioMedia.playSequence("ENG", laser);
      dispatch(missShot());
      setClicked(false);
      setShot(false);
      missCount();
      setShowImageMiss(true);
      setTimeout(() => {
        setShowImageMiss(false);
      }, 1000);
    }
  };

  useEffect(() => {
    const updatePosition = () => {
      if (playPause) {
        setPosition((prevPosition) => {
          let newX = prevPosition.x + velocity.x;
          let newY = prevPosition.y + velocity.y;

          // Verificar limites da tela e mudar de direção se necessário
          if (newX < 0 || newX > window.innerWidth - 120) {
            setVelocity((prevVelocity) => ({
              ...prevVelocity,
              x: -prevVelocity.x,
            }));
          }
          if (newY < 0 || newY > window.innerHeight - 120) {
            setVelocity((prevVelocity) => ({
              ...prevVelocity,
              y: -prevVelocity.y,
            }));
          }

          return {
            x: newX,
            y: newY,
          };
        });
      }
    };

    const animationId = requestAnimationFrame(updatePosition);

    return () => cancelAnimationFrame(animationId);
  }, [position, velocity]);

  const rotate = index % 2 === 0 ? 15 : 30;

  return (
    <Flex zIndex={10}>
      <motion.div
        animate={
          showImageMiss
            ? {
                rotate: [0, 360],
              }
            : {
                rotate: [0, rotate, -rotate, 0],
              }
        }
        transition={{
          duration: 1,
          ease: "linear",
          repeat: Infinity,
          repeatType: "loop",
        }}
        onClick={() => handleClick()}
        style={{
          width: "120px",
          height: "90px",
          // opacity: clicked ? 0 : 1,
          position: "absolute",
          cursor: "crosshair",
          left: position.x,
          top: position.y,
          userSelect: "none",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Image src={bug} alt="UFO" w="full" h="full" />
        <Text
          bg="rgba(0,0,0,0.6)"
          px="8px"
          py="2px"
          opacity={showImageMiss ? 0 : 1}
          borderRadius="12px"
          color="white"
          whiteSpace="nowrap"
          w="fit-content"
          fontSize="14px"
        >
          {option ?? optionVm?.word}
        </Text>
      </motion.div>
      <motion.div
        onClick={() => handleClick()}
        style={{
          width: "150px",
          height: "150px",
          overflow: "hidden",
          opacity: shot ? 1 : 0,
          position: "absolute",
          cursor: "crosshair",
          left: position.x,
          top: position.y,
          userSelect: "none",
        }}
      >
        <Image src={net} alt="explosion" w="full" h="full" />
      </motion.div>
      {/* <motion.div
        onClick={() => handleClick()}
        style={{
          width: "150px",
          height: "150px",
          overflow: "hidden",
          opacity: showImageMiss ? 1 : 0,
          position: "absolute",
          cursor: "crosshair",
          left: position.x,
          top: position.y,
          userSelect: "none",
        }}
      >
        <Image src={spark} alt="explosion" w="full" h="full" />
      </motion.div> */}
    </Flex>
  );
};

export default BugElement;
