import {
  Flex,
  Heading,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import OutlineButton from "../global/OutlineButton";
import rocketImage from "../../assets/image/rocket.png";
import RegularButton from "../global/RegularButton";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { logOut } from "../../stores/ducks/auth/actions";
import { X } from "lucide-react";

interface ModalReadyProps {
  onClose(): void;
  isOpen: boolean;
}

const ModalReady: React.FC<ModalReadyProps> = ({ onClose, isOpen }) => {
  const navigate = useNavigate();
  const { student } = useAppSelector((i) => i.auth);
  const dispatch = useAppDispatch();

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        p="32px"
        maxW="553px"
        textAlign="center"
        alignItems="center"
        borderRadius="16px"
      >
        <Image boxSize="125px" src={rocketImage} />
        <Heading fontSize="32px" fontWeight="700" color="gray.700" mt="32px">
          Are you ready?
        </Heading>

        <Flex textAlign="center" mt="16px">
          <Text
            fontWeight="400"
            fontSize="16px"
            lineHeight="150%"
            color="gray.500"
          >
            Now we will begin the lessons. First you will go through a
            diagnostic test, so that we know what level you are at. Enjoy your
            journey.
          </Text>
        </Flex>

        <RegularButton
          mt="50px"
          w="80%"
          _hover={{}}
          onClick={() => {
            if (student?.in_ras) {
              navigate("/lesson/reading-attitude-survey");
              return;
            }
          }}
        >
          Start Lesson
        </RegularButton>
        <OutlineButton
          mt="16px"
          fontSize={["14px", "16px"]}
          h={["36px", "48px"]}
          onClick={() => dispatch(logOut(navigate))}
        >
          Sign Out
        </OutlineButton>
      </ModalContent>
    </Modal>
  );
};

export default ModalReady;
