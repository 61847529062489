import { useEffect, useRef, useState } from "react";
import ContainerLesson from "../../components/global/ContainerLesson";
import PageGrid from "../../components/global/PageGrid";
import ProgressBar from "../../components/global/ProgressBar";
import StartLesson from "../../components/lessons/common/StartLesson";
import { usePages } from "../../hooks/usePages";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  clearReinforcementList,
  loadReinforcementRequest,
  onNextReinforcement,
  onNextReinforcementLSAlphabetical,
  onNextReinforcementLSSpelling,
  onPreviousReinforcement,
  setAlternativeRA,
  setAnswerReinforcement,
  setAnswerReinforcementAlphabetical,
  setAnswerReinforcementSpelling,
  setTimerReinforcement,
} from "../../stores/ducks/reinforcement-activities/actions";
import {
  audioCorrectAnswer,
  audioCorrectAnswerOgg,
  urlsPaths,
} from "../../utils/Utility";
import PrintFinalLesson from "../../components/lessons/PrintFinalLesson";
import {
  loadTalkingBookIDRequest,
  loadTalkingBookRequest,
} from "../../stores/ducks/talking-book/actions";
import FillTheGap from "../../components/lessons/FillTheGap";
import { useNavigate } from "react-router-dom";
import GreatJob from "../../components/lessons/common/GreatJob";
import { assetsBaseUrl } from "../../utils/assets-helper";
import ReinforcementSpelling from "../../components/lessons/ReinforcementSpelling";
import { useLanguageContext } from "../../providers/LanguageProvider";
import useMediaPlayer from "../../hooks/useMediaPlayer";
import LaunchLesson from "../../components/lessons/common/LaunchLesson";
import { Flex } from "@chakra-ui/react";
import SignLanguage from "../../components/global/SignLanguage";
import { useAccesibiltyContext } from "../../providers/AccessibilityController";
import SubtitleComponent from "../../components/global/SubtitleComponent";

function ReinforcementActivities() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { playMedias, setVideoWindow, accessibility, captionText } =
    useAccesibiltyContext();

  const {
    ReinforcementActivities,
    questionIndex,
    questionIndexLS,
    alternativeRA,
    answersRA,
    answersRaSpelling,
    answersRaAlphabetical,
    isLoading,
  } = useAppSelector((item) => item.reinforcementActivities);
  const { currentLesson, inAudio } = useAppSelector((i) => i.lesson);
  const { student } = useAppSelector((i) => i.auth);
  const { language } = useLanguageContext();
  const [firstClick, setFirstClick] = useState(true);

  const { bookId } = useAppSelector((item) => item.talkingBook);
  const { activityData } = useAppSelector((item) => item.dashboard);
  const inLifeSkills =
    activityData?.lesson.story.level.program.short_name === "LF";
  const ReinforcementActivitiesArray =
    ReinforcementActivities?.results[0].question[questionIndex];

  const spellingsArray =
    ReinforcementActivities?.results[0].spelling[questionIndexLS].options;
  const alphabeticalArray =
    ReinforcementActivities?.results[0].alphabetical[questionIndexLS].options;
  useEffect(() => {
    setVideoWindow(true);
    if (currentLesson) {
      dispatch(loadReinforcementRequest({ activity__id: currentLesson.id }));
    }
    dispatch(setTimerReinforcement(Date.now()));
    return () => {
      setVideoWindow(false);
      dispatch(clearReinforcementList());
    };
  }, []);

  useEffect(() => {
    setFirstClick(true);
  }, [questionIndex, questionIndexLS]);

  async function handleConfirm() {
    setFirstClick(false);

    const currentQuestion = ReinforcementActivitiesArray?.text.replace(
      /_+/,
      alternativeRA
    );
    const correctAnswer = ReinforcementActivitiesArray?.answer;

    if (ReinforcementActivitiesArray) {
      const isCorrect =
        currentQuestion?.toLowerCase() === correctAnswer?.toLowerCase();
      if (!isCorrect) {
        await playMedias([
          `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/TRYAGAIN.mp3`,
          `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in74.ogg`,
        ]);
        if (firstClick) {
          dispatch(setAnswerReinforcement());
        }
        return;
      }

      await playMedias([audioCorrectAnswer(), audioCorrectAnswerOgg()]);
    }
    if (firstClick) {
      dispatch(setAnswerReinforcement());
    }
    dispatch(onNextReinforcement(setPage));
  }

  async function verifyResponseSpelling() {
    setFirstClick(false);

    const correctAnswer =
      ReinforcementActivities?.results[0].spelling[questionIndexLS].answer;

    if (ReinforcementActivitiesArray) {
      const isCorrect =
        alternativeRA.toLowerCase() === correctAnswer?.toLowerCase();
      if (!isCorrect) {
        await playMedias([
          `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/TRYAGAIN.mp3`,
          `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in74.ogg`,
        ]);
        if (firstClick) {
          dispatch(setAnswerReinforcementSpelling());
        }
        return;
      }

      await playMedias([audioCorrectAnswer(), audioCorrectAnswerOgg()]);
    }
    if (firstClick) {
      dispatch(setAnswerReinforcementSpelling());
    }
    dispatch(onNextReinforcementLSSpelling(setPage));
  }
  async function verifyResponseAlphabetical() {
    setFirstClick(false);

    const correctAnswer =
      ReinforcementActivities?.results[0].alphabetical[questionIndexLS].answer;

    if (ReinforcementActivitiesArray) {
      const isCorrect =
        alternativeRA.toLowerCase() === correctAnswer?.toLowerCase();
      if (!isCorrect) {
        await playMedias([
          `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/TRYAGAIN.mp3`,
          `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in74.ogg`,
        ]);
        if (firstClick) {
          dispatch(setAnswerReinforcementAlphabetical());
        }

        return;
      }

      await playMedias([audioCorrectAnswer(), audioCorrectAnswerOgg()]);
    }
    if (firstClick) {
      dispatch(setAnswerReinforcementAlphabetical());
    }
    dispatch(onNextReinforcementLSAlphabetical(setPage));
  }

  const setAlternativeFn = (alternative: string) => {
    dispatch(setAlternativeRA(alternative));
  };

  const getTalkingBook = () => {
    if (ReinforcementActivities) {
      dispatch(
        loadTalkingBookIDRequest(
          ReinforcementActivities.results[0].talking_book
        )
      );
    }
  };

  const initialPage = student?.progress.finished ? "start" : "accessibility";

  const { page, setPage, pageName } = usePages<
    | "start"
    | "question"
    | "print_lesson"
    | "greatJob"
    | "reinforcement_spelling"
    | "reinforcement_alphabetical"
    | "accessibility"
  >({
    initialPage,
    pages: {
      accessibility: <LaunchLesson onNext={() => setPage("start")} />,
      start: (
        <StartLesson
          title="Reinforcement activities"
          introduction={
            inLifeSkills
              ? [
                  `${assetsBaseUrl()}/app-2.0/jr4_html5/audio/ENG/dirs_mp3/REIN_INTRO.mp3`,
                  `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/in72.ogg`,
                ]
              : [
                  `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/REIN_INTRO.mp3`,
                  `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in42.ogg`,
                ]
          }
          onNext={() => setPage("question")}
          inAudio={inAudio}
          button="Start Lesson"
        />
      ),
      question: ReinforcementActivitiesArray && (
        <FillTheGap
          alternativeData={alternativeRA}
          optionsRA={ReinforcementActivitiesArray.possible_answers}
          question={ReinforcementActivitiesArray.text}
          setAlternativeFn={setAlternativeFn}
          questionIndex={questionIndex}
          audioIntro={[
            `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/FILLIN.mp3`,
            `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in43.ogg`,
          ]}
        />
      ),
      print_lesson: (
        <PrintFinalLesson
          text={bookId?.text}
          image={bookId?.image}
          getData={getTalkingBook}
          setPage={() => setPage("greatJob")}
        />
      ),
      reinforcement_spelling: spellingsArray && (
        <ReinforcementSpelling
          inAudio={inAudio}
          audioIntro={[
            `${assetsBaseUrl()}/app-2.0/jr4_html5/audio/ENG/dirs_mp3/SPELLDIR.mp3`,
            `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/in58.ogg`,
          ]}
          data={spellingsArray}
          setAlternative={setAlternativeFn}
          onNext={verifyResponseSpelling}
          index={questionIndexLS}
          title="Correct spelling of the words"
        />
      ),
      reinforcement_alphabetical: alphabeticalArray && (
        <ReinforcementSpelling
          inAudio={inAudio}
          audioIntro={[
            `${assetsBaseUrl()}/app-2.0/jr4_html5/audio/ENG/dirs_mp3/ALPHADIR.mp3`,
            `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/in76.ogg`,
          ]}
          data={alphabeticalArray}
          setAlternative={setAlternativeFn}
          onNext={verifyResponseAlphabetical}
          index={questionIndexLS}
          title="Word in alphabetical order"
        />
      ),
      greatJob: (
        <GreatJob
          onNext={() => {
            if (currentLesson) {
              navigate(`/lesson/${urlsPaths[currentLesson?.mechanic_type]}`);
            }
          }}
        />
      ),
    },
  });

  return (
    <PageGrid
      activity="Reinforcement activities"
      lesson="Joseph's Readers"
      onHelp={() => {}}
      audioHelp={
        pageName === "question"
          ? [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/FILLIN.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in3.ogg`,
            ]
          : [
              `${assetsBaseUrl()}/app-2.0/jr4_html5/audio/ENG/dirs_mp3/SPELLDIR.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/in58.ogg`,
            ]
      }
      disabledButton={
        inAudio ||
        pageName === "start" ||
        pageName === "greatJob" ||
        pageName === "accessibility" ||
        pageName === "print_lesson"
      }
    >
      <ContainerLesson>
        {page}
        <SubtitleComponent
          hide={
            !accessibility.subtitles ||
            !inAudio ||
            captionText === "" ||
            pageName === "greatJob"
          }
        />
        {ReinforcementActivities && (
          <ProgressBar
            hide={pageName !== "question"}
            data={{
              max: ReinforcementActivities?.results[0].question.length,
              step: questionIndex + 1,
            }}
            onConfirm={handleConfirm}
            // onPrevious={() => {
            //   dispatch(onPreviousReinforcement());
            // }}
            hideButtonPrevious
            hideButton={alternativeRA === "" || inAudio}
          />
        )}
      </ContainerLesson>
    </PageGrid>
  );
}

export default ReinforcementActivities;
