import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import {
  RasAnswer,
  Question,
} from "../../../@types/interfaces/api/ras.interface";

export enum RasActionTypes {
  LOAD_QUESTIONS_REQUEST = "@ras/LOAD_QUESTIONS_REQUEST",
  LOAD_QUESTIONS_SUCCESS = "@ras/LOAD_QUESTIONS_SUCCESS",
  LOAD_QUESTIONS_ERROR = "@ras/LOAD_QUESTIONS_ERROR",

  LOAD_ANSWER_REQUEST = "@ras/LOAD_ANSWER_REQUEST",
  LOAD_ANSWER_SUCCESS = "@ras/LOAD_ANSWER_SUCCESS",
  LOAD_ANSWER_ERROR = "@ras/LOAD_ANSWER_ERROR",

  POST_ANSWER_REQUEST = "@ras/POST_ANSWER_REQUEST",
  POST_ANSWER_SUCCESS = "@ras/POST_ANSWER_SUCCESS",
  POST_ANSWER_ERROR = "@ras/POST_ANSWER_ERROR",

  CLEAR_LIST = "@ras/CLEAR_LIST",
  SET_ALTERNATIVE_BEST_FIT = "@ras/ SET_ALTERNATIVE_BEST_FIT",
  SET_AUDIO = "@ras/ SET_AUDIO",

  ON_NEXT = "@ras/ ON_NEXT",
  ON_PREVIOUS = "@ras/ PREVIOUS",
}

export interface RasState extends RasStateRequestsStatus {
  questions: PaginationBase<Question[]> | undefined;
  answers: RasAnswer[];
  alternativeBestFit: AlternativeBestfit | undefined;
  questionIndex: number;
  selectedAudio: string;
}

interface RasStateRequestsStatus {
  isLoading: boolean;
}

export interface AlternativeBestfit {
  textReplace: string;
  answerid: number;
}
