import store from "..";
import AnyAction from "redux";

import {
  ISpellingQuestion,
  StoryFeed,
} from "../../../@types/interfaces/api/pre-teaching.interface";
import { assetsBaseUrl } from "../../../utils/assets-helper";
import { VocabularyBuilderActionTypes } from "./type";
import VocabularyBuilderFilterInterface, {
  IWordFeedOgg,
  VocabularyBuilderWord,
  alternativeVocabulary,
} from "../../../@types/interfaces/api/vocabulary-builder.interface";
import { IVocabularyBuilder } from "../../../@types/interfaces/api/vocabulary-builder.interface";
import { IWordFeed } from "../../../@types/interfaces/api/vocabulary-builder.interface";

export const loadVocabularyBuilderRequest = (
  filter: VocabularyBuilderFilterInterface
) => ({
  type: VocabularyBuilderActionTypes.LOAD_VOCABULRY_BUILDER_REQUEST,
  payload: filter,
});

export const loadVocabularyBuilderSuccess = (data: IVocabularyBuilder) => {
  return {
    type: VocabularyBuilderActionTypes.LOAD_VOCABULRY_BUILDER_SUCCESS,
    payload: data,
  };
};
export const loadVocabularyBuilderError = () => ({
  type: VocabularyBuilderActionTypes.LOAD_VOCABULRY_BUILDER_ERROR,
});

export const getWordVocabularyBuilder = () => {
  const data = store.getState().vocabularyBuilder.vocabularyData;
  const alternative = store.getState().vocabularyBuilder.alternativeVocabulary;

  const contentData = data?.words.find((word) => word.id === alternative?.id);

  if (contentData) {
    const sentence = contentData.context;
    const quiz = contentData.quiz;
    const separator = contentData.text;

    const matchVerifyQuiz = () => {
      const partsQ = quiz.split(separator);
      if (quiz.indexOf(separator) === -1) {
        return [quiz];
      } else {
        const sentenceSplitedQ = [
          partsQ[0].trim(),
          separator,
          partsQ[1].trim(),
        ];
        return sentenceSplitedQ;
      }
    };
    const matchVerifySentence = () => {
      const partsS = sentence.split(separator);
      if (sentence.indexOf(separator) === -1) {
        return [sentence];
      } else {
        const sentenceSplitedS = [
          partsS[0].trim(),
          separator,
          partsS[1].trim(),
        ];
        return sentenceSplitedS;
      }
    };
    const feed: IWordFeedOgg = {
      word: {
        text: contentData.text,
        audio: [
          `${assetsBaseUrl()}${contentData.text_audio}`,
          `${assetsBaseUrl()}${contentData.text_audio.replace(".mp3", ".ogg")}`,
        ],
      },
      synonym: {
        text: contentData.synonym,
        audio: [
          `${assetsBaseUrl()}${contentData.synonym_audio}`,
          `${assetsBaseUrl()}${contentData.synonym_audio.replace(
            ".mp3",
            ".ogg"
          )}`,
        ],
      },
      definition: {
        text: contentData.definition,
        audio: [
          `${assetsBaseUrl()}${contentData.definition_audio}`,
          `${assetsBaseUrl()}${contentData.definition_audio.replace(
            ".mp3",
            ".ogg"
          )}`,
        ],
      },
      sentence: {
        text: matchVerifySentence(),
        audio: [
          `${assetsBaseUrl()}${contentData.context_audio}`,
          `${assetsBaseUrl()}${contentData.context_audio.replace(
            ".mp3",
            ".ogg"
          )}`,
        ],
      },
      selectQuestion: {
        text: matchVerifyQuiz(),
        audio: [
          `${assetsBaseUrl()}${contentData.quiz_audio}`,
          `${assetsBaseUrl()}${contentData.quiz_audio.replace(".mp3", ".ogg")}`,
        ],
        response: contentData.quiz_answer,
        correct: [
          `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/praise02.mp3`,
          `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/in67.ogg`,
        ],
        wrong: [
          `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/uh03.mp3`,
          `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/QUESTYES.ogg`,
        ],
      },
    };

    const feedSpelling: StoryFeed[] = [];

    feedSpelling.push({
      type: "text",
      content: contentData.text,
    });
    feedSpelling.push({
      type: "audio",
      content: "",
      contentArray: [
        `${assetsBaseUrl()}${contentData.text_audio}`,
        `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/common_ogg/${contentData.text.toLowerCase()}.ogg`,
      ],
    });
    let currentWord = "";
    for (let i = 0; i < contentData.text.length; i++) {
      currentWord += contentData.text[i];
      feedSpelling.push({
        type: "text",
        content: currentWord,
      });
      const audioUrl = `${assetsBaseUrl()}/app-2.0/vm_html5/audio/alpha_mp3/${
        contentData.text[i]
      }.mp3`;
      const audioUrlOgg = `${assetsBaseUrl()}/app-2.0/jr4_html5/audio/alpha_ogg/${
        contentData.text[i]
      }.ogg`;
      feedSpelling.push({
        type: "audio",
        content: "",
        contentArray: [audioUrl, audioUrlOgg],
      });
    }
    feedSpelling.push({
      type: "audio",
      content: "",
      contentArray: [
        `${assetsBaseUrl()}${contentData.text_audio}`,
        `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/common_ogg/${contentData.text.toLowerCase()}.ogg`,
      ],
    });
    feedSpelling.push({
      type: "text",
      content: contentData.text,
    });

    const spellingQuestion: ISpellingQuestion = {
      audio: [
        `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/uh05.mp3`,
        `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/in46.ogg`,
        `${assetsBaseUrl()}${contentData.text_audio}`,
        `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/common_ogg/${contentData?.text.toLowerCase()}.ogg`,
        `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/uh06.mp3`,
        `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/in84.ogg`,
      ],
      response: contentData.text,
      correct: [
        `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/FANTAST.mp3`,
        `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/in67.ogg`,
      ],
      wrong: [
        `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/uh07.mp3`,
        `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/in74.ogg`,
      ],
    };

    return {
      type: VocabularyBuilderActionTypes.GET_WORD_VOCABULARY_BUILDER,
      payload: { feed, feedSpelling, spellingQuestion },
    };
  } else {
    const feed = data?.words[0];
    return {
      type: VocabularyBuilderActionTypes.GET_WORD_VOCABULARY_BUILDER,
      payload: feed,
    };
  }
};

export const clearVocabularyBuilderList = () => {
  return {
    type: VocabularyBuilderActionTypes.CLEAR_LIST,
  };
};

export const setAlternativeVocabularyBuilder = (
  alternative: alternativeVocabulary
) => {
  return {
    type: VocabularyBuilderActionTypes.SET_ALTERNATIVE_VOCABULARY_BUILDER,
    payload: alternative,
  };
};

export const postAnswerVocabularyBuilderRequest = (
  setPage: (page: any) => void
) => {
  setPage("greatJob");
  return {
    type: VocabularyBuilderActionTypes.POST_ANSWER_VOCABULARY_BUILDER_REQUEST,
  };
};
export const postAnswerVocabularyBuilderSuccess = () => ({
  type: VocabularyBuilderActionTypes.POST_ANSWER_VOCABULARY_BUILDER_SUCCESS,
});
export const postAnswerVocabularyBuilderError = () => ({
  type: VocabularyBuilderActionTypes.POST_ANSWER_VOCABULARY_BUILDER_ERROR,
});

export const setFullResponseVocabularyBuilder = () => {
  const contentListAnswer = store.getState().vocabularyBuilder;
  const question = contentListAnswer.alternativeVocabulary?.id;
  const answer = contentListAnswer.textVocabulary;

  const contentAnswer = {
    answer,
    question,
    history: 0,
  };

  return {
    type: VocabularyBuilderActionTypes.ON_NEXT,
    payload: contentAnswer,
  };
};

export const setTimerVocabularyBuilder = (date: number) => {
  return {
    type: VocabularyBuilderActionTypes.SET_TIMER_VOCABULARY_BUILDER,
    payload: date,
  };
};
export const learnedWordVocabulary = () => {
  const contentListAnswer =
    store.getState().vocabularyBuilder.alternativeVocabulary?.id;

  return {
    type: VocabularyBuilderActionTypes.LEARNED_WORD_VOCABULARY_BUILDER,
    payload: contentListAnswer,
  };
};
export const setTextVocabulary = (text: string) => {
  return {
    type: VocabularyBuilderActionTypes.SET_TEXT_VOCABULARY_BUILDER,
    payload: text,
  };
};
// export const onPreviousVocabularyBuilder = () => {
//   const contentListAnswer = store.getState().VocabularyBuilder;
//   const answerArray = contentListAnswer.answers;

//   answerArray.pop();

//   return {
//     type: VocabularyBuilderActionTypes.ON_PREVIOUS,
//     payload: answerArray,
//   };
// };
