import {
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import OutlineButton from "../global/OutlineButton";

interface ModalPrintAccessProps {
  onClose(): void;
  isOpen: boolean;
}

const ModalLogin: React.FC<ModalPrintAccessProps> = ({ onClose, isOpen }) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent
        p="32px"
        w="403px"
        textAlign="center"
        alignItems="center"
        borderRadius="16px"
      >
        <Flex textAlign="center" mt="16px">
          <Text
            fontWeight="400"
            fontSize="15px"
            lineHeight="150%"
            color="gray.500"
          >
            Please contact Failure Free or your School’s Principal in order to
            reset your password.
          </Text>
        </Flex>
        <Flex justify="center" mt="32px" w="full">
          <OutlineButton onClick={onClose} w="full">
            Close
          </OutlineButton>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default ModalLogin;
