import { Button, Flex, Image, Text } from "@chakra-ui/react";
import { ArrowRight, Play } from "lucide-react";
import React, { useEffect, useState } from "react";
import { TalkingBook } from "../../@types/interfaces/api/talking-book.interface";
import { useAudio } from "../../hooks/useAudio";
import { BASE_URL_MIDIA } from "../../utils/Utility";
import RegularButton from "../global/RegularButton";
import Subtitle from "../global/text/Subtitle";
import Text32px from "../global/text/Text32px";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { setActiveButtonHelp } from "../../stores/ducks/lesson/action";
import { useLanguageContext } from "../../providers/LanguageProvider";
import useVideoPlayer from "../../hooks/useMediaPlayer";
import SignLanguage from "../global/SignLanguage";
import { useAccesibiltyContext } from "../../providers/AccessibilityController";
import { assetsBaseUrl } from "../../utils/assets-helper";
import SubtitleComponent from "../global/SubtitleComponent";

interface IBookSentence {
  book: TalkingBook | undefined;
  onNext: () => void;
  audioIntro: string[];
}
const BookSentence: React.FC<IBookSentence> = ({
  book,
  onNext,
  audioIntro,
}) => {
  const { language } = useLanguageContext();
  const [reader, setReader] = useState<number | null>(0);
  const [sequence, setSequence] = useState(true);
  const [finish, setFinish] = useState(false);
  const dispatch = useAppDispatch();
  const { inAudio } = useAppSelector((i) => i.lesson);
  const { playMedias, captionText, accessibility } = useAccesibiltyContext();

  useEffect(() => {
    const playSentence = async () => {
      dispatch(setActiveButtonHelp(false));
      if (sequence && reader === 0) {
        await playMedias([...audioIntro]);
        // await onPlayAudio( ...audioIntro);
      }
      if (book && reader !== null && reader < book.words.length && sequence) {
        const updatedList = book.words.map((item) => {
          return {
            ...item,
            audio: item.audio.replace(/[.,]+/g, "."),
          };
        });
        const updatedListOgg = book.words.map((item) => {
          return {
            ...item,
            audio: item.audio
              .split("/")
              .pop()
              ?.split(".")[0]
              .replace(/[.,]+/g, "."),
          };
        });

        await playMedias([
          assetsBaseUrl() + updatedList[reader].audio,
          `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/common_ogg/${
            updatedListOgg[reader].audio
          }.ogg`,
        ]);

        setReader((prev) => prev! + 1);
      } else {
        setSequence(false);
        dispatch(setActiveButtonHelp(true));
      }
    };

    playSentence();
  }, [reader, book, sequence]);

  useEffect(() => {
    const playAudio = async () => {
      if (!book) return;
      await playMedias([
        `${assetsBaseUrl()}${book.audio}`,
        `${assetsBaseUrl()}${book.audio.replace(".mp3", ".ogg")}`,
      ]);
    };

    if (book && reader && reader >= book.words.length) {
      setFinish(true);
      setSequence(false);
      playAudio();

      setReader(null);
    }
  }, [reader, book]);

  return (
    <Flex w="full" px={["0px", "100px"]} flexDir="column" align="center">
      <Subtitle>Press the 'Play' button to have the page read to you</Subtitle>
      <Text32px mt="16px">Talking Book</Text32px>

      <Flex mt="30px" gap={["24px", "140px"]} flexDir={["column", "row"]}>
        <Flex flexDir="column" h="100%" justifyContent="flex-end">
          <Flex>
            <Button
              bg="white"
              disabled={inAudio}
              border="1.5px solid #CBD5E0"
              borderRadius="100px"
              onClick={() => {
                playMedias([
                  `${assetsBaseUrl()}${book?.audio}`,
                  `${assetsBaseUrl()}${book?.audio.replace(".mp3", ".ogg")}`,
                ]);
              }}
              p="10px 24px"
              rightIcon={<Play size="18px" />}
            >
              play
            </Button>
          </Flex>

          <Flex
            mt="16px"
            flexWrap={"wrap"}
            gap="3px"
            maxW="442px"
            p="24px"
            bg="white"
            borderRadius="16px"
            border="1.5px solid #CBD5E0"
          >
            {book?.words.map((word, index) => {
              async function playWord() {
                setSequence(false);
                setReader(index);
                const wordForOgg = word.audio
                  .split("/")
                  .pop()
                  ?.split(".")[0]
                  .replace(/[.,]+/g, ".");

                await playMedias([
                  BASE_URL_MIDIA + word.audio.replace(/[.,]+/g, "."),
                  `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/common_ogg/${wordForOgg}.ogg`,
                ]);

                if (reader) {
                  setSequence(true);
                  setReader(0);
                } else {
                  setReader(null);
                }
              }
              return (
                <Text
                  onClick={playWord}
                  marginX={"1"}
                  cursor="pointer"
                  key={index}
                  fontWeight={reader === index ? "600" : "400"}
                  textColor={reader === index ? "#0C4DA2" : "#718096"}
                >
                  {word.text}
                </Text>
              );
            })}
          </Flex>
        </Flex>
        <Image
          maxW={["full", "583px"]}
          src={BASE_URL_MIDIA + book?.image}
          borderRadius="16px"
        />
      </Flex>
      <SubtitleComponent
        hide={!accessibility.subtitles || captionText === ""}
        mt="8px"
      />

      <RegularButton
        rightIcon={<ArrowRight />}
        mt="56px"
        disabled={inAudio}
        isLoading={inAudio}
        onClick={onNext}
      >
        Next
      </RegularButton>
    </Flex>
  );
};

export default BookSentence;
