import { Flex, Text } from "@chakra-ui/react";
import React from "react";

interface ButtonRadioLaunchLessonProps {
  text: string;
  isSelected?: boolean;
  handleClick: () => void;
  subTitle?: string;
}

const ButtonRadioLaunchLesson: React.FC<ButtonRadioLaunchLessonProps> = ({
  isSelected,
  handleClick,
  text,
  subTitle,
  ...rest
}) => {
  return (
    <Flex
      align="center"
      p="24px"
      bg="white"
      borderRadius="16px"
      border="1.5px solid #CBD5E0"
      cursor="pointer"
      aria-selected={isSelected}
      _selected={{ border: `2px solid #0e62bc ` }}
      onClick={handleClick}
      gap="16px"
    >
      <Flex align="center" flexDir="column">
        <Text fontWeight={600} fontSize="18px">
          {text}
        </Text>
        {subTitle && <Text color="gray.500">{subTitle}</Text>}
      </Flex>
      <Flex
        borderRadius="50%"
        boxSize="20px"
        border="1px solid #E2E8F0"
        aria-selected={isSelected}
        _selected={{ border: `6px solid #0e62bc ` }}
        onClick={handleClick}
      />
    </Flex>
  );
};

export default ButtonRadioLaunchLesson;
