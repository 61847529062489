import { createStandaloneToast } from "@chakra-ui/react";
import theme from "../../../styles/theme";
import { AxiosResponse } from "axios";
import { call, put, select } from "redux-saga/effects";
import api from "../../../services/api";
import { AnyAction } from "redux";
import {
  ActivityIdFilter,
  TalkingBook,
} from "../../../@types/interfaces/api/talking-book.interface";
import {
  loadTalkingBookError,
  loadTalkingBookIDError,
  loadTalkingBookIDSuccess,
  loadTalkingBookSuccess,
  postTalkingBookError,
  postTalkingBookSuccess,
} from "./actions";
import { ApplicationState, getGlobalState } from "..";
import { postProgress } from "../word-recognition/sagas";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";

const { toast } = createStandaloneToast({ theme });

export function* loadTalkingBook(action: AnyAction) {
  const filter: ActivityIdFilter = action.payload;
  try {
    const response: AxiosResponse<PaginationBase<TalkingBook>> = yield call(
      api.get,
      `/talking-book`,
      {
        params: filter,
      }
    );
    if (response.status == 200) {
      yield put(loadTalkingBookSuccess(response.data));
    }
  } catch (e: any) {
    yield put(loadTalkingBookError());
  }
}

export function* loadTalkingBookId(action: AnyAction) {
  const id: ActivityIdFilter = action.payload;
  try {
    const response: AxiosResponse<TalkingBook> = yield call(
      api.get,
      `/talking-book/${id}`
    );
    if (response.status == 200) {
      yield put(loadTalkingBookIDSuccess(response.data));
    }
  } catch (e: any) {
    yield put(loadTalkingBookIDError());
  }
}
export function* postTalkingBook(action: AnyAction) {
  const setPage = action.payload.setPage;
  const data: ApplicationState = yield select(getGlobalState);
  const timeLesson = Date.now() - data.talkingBook.dateMileseconds;
  const activity = data.talkingBook.book?.results[0].activity;
  const dataHitory = {
    time: timeLesson,
    student: data.auth.student?.user,
  };
  try {
    const response: AxiosResponse = yield call(
      api.post,
      "/history",
      dataHitory
    );

    if (response.status === 201) {
      yield put(postTalkingBookSuccess());
      yield call(postProgress, activity);
    }
  } catch (error) {
    yield put(postTalkingBookError());
  }
}
