import { createStandaloneToast } from "@chakra-ui/react";

import theme from "../../../styles/theme";
import axios, { AxiosResponse } from "axios";
import { call, put, select } from "redux-saga/effects";
import api from "../../../services/api";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import { AnyAction } from "redux";
import store, { ApplicationState, getGlobalState } from "..";
import {
  HistoryRecognition,
  HistoryRecognitionResponse,
} from "../../../@types/interfaces/api/word-recognition.interface";
import { postProgress } from "../word-recognition/sagas";
import {
  loadUnitReviewComprehensionError,
  loadUnitReviewComprehensionSuccess,
  postAnswerUnitReviewComprehensionError,
  postAnswerUnitReviewComprehensionSuccess,
} from "./actions";
import UnitReviewComprehensionFilterInterface, {
  IUnitReviewComprehension,
} from "../../../@types/interfaces/api/unit-review-reading-comprehension";

const { toast } = createStandaloneToast({ theme });

export function* loadQuestionUnitReviewComprehension(action: AnyAction) {
  const filters: UnitReviewComprehensionFilterInterface = action.payload;
  try {
    const response: AxiosResponse<PaginationBase<IUnitReviewComprehension[]>> =
      yield call(api.get, `/unit-review-reading-comprehension`, {
        params: filters,
      });
    if (response.status == 200) {
      yield put(loadUnitReviewComprehensionSuccess(response.data.results[0]));
    }
  } catch (e: any) {
    yield put(loadUnitReviewComprehensionError());
  }
}

export function* postHistoryUnitReview() {
  const data: ApplicationState = yield select(getGlobalState);

  const timeLesson =
    Date.now() - data.unitReviewReadingCcomprehension.dateMileseconds;

  const dataHitory: HistoryRecognition = {
    time: timeLesson,
    student: data.auth.student?.user,
  };

  try {
    const response: AxiosResponse = yield call(
      api.post,
      "/history",
      dataHitory
    );
    if (response.status == 201) {
      return response.data;
    }
  } catch (e: any) {
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* postAnswerUnitReviewComprehension(action: AnyAction) {
  const data: ApplicationState = yield select(getGlobalState);
  const setPage = action.payload.setPage;
  const activity =
    data.unitReviewReadingCcomprehension.unitReviewComprehensionData?.activity;

  try {
    const responseHistory: HistoryRecognitionResponse = yield call(
      postHistoryUnitReview
    );

    const completeData = data.unitReviewReadingCcomprehension.answers.map(
      (answer) => {
        return { ...answer, history: responseHistory.id };
      }
    );

    const response: AxiosResponse = yield call(
      api.post,
      "/unit-review-reading-comprehension/answer",
      completeData
    );
    if (response.status === 201) {
      yield put(postAnswerUnitReviewComprehensionSuccess());
      yield call(postProgress, activity);
      setPage("greatJob");
    }
  } catch (e: any) {
    yield put(postAnswerUnitReviewComprehensionError());
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}
