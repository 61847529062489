import {
  Flex,
  Heading,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import OutlineButton from "../global/OutlineButton";
import Ximage from "../../assets/image/x-circle.png";
import RegularButton from "../global/RegularButton";
import { useNavigate } from "react-router-dom";

interface ModalPrintAccessProps {
  onClose(): void;
  isOpen: boolean;
}

const ModalCloseLesson: React.FC<ModalPrintAccessProps> = ({
  onClose,
  isOpen,
}) => {
  const navigate = useNavigate();
  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent
        p={["24px", "32px"]}
        w={["303px", "403px"]}
        textAlign="center"
        alignItems="center"
        borderRadius="16px"
        mt="14%"
      >
        <Image boxSize="80px" src={Ximage} />
        <Heading fontSize="24px" fontWeight="500" color="gray.700" mt="16px">
          Close Lesson?
        </Heading>

        <Flex textAlign="center" mt="16px">
          <Text
            fontWeight="400"
            fontSize="16px"
            lineHeight="150%"
            color="gray.500"
          >
            Close lesson and go back to the dashboard? If you close, all of your
            progress will lost.
          </Text>
        </Flex>
        <Flex justifyContent="space-between" mt="32px" w="full" gap="10px">
          <OutlineButton onClick={onClose} w="169px">
            Cancel
          </OutlineButton>
          <RegularButton
            w="169px"
            bgColor="#E53E3E"
            _hover={{}}
            onClick={() => window.location.reload()}
          >
            Close
          </RegularButton>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default ModalCloseLesson;
