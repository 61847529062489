import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ContainerLesson from "../../components/global/ContainerLesson";
import PageGrid from "../../components/global/PageGrid";
import ProgressBar from "../../components/global/ProgressBar";
import FillTheGap from "../../components/lessons/FillTheGap";
import DonNotStop from "../../components/lessons/common/DoNotStop";
import GreatJob from "../../components/lessons/common/GreatJob";
import StartLesson from "../../components/lessons/common/StartLesson";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { usePages } from "../../hooks/usePages";
import { useLanguageContext } from "../../providers/LanguageProvider";
import {
  clearDiagnosticList,
  loadQuestionDiagnosticRequest,
  onNexDiagnostic,
  onPreviousDiagnostic,
  setAlternativeDiagnostic,
  setTimerDiagnostic,
} from "../../stores/ducks/diagnostic/actions";
import { assetsBaseUrl } from "../../utils/assets-helper";

const DiagnosticPrescriptive = () => {
  const dispatch = useAppDispatch();
  const {
    questions,
    questionIndex,
    alternativeDiagnostic,
    pageNumber,
    additional,
  } = useAppSelector((item) => item.diagnostic);
  const { language } = useLanguageContext();

  const navigate = useNavigate();

  const questionsArray = questions?.results;
  const setAlternativeFn = (alternative: string) => {
    dispatch(setAlternativeDiagnostic(alternative));
  };

  useEffect(() => {
    dispatch(
      loadQuestionDiagnosticRequest({
        is_active__exact: "true",
        level__id__exact: 1,
      })
    );
    dispatch(setTimerDiagnostic(Date.now()));

    return () => {
      dispatch(clearDiagnosticList());
    };
  }, []);

  const audio =
    language === "ENG"
      ? `${assetsBaseUrl()}/app-2.0/dp2_html5/audio/eng/dirsSilent.mp3`
      : `${assetsBaseUrl()}/app-2.0/dp2_html5/audio/span/dirsSilent.mp3`;

  const { page, setPage, pageName } = usePages<
    | "firstScreen"
    | "start"
    // | "language"
    | "question"
    | "do_not_stop"
    | "greatJob"
  >({
    initialPage: "firstScreen",

    pages: {
      firstScreen: (
        <StartLesson
          title="Failure Free Reading Lesson"
          onNext={() => setPage("start")}
          button="Next"
          subTitle="DIAGNOSTIC PRESCRIPTIVE"
          videoExplanationUrl={`${assetsBaseUrl()}/app-2.0/dp2_html5/vids/${language}/video.mp4`}
        />
      ),
      start: (
        <StartLesson
          title="Diagnostic Prescriptive"
          onNext={() => setPage("question")}
          button="start diagnostic"
          titleHelp="(Speakers are NOT needed for this part.)"
        />
      ),
      // language: <Language onNext={() => setPage("question")} />,

      question: questionsArray && (
        <FillTheGap
          question={questionsArray[questionIndex].question}
          options={questionsArray[questionIndex].possible_answers}
          setAlternativeFn={setAlternativeFn}
          alternativeData={alternativeDiagnostic}
          audioIntro={audio}
        />
      ),
      do_not_stop: (
        <DonNotStop
          onNext={() => {
            dispatch(
              loadQuestionDiagnosticRequest({
                is_active__exact: "true",
                level__id__exact: pageNumber,
                aditional__exact: additional,
              })
            );

            pageNumber >= 12
              ? navigate("/lesson/pre-test-verbal-master")
              : setPage("question");
          }}
        />
      ),

      greatJob: (
        <GreatJob
          onNext={
            questions && questions?.results[0].level >= 9
              ? () => navigate("/lesson/pre-test-verbal-master")
              : () => navigate("/lesson/pre-test")
          }
        />
      ),
    },
  });

  return (
    <PageGrid
      activity="Diagnostic Prescriptive"
      onHelp={() => console.log("help")}
      disabledButton
    >
      <ContainerLesson>
        {page}
        {/* <SignLanguage />
        <SubtitleComponent /> */}
        {questionsArray && (
          <ProgressBar
            hide={pageName !== "question"}
            data={{ max: questionsArray?.length, step: questionIndex + 1 }}
            onConfirm={() => dispatch(onNexDiagnostic(setPage))}
            onPrevious={() => dispatch(onPreviousDiagnostic())}
            hideButton={alternativeDiagnostic === ""}
            // hideButtonPrevious={true}
          />
        )}
      </ContainerLesson>
    </PageGrid>
  );
};

export default DiagnosticPrescriptive;
