import store from "..";
import AnyAction from "redux";
import { UnitReviewFillTheBlankActionTypes } from "./type";
import UnitReviewFillTheBlankFilterInterface from "../../../@types/interfaces/api/unit-review-definiton.interface";
import {
  AnswerUnitReviewFillheBlank,
  IParagraphParts,
  IQuestionFillTheBlank,
  IUnitReviewFillTheBlank,
} from "../../../@types/interfaces/api/unit-review-fill-the-blank.interface";

export const loadUnitReviewFillTheBlankRequest = (
  filter: UnitReviewFillTheBlankFilterInterface
) => ({
  type: UnitReviewFillTheBlankActionTypes.LOAD_QUESTION_UNIT_REVIEW_FILL_THE_BLANK_REQUEST,
  payload: filter,
});

export const loadUnitReviewFillTheBlankSuccess = (
  data: IUnitReviewFillTheBlank
) => {
  function modifyText(
    paragraphParts: IParagraphParts[]
  ): { text: string; id: number }[] {
    return paragraphParts.map((part) => {
      const words = part.text.split(" ");
      words[part.word_index_mark - 1] = "_____";
      return {
        text: words.join(" "),
        id: part.id,
      };
    });
  }

  const questions = modifyText(data.paragraph_parts ?? []);

  const possibleAnswers = data?.words.map((answer) => answer.word);
  const shuffledAnswers = possibleAnswers
    .slice()
    .sort(() => Math.random() - 0.5);

  const questionModify: IQuestionFillTheBlank[] = questions.map((q, index) => {
    const wordIndexMark = data.paragraph_parts[index].word_index_mark;
    const answer = data.paragraph_parts[index].text
      .split(" ")
      [wordIndexMark - 1].replace(/[.,]/g, "");

    return {
      question: q.text,
      possible_answers: shuffledAnswers,
      answer,
      id: q.id,
    };
  });

  return {
    type: UnitReviewFillTheBlankActionTypes.LOAD_QUESTION_UNIT_REVIEW_FILL_THE_BLANK_SUCCESS,
    payload: { data, questionModify },
  };
};
export const loadUnitReviewFillTheBlankError = () => ({
  type: UnitReviewFillTheBlankActionTypes.LOAD_QUESTION_UNIT_REVIEW_FILL_THE_BLANK_ERROR,
});

export const clearUnitReviewFillTheBlankList = () => {
  return {
    type: UnitReviewFillTheBlankActionTypes.CLEAR_LIST,
  };
};
export const setAlternativeFillTheBlank = (alternative: string) => {
  return {
    type: UnitReviewFillTheBlankActionTypes.SET_ALTERNATIVE_UNIT_REVIEW_FILL_THE_BLANK,
    payload: alternative,
  };
};
export const setFullAlternativeFillTheBlank = (alternative: string) => {
  return {
    type: UnitReviewFillTheBlankActionTypes.SET_FULL_ALTERNATIVE_UNIT_REVIEW_FILL_THE_BLANK,
    payload: alternative,
  };
};

export const setAnswerUnitReviewFillTheBlank = () => {
  const contentListAnswer = store.getState().unitReviewFillTheBlank;
  const question =
    contentListAnswer.questionsModified[contentListAnswer.questionIndex].id;
  const fullAlternative =
    contentListAnswer.fullAlternativeUnitReviewFillTheBlank;
  const contentAnswer = {
    answer: fullAlternative,
    question,
    history: 0,
  };
  return {
    type: UnitReviewFillTheBlankActionTypes.SET_ANSWER_UNIT_REVIEW_FILL_THE_BLANK,
    payload: contentAnswer,
  };
};

export const onNextUnitReviewFillTheBlank = (setPage: (page: any) => void) => {
  const contentListAnswer = store.getState().unitReviewFillTheBlank;

  if (
    contentListAnswer.questionsModified &&
    contentListAnswer.questionIndex <
      contentListAnswer.questionsModified.length - 1
  ) {
    return {
      type: UnitReviewFillTheBlankActionTypes.ON_NEXT,
      payload: { setPage },
    };
  } else {
    setPage("greatJob");

    return {
      type: UnitReviewFillTheBlankActionTypes.POST_ANSWER_UNIT_REVIEW_FILL_THE_BLANK_REQUEST,
      payload: { setPage },
    };
  }
};

export const postAnswerUnitReviewFillTheBlankRequest = (
  data: AnswerUnitReviewFillheBlank[]
) => {
  return {
    type: UnitReviewFillTheBlankActionTypes.POST_ANSWER_UNIT_REVIEW_FILL_THE_BLANK_REQUEST,
    payload: data,
  };
};

export const postAnswerUnitReviewFillTheBlankSuccess = () => ({
  type: UnitReviewFillTheBlankActionTypes.POST_ANSWER_UNIT_REVIEW_FILL_THE_BLANK_SUCCESS,
});
export const postAnswerUnitReviewFillTheBlankError = () => ({
  type: UnitReviewFillTheBlankActionTypes.POST_ANSWER_UNIT_REVIEW_FILL_THE_BLANK_ERROR,
});

export const setTimerUnitReviewFillTheBlank = (date: number) => {
  return {
    type: UnitReviewFillTheBlankActionTypes.SET_TIMER_UNIT_REVIEW_FILL_THE_BLANK,
    payload: date,
  };
};
// export const onPreviousUnitReviewFillTheBlank = () => {
//   const contentListAnswer = store.getState().wordVm;
//   const answerArray = contentListAnswer.answers;

//   answerArray.pop();

//   return {
//     type: UnitReviewFillTheBlankActionTypes.ON_PREVIOUS,
//     payload: answerArray,
//   };
// };
