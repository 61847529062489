import store from "..";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import {
  AnswerRC,
  IReadingComprehension,
} from "../../../@types/interfaces/api/reading-comprehension";

import { ActivityIdFilter } from "../../../@types/interfaces/api/talking-book.interface";
import { ReadingComprehensionActionTypes } from "./type";

export const loadRComprehensionRequest = (filter: ActivityIdFilter) => ({
  type: ReadingComprehensionActionTypes.LOAD_RCOMPREHENSION_REQUEST,
  payload: filter,
});

export const loadRComprehensionSuccess = (data: IReadingComprehension) => ({
  type: ReadingComprehensionActionTypes.LOAD_RCOMPREHENSION_SUCCESS,
  payload: data,
});

export const loadRComprehensionError = () => ({
  type: ReadingComprehensionActionTypes.LOAD_RCOMPREHENSION_ERROR,
});

export const setAlternativeRC = (alternative: string) => {
  return {
    type: ReadingComprehensionActionTypes.SET_ALTERNATIVE_RC,
    payload: alternative,
  };
};

export const clearRComprehensionList = () => {
  return {
    type: ReadingComprehensionActionTypes.CLEAR_LIST,
  };
};

export const onPreviousRComprehension = () => {
  const contentListAnswer = store.getState().readingComprehension;
  const answerArray = contentListAnswer.answersRC;

  answerArray.pop();
  return {
    type: ReadingComprehensionActionTypes.ON_PREIVIOUS_RC,
  };
};

export const onNextRComprehension = (setPage: (page: any) => void) => {
  const contentListAnswer = store.getState().readingComprehension;

  const chosenAnswer = contentListAnswer.alternativeRC;
  const questionId =
    contentListAnswer.readingComprehensionData?.questions[
      contentListAnswer.questionIndex
    ].id;

  const contentAnswer: AnswerRC = {
    answer: chosenAnswer,
    question: questionId,
    history: 0,
  };

  if (
    contentListAnswer.readingComprehensionData &&
    contentListAnswer.questionIndex <
      contentListAnswer.readingComprehensionData.questions.length - 1
  ) {
    return {
      type: ReadingComprehensionActionTypes.ON_NEXT_RC,
      payload: { contentAnswer, setPage },
    };
  } else {
    setPage("greatJob");
    return {
      type: ReadingComprehensionActionTypes.POST_RCOMPREHENSION_REQUEST,
      payload: { contentAnswer, setPage },
    };
  }
};

export const setAnswerRComprehension = () => {
  const contentListAnswer = store.getState().readingComprehension;

  const chosenAnswer = contentListAnswer.alternativeRC;
  const questionId =
    contentListAnswer.readingComprehensionData?.questions[
      contentListAnswer.questionIndex
    ].id;

  const contentAnswer: AnswerRC = {
    answer: chosenAnswer,
    question: questionId,
    history: 0,
  };

  return {
    type: ReadingComprehensionActionTypes.SET_ANSWER_RC,
    payload: contentAnswer,
  };
};

export const postAnswerRComprehensionRequest = (data: AnswerRC[]) => ({
  type: ReadingComprehensionActionTypes.POST_RCOMPREHENSION_REQUEST,
  payload: data,
});

export const postAnswerRComprehensionSuccess = () => ({
  type: ReadingComprehensionActionTypes.POST_RCOMPREHENSION_SUCCESS,
});
export const postAnswerRComprehensionError = () => ({
  type: ReadingComprehensionActionTypes.POST_RCOMPREHENSION_ERROR,
});

export const setTimerRComprehension = (date: number) => ({
  type: ReadingComprehensionActionTypes.SET_TIMER_RC,
  payload: date,
});
