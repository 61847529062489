import { Button, Flex, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import PlayIcon from "../../../assets/icons/PlayIcon";
import RegularButton from "../../global/RegularButton";
import Subtitle from "../../global/text/Subtitle";
import Text32px from "../../global/text/Text32px";
import { useLanguageContext } from "../../../providers/LanguageProvider";
import { useAccesibiltyContext } from "../../../providers/AccessibilityController";

type DataType = {
  text: string;
  audio: string;
};
export type ShowSynonymDefinitionData = {
  word: DataType;
  definition: DataType;
  synonym: DataType;
};
interface Props {
  data: ShowSynonymDefinitionData;
  onNext: () => void;
}
function ShowSynonymDefinition({ data, onNext }: Props) {
  const [disabled, setDisabled] = useState(true);
  const { language } = useLanguageContext();
  const { onPlayAudio } = useAccesibiltyContext();
  useEffect(() => {
    async function play() {
      await onPlayAudio([
        data.word.audio,
        data.synonym.audio,
        data.definition.audio,
      ]);
      setDisabled(false);
    }

    play();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex flexDir={"column"} alignItems="center">
      <Subtitle>VOCABULARY BUILDER</Subtitle>
      <Flex align="center" mt="24px">
        <Text32px>{data.word.text}</Text32px>{" "}
        <PlayIcon
          ml="10px"
          onClick={() => onPlayAudio(data.word.audio)}
          disabled={disabled}
        />
      </Flex>

      <Flex
        gap="16px"
        align="center"
        justify="center"
        mt="56px"
        flexDir={["column", "row"]}
      >
        <PlayIcon
          onClick={() =>
            onPlayAudio([data.synonym.audio, data.definition.audio])
          }
          disabled={disabled}
        />
        <Flex
          minW={["300px", "500px"]}
          p="24px"
          bg="white"
          borderRadius="16px"
          border="1.5px solid #CBD5E0"
          flexDir="column"
        >
          <Text fontSize="20px" color="gray.500">
            Synonym
          </Text>
          <Text fontSize="20px" fontWeight="500">
            {data.synonym.text}
          </Text>
          <Text fontSize="20px" color="gray.500" mt="24px">
            Definition
          </Text>
          <Text fontSize="20px" fontWeight="500">
            {data.definition.text}
          </Text>
        </Flex>
      </Flex>

      <RegularButton mt="56px" disabled={disabled} onClick={onNext}>
        Continue
      </RegularButton>
    </Flex>
  );
}

export default ShowSynonymDefinition;
