import { createStandaloneToast } from "@chakra-ui/react";
import theme from "../../../styles/theme";
import axios, { AxiosResponse } from "axios";
import { call, put, select } from "redux-saga/effects";
import api from "../../../services/api";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import { AnyAction } from "redux";
import store, { ApplicationState, getGlobalState } from "..";
import {
  PreTestFilterInterface,
  IPretest,
  DiagnosticData2,
} from "../../../@types/interfaces/api/pre-test.interface";
import {
  loadPreTestError,
  loadPreTestSuccess,
  postAnswerPreTestError,
  postAnswerPreTestLifeSkillsError,
  postAnswerPreTestLifeSkillsSuccess,
  postAnswerPreTestSuccess,
} from "./actions";
import {
  HistoryRecognition,
  HistoryRecognitionResponse,
} from "../../../@types/interfaces/api/word-recognition.interface";
import { loadStudent } from "../auth/sagas";
import { postProgress } from "../word-recognition/sagas";
import { loadActivity } from "../dashboard/sagas";

const { toast } = createStandaloneToast({ theme });

export function* loadQuestionsPreTest(action: AnyAction) {
  const filters: PreTestFilterInterface = action.payload;
  try {
    const response: AxiosResponse<PaginationBase<IPretest>> = yield call(
      api.get,
      `/pre-test`,
      {
        params: filters,
      }
    );
    if (response.status == 200) {
      yield put(loadPreTestSuccess(response.data));
    }
  } catch (e: any) {
    yield put(loadPreTestError());
  }
}

export function* postHistoryPreTest() {
  const data: ApplicationState = yield select(getGlobalState);

  const timeLesson = Date.now() - data.preTest.dateMileseconds;

  const dataHitory: HistoryRecognition = {
    time: timeLesson,
    student: data.auth.student?.user,
  };

  try {
    const response: AxiosResponse = yield call(
      api.post,
      "/history",
      dataHitory
    );
    if (response.status == 201) {
      return response.data;
    }
  } catch (e: any) {
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* postDiagnostic2History(historyId: number, isVM = false) {
  const data: ApplicationState = yield select(getGlobalState);
  const pageNumber = data.preTest.pageNumber;
  const order = data.preTest.storyOrder;
  let additional: string = "";

  if (pageNumber === 3 && order <= 5) {
    additional = "orangea";
  } else if (pageNumber === 3 && order >= 6) {
    additional = "orangeb";
  } else if (pageNumber === 4 && order <= 3) {
    additional = "teal1";
  } else if (pageNumber === 4 && order <= 5) {
    additional = "teal2";
  } else if (pageNumber === 4 && order >= 6) {
    additional = "teal3";
  } else {
    additional = "";
  }

  const dataHitory: DiagnosticData2 = {
    grade: data.auth.student?.grade ?? "",
    pre_test_type: isVM ? "vm-pre-test" : "pre-test",
    student: data.auth.student?.user,
    pre_test_history: historyId,
    level: isVM ? 9 : data.preTest.pageNumber,
    additional,
  };

  try {
    const response: AxiosResponse = yield call(
      api.post,
      "/diagnostic/history2",
      dataHitory
    );
    if (response.status == 201) {
      return response.data;
    }
  } catch (e: any) {
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* postAnswerPreTest(action: AnyAction) {
  const data: ApplicationState = yield select(getGlobalState);
  const setPage = action.payload.setPage;
  const activity = data.preTest.preTestData?.results[0].activity;
  const student = data.auth.user?.id;
  const studentActivity = data.auth.student?.progress.activity;
  const wrongAnswer = data.preTest.numberOfWrongAnswers;
  const pageNumber = data.preTest.pageNumber;
  const user = data.auth.student;

  try {
    const responseHistory: HistoryRecognitionResponse = yield call(
      postHistoryPreTest
    );

    const completeData = data.preTest.answers.map((answer) => {
      return { ...answer, history: responseHistory.id };
    });

    const response: AxiosResponse = yield call(
      api.post,
      "/pre-test/answer",
      completeData
    );
    if (response.status === 201) {
      yield put(postAnswerPreTestSuccess());
      if (student) {
        yield call(loadStudent, student);
      }
      if (wrongAnswer >= 4 || user?.progress.activity) {
        setPage("greatJob");
        if (user?.progress.activity === null) {
          yield call(postDiagnostic2History, responseHistory.id);
        }
        yield call(postProgress, activity);
        yield call(loadActivity, action);
      } else {
        if (pageNumber > 4) {
          setPage("greatJob");
        } else {
          setPage("do_not_stop");
        }
      }
    }
  } catch (e: any) {
    yield put(postAnswerPreTestError());
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* postAnswerPreTestLifeSkills(action: AnyAction) {
  const data: ApplicationState = yield select(getGlobalState);
  const setPage = action.payload.setPage;
  const activity = data.preTest.preTestData?.results[0].activity;
  const student = data.auth.user?.id;

  try {
    const responseHistory: HistoryRecognitionResponse = yield call(
      postHistoryPreTest
    );

    const completeData = data.preTest.answers.map((answer) => {
      return { ...answer, history: responseHistory.id };
    });

    const response: AxiosResponse = yield call(
      api.post,
      "/pre-test/answer",
      completeData
    );
    if (response.status === 201) {
      yield put(postAnswerPreTestLifeSkillsSuccess());
      setPage("greatJob");
      yield call(postProgress, activity);
      yield call(loadActivity, action);
      if (student) {
        yield call(loadStudent, student);
      }
    }
  } catch (e: any) {
    yield put(postAnswerPreTestLifeSkillsError());
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}
