import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import {
  IScrambled,
  IScrambledAnswerPost,
} from "../../../@types/interfaces/api/scrambled-sentence.interface";

export enum ScrambledSentenceActionTypes {
  LOAD_SCRAMBLED_REQUEST = "@scrambled/LOAD_SCRAMBLED_REQUEST",
  LOAD_SCRAMBLED_SUCCESS = "@scrambled/LOAD_SCRAMBLED_SUCCESS",
  LOAD_SCRAMBLED_ERROR = "@scrambled/LOAD_SCRAMBLED_ERROR",

  POST_SCRAMBLED_REQUEST = "@scrambled/POST_SCRAMBLED_REQUEST",
  POST_SCRAMBLED_SUCCESS = "@scrambled/POST_SCRAMBLED_SUCCESS",
  POST_SCRAMBLED_ERROR = "@scrambled/POST_SCRAMBLED_ERROR",

  SET_ANSWER_SCRAMBLED = "@scrambled/ SET_ANSWER_SCRAMBLED",
  CLEAR_LIST_SCRAMBLED = "@scrambled/ CLEAR_LIST_SCRAMBLED",
  SET_TIMER_SCRAMBLED = "@scrambled/ SET_TIMER_SCRAMBLED",
  SET_WRONG_ANSWER_SCRAMBLED = "@scrambled/ SET_WRONG_ANSWER_SCRAMBLED",
}
export interface ScrambledSentenceState
  extends ScrambledSentenceStateRequestsStatus {
  scrambledData: PaginationBase<IScrambled[]> | undefined;
  answerScrambled: IScrambledAnswerPost[];
  wrongAnswer: number;
  dateMileseconds: number;
}

interface ScrambledSentenceStateRequestsStatus {
  isLoading: boolean;
}
