import {
  ISpellingQuestion,
  StoryFeed,
} from "../../../@types/interfaces/api/pre-teaching.interface";
import {
  AnswerVocabularyBuilder,
  IVocabularyBuilder,
  IWordFeedOgg,
  alternativeVocabulary,
} from "../../../@types/interfaces/api/vocabulary-builder.interface";
import {
  AnswerWordVm,
  IWordFeed,
  IWordVm,
} from "../../../@types/interfaces/api/word-vm.interface";

export enum VocabularyBuilderActionTypes {
  LOAD_VOCABULRY_BUILDER_REQUEST = "@vocabularyBuilder/LOAD_VOCABULRY_BUILDER_REQUEST",
  LOAD_VOCABULRY_BUILDER_SUCCESS = "@vocabularyBuilder/LOAD_VOCABULRY_BUILDER_SUCCESS",
  LOAD_VOCABULRY_BUILDER_ERROR = "@vocabularyBuilder/LOAD_VOCABULRY_BUILDER_ERROR",

  POST_ANSWER_VOCABULARY_BUILDER_REQUEST = "@vocabularyBuilder/POST_ANSWER_VOCABULARY_BUILDER_REQUEST",
  POST_ANSWER_VOCABULARY_BUILDER_SUCCESS = "@vocabularyBuilder/POST_ANSWER_VOCABULARY_BUILDER_SUCCESS",
  POST_ANSWER_VOCABULARY_BUILDER_ERROR = "@vocabularyBuilder/POST_ANSWER_VOCABULARY_BUILDER_ERROR",

  SET_ALTERNATIVE_VOCABULARY_BUILDER = "@vocabularyBuilder/ SET_ALTERNATIVE_VOCABULARY_BUILDER",
  SET_TIMER_VOCABULARY_BUILDER = "@vocabularyBuilder/ SET_TIMER_VOCABULARY_BUILDER",
  SET_TEXT_VOCABULARY_BUILDER = "@vocabularyBuilder/ SET_TEXT_VOCABULARY_BUILDER",
  GET_WORD_VOCABULARY_BUILDER = "@vocabularyBuilder/ GET_WORD_VOCABULARY_BUILDER",
  LEARNED_WORD_VOCABULARY_BUILDER = "@vocabularyBuilder/LEARNED_WORD_VOCABULARY_BUILDER",
  ON_NEXT = "@vocabularyBuilder/ ON_NEXT",
  ON_PREVIOUS = "@vocabularyBuilder/ PREVIOUS",
  CLEAR_LIST = "@vocabularyBuilder/CLEAR_LIST",
}

export interface VocabularyBuilderState
  extends VocabularyBuilderStateRequestsStatus {
  vocabularyData: IVocabularyBuilder | undefined;
  answers: AnswerVocabularyBuilder[];
  alternativeVocabulary: alternativeVocabulary | undefined;
  questionIndex: number;
  dateMileseconds: number;
  wordFeedData: IWordFeedOgg | undefined;
  spelling: StoryFeed[];
  spellingQuestionData: ISpellingQuestion | undefined;
  learnedWords: number[];
  textVocabulary: "";
}

interface VocabularyBuilderStateRequestsStatus {
  isLoading: boolean;
}
