import { Button } from "@chakra-ui/button";
import { Flex, Heading, Text } from "@chakra-ui/layout";
import { ArrowLeft, ArrowRight, RefreshCw, SkipForward } from "lucide-react";
import { useEffect, useState } from "react";
import PlayIcon from "../../assets/icons/PlayIcon";
import { AudioMedia } from "../../hooks/AudioMedia";
import ButtonRadio from "../global/ButtonRadio";
import OutlineButton from "../global/OutlineButton";
import RegularButton from "../global/RegularButton";
import Subtitle from "../global/text/Subtitle";
import Text32px from "../global/text/Text32px";
import { SpellingWordsType } from "../../@types/interfaces/api/spelling-program.interface";
import { assetsBaseUrl } from "../../utils/assets-helper";
import { useLanguageContext } from "../../providers/LanguageProvider";
import { useAccesibiltyContext } from "../../providers/AccessibilityController";

interface Props {
  data: SpellingWordsType[];
  audiosIntro: string[];
  onNext: (word: SpellingWordsType) => void;
  setAlternative: (word: SpellingWordsType) => void;
  postActivity: () => void;
  learnedWords: number[];
}

function SpellingWords({
  data,
  onNext,
  setAlternative,
  audiosIntro,
  postActivity,
  learnedWords,
}: Props) {
  const [selected, setSelected] = useState<SpellingWordsType | null>(null);
  const [selectedAnswer, setSelecteddAnswer] = useState<number | null>(null);
  const [indexWords, setIndexWords] = useState(0);
  const { language } = useLanguageContext();
  const { playMedias } = useAccesibiltyContext();

  useEffect(() => {
    playMedias(audiosIntro);
    return () => {
      AudioMedia.pauseSequence();
    };
  }, []);

  useEffect(() => {
    if (!selected) return;
    playMedias([
      assetsBaseUrl() + selected.audio,
      `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/common_ogg/${
        selected.text
      }.ogg`,
    ]);

    return () => {
      AudioMedia.pauseSequence();
    };
  }, [selected]);

  const splitArray = (
    array: SpellingWordsType[],
    size: number
  ): SpellingWordsType[][] => {
    const setWords: SpellingWordsType[][] = [];
    for (let i = 0; i < array.length; i += size) {
      setWords.push(array.slice(i, i + size));
    }
    return setWords;
  };
  const dataSplited = splitArray(data, 7);

  return (
    <Flex gap={2} flexDir="column" align="center">
      <Subtitle>Click on any word you would like to spell.</Subtitle>
      <Text32px mt="16px">Spelling Program</Text32px>

      <Flex gap="16px" maxW="1500px" flexWrap="wrap" justify="center" mt="56px">
        {dataSplited[indexWords].map((word, index) => {
          return (
            <ButtonRadio
              handleClick={() => {
                setSelecteddAnswer(index);
                setSelected(word);
                setAlternative(word);
              }}
              disabled={learnedWords.includes(word.id)}
              inPlaying={learnedWords.includes(word.id)}
              isSelected={selectedAnswer === index}
              text={word.text}
              key={index}
            />
          );
        })}
      </Flex>

      <Flex w="full" justifyContent="space-evenly" align="center">
        <OutlineButton
          mt="32px"
          color="gray.700"
          leftIcon={<ArrowLeft size="16px" />}
          disabled={indexWords === 0}
          onClick={() => {
            if (indexWords > 0) {
              setIndexWords((prev) => prev - 1);
              setSelecteddAnswer(null);
            }
          }}
        >
          Previous words
        </OutlineButton>
        <Subtitle mt="32px">
          {indexWords + 1} / {dataSplited.length}
        </Subtitle>
        <OutlineButton
          mt="32px"
          color="gray.700"
          rightIcon={<ArrowRight size="16px" />}
          disabled={indexWords === dataSplited.length - 1}
          onClick={() => {
            if (indexWords < dataSplited.length - 1) {
              setIndexWords((prev) => prev + 1);
              setSelecteddAnswer(null);
            }
          }}
        >
          Next words
        </OutlineButton>
      </Flex>

      <Flex w="full" align="center" justify="center" gap="16px">
        {selected && (
          <Flex align="center" gap="24px" mt="60px" flexDir={["column", "row"]}>
            <PlayIcon
              onClick={() =>
                playMedias([
                  assetsBaseUrl() + selected.audio,
                  `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/common_ogg/${
                    selected.text
                  }.ogg`,
                ])
              }
            >
              play
            </PlayIcon>
            <Flex
              p="24px"
              bg="white"
              borderRadius="16px"
              border="1.5px solid #CBD5E0"
            >
              <Text32px color="primary">{selected.text}</Text32px>
            </Flex>

            <RegularButton
              rightIcon={<ArrowRight size="16px" />}
              onClick={() => onNext(selected)}
            >
              Next
            </RegularButton>
          </Flex>
        )}
        <OutlineButton
          mt="60px"
          color="gray.700"
          rightIcon={<SkipForward size="16px" />}
          onClick={postActivity}
        >
          Finish Lesson
        </OutlineButton>
      </Flex>
    </Flex>
  );
}

export default SpellingWords;
