import { Reducer } from "redux";
import { WordVmActionTypes, WordVmState } from "./type";

const INITIAL_STATE: WordVmState = {
  isLoading: false,
  alternativeWordVm: "",
  answers: [],
  dateMileseconds: 0,
  questionIndex: 0,
  wordVmData: undefined,
  wordFeedData: undefined,
  spellingQuestionData: undefined,
  spelling: [],
};

const reducer: Reducer<WordVmState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WordVmActionTypes.LOAD_QUESTION_WORD_VM_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case WordVmActionTypes.LOAD_QUESTION_WORD_VM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        wordVmData: action.payload.data,
        wordFeedData: action.payload.feed,
        spelling: action.payload.feedSpelling,
        spellingQuestionData: action.payload.spellingQuestion,
        questionIndex: 0,
      };
    case WordVmActionTypes.LOAD_QUESTION_WORD_VM_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case WordVmActionTypes.CLEAR_LIST:
      return INITIAL_STATE;

    case WordVmActionTypes.SET_ALTERNATIVE_WORD_VM:
      return { ...state, alternativeWordVm: action.payload };

    case WordVmActionTypes.ON_NEXT:
      return {
        ...state,
        questionIndex: state.questionIndex + 1,
        alternativeWordVm: "",
        answers: [...state.answers, action.payload],
      };

    case WordVmActionTypes.POST_ANSWER_WORD_VM_REQUEST:
      return {
        ...state,
        alternativeWordVm: "",
        answers: [...state.answers, action.payload.contentAnswer],
        isLoading: true,
      };
    case WordVmActionTypes.POST_ANSWER_WORD_VM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        answers: [],
      };
    case WordVmActionTypes.POST_ANSWER_WORD_VM_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case WordVmActionTypes.ON_PREVIOUS:
      return {
        ...state,
        questionIndex: state.questionIndex - 1,
        alternativeWordVm: "",
        answers: action.payload,
      };

    case WordVmActionTypes.SET_TIMER_WORD_VM:
      return { ...state, dateMileseconds: action.payload };

    default:
      return state;
  }
};
export default reducer;
