import { all, takeLeading } from "redux-saga/effects";
import {
  loadAlphabetical,
  postAnswerAlphabetical,
} from "./alphabetical-order/sagas";
import { AlphabeticalOrderActionTypes } from "./alphabetical-order/type";
import { handleSignIn, loadAuthUser, loadStudent } from "./auth/sagas";
import { AuthActionTypes } from "./auth/type";
import {
  loadActivity,
  loadClassroom,
  loadNextActivity,
  loadNextLesson,
} from "./dashboard/sagas";
import { DashboardActionTypes } from "./dashboard/type";
import {
  loadQuestionsDiagnostic,
  postAnswerDiagnostic,
} from "./diagnostic/sagas";
import { DiagnosticActionTypes } from "./diagnostic/type";
import { loadDragFillId, postAnswerDrag } from "./drag-fill/sagas";
import { DragFillActionTypes } from "./drag-fill/type";
import { loadCurrentLesson, loadLessonById } from "./lesson/sagas";
import { LessonsActionTypes } from "./lesson/types";
import { loadMachingId, postAnswerMatching } from "./matching/sagas";
import { MatchingActionTypes } from "./matching/type";
import { loadPreTeaching, postAnswerPreTeaching } from "./pre-teaching/sagas";
import { PreTeachingActionTypes } from "./pre-teaching/type";
import {
  loadQuestionsPreTest,
  postAnswerPreTest,
  postAnswerPreTestLifeSkills,
} from "./pre-test/sagas";
import { PreTestActionTypes } from "./pre-test/type";
import { loadQuestions, postAnswerSaga } from "./ras/sagas";
import { RasActionTypes } from "./ras/type";
import {
  loadReinforcementId,
  postAnswerReinforcement,
  postAnswerReinforcementLifeSkills,
} from "./reinforcement-activities/sagas";
import { ReinforcementActivitiesActionTypes } from "./reinforcement-activities/type";
import {
  loadScrambledId,
  postAnswerScrambled,
} from "./scrambled-sentence/sagas";
import { ScrambledSentenceActionTypes } from "./scrambled-sentence/type";
import { loadShortAnswerId, postAnswerSA } from "./short-answer/sagas";
import { ShortAnswerActionTypes } from "./short-answer/type";
import {
  loadTalkingBook,
  loadTalkingBookId,
  postTalkingBook,
} from "./talking-book/sagas";
import { TalkingBookActionTypes } from "./talking-book/type";
import {
  loadWordRecognitionId,
  postAnswerRecognition,
} from "./word-recognition/sagas";
import { WordRecognitionActionTypes } from "./word-recognition/type";
import { ReadingComprehensionActionTypes } from "./reading-comprehension/type";
import {
  loadRComprehension,
  postAnswerRComprehension,
} from "./reading-comprehension/sagas";
import { PostTestActionTypes } from "./pos-test/type";
import { loadQuestionsPostTest, postAnswerPostTest } from "./pos-test/sagas";
import { loadPreTestVm, postAnswerPreTestVm } from "./pre-test-vm/sagas";
import { PreTestVmActionTypes } from "./pre-test-vm/type";
import { WordVmActionTypes } from "./word-vm/type";
import { loadQuestionWordVm, postAnswerWordVm } from "./word-vm/sagas";
import { UnitReviewSynonymActionTypes } from "./unit-review-synonym/type";
import {
  loadQuestionUnitReviewSynonym,
  postAnswerUnitReviewSynonym,
} from "./unit-review-synonym/sagas";
import { UnitReviewDefinitionActionTypes } from "./unit-review-definition/type";
import {
  loadQuestionUnitReviewDefinition,
  postAnswerUnitReviewDefinition,
} from "./unit-review-definition/sagas";
import { UnitReviewFillTheBlankActionTypes } from "./unit-review-fill-the-blank/type";
import {
  loadQuestionUnitReviewFillTheBlank,
  postAnswerUnitReviewFillTheBlank,
} from "./unit-review-fill-the-blank/sagas";

import { PostTestVmActionTypes } from "./pos-test-vm/type";
import { loadPostTestVm, postAnswerPostTestVm } from "./pos-test-vm/sagas";
import { UnitReviewComprehensionActionTypes } from "./unit-review-reading-comprehension/type";
import {
  loadQuestionUnitReviewComprehension,
  postAnswerUnitReviewComprehension,
} from "./unit-review-reading-comprehension/sagas";
import { SpellingProgramActionTypes } from "./spelling-program/type";
import {
  loadSpellingProgram,
  postAnswerSpelling,
} from "./spelling-program/sagas";
import { VocabularyBuilderActionTypes } from "./vocabulary-builder/type";
import {
  loadQuestionVocabularyBuilder,
  postAnswerVocabularyBuilder,
} from "./vocabulary-builder/sagas";
import { MinigameActionTypes } from "./minigame/types";
import { loadMinigames, saveMinigame } from "./minigame/sagas";

export default function* rootSaga() {
  yield all([
    takeLeading(AuthActionTypes.SIGN_IN_REQUEST, handleSignIn),
    takeLeading(AuthActionTypes.LOAD_TOKEN_REQUEST, loadAuthUser),

    takeLeading(RasActionTypes.LOAD_QUESTIONS_REQUEST, loadQuestions),
    takeLeading(RasActionTypes.POST_ANSWER_REQUEST, postAnswerSaga),

    takeLeading(
      DiagnosticActionTypes.LOAD_QUESTIONS_DIAGNOSTIC_REQUEST,
      loadQuestionsDiagnostic
    ),
    takeLeading(
      DiagnosticActionTypes.POST_ANSWER_DIAGNOSTIC_REQUEST,
      postAnswerDiagnostic
    ),

    takeLeading(TalkingBookActionTypes.LOAD_BOOK_ID_REQUEST, loadTalkingBookId),
    takeLeading(TalkingBookActionTypes.LOAD_BOOK_REQUEST, loadTalkingBook),
    takeLeading(
      TalkingBookActionTypes.POST_TALKING_BOOK_REQUEST,
      postTalkingBook
    ),

    takeLeading(
      WordRecognitionActionTypes.LOAD_RECOGNITION_REQUEST,
      loadWordRecognitionId
    ),
    takeLeading(
      WordRecognitionActionTypes.POST_ANSWER_RECOGNITION_REQUEST,
      postAnswerRecognition
    ),
    takeLeading(
      ReinforcementActivitiesActionTypes.LOAD_REINFORCEMENT_REQUEST,
      loadReinforcementId
    ),
    takeLeading(
      ReinforcementActivitiesActionTypes.POST_REINFORCEMENT_REQUEST,
      postAnswerReinforcement
    ),
    takeLeading(
      ReinforcementActivitiesActionTypes.POST_REINFORCEMENT_LIFE_SKILLS_REQUEST,
      postAnswerReinforcementLifeSkills
    ),

    takeLeading(DragFillActionTypes.LOAD_DRAG_FILL_REQUEST, loadDragFillId),
    takeLeading(DragFillActionTypes.POST_ANSWER_DRAG_REQUEST, postAnswerDrag),

    takeLeading(MatchingActionTypes.LOAD_MATCHING_REQUEST, loadMachingId),
    takeLeading(MatchingActionTypes.POST_MATCHING_REQUEST, postAnswerMatching),
    takeLeading(
      ScrambledSentenceActionTypes.LOAD_SCRAMBLED_REQUEST,
      loadScrambledId
    ),
    takeLeading(
      ScrambledSentenceActionTypes.POST_SCRAMBLED_REQUEST,
      postAnswerScrambled
    ),
    takeLeading(
      AlphabeticalOrderActionTypes.LOAD_ALPHABETICAL_REQUEST,
      loadAlphabetical
    ),
    takeLeading(
      AlphabeticalOrderActionTypes.POST_ALPHABETICAL_REQUEST,
      postAnswerAlphabetical
    ),
    takeLeading(
      ShortAnswerActionTypes.LOAD_SHORT_ANSWER_REQUEST,
      loadShortAnswerId
    ),
    takeLeading(ShortAnswerActionTypes.POST_SHORT_ANSWER_REQUEST, postAnswerSA),

    takeLeading(
      PreTestActionTypes.LOAD_QUESTIONS_PRE_TEST_REQUEST,
      loadQuestionsPreTest
    ),
    takeLeading(
      PreTestActionTypes.POST_ANSWER_PRE_TEST_REQUEST,
      postAnswerPreTest
    ),
    takeLeading(
      PreTestActionTypes.POST_ANSWER_PRE_TEST_LIFE_SKILLS_REQUEST,
      postAnswerPreTestLifeSkills
    ),
    takeLeading(DashboardActionTypes.LOAD_CLASSROOM_REQUEST, loadClassroom),
    takeLeading(DashboardActionTypes.LOAD_ACTIVITY_REQUEST, loadActivity),
    takeLeading(
      DashboardActionTypes.LOAD_NEXT_LESSON_REQUEST,
      loadNextActivity
    ),

    takeLeading(
      LessonsActionTypes.LOAD_CURRENT_LESSON_REQUEST,
      loadCurrentLesson
    ),
    takeLeading(LessonsActionTypes.LOAD_LESSON_REQUEST, loadLessonById),

    takeLeading(
      PreTeachingActionTypes.LOAD_PRE_TEACHING_REQUEST,
      loadPreTeaching
    ),
    takeLeading(
      PreTeachingActionTypes.POST_PRE_TEACHING_REQUEST,
      postAnswerPreTeaching
    ),

    takeLeading(
      ReadingComprehensionActionTypes.LOAD_RCOMPREHENSION_REQUEST,
      loadRComprehension
    ),
    takeLeading(
      ReadingComprehensionActionTypes.POST_RCOMPREHENSION_REQUEST,
      postAnswerRComprehension
    ),

    takeLeading(
      PostTestActionTypes.LOAD_QUESTIONS_POST_TEST_REQUEST,
      loadQuestionsPostTest
    ),
    takeLeading(
      PostTestActionTypes.POST_ANSWER_POST_TEST_REQUEST,
      postAnswerPostTest
    ),

    takeLeading(PreTestVmActionTypes.LOAD_PRE_TEST_VM_REQUEST, loadPreTestVm),
    takeLeading(
      PreTestVmActionTypes.POST_PRE_TEST_VM_REQUEST,
      postAnswerPreTestVm
    ),

    takeLeading(
      WordVmActionTypes.LOAD_QUESTION_WORD_VM_REQUEST,
      loadQuestionWordVm
    ),
    takeLeading(
      WordVmActionTypes.POST_ANSWER_WORD_VM_REQUEST,
      postAnswerWordVm
    ),

    takeLeading(
      UnitReviewSynonymActionTypes.LOAD_QUESTION_UNIT_REVIEW_SYNONYM_REQUEST,
      loadQuestionUnitReviewSynonym
    ),
    takeLeading(
      UnitReviewSynonymActionTypes.POST_ANSWER_UNIT_REVIEW_SYNONYM_REQUEST,
      postAnswerUnitReviewSynonym
    ),

    takeLeading(
      UnitReviewDefinitionActionTypes.LOAD_QUESTION_UNIT_REVIEW_DEFINITION_REQUEST,
      loadQuestionUnitReviewDefinition
    ),
    takeLeading(
      UnitReviewDefinitionActionTypes.POST_ANSWER_UNIT_REVIEW_DEFINITION_REQUEST,
      postAnswerUnitReviewDefinition
    ),

    takeLeading(
      UnitReviewFillTheBlankActionTypes.LOAD_QUESTION_UNIT_REVIEW_FILL_THE_BLANK_REQUEST,
      loadQuestionUnitReviewFillTheBlank
    ),
    takeLeading(
      UnitReviewFillTheBlankActionTypes.POST_ANSWER_UNIT_REVIEW_FILL_THE_BLANK_REQUEST,
      postAnswerUnitReviewFillTheBlank
    ),

    takeLeading(
      UnitReviewComprehensionActionTypes.LOAD_QUESTION_UNIT_REVIEW_COMPREHENSION_REQUEST,
      loadQuestionUnitReviewComprehension
    ),
    takeLeading(
      UnitReviewComprehensionActionTypes.POST_ANSWER_UNIT_REVIEW_COMPREHENSION_REQUEST,
      postAnswerUnitReviewComprehension
    ),

    takeLeading(
      PostTestVmActionTypes.LOAD_POST_TEST_VM_REQUEST,
      loadPostTestVm
    ),
    takeLeading(
      PostTestVmActionTypes.POST_POST_TEST_VM_REQUEST,
      postAnswerPostTestVm
    ),

    takeLeading(
      SpellingProgramActionTypes.LOAD_SPELLING_REQUEST,
      loadSpellingProgram
    ),
    takeLeading(
      SpellingProgramActionTypes.POST_ANSWER_SPELLING_REQUEST,
      postAnswerSpelling
    ),

    takeLeading(
      VocabularyBuilderActionTypes.LOAD_VOCABULRY_BUILDER_REQUEST,
      loadQuestionVocabularyBuilder
    ),
    takeLeading(
      VocabularyBuilderActionTypes.POST_ANSWER_VOCABULARY_BUILDER_REQUEST,
      postAnswerVocabularyBuilder
    ),

    takeLeading(MinigameActionTypes.LOAD_MINIGAME_REQUEST, loadMinigames),
    takeLeading(MinigameActionTypes.POST_MINIGAME_REQUEST, saveMinigame),
  ]);
}
