import { useEffect, useState } from "react";
import PageGrid from "../../../components/global/PageGrid";
import StartLesson from "../../../components/lessons/common/StartLesson";
import SpellingQuestion from "../../../components/lessons/word/SpellingQuestion";
import ShowSynonymDefinition from "../../../components/lessons/word/ShowSynonymDefinition";
import WordSelectQuestion from "../../../components/lessons/word/WordSelectQuestion";
import WordSentence from "../../../components/lessons/word/WordSentence";
import VocabularyWords from "../../../components/lessons/VocabularyWords";
import { usePages } from "../../../hooks/usePages";
import LessonSpelling from "../../../components/lessons/LessonSpelling";
import ContainerLesson from "../../../components/global/ContainerLesson";
import GreatJob from "../../../components/lessons/common/GreatJob";
import { StoryFeed } from "../../../@types/interfaces/api/pre-teaching.interface";
import { assetsBaseUrl } from "../../../utils/assets-helper";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import {
  clearVocabularyBuilderList,
  getWordVocabularyBuilder,
  learnedWordVocabulary,
  loadVocabularyBuilderRequest,
  postAnswerVocabularyBuilderRequest,
  setAlternativeVocabularyBuilder,
  setFullResponseVocabularyBuilder,
  setTextVocabulary,
  setTimerVocabularyBuilder,
} from "../../../stores/ducks/vocabulary-builder/actions";
import { urlsPaths } from "../../../utils/Utility";
import { useNavigate } from "react-router-dom";
import { VerifyButtonHelp } from "./SpellingProgramPage";
import ShowSynonymDefinitionLS from "../../../components/lessons/word/ShowSynonymDefinitionLS";
import WordSentenceLS from "../../../components/lessons/word/WordSentenceLS";
import WordSelectQuestionLS from "../../../components/lessons/word/WordSelectQuestionLS";
import { useAccesibiltyContext } from "../../../providers/AccessibilityController";
import LaunchLesson from "../../../components/lessons/common/LaunchLesson";
import SubtitleComponent from "../../../components/global/SubtitleComponent";

function WordPage() {
  const [spellingQuestionHelp, setSpellingQuestionHelp] = useState(false);
  const [functionalities, setFunctionalities] = useState({
    wrongAnswer: false,
    activeSkip: false,
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentLesson, activeButtonHelp, inAudio } = useAppSelector(
    (i) => i.lesson
  );
  const {
    wordFeedData,
    spelling,
    spellingQuestionData,
    learnedWords,
    isLoading,
  } = useAppSelector((i) => i.vocabularyBuilder);
  const { student } = useAppSelector((item) => item.auth);
  const { setVideoWindow, captionText, accessibility } =
    useAccesibiltyContext();

  useEffect(() => {
    setVideoWindow(true);
    dispatch(setTimerVocabularyBuilder(Date.now()));
    dispatch(loadVocabularyBuilderRequest({ activity__id: currentLesson?.id }));
    return () => {
      setVideoWindow(false);
      dispatch(clearVocabularyBuilderList());
    };
  }, []);

  const verifyButtonHelp = (): VerifyButtonHelp => {
    if (pageName === "words") {
      return {
        inactiveButton: false,
        audios: [
          `${assetsBaseUrl()}/app-2.0/jr4_html5/audio/ENG/dirs_mp3/VOCABBUILDERDIR.mp3`,
          `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/in87.ogg`,
        ],
      };
    } else if (pageName === "definition") {
      return {
        inactiveButton: false,
        audios: [
          `${assetsBaseUrl()}/app-2.0/jr4_html5/audio/ENG/dirs_mp3/CONTINUE_WITH_SYNDEF.mp3`,
          `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/CONTINUE_WITH_SYNDEF.ogg`,
        ],
      };
    } else if (pageName === "sentence") {
      return {
        inactiveButton: false,
        audios: [
          `${assetsBaseUrl()}/app-2.0/jr4_html5/audio/ENG/dirs_mp3/CTXHELP.mp3`,
          `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/CTXHELP.ogg`,
        ],
      };
    } else if (pageName === "selectQuestion") {
      return {
        inactiveButton: false,
        audios: [
          `${assetsBaseUrl()}/app-2.0/jr4_html5/audio/ENG/dirs_mp3/YNHELP.mp3`,
          `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/YNHELP.ogg`,
        ],
      };
    } else if (pageName === "spelling") {
      return {
        inactiveButton: false,
        audios: [
          `${assetsBaseUrl()}/app-2.0/jr4_html5/audio/ENG/dirs_mp3/SPELLING_DIRECTIONS_1.mp3`,
          `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/in83.ogg`,
        ],
      };
    } else if (pageName === "spellingQuestion" && spellingQuestionData) {
      return {
        inactiveButton: false,
        audios: spellingQuestionData.audio,
      };
    } else {
      return { inactiveButton: true, audios: [] };
    }
  };

  const initialPage = student?.progress.finished ? "start" : "accessibility";

  const { page, setPage, pageName } = usePages<
    | "start"
    | "greatJob"
    | "definition"
    | "sentence"
    | "selectQuestion"
    | "spelling"
    | "spellingQuestion"
    | "words"
    | "accessibility"
  >({
    initialPage,
    pages: {
      accessibility: <LaunchLesson onNext={() => setPage("start")} />,
      start: (
        <StartLesson
          title="Vocabulary builder"
          inAudio={inAudio}
          onNext={() => setPage("words")}
          button="start"
          introduction={[
            `${assetsBaseUrl()}/app-2.0/jr4_html5/audio/ENG/dirs_mp3/WELCOMEVOCABULARY.mp3`,
            `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/in86.ogg`,
          ]}
        />
      ),
      words: (
        <VocabularyWords
          activeButton={functionalities.activeSkip}
          audiosIntro={[
            `${assetsBaseUrl()}/app-2.0/jr4_html5/audio/ENG/dirs_mp3/VOCABBUILDERDIR.mp3`,
            `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/in87.ogg`,
          ]}
          postActivity={() =>
            dispatch(postAnswerVocabularyBuilderRequest(setPage))
          }
          learnedWords={learnedWords}
          onClick={(word) => {
            dispatch(setAlternativeVocabularyBuilder(word));
            dispatch(getWordVocabularyBuilder());
            setPage("definition");
          }}
        />
      ),
      definition: wordFeedData && (
        <ShowSynonymDefinitionLS
          data={wordFeedData}
          onNext={() => {
            if (functionalities.wrongAnswer) {
              setPage("spelling");
            } else {
              setPage("sentence");
            }
          }}
        />
      ),
      sentence: wordFeedData && (
        <WordSentenceLS
          data={wordFeedData}
          onNext={() => {
            setPage("selectQuestion");
          }}
        />
      ),
      selectQuestion: wordFeedData && (
        <WordSelectQuestionLS
          inAudio={inAudio}
          data={wordFeedData}
          onWrong={() => {
            setFunctionalities({ ...functionalities, wrongAnswer: true });
            setPage("definition");
          }}
          onCorrect={() => {
            setFunctionalities({ ...functionalities, wrongAnswer: false });
            setPage("spelling");
          }}
        />
      ),
      spelling: (
        <LessonSpelling
          story={spelling}
          onFinish={async () => {
            setPage("spellingQuestion");
          }}
        />
      ),
      spellingQuestion: spellingQuestionData && (
        <SpellingQuestion
          data={{ spellingQuestionData, spellingHelp: spellingQuestionHelp }}
          onWrong={() => {
            setPage("spelling");
            setSpellingQuestionHelp(true);
            dispatch(setFullResponseVocabularyBuilder());
          }}
          onCorrect={() => {
            setPage("words");
            dispatch(learnedWordVocabulary());
            dispatch(setFullResponseVocabularyBuilder());
            setFunctionalities({ ...functionalities, activeSkip: true });
          }}
          saveResponse={(answer) => dispatch(setTextVocabulary(answer))}
        />
      ),
      greatJob: (
        <GreatJob
          onNext={() => {
            if (currentLesson) {
              navigate(`/lesson/${urlsPaths[currentLesson?.mechanic_type]}`);
            }
          }}
        />
      ),
    },
  });

  return (
    <PageGrid
      activity="Vocabulary Builder"
      lesson="Life Skills"
      onHelp={() => {}}
      disabledButton={
        verifyButtonHelp().inactiveButton ||
        pageName === "accessibility" ||
        inAudio
      }
      audioHelp={verifyButtonHelp().audios}
    >
      <ContainerLesson>
        {page}
        <SubtitleComponent
          hide={
            !accessibility.subtitles ||
            !inAudio ||
            captionText === "" ||
            pageName === "greatJob"
          }
        />
        {/* <ProgressBar data={{ max: 12, step: 1 }} hide={pageName === "start"} /> */}
      </ContainerLesson>
    </PageGrid>
  );
}

export default WordPage;
