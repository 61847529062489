import axios, { AxiosError } from "axios";

interface FailedRequest {
  onSuccess: (token: string) => void;
  onFailure: (err: AxiosError) => void;
}

let isRefreshing = false;
let failedRequestQueue: FailedRequest[] = [];

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  timeout: 60000, //1m
  timeoutErrorMessage: "Timeout, please check your network connection!",
});

api.interceptors.response.use(undefined, (e: AxiosError) => {
  if (e.response?.status === 401) {
    const refreshToken = localStorage.get("student_refresh");
    const token = localStorage.get("student_token");

    if (refreshToken) {
      const originalConfig = e.config;

      if (!isRefreshing) {
        isRefreshing = true;

        api
          .post("/auth/refresh", {
            refresh: refreshToken,
          })
          .then((s) => {
            const { access, refresh } = s.data;

            localStorage.set("student_refresh", refresh);
            localStorage.set("student_token", access);

            api.defaults.headers.common["Authorization"] = `Bearer ${access}`;

            failedRequestQueue.forEach((request) => request.onSuccess(access));
            failedRequestQueue = [];
          })
          .catch((e) => {
            failedRequestQueue.forEach((request) => request.onFailure(e));
            failedRequestQueue = [];

            localStorage.remove("student_refresh");
            localStorage.remove("student_token");

            window.location.href = "/admin";
          })
          .finally(() => {
            isRefreshing = false;
          });
      }

      return new Promise((resolve, reject) => {
        failedRequestQueue.push({
          onSuccess: (token: string) => {
            if (originalConfig!.headers) {
              originalConfig!.headers["Authorization"] = `Bearer ${token}`;
            }
            resolve(api(originalConfig!));
          },
          onFailure: (err: AxiosError) => {
            reject(err);
          },
        });
      });
    } else {
      return Promise.reject(e);
    }
  }

  return Promise.reject(e);
});

export default api;
