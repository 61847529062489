import { Reducer } from "redux";
import { RasActionTypes, RasState } from "./type";

const INITIAL_STATE: RasState = {
  isLoading: false,
  questions: undefined,
  answers: [],
  alternativeBestFit: { answerid: 0, textReplace: "" },
  questionIndex: 0,
  selectedAudio: "",
};

const reducer: Reducer<RasState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RasActionTypes.LOAD_QUESTIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case RasActionTypes.LOAD_QUESTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        questions: action.payload,
      };
    case RasActionTypes.LOAD_QUESTIONS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case RasActionTypes.POST_ANSWER_REQUEST:
      return {
        ...state,
        alternativeBestFit: { answerid: 0, textReplace: "" },
        answers: [...state.answers, action.payload.contentAnswer],
        isLoading: true,
      };
    case RasActionTypes.POST_ANSWER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case RasActionTypes.POST_ANSWER_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case RasActionTypes.SET_ALTERNATIVE_BEST_FIT:
      return { ...state, alternativeBestFit: action.payload };

    case RasActionTypes.ON_NEXT:
      return {
        ...state,
        questionIndex: state.questionIndex + 1,
        alternativeBestFit: { answerid: 0, textReplace: "" },
        selectedAudio: "",
        answers: [...state.answers, action.payload.contentAnswer],
      };
    case RasActionTypes.ON_PREVIOUS:
      return {
        ...state,
        questionIndex: state.questionIndex - 1,
        alternativeBestFit: { answerid: 0, textReplace: "" },
        selectedAudio: "",
        answers: action.payload,
      };

    case RasActionTypes.SET_AUDIO:
      return {
        ...state,
        selectedAudio: action.payload,
      };
    case RasActionTypes.CLEAR_LIST:
      return INITIAL_STATE;

    default:
      return state;
  }
};
export default reducer;
