import { createStandaloneToast } from "@chakra-ui/react";

import theme from "../../../styles/theme";
import axios, { AxiosResponse } from "axios";
import { call, put, select } from "redux-saga/effects";
import api from "../../../services/api";

import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import { AnyAction } from "redux";
import store, { ApplicationState, getGlobalState } from "..";

import {
  loadPostTestError,
  loadPostTestSuccess,
  postAnswerPostTestError,
  postAnswerPostTestSuccess,
} from "./actions";
import {
  HistoryRecognition,
  HistoryRecognitionResponse,
  IProgress,
} from "../../../@types/interfaces/api/word-recognition.interface";
import { loadStudent } from "../auth/sagas";
import { loadCurrentLessonRequest } from "../lesson/action";
import { postProgress } from "../word-recognition/sagas";
import { loadCurrentLesson } from "../lesson/sagas";
import { loadStudentByIdRequest } from "../auth/actions";
import PostTestFilterInterface, {
  IPostTest,
} from "../../../@types/interfaces/api/post-test.interface";

const { toast } = createStandaloneToast({ theme });

export function* loadQuestionsPostTest(action: AnyAction) {
  const filters: PostTestFilterInterface = action.payload;
  try {
    const response: AxiosResponse<PaginationBase<IPostTest>> = yield call(
      api.get,
      `/post-test`,
      {
        params: filters,
      }
    );
    if (response.status == 200) {
      yield put(loadPostTestSuccess(response.data));
    }
  } catch (e: any) {
    yield put(loadPostTestError());
  }
}

export function* postHistoryPostTest() {
  const data: ApplicationState = yield select(getGlobalState);

  const timeLesson = Date.now() - data.postTest.dateMileseconds;

  const dataHitory: HistoryRecognition = {
    time: timeLesson,
    student: data.auth.student?.user,
  };

  try {
    const response: AxiosResponse = yield call(
      api.post,
      "/history",
      dataHitory
    );
    if (response.status == 201) {
      return response.data;
    }
  } catch (e: any) {
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* postAnswerPostTest(action: AnyAction) {
  const data: ApplicationState = yield select(getGlobalState);
  const setPage = action.payload.setPage;
  const activity = data.postTest.postTestData?.results[0].activity;
  const student = data.auth.user?.id;

  try {
    const responseHistory: HistoryRecognitionResponse = yield call(
      postHistoryPostTest
    );

    const completeData = data.postTest.answers.map((answer) => {
      return { ...answer, history: responseHistory.id };
    });

    const response: AxiosResponse = yield call(
      api.post,
      "/post-test/answer",
      completeData
    );
    if (response.status === 201) {
      yield put(postAnswerPostTestSuccess());
      yield call(postProgress, activity);
      yield call(loadCurrentLesson);
      if (student) {
        yield call(loadStudent, student);
      }
    }
  } catch (e: any) {
    yield put(postAnswerPostTestError());
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}
