import { combineReducers } from "redux";

import lesson from "./lesson";
import auth from "./auth";
import ras from "./ras";
import diagnostic from "./diagnostic";
import talkingBook from "./talking-book";
import wordRecognition from "./word-recognition";
import reinforcementActivities from "./reinforcement-activities";
import dragFill from "./drag-fill";
import matching from "./matching";
import scrambledSentence from "./scrambled-sentence";
import shortAnswer from "./short-answer";
import preTest from "./pre-test";
import dashboard from "./dashboard";
import preTeaching from "./pre-teaching";
import alphabeticalOrder from "./alphabetical-order";
import readingComprehension from "./reading-comprehension";
import postTest from "./pos-test";
import preTestVm from "./pre-test-vm";
import wordVm from "./word-vm";
import unitReviewSynonym from "./unit-review-synonym";
import unitReviewDefinition from "./unit-review-definition";
import unitReviewFillTheBlank from "./unit-review-fill-the-blank";
import unitReviewReadingCcomprehension from "./unit-review-reading-comprehension";
import postTestVm from "./pos-test-vm";
import spellingProgram from "./spelling-program";
import vocabularyBuilder from "./vocabulary-builder";
import minigame from "./minigame";

export default combineReducers({
  lesson,
  auth,
  ras,
  diagnostic,
  talkingBook,
  wordRecognition,
  reinforcementActivities,
  dragFill,
  matching,
  scrambledSentence,
  shortAnswer,
  preTest,
  dashboard,
  preTeaching,
  alphabeticalOrder,
  readingComprehension,
  postTest,
  preTestVm,
  wordVm,
  unitReviewSynonym,
  unitReviewDefinition,
  unitReviewFillTheBlank,
  unitReviewReadingCcomprehension,
  postTestVm,
  spellingProgram,
  vocabularyBuilder,
  minigame,
});
