import { Reducer } from "redux";
import { DashboardActionTypes, DashboardState } from "./type";

const INITIAL_STATE: DashboardState = {
  isLoading: false,
  classroomData: undefined,
  activityData: undefined,
  nextLessonId: undefined,
  nextLessonFullData: undefined,
};

const reducer: Reducer<DashboardState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DashboardActionTypes.LOAD_CLASSROOM_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DashboardActionTypes.LOAD_CLASSROOM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        classroomData: action.payload,
      };
    case DashboardActionTypes.LOAD_CLASSROOM_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case DashboardActionTypes.LOAD_ACTIVITY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DashboardActionTypes.LOAD_ACTIVITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        activityData: action.payload,
      };
    case DashboardActionTypes.LOAD_ACTIVITY_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case DashboardActionTypes.LOAD_NEXT_LESSON_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DashboardActionTypes.LOAD_NEXT_LESSON_SUCCESS:
      return {
        ...state,
        isLoading: false,
        nextLessonFullData: action.payload,
      };
    case DashboardActionTypes.LOAD_NEXT_LESSON_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case DashboardActionTypes.CLEAR_LIST:
      return INITIAL_STATE;

    default:
      return state;
  }
};
export default reducer;
