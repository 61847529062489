import { Reducer } from "redux";
import { PostTestActionTypes, PostTestState } from "./type";

const INITIAL_STATE: PostTestState = {
  isLoading: false,
  alternativePostTest: "",
  answers: [],
  postTestData: undefined,
  questionIndex: 0,
  selectedAnswer: "",
  numberOfCorrectAnswers: 0,
  numberOfWrongAnswers: 0,
  pageNumber: 1,
  levelId: 1,
  dateMileseconds: 0,
  fullAlternative: "",
};

const reducer: Reducer<PostTestState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PostTestActionTypes.LOAD_QUESTIONS_POST_TEST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case PostTestActionTypes.LOAD_QUESTIONS_POST_TEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        postTestData: action.payload,
        questionIndex: 0,
        numberOfCorrectAnswers: 0,
        numberOfWrongAnswers: 0,
      };
    case PostTestActionTypes.LOAD_QUESTIONS_POST_TEST_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case PostTestActionTypes.CLEAR_LIST:
      return INITIAL_STATE;

    case PostTestActionTypes.SET_ALTERNATIVE_POST_TEST:
      return { ...state, alternativePostTest: action.payload };

    case PostTestActionTypes.SET_FULL_ALTERNATIVE_POST_TEST:
      return { ...state, fullAlternative: action.payload };

    case PostTestActionTypes.SET_CORRECT_ANSWER_POST_TEST:
      return { ...state, selectedAnswer: action.payload };

    case PostTestActionTypes.ON_NEXT:
      return {
        ...state,
        questionIndex: state.questionIndex + 1,
        alternativePostTest: "",
        answers: [...state.answers, action.payload.contentAnswer],
      };
    case PostTestActionTypes.DO_NOT_STOP:
      return {
        ...state,
        alternativePostTest: "",
        answers: [...state.answers, action.payload.contentAnswer],
      };
    case PostTestActionTypes.POST_ANSWER_POST_TEST_REQUEST:
      return {
        ...state,
        alternativePostTest: "",
        answers: [...state.answers, action.payload.contentAnswer],
        isLoading: true,
      };
    case PostTestActionTypes.POST_ANSWER_POST_TEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        answers: [],
      };
    case PostTestActionTypes.POST_ANSWER_POST_TEST_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case PostTestActionTypes.ON_PREVIOUS:
      return {
        ...state,
        questionIndex: state.questionIndex - 1,
        alternativePostTest: "",
        answers: action.payload,
      };

    case PostTestActionTypes.SET_PAGE_NUMBER_POST_TEST:
      return { ...state, pageNumber: action.payload >= 4 ? 4 : action.payload };

    case PostTestActionTypes.SET_TIMER_POST_TEST:
      return { ...state, dateMileseconds: action.payload };

    default:
      return state;
  }
};
export default reducer;
