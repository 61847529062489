import { Button, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import PlayIcon from "../../assets/icons/PlayIcon";
import RegularButton from "../global/RegularButton";
import Subtitle from "../global/text/Subtitle";
import Text32px from "../global/text/Text32px";
import TextHighlightWord, { HighlightText } from "../global/TextHighlightWord";
import { useAppDispatch } from "../../hooks/redux";
import { setActiveButtonHelp } from "../../stores/ducks/lesson/action";
import { useLanguageContext } from "../../providers/LanguageProvider";
import { useAccesibiltyContext } from "../../providers/AccessibilityController";

export type ReadParagraphData = {
  introduction?: {
    audio: string[];
  };
  paragraph: {
    text: string;
    audio: string;
  };
  highlights?: Array<{
    id: number;
    text: string;
  }>;
};
interface Props {
  data: ReadParagraphData | undefined;
  onNext: () => void;
  onHighlight?: (word: HighlightText) => void;
  inAudio: boolean;
}
function ReadParagraph({ data, onNext, onHighlight, inAudio }: Props) {
  const dispatch = useAppDispatch();
  const { language } = useLanguageContext();
  const { onPlayAudio } = useAccesibiltyContext();
  useEffect(() => {
    dispatch(setActiveButtonHelp(false));
    async function onPageLoad() {
      if (data?.introduction) {
        await onPlayAudio([...data.introduction.audio]);
        dispatch(setActiveButtonHelp(true));
      }
    }
    onPageLoad();
  }, []);

  return (
    <Flex flexDir={"column"} alignItems="center">
      <Subtitle>HEAR THE PARAGRAPH</Subtitle>
      <Text32px mt="16px">Pre-Test Comprehension</Text32px>
      <Flex align="center" gap="24px" mt="56px" flexDir={["column", "row"]}>
        <PlayIcon onClick={() => onPlayAudio(data?.paragraph.audio)} />

        <Flex
          flexWrap={"wrap"}
          gap="3px"
          maxW="900px"
          p="24px"
          bg="white"
          borderRadius="16px"
          border="1.5px solid #CBD5E0"
        >
          <TextHighlightWord
            text={data?.paragraph.text}
            highlights={data?.highlights}
            handleClick={onHighlight}
          />
        </Flex>
      </Flex>

      <RegularButton mt="60px" onClick={onNext} disabled={inAudio}>
        Next
      </RegularButton>
    </Flex>
  );
}

export default ReadParagraph;
