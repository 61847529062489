import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContainerLesson from "../../components/global/ContainerLesson";
import PageGrid from "../../components/global/PageGrid";
import ProgressBar from "../../components/global/ProgressBar";
import BestFit from "../../components/lessons/BestFit";
import GreatJob from "../../components/lessons/common/GreatJob";
import StartLesson from "../../components/lessons/common/StartLesson";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { usePages } from "../../hooks/usePages";
import {
  clearList,
  loadQuestionRequest,
  onNext,
  onPrevious,
  setAlternativeBestFit,
  setAudio,
} from "../../stores/ducks/ras/actions";
import { AlternativeBestfit } from "../../stores/ducks/ras/type";
import { urlsPaths } from "../../utils/Utility";
import { assetsBaseUrl } from "../../utils/assets-helper";
import { useLanguageContext } from "../../providers/LanguageProvider";
import Language from "../../components/lessons/common/Language";

const ReadingAttitudeSurvey = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { questions, alternativeBestFit, questionIndex, selectedAudio } =
    useAppSelector((item) => item.ras);
  const { student } = useAppSelector((item) => item.auth);
  const { currentLesson } = useAppSelector((item) => item.lesson);
  const { language } = useLanguageContext();

  const urlAudio = `${assetsBaseUrl()}/app-2.0/ras/sounds/sound_students/`;

  const questionAudio = `${assetsBaseUrl()}/app-2.0/ras/sounds/sound_students/${
    questions?.results[questionIndex].question_audio
  }`;

  const setAudioFn = (audio: string) => {
    dispatch(setAudio(audio));
  };
  const setAlternativeFn = (alternative: AlternativeBestfit) => {
    dispatch(setAlternativeBestFit(alternative));
  };
  const questionsArray = questions?.results;

  const optionsAnswer = questions?.results[questionIndex].options.map(
    (option) => option
  );

  useEffect(() => {
    dispatch(loadQuestionRequest());

    return () => {
      dispatch(clearList());
    };
  }, []);

  const { page, setPage, pageName } = usePages<
    "start" | "great_job" | "survey" | "language"
  >({
    initialPage: "language",
    pages: {
      start: (
        <StartLesson
          title="Reading Attitude survey"
          onNext={() => setPage("survey")}
          videoExplanationUrl={`${assetsBaseUrl()}/app-2.0/ras/vids/student/${language}/video.mp4`}
          button="start"
        />
      ),
      language: <Language onNext={() => setPage("start")} />,

      survey: questionsArray && (
        <BestFit
          setAlternativeFn={setAlternativeFn}
          setAudioFn={setAudioFn}
          question={questionsArray[questionIndex].question}
          optionsAnswer={optionsAnswer}
          alternativeData={alternativeBestFit?.textReplace}
          alternativeDataId={alternativeBestFit?.answerid}
          selectedAudio={selectedAudio}
          urlAudio={urlAudio}
          questionAudio={questionAudio}
        />
      ),
      great_job: (
        <GreatJob
          onNext={() => {
            if (student?.progress.activity !== null && currentLesson) {
              navigate(`/lesson/${urlsPaths[currentLesson?.mechanic_type]}`);
            } else {
              navigate("/lesson/diagnostic-prescriptive");
            }
          }}
        />
      ),
    },
  });

  return (
    <PageGrid activity="ReadingAttitudeSurvey" onHelp={() => {}} disabledButton>
      <ContainerLesson>
        {page}

        {questionsArray && (
          <ProgressBar
            data={{ max: questionsArray.length, step: questionIndex + 1 }}
            onConfirm={() => dispatch(onNext(setPage))}
            onPrevious={() => dispatch(onPrevious())}
            hideButton={alternativeBestFit?.textReplace === ""}
            hide={pageName !== "survey"}
          />
        )}
      </ContainerLesson>
    </PageGrid>
  );
};

export default ReadingAttitudeSurvey;
