import { createStandaloneToast } from "@chakra-ui/react";
import theme from "../../../styles/theme";
import { AxiosResponse } from "axios";
import { call, put, select } from "redux-saga/effects";
import api from "../../../services/api";
import { AnyAction } from "redux";
import { ApplicationState, getGlobalState } from "..";
import { postProgress } from "../word-recognition/sagas";
import { ActivityIdFilter } from "../../../@types/interfaces/api/talking-book.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import { IReadingComprehension } from "../../../@types/interfaces/api/reading-comprehension";
import {
  loadPreTestVmError,
  loadPreTestVmSuccess,
  postAnswerPreTestVmError,
  postAnswerPreTestVmSuccess,
  setTypLessonPreTestVm,
} from "./actions";
import {
  HistoryRecognition,
  HistoryRecognitionResponse,
} from "../../../@types/interfaces/api/word-recognition.interface";
import { IPreTestVm } from "../../../@types/interfaces/api/pre-test-vm.interface";
import { postDiagnostic2History } from "../pre-test/sagas";

const { toast } = createStandaloneToast({ theme });

export function* loadPreTestVm(action: AnyAction) {
  const filter: ActivityIdFilter = action.payload;

  try {
    const response: AxiosResponse<PaginationBase<IPreTestVm[]>> = yield call(
      api.get,
      `/pre-test-verbal-master`,
      {
        params: filter,
      }
    );
    if (response.status == 200) {
      yield put(loadPreTestVmSuccess(response.data.results[0]));
    }
  } catch (e: any) {
    yield put(loadPreTestVmError());
  }
}

export function* postHistoryPreTestVm() {
  const data: ApplicationState = yield select(getGlobalState);

  const timeLesson = Date.now() - data.preTestVm.dateMileseconds;

  const dataHitory: HistoryRecognition = {
    time: timeLesson,
    student: data.auth.student?.user,
  };

  try {
    const response: AxiosResponse = yield call(
      api.post,
      "/history",
      dataHitory
    );
    if (response.status == 201) {
      return response.data;
    }
  } catch (e: any) {
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* postAnswerPreTestVm(action: AnyAction) {
  const data: ApplicationState = yield select(getGlobalState);
  const setPage = action.payload.setPage;
  const activity = data.preTestVm.preTestVmData?.activity;
  const typeLesson = data.preTestVm.typeLesson;
  const currentLesson = data.lesson.currentLesson;
  const user = data.auth.student;

  try {
    const responseHistory: HistoryRecognitionResponse = yield call(
      postHistoryPreTestVm
    );

    const completeData = data.preTestVm.answersPreTestVm.map((answer) => {
      return { ...answer, history: responseHistory.id };
    });

    const response: AxiosResponse = yield call(
      api.post,
      "/pre-test-verbal-master/answer",
      completeData
    );
    if (response.status === 201) {
      if (typeLesson === "definition") {
        if (currentLesson) {
          yield put(postAnswerPreTestVmSuccess());
          yield call(postProgress, activity);
          yield put(setTypLessonPreTestVm("synonym"));
          setPage("greatJob");
        } else {
          setPage("greatJob");
          if (user?.progress.activity === null) {
            yield call(postDiagnostic2History, responseHistory.id, true);
          }
          yield put(postAnswerPreTestVmSuccess());
          yield call(postProgress, activity);
          yield put(setTypLessonPreTestVm("synonym"));
        }
      } else {
        setPage("do_not_stop");
        yield put(setTypLessonPreTestVm("definition"));
      }
    }
  } catch (e: any) {
    yield put(postAnswerPreTestVmError());
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}
