import {
  Button,
  calc,
  Flex,
  Grid,
  Heading,
  Skeleton,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CheckBoxIcon from "../assets/icons/CheckBoxIcon";
import ClassIcon from "../assets/icons/ClassIcon";
import LessonGrayIcon from "../assets/icons/LessonGrayIcon";
import LessonRedIcon from "../assets/icons/LessonRedIcon";
import HeaderDashboard from "../components/dashboard/HeaderDashboard";
import HeaderDashboardMobile from "../components/dashboard/HeaderDashboardMobile";
import Header from "../components/global/Header";
import OutlineButton from "../components/global/OutlineButton";
import ModalReady from "../components/modals/ModalReady";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import {
  clearDashboard,
  loadActivityRequest,
  loadClassroomRequest,
  loadNextActivityLessonRequest,
} from "../stores/ducks/dashboard/actions";
import { mechanicTypeName, urlsPaths } from "../utils/Utility";
import {
  loadLessonIDRequest,
  setCurrentLesson,
} from "../stores/ducks/lesson/action";
import { motion } from "framer-motion";

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { student } = useAppSelector((i) => i.auth);
  const { currentLesson, currentProgram, lessonIdData } = useAppSelector(
    (i) => i.lesson
  );
  const { pageNumber } = useAppSelector((i) => i.preTest);
  const { classroomData, activityData, nextLessonFullData } = useAppSelector(
    (i) => i.dashboard
  );

  useEffect(() => {
    if (student?.in_ras) {
      setIsOpenModal(true);
    }
    if (student && student.classroom) {
      dispatch(loadClassroomRequest(student?.classroom));
    }
    if (student?.progress.activity) {
      dispatch(loadActivityRequest(student.progress.activity));
      // dispatch(loadNextActivityLessonRequest());
    }
    if (currentLesson) {
      dispatch(loadLessonIDRequest(currentLesson?.lesson));
    }

    return () => {
      dispatch(clearDashboard());
    };
  }, [student]);

  const textCardRecently = (): string[] => {
    if (
      student?.in_ras &&
      student.in_diagnostic_part_1 &&
      student.in_diagnostic_part_2
    ) {
      return ["in review", "Lesson", "No activity done", ""];
    }
    if (
      student?.in_ras === false &&
      student.in_diagnostic_part_1 &&
      student.in_diagnostic_part_2
    ) {
      return ["in review", "Lesson", "Rading attitude survey", ""];
    }
    if (
      student?.in_ras === false &&
      student.in_diagnostic_part_1 === false &&
      student.in_diagnostic_part_2
    ) {
      return ["in review", "Lesson", "Diagnostic Prescriptive", ""];
    }
    if (
      student?.in_ras &&
      student.in_diagnostic_part_1 === false &&
      student.in_diagnostic_part_2 === false &&
      activityData
    ) {
      return ["in review", "Lesson", "No activity done", ""];
    }
    if (
      student?.in_ras === false &&
      student.in_diagnostic_part_1 === false &&
      student.in_diagnostic_part_2 === false &&
      activityData
    ) {
      return [
        `${activityData?.lesson.story.level.program.name} ${activityData?.lesson.story.level.name}`,
        `Lesson ${
          activityData?.mechanic_type === "post_test"
            ? "Post Test"
            : activityData?.mechanic_type === "pre_test"
            ? "Pre Test"
            : activityData?.mechanic_type === "pre_test_verbal_master"
            ? "Pre Test"
            : activityData?.mechanic_type === "reading_comprehension"
            ? "Reading Comprehension"
            : activityData?.lesson.lesson_order
        }`,
        `${activityData?.lesson.story.name}`,
        `${activityData?.mechanic_type}`,
      ];
    }
    return [];
  };

  const textCardNext = (): string[] => {
    if (
      student?.in_ras &&
      student.in_diagnostic_part_1 &&
      student.in_diagnostic_part_2
    ) {
      return ["in review", "Lesson", "Rading attitude survey", ""];
    }
    if (
      student?.in_ras === false &&
      student.in_diagnostic_part_1 &&
      student.in_diagnostic_part_2
    ) {
      return ["in review", "Lesson", "Diagnostic Prescriptive", ""];
    }
    if (
      student?.in_ras === false &&
      student.in_diagnostic_part_1 === false &&
      student.in_diagnostic_part_2
    ) {
      return ["in review", "Lesson", "Pre Test", ""];
    }
    if (
      student?.in_ras &&
      student.in_diagnostic_part_1 === false &&
      student.in_diagnostic_part_2 === false &&
      activityData
    ) {
      return ["in review", "Lesson", "Rading attitude survey", ""];
    }
    if (
      student?.in_ras === false &&
      student.in_diagnostic_part_1 === false &&
      student.in_diagnostic_part_2 === false &&
      activityData
    ) {
      return [
        `${nextLessonFullData?.lesson.story.level.program.name} ${nextLessonFullData?.lesson.story.level.name}`,
        `Lesson ${
          nextLessonFullData?.mechanic_type === "post_test"
            ? "Post Test"
            : nextLessonFullData?.mechanic_type === "pre_test"
            ? "Pre Test"
            : nextLessonFullData?.mechanic_type === "pre_test_verbal_master"
            ? "Pre Test"
            : nextLessonFullData?.mechanic_type === "reading_comprehension"
            ? "Reading Comprehension"
            : nextLessonFullData?.lesson.lesson_order
        }`,
        `${nextLessonFullData?.lesson.story.name}`,
        `${nextLessonFullData?.mechanic_type}`,
      ];
    }
    return [];
  };

  const renderCardRecently = (): boolean => {
    if (
      student?.in_ras ||
      student?.in_diagnostic_part_1 ||
      student?.in_diagnostic_part_2
    ) {
      return true;
    } else if (textCardRecently().length > 0 && activityData) {
      return true;
    } else {
      return false;
    }
  };
  const renderCardNext = (): boolean => {
    if (
      student?.in_ras ||
      student?.in_diagnostic_part_1 ||
      student?.in_diagnostic_part_2
    ) {
      return true;
    } else if (
      textCardNext().length > 0 &&
      activityData &&
      nextLessonFullData
    ) {
      return true;
    } else {
      return false;
    }
  };

  const pageNumberPT = parseInt(localStorage.getItem("pageNumberPT") || "0");

  return (
    <>
      <HeaderDashboard />
      <HeaderDashboardMobile />
      <Flex
        width="100%"
        h="calc(100vh -298px)"
        bg="bg"
        px={["30px", "149px"]}
        position="relative"
      >
        <Flex
          bg="primary"
          w="100%"
          h={["350px", "278px"]}
          position="absolute"
          top={0}
          left={0}
          borderRadius={["0px 0px 16px 16px", "0px 0px 48px 48px"]}
          zIndex="-10"
        />
        <Flex mt="56px" w="full" flexDir="column">
          <Flex
            justify="space-between"
            w="full"
            flexDir={["column", "column", "row"]}
            align="center"
          >
            <Heading
              color="white"
              fontSize={["28px", "32px"]}
              textAlign="center"
            >
              {student?.first_name} {student?.last_name}
            </Heading>
            <Button
              mt={["24px", "0"]}
              bg="gray.200"
              color="primary"
              borderRadius="100px"
              p="10px 24px"
              w="fit-content"
              onClick={() => {
                if (student?.in_diagnostic_part_1) {
                  navigate("/lesson/diagnostic-prescriptive");
                  return;
                }
                if (student?.in_diagnostic_part_2 && pageNumberPT >= 4) {
                  navigate("/lesson/pre-test-verbal-master");
                  return;
                }

                if (student?.in_diagnostic_part_2) {
                  navigate("/lesson/pre-test");
                  return;
                }
                if (currentLesson) {
                  navigate(
                    `/lesson/${urlsPaths[currentLesson?.mechanic_type]}`
                  );
                }
              }}
            >
              Start Lesson
            </Button>
          </Flex>
          <Grid
            mb="100px"
            justifyContent="center"
            templateColumns={[
              "repeat( auto-fit, minmax(290px, 1fr) )",
              "repeat( auto-fit, minmax(555px, 1fr) )",
            ]}
            mt="48px"
            gap="32px"
          >
            {activityData && (
              <Flex flexDir="column" flex={1}>
                <Text
                  fontSize="20px"
                  fontWeight="600"
                  color={["white", "blue.200"]}
                >
                  Recently finished
                </Text>
                <motion.div
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{
                    delay: 0.1,
                    type: "spring",
                    stiffness: 260,
                    damping: 20,
                  }}
                >
                  <Flex
                    bg="white"
                    p="32px 24px"
                    mt="16px"
                    borderRadius="16px"
                    boxShadow=" 1px 1px 24px -4px rgba(12, 77, 162, 0.19)"
                    flexDir="column"
                  >
                    <LessonRedIcon ml="auto" />
                    <Flex mt="32px" align="center" overflow="hidden">
                      {renderCardRecently() ? (
                        <>
                          {student?.progress.finished ? (
                            <CheckBoxIcon />
                          ) : (
                            <Flex
                              maxW="20px"
                              height="20px"
                              border="2px solid #9c9c9c"
                              borderRadius="full"
                              flex={1}
                              p="10px"
                            ></Flex>
                          )}

                          <Flex ml="8px" flexDir="column">
                            <Text
                              color="gray.500"
                              fontWeight="600"
                              fontSize="12px"
                            >
                              {textCardRecently()[0]}
                            </Text>
                            <Flex flexDir={["column", "row"]}>
                              <Text
                                color="gray.600"
                                fontWeight="700"
                                fontSize={["18px", "24px"]}
                                whiteSpace="nowrap"
                              >
                                {textCardRecently()[1]}
                              </Text>
                              <Tooltip
                                borderRadius="8px"
                                bg="gray.600"
                                label={mechanicTypeName[textCardRecently()[3]]}
                              >
                                <Text
                                  color="gray.500"
                                  fontWeight="500"
                                  fontSize={["16px", "20px"]}
                                  ml="8px"
                                  mt="4px"
                                  textOverflow="ellipsis"
                                  whiteSpace="nowrap"
                                  overflow="hidden"
                                >
                                  | {textCardRecently()[2]} |{" "}
                                  {mechanicTypeName[textCardRecently()[3]]}
                                </Text>
                              </Tooltip>
                            </Flex>
                          </Flex>
                        </>
                      ) : (
                        <Flex w="full" flexDir="column" gap="8px">
                          <Skeleton
                            w="80%"
                            h="20px"
                            borderRadius="16px"
                            ml="8px"
                          />
                          <Skeleton
                            w="80%"
                            h="30px"
                            borderRadius="16px"
                            ml="8px"
                          />
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                </motion.div>
              </Flex>
            )}

            {nextLessonFullData && (
              <Flex flexDir="column" flex={1}>
                <Text
                  fontSize="20px"
                  fontWeight="600"
                  color={["gray.700", "gray.700", "blue.200"]}
                >
                  Next lesson
                </Text>
                <motion.div
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{
                    delay: 0.2,
                    type: "spring",
                    stiffness: 260,
                    damping: 20,
                  }}
                >
                  <Flex
                    bg="white"
                    p="32px 24px"
                    mt="16px"
                    borderRadius="16px"
                    boxShadow=" 1px 1px 24px -4px rgba(12, 77, 162, 0.19)"
                    flexDir="column"
                  >
                    <LessonGrayIcon ml="auto" />
                    <Flex mt="32px" align="center" overflow="hidden">
                      {renderCardNext() ? (
                        <>
                          {/* <CheckBoxIcon /> */}
                          <Flex ml="8px" flexDir="column">
                            <Text
                              color="gray.500"
                              fontWeight="600"
                              fontSize="12px"
                            >
                              {textCardNext()[0]}
                            </Text>
                            <Flex flexDir={["column", "row"]}>
                              <Text
                                color="gray.600"
                                fontWeight="700"
                                fontSize={["18px", "24px"]}
                                whiteSpace="nowrap"
                              >
                                {textCardNext()[1]}
                              </Text>
                              <Tooltip
                                borderRadius="8px"
                                bg="gray.600"
                                label={mechanicTypeName[textCardNext()[3]]}
                              >
                                <Text
                                  color="gray.500"
                                  fontWeight="500"
                                  fontSize={["16px", "20px"]}
                                  ml="8px"
                                  mt="4px"
                                  textOverflow="ellipsis"
                                  whiteSpace="nowrap"
                                  overflow="hidden"
                                >
                                  | {textCardNext()[2]} |{" "}
                                  {mechanicTypeName[textCardNext()[3]]}
                                </Text>
                              </Tooltip>
                            </Flex>
                          </Flex>
                        </>
                      ) : (
                        <Flex w="full" flexDir="column" gap="8px">
                          <Skeleton
                            w="80%"
                            h="20px"
                            borderRadius="16px"
                            ml="8px"
                          />
                          <Skeleton
                            w="80%"
                            h="30px"
                            borderRadius="16px"
                            ml="8px"
                          />
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                </motion.div>
              </Flex>
            )}

            {classroomData && (
              <Flex flexDir="column" flex={1}>
                <Text fontSize="20px" fontWeight="600">
                  Your Classroom
                </Text>
                <motion.div
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{
                    delay: 0.2,
                    type: "spring",
                    stiffness: 260,
                    damping: 20,
                  }}
                >
                  <Flex
                    bg="white"
                    p="32px 24px"
                    mt="16px"
                    borderRadius="16px"
                    boxShadow=" 1px 1px 24px -4px rgba(12, 77, 162, 0.19)"
                    flexDir="column"
                  >
                    <ClassIcon ml="auto" />
                    <Flex mt="32px" flexDir="column">
                      <Text fontWeight="600">{classroomData?.name}</Text>
                      <Text color="gray.500">
                        Teacher: <b>{classroomData?.teacher}</b>
                      </Text>
                      <Text color="gray.500">
                        School: <b> {classroomData?.school}</b>
                      </Text>
                    </Flex>
                  </Flex>
                </motion.div>
              </Flex>
            )}
          </Grid>
        </Flex>
      </Flex>
      <ModalReady isOpen={isOpenModal} onClose={() => setIsOpenModal(false)} />
    </>
  );
};

export default Dashboard;
