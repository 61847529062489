import { Button, Flex, Text } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import PlayIcon from "../../assets/icons/PlayIcon";
import ButtonRadio from "../global/ButtonRadio";
import RegularButton from "../global/RegularButton";
import Subtitle from "../global/text/Subtitle";
import Text32px from "../global/text/Text32px";
import {
  OptionsPreTeaching,
  QuestionsPreTeaching,
} from "../../@types/interfaces/api/pre-teaching.interface";
import { BASE_URL_MIDIA } from "../../utils/Utility";
import { useAppDispatch } from "../../hooks/redux";
import {
  clearIndexPreTeaching,
  setOptionPreTeaching,
} from "../../stores/ducks/pre-teaching/actions";
import AudioWave from "../global/AudioWave";
import { assetsBaseUrl } from "../../utils/assets-helper";
import { useLanguageContext } from "../../providers/LanguageProvider";
import { useAccesibiltyContext } from "../../providers/AccessibilityController";
import SubtitleComponent from "../global/SubtitleComponent";

interface Props {
  question: QuestionsPreTeaching;
  selectedOption: OptionsPreTeaching | undefined;
  questionIndex: number;
}

const StoryQuestion: FC<Props> = (props) => {
  const { question, selectedOption, questionIndex } = props;
  const { language } = useLanguageContext();
  const { playMedias, onPlayAudio } = useAccesibiltyContext();

  const dispatch = useAppDispatch();
  const [inPlaying, setInPlaying] = useState(true);
  const audiosIntro = [
    `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/QNA1.mp3`,
    `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/QNA2.mp3`,
    `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/QNA3.mp3`,
    `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/QNA4.mp3`,
    `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/QNA5.mp3`,
    `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/QNA6.mp3`,
    `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/QNA7.mp3`,
    `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/QNA8.mp3`,
    `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/QNA9.mp3`,
  ];
  const audiosIntroOgg = [
    `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in26.ogg`,
    `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in27.ogg`,
    `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in28.ogg`,
    `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in29.ogg`,
    `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in30.ogg`,
    `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in31.ogg`,
    `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in32.ogg`,
    `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in33.ogg`,
  ];

  useEffect(() => {
    const playAudio = async () => {
      if (inPlaying && questionIndex === 0) {
        await playMedias([...audiosIntro, ...audiosIntroOgg]);
        // await onPlayAudio( ...audiosIntro);
        setInPlaying(false);
      }

      await playMedias([
        `${assetsBaseUrl()}${selectedOption?.audio}`,
        `${assetsBaseUrl()}${selectedOption?.audio.replace(".mp3", ".ogg")}`,
      ]);
    };
    playAudio();
  }, [question, selectedOption]);

  return (
    <Flex flexDir={"column"} align="center">
      <Flex align="center" pos="relative">
        <Subtitle>QUESTIONS</Subtitle>
        <Flex position="absolute" hidden={!inPlaying} right={-70}>
          <AudioWave />
        </Flex>
      </Flex>
      <Flex align="center" mt="16px" flexDir={["column", "row"]}>
        <Text32px>{question.text}</Text32px>{" "}
        <PlayIcon
          isDisabled={inPlaying}
          onClick={() =>
            playMedias([
              `${assetsBaseUrl()}${question?.audio}`,
              `${assetsBaseUrl()}${question?.audio.replace(".mp3", ".ogg")}`,
            ])
          }
        />
      </Flex>

      <Flex mt="32px" gap="24px" flexDir={["column", "row"]}>
        {question.options.map((answer, index) => {
          return (
            <ButtonRadio
              key={answer.id}
              inPlaying={inPlaying}
              isDisabled={inPlaying}
              isSelected={selectedOption?.id === answer.id}
              text={answer.text}
              handleClick={() => {
                dispatch(setOptionPreTeaching(answer));
              }}
            />
          );
        })}
      </Flex>
      {selectedOption?.text !== "" && (
        <Flex
          mt="24px"
          p="16px 24px"
          justify="center"
          bg="white"
          borderRadius="16px"
          border="1.5px solid #CBD5E0"
        >
          <Text fontWeight="600" fontSize="24px">
            {selectedOption?.text}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default StoryQuestion;
