import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import {
  AnswerRC,
  IReadingComprehension,
} from "../../../@types/interfaces/api/reading-comprehension";
import { AnswerRA } from "../../../@types/interfaces/api/reinforcement-activities.interface";

export enum ReadingComprehensionActionTypes {
  LOAD_RCOMPREHENSION_REQUEST = "@readingComprehension/LOAD_RCOMPREHENSION_REQUEST",
  LOAD_RCOMPREHENSION_SUCCESS = "@readingComprehension/LOAD_RCOMPREHENSION_SUCCESS",
  LOAD_RCOMPREHENSION_ERROR = "@readingComprehension/LOAD_RCOMPREHENSION_ERROR",

  POST_RCOMPREHENSION_REQUEST = "@readingComprehension/POST_RCOMPREHENSION_REQUEST",
  POST_RCOMPREHENSION_SUCCESS = "@readingComprehension/POST_RCOMPREHENSION_SUCCESS",
  POST_RCOMPREHENSION_ERROR = "@readingComprehension/POST_RCOMPREHENSION_ERROR",

  ON_NEXT_RC = "@readingComprehension/ ON_NEXT_RC",
  ON_PREIVIOUS_RC = "@readingComprehension/ ON_PREIVIOUS_RC",
  SET_ALTERNATIVE_RC = "@readingComprehension/ SET_ALTERNATIVE_RC",
  SET_ANSWER_RC = "@readingComprehension/ SET_ANSWER_RC",
  CLEAR_LIST = "@readingComprehension/ CLEAR_LIST",
  SET_TIMER_RC = "@readingComprehension/ SET_TIMER_RC",
}

export interface ReadingComprehensionState
  extends ReadingComprehensionStateRequestsStatus {
  readingComprehensionData: IReadingComprehension | undefined;
  questionIndex: number;
  alternativeRC: string;
  dateMileseconds: number;
  answersRC: AnswerRC[];
}

interface ReadingComprehensionStateRequestsStatus {
  isLoading: boolean;
}
