import { Action } from "@dnd-kit/core/dist/store";
import { Reducer } from "redux";
import { SpellingProgramActionTypes, SpellingProgramState } from "./type";

const INITIAL_STATE: SpellingProgramState = {
  isLoading: false,
  questionIndex: 0,
  alternativeSpelling: undefined,
  answers: [],
  dateMileseconds: 0,
  spellingData: undefined,
  wordFeed: [],
  spellingQuestionData: undefined,
  textSpelling: "",
  learnedWords: [],
};

const reducer: Reducer<SpellingProgramState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case SpellingProgramActionTypes.LOAD_SPELLING_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SpellingProgramActionTypes.LOAD_SPELLING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        spellingData: action.payload,
      };
    case SpellingProgramActionTypes.LOAD_SPELLING_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case SpellingProgramActionTypes.SET_WORD_FEED_SPELLING:
      return {
        ...state,
        wordFeed: action.payload.wordFeed,
        spellingQuestionData: action.payload.spellingQuestionData,
      };

    case SpellingProgramActionTypes.SET_ALTERNATIVE_SPELLING:
      return { ...state, alternativeSpelling: action.payload };

    case SpellingProgramActionTypes.ON_NEXT:
      return {
        ...state,
        questionIndex: state.questionIndex + 1,
        answers: [...state.answers, action.payload.contentAnswer],
      };
    case SpellingProgramActionTypes.SET_ANSWER_SPELLING:
      return {
        ...state,
        answers: [...state.answers, action.payload],
      };

    case SpellingProgramActionTypes.SET_TEXT_SPELLING:
      return {
        ...state,
        textSpelling: action.payload,
      };
    case SpellingProgramActionTypes.LEARNED_WORD_SPELLING:
      return {
        ...state,
        learnedWords: [...state.learnedWords, action.payload],
      };
    case SpellingProgramActionTypes.POST_ANSWER_SPELLING_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case SpellingProgramActionTypes.POST_ANSWER_SPELLING_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case SpellingProgramActionTypes.POST_ANSWER_SPELLING_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case SpellingProgramActionTypes.SET_TIMER_SPELLING:
      return {
        ...state,
        dateMileseconds: action.payload,
      };
    case SpellingProgramActionTypes.CLEAR_SPELLING:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default reducer;
