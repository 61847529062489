import { createStandaloneToast } from "@chakra-ui/react";
import theme from "../../../styles/theme";
import { AxiosResponse } from "axios";
import { call, put, select } from "redux-saga/effects";
import api from "../../../services/api";
import { AnyAction } from "redux";
import { IReinforcementActivities } from "../../../@types/interfaces/api/reinforcement-activities.interface";

import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import {
  FilterMinigames,
  IMinigameData,
  IMinigameSaveResponse,
} from "../../../@types/interfaces/api/minigame.interface";
import {
  loadMinigameError,
  loadMinigameSuccess,
  saveMinigameError,
  saveMinigameSuccess,
} from "./action";
import { ApplicationState, getGlobalState } from "..";
import { HistoryRecognitionResponse } from "../../../@types/interfaces/api/word-recognition.interface";
import { postProgress } from "../word-recognition/sagas";
import { NavigateFunction } from "react-router-dom";
import { urlsPaths } from "../../../utils/Utility";

const { toast } = createStandaloneToast({ theme });

export function* loadMinigames(action: AnyAction) {
  const filter: FilterMinigames = action.payload;

  try {
    const response: AxiosResponse<PaginationBase<IMinigameData[]>> = yield call(
      api.get,
      `/minigame`,
      {
        params: filter,
      }
    );
    if (response.status === 200) {
      yield put(loadMinigameSuccess(response.data.results[0]));
    }
  } catch (e: any) {
    yield put(loadMinigameError());
  }
}

export function* postHistoryGame() {
  const data: ApplicationState = yield select(getGlobalState);

  const timeLesson = Date.now() - data.minigame.dateMileseconds;

  const dataHitory = {
    time: timeLesson,
    student: data.auth.student?.user,
  };

  try {
    const response: AxiosResponse = yield call(
      api.post,
      "/history",
      dataHitory
    );
    if (response.status == 201) {
      return response.data;
    }
  } catch (e: any) {
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* saveMinigame(action: AnyAction) {
  const { navigate }: { navigate: NavigateFunction } = action.payload;
  const data: ApplicationState = yield select(getGlobalState);
  const activity = data.minigame.minigameData?.activity;
  const id = data.minigame.minigameData?.id;
  try {
    const responseHistory: HistoryRecognitionResponse = yield call(
      postHistoryGame
    );

    const completeData: IMinigameSaveResponse = {
      history: responseHistory.id,
      misses: data.minigame.misses,
      other_hits: data.minigame.otherHits,
      shots_fired: data.minigame.shotsFired,
      targets_hit: data.minigame.targetsHit,
      minigame: id ?? 0,
    };

    const response: AxiosResponse = yield call(
      api.post,
      "/minigame/answer",
      completeData
    );
    if (response.status == 201) {
      yield put(saveMinigameSuccess());
      yield call(postProgress, activity);
      navigate(`/dashboard`);
    }
  } catch (e: any) {
    yield put(saveMinigameError());
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}
