import React, { useState } from "react";

export function usePages<LessonPage extends string>(params: {
  initialPage: LessonPage;
  pages: { [key in LessonPage]: React.ReactNode };
}) {
  const [page, _setPage] = useState<LessonPage>(params.initialPage);
  const [lastPage, setLastPage] = useState<LessonPage | undefined>();

  function setPage(nextPage: LessonPage) {
    setLastPage(page);
    _setPage(nextPage);
  }

  function returnToLastPage() {
    if (lastPage) {
      setLastPage(page);
      _setPage(lastPage);
    }
  }

  return { page: params.pages[page], setPage, returnToLastPage, pageName: page };
}
