import { Button, Flex, Text } from "@chakra-ui/react";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { useEffect, useState } from "react";
import { DraggableButton } from "../global/DraggableButton";
import { DroppableBox } from "../global/DroppableBox";
import Subtitle from "../global/text/Subtitle";
import Text32px from "../global/text/Text32px";
import { useLanguageContext } from "../../providers/LanguageProvider";
import { useAccesibiltyContext } from "../../providers/AccessibilityController";

export type OptionSelected = {
  id: string;
  text: string;
  audio?: string;
};
export type DragToCorrectPlaceData = {
  introduction?: {
    audio?: string;
  };
  word: {
    text: string;
    audio?: string;
  };
  options: OptionSelected[];
  response: {
    id: string;
    correct: string;
    wrong: string;
  };
};
interface Props {
  data: DragToCorrectPlaceData;
  questionIndex: number;
  onNext: (answer: OptionSelected) => void;
  onWrong?: (answer: OptionSelected) => void;
}
function DragToCorrectPlace({ data, onNext, onWrong, questionIndex }: Props) {
  const [process, setProcess] = useState(false);
  const [parent, setParent] = useState<string | null>(null);
  const { language } = useLanguageContext();
  const { onPlayAudio } = useAccesibiltyContext();

  useEffect(() => {
    setProcess(false);
    setParent(null);
  }, [questionIndex]);
  const draggableMarkup = (
    <DraggableButton id="word" disabled={process}>
      <Flex
        w="160px"
        p="24px"
        bg="white"
        borderRadius="16px"
        border="1.5px solid #CBD5E0"
        align="center"
        justify="center"
        boxShadow="0px 3px 6px rgba(119, 119, 119, 0.196)"
      >
        <Text fontSize="18px" fontWeight="700">
          {data.word.text}
        </Text>
      </Flex>
    </DraggableButton>
  );

  function handleDragEnd(event: DragEndEvent) {
    const id = event.over?.id as string | undefined;

    if (id) {
      setParent(id);
      correctQuestion(id);
    }
  }
  async function correctQuestion(id?: string) {
    setProcess(true);
    if (data.response.id === id) {
      await onPlayAudio(data.response.correct);
      const selected = data.options.find((option) => option.id === id);
      onNext(selected!);
    } else {
      await onPlayAudio(data.response.wrong);
      const selected = data.options.find((option) => option.id === id);
      setParent(null);
      onWrong && onWrong(selected!);
    }
    setProcess(true);
  }

  return (
    <DndContext onDragEnd={handleDragEnd} autoScroll={false}>
      <Flex flexDir="column" align="center">
        <Subtitle>SYNONYM AND DEFINITION</Subtitle>
        <Text32px mt="16px">Click and drag</Text32px>
        <Flex
          w="full"
          justify="space-around"
          align="center"
          flexDir="column"
          mb="50px"
        >
          <Flex flexDir="column" align="center">
            <Flex flexWrap="wrap" flexDir="row" gap="14px" mt="56px">
              {data.options.map((option, index) => {
                return (
                  <DroppableBox id={option.id} key={index}>
                    <Flex
                      p="24px"
                      borderRadius="16px"
                      border="1.5px solid #CBD5E0"
                      align="center"
                      justify="left"
                      bg="#4D9A39"
                    >
                      {parent === option.id && draggableMarkup}
                      <Text
                        fontSize="18px"
                        fontWeight="700"
                        color="white"
                        pl={draggableMarkup ? "10px" : ""}
                      >
                        {option.text}
                      </Text>
                    </Flex>
                  </DroppableBox>
                );
              })}
            </Flex>
          </Flex>
          <Flex mt="60px">{parent === null ? draggableMarkup : null}</Flex>
        </Flex>
      </Flex>
    </DndContext>
  );
}

export default DragToCorrectPlace;
