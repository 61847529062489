import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { call, put } from "redux-saga/effects";
import {
  ICurrentLessonData,
  IlessonById,
} from "../../../@types/interfaces/api/dashboard.interface";
import api from "../../../services/api";
import {
  loadCurrentLessonError,
  loadCurrentLessonSuccess,
  loadLessonIDError,
  loadLessonIDSuccess,
  setCurrentLesson,
} from "./action";

export function* loadCurrentLesson() {
  try {
    const response: AxiosResponse<ICurrentLessonData[]> = yield call(
      api.get,
      `/activity/current`
    );
    if (response.status == 200) {
      yield put(loadCurrentLessonSuccess(response.data));
    }
  } catch (e: any) {
    yield put(loadCurrentLessonError());
  }
}

export function* loadLessonById(action: AnyAction) {
  const id: number = action.payload;
  try {
    const response: AxiosResponse<IlessonById> = yield call(
      api.get,
      `/lesson/${id}`
    );
    if (response.status == 200) {
      yield put(loadLessonIDSuccess(response.data));
    }
  } catch (e: any) {
    yield put(loadLessonIDError());
  }
}
