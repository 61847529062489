import { Button } from "@chakra-ui/button";
import { Flex, Text } from "@chakra-ui/layout";
import React, { useEffect } from "react";
import Subtitle from "../global/text/Subtitle";
import Text32px from "../global/text/Text32px";
import { useAppSelector } from "../../hooks/redux";
import { alternativeVocabulary } from "../../@types/interfaces/api/vocabulary-builder.interface";
import OutlineButton from "../global/OutlineButton";
import { SkipForward } from "lucide-react";
import { AudioMedia } from "../../hooks/AudioMedia";
import { useLanguageContext } from "../../providers/LanguageProvider";
import { useAccesibiltyContext } from "../../providers/AccessibilityController";

interface Props {
  onClick: ({ text, id }: alternativeVocabulary) => void;
  learnedWords: number[];
  postActivity: () => void;
  audiosIntro: string[];
  activeButton: boolean;
}
function VocabularyWords({
  onClick,
  learnedWords,
  postActivity,
  audiosIntro,
  activeButton,
}: Props) {
  const { vocabularyData } = useAppSelector((i) => i.vocabularyBuilder);
  const { language } = useLanguageContext();
  const { playMedias } = useAccesibiltyContext();

  useEffect(() => {
    playMedias(audiosIntro);
    return () => {
      AudioMedia.pauseSequence();
    };
  }, []);

  return (
    <Flex flexDir="column" align="center">
      <Subtitle>Click on the word you would like to learn</Subtitle>
      <Text32px mt="16px">Vocabulary Builder</Text32px>
      <Flex gap={2} wrap={"wrap"} mt="56px" justify="center">
        {vocabularyData?.words.map((word) => {
          return (
            <Button
              key={word.id}
              p="24px"
              bg="white"
              borderRadius="16px"
              border="1.5px solid #CBD5E0"
              onClick={() => onClick({ text: word.text, id: word.id })}
              disabled={learnedWords.includes(word.id)}
            >
              <Text fontSize="24px" fontWeight="700">
                {word.text}
              </Text>
            </Button>
          );
        })}
      </Flex>
      {activeButton && (
        <OutlineButton
          mt="60px"
          color="gray.700"
          rightIcon={<SkipForward size="16px" />}
          onClick={postActivity}
        >
          Finish Lesson
        </OutlineButton>
      )}
    </Flex>
  );
}

export default VocabularyWords;
