import { Box } from "@chakra-ui/react";
import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import React from "react";

interface Props {
  id: string;
  position?: { x: number; y: number };
  disabled?: boolean;
  onClick?: () => void;
}
export function DraggableButton({
  id,
  position = { x: 0, y: 0 },
  disabled,
  onClick,
  children,
}: React.PropsWithChildren<Props>) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id,
    disabled,
  });
  const style: React.CSSProperties | undefined = transform
    ? {
        transform: CSS.Transform.toString({
          x: transform.x + position.x,
          y: transform.y + position.y,
          scaleX: 1,
          scaleY: 1,
        }),
      }
    : {
        transform: CSS.Transform.toString({
          x: position.x,
          y: position.y,
          scaleX: 1,
          scaleY: 1,
        }),
      };

  return (
    <Box
      zIndex={1}
      ref={setNodeRef}
      style={style}
      onClick={onClick}
      {...listeners}
      {...attributes}
    >
      {children}
    </Box>
  );
}
