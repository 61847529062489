import { Grid, GridItem } from "@chakra-ui/react";
import React from "react";
import { IActivityData } from "../../@types/interfaces/api/dashboard.interface";
import Header, { HeaderProps } from "./Header";
import HeaderMobile from "./HeaderMobile";
import { useLanguageContext } from "../../providers/LanguageProvider";
import { useAccesibiltyContext } from "../../providers/AccessibilityController";

interface Props extends HeaderProps {
  activityData?: IActivityData | undefined;
  audioHelp?: string[];
  disabledButton?: boolean;
}
function PageGrid({
  children,
  activityData,
  audioHelp,
  disabledButton,
  ...rest
}: React.PropsWithChildren<Props>) {
  const { language } = useLanguageContext();
  const { playMedias } = useAccesibiltyContext();
  return (
    <Grid
      templateAreas={`"header"
                      "main"`}
      gridTemplateRows={"auto 1fr"}
      height="100vh"
      width="100%"
    >
      <GridItem bg="#ffff" area={"header"}>
        <Header
          {...rest}
          onHelp={() => {
            if (audioHelp) {
              playMedias(audioHelp);
            }
          }}
          disabledButton={disabledButton}
        />
        <HeaderMobile {...rest} />
      </GridItem>

      <GridItem px="8" pb="8" pt="10" bg="bg" area={"main"}>
        {children}
      </GridItem>
    </Grid>
  );
}

export default PageGrid;
