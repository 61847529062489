import { createStandaloneToast } from "@chakra-ui/react";

import theme from "../../../styles/theme";
import axios, { AxiosResponse } from "axios";
import { call, put, select } from "redux-saga/effects";
import api from "../../../services/api";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import { AnyAction } from "redux";
import store, { ApplicationState, getGlobalState } from "..";
import {
  loadWordVmError,
  loadWordVmSuccess,
  postAnswerWordVmError,
  postAnswerWordVmSuccess,
} from "./actions";

import WordVmFilterInterface, {
  IWordVm,
} from "../../../@types/interfaces/api/word-vm.interface";
import {
  HistoryRecognition,
  HistoryRecognitionResponse,
} from "../../../@types/interfaces/api/word-recognition.interface";
import { postProgress } from "../word-recognition/sagas";

const { toast } = createStandaloneToast({ theme });

export function* loadQuestionWordVm(action: AnyAction) {
  const filters: WordVmFilterInterface = action.payload;
  try {
    const response: AxiosResponse<PaginationBase<IWordVm[]>> = yield call(
      api.get,
      `/word-vm`,
      {
        params: filters,
      }
    );
    if (response.status == 200) {
      yield put(loadWordVmSuccess(response.data.results[0]));
    }
  } catch (e: any) {
    yield put(loadWordVmError());
  }
}

export function* postHistoryWordVm() {
  const data: ApplicationState = yield select(getGlobalState);

  const timeLesson = Date.now() - data.wordVm.dateMileseconds;

  const dataHitory: HistoryRecognition = {
    time: timeLesson,
    student: data.auth.student?.user,
  };

  try {
    const response: AxiosResponse = yield call(
      api.post,
      "/history",
      dataHitory
    );
    if (response.status == 201) {
      return response.data;
    }
  } catch (e: any) {
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* postAnswerWordVm(action: AnyAction) {
  const data: ApplicationState = yield select(getGlobalState);
  const setPage = action.payload.setPage;
  const activity = data.wordVm.wordVmData?.activity;

  try {
    const responseHistory: HistoryRecognitionResponse = yield call(
      postHistoryWordVm
    );

    const completeData = data.wordVm.answers.map((answer) => {
      return { ...answer, history: responseHistory.id };
    });

    const response: AxiosResponse = yield call(
      api.post,
      "/word-vm/answer",
      completeData
    );
    if (response.status === 201) {
      yield put(postAnswerWordVmSuccess());
      yield call(postProgress, activity);
      setPage("greatJob");
    }
  } catch (e: any) {
    yield put(postAnswerWordVmError());
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}
