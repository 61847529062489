import { Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ContainerLesson from "../../components/global/ContainerLesson";
import PageGrid from "../../components/global/PageGrid";
import ProgressBar from "../../components/global/ProgressBar";
import DragFill from "../../components/lessons/DragFill";
import StartLesson from "../../components/lessons/common/StartLesson";
import { usePages } from "../../hooks/usePages";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  clearDragFillList,
  loadDragFillRequest,
  onNextDragFill,
  onPreviousDragFill,
  setAlternativeDrag,
  setAnswerDragFill,
  setSkipDrag,
  setTimerDrag,
  setWordDrag,
  skipQuestion,
} from "../../stores/ducks/drag-fill/actions";
import { audioCorrectAnswer, urlsPaths } from "../../utils/Utility";
import {
  DragAlternative,
  DragOption,
} from "../../@types/interfaces/api/drag-fill.interface";
import { useNavigate } from "react-router-dom";
import RegularButton from "../../components/global/RegularButton";
import GreatJob from "../../components/lessons/common/GreatJob";
import { assetsBaseUrl } from "../../utils/assets-helper";
import { useLanguageContext } from "../../providers/LanguageProvider";
import { useAccesibiltyContext } from "../../providers/AccessibilityController";

function DragFillExercise() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    DragFillData,
    questionIndex,
    alternativeDrag,
    wordSelected,
    answerDrag,
    skip,
    wrongAnswer,
    inactiveDrag,
    isLoading,
  } = useAppSelector((item) => item.dragFill);

  const { currentLesson, currentProgram } = useAppSelector((i) => i.lesson);
  const { language } = useLanguageContext();
  const { onPlayAudio } = useAccesibiltyContext();

  const dragFillDataArray = DragFillData?.results[0].questions[questionIndex];

  const initialState = {
    items: dragFillDataArray?.options ?? [],
    selected: [],
  };

  const [state, setState] = useState<DragAlternative>(initialState);

  const getResponse = (array: DragOption[]): string => {
    return array.map((t) => t.text).join("");
  };
  useEffect(() => {
    if (currentLesson) {
      dispatch(loadDragFillRequest({ activity__id: currentLesson.id }));
    }
    dispatch(setTimerDrag(Date.now()));
    return () => {
      dispatch(clearDragFillList());
    };
  }, []);

  useEffect(() => {
    dispatch(setWordDrag(getResponse(state.selected)));
  }, [state]);

  const handleConfirme = () => {
    if (dragFillDataArray?.text) {
      const currentQuestion = dragFillDataArray.text.replace(
        /_+/,
        state.selected[0].text
      );
      const correctAnswer = dragFillDataArray.answer;

      const isCorrect =
        currentQuestion.toLowerCase() === correctAnswer.toLowerCase();
      if (!isCorrect) {
        onPlayAudio(
          `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/TRYAGAIN.mp3`
        );
        dispatch(setSkipDrag());
        dispatch(setAnswerDragFill());
        setState(initialState);

        return;
      }

      onPlayAudio(audioCorrectAnswer());
      dispatch(setAlternativeDrag(initialState));
      setState(initialState);
      dispatch(onNextDragFill(setPage));
    }
  };

  const { page, setPage, pageName } = usePages<
    "start" | "question" | "greatJob"
  >({
    initialPage: "start",
    pages: {
      start: (
        <StartLesson
          title="Drag & Fill Exercises"
          onNext={() => setPage("question")}
          button="start"
        />
      ),
      question: dragFillDataArray && (
        <DragFill
          question={dragFillDataArray.text}
          options={dragFillDataArray.options}
          state={state}
          setState={setState}
          skip={skip}
          onNext={() => dispatch(skipQuestion(setPage))}
          inactiveDrag={inactiveDrag}
          currentProgram={currentProgram}
        />
      ),
      greatJob: (
        <GreatJob
          onNext={() => {
            if (currentLesson) {
              navigate(`/lesson/${urlsPaths[currentLesson?.mechanic_type]}`);
            }
          }}
        />
      ),
    },
  });

  return (
    <PageGrid
      activity="Drag & Fill Exercises"
      lesson="Joseph's Readers"
      onHelp={() => {}}
      audioHelp={[
        `${assetsBaseUrl()}/app-2.0/jr_html5/audio/dirs/ENG/drag_best_word.mp3`,
      ]}
      disabledButton={pageName === "start" || pageName === "greatJob"}
    >
      <ContainerLesson>
        {page}

        {DragFillData && (
          <ProgressBar
            data={{
              max: DragFillData?.results[0].questions.length,
              step: questionIndex + 1,
            }}
            hide={pageName !== "question"}
            onConfirm={handleConfirme}
            onPrevious={() => {
              dispatch(onPreviousDragFill());
            }}
          />
        )}
      </ContainerLesson>
    </PageGrid>
  );
}

export default DragFillExercise;
