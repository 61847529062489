import {
  AnswerUnitReviewFillheBlank,
  IQuestionFillTheBlank,
  IUnitReviewFillTheBlank,
} from "../../../@types/interfaces/api/unit-review-fill-the-blank.interface";

export enum UnitReviewFillTheBlankActionTypes {
  LOAD_QUESTION_UNIT_REVIEW_FILL_THE_BLANK_REQUEST = "@unitReviewFillTheBlank/LOAD_QUESTION_UNIT_REVIEW_FILL_THE_BLANK_REQUEST",
  LOAD_QUESTION_UNIT_REVIEW_FILL_THE_BLANK_SUCCESS = "@unitReviewFillTheBlank/LOAD_QUESTION_UNIT_REVIEW_FILL_THE_BLANK_SUCCESS",
  LOAD_QUESTION_UNIT_REVIEW_FILL_THE_BLANK_ERROR = "@unitReviewFillTheBlank/LOAD_QUESTION_UNIT_REVIEW_FILL_THE_BLANK_ERROR",

  POST_ANSWER_UNIT_REVIEW_FILL_THE_BLANK_REQUEST = "@unitReviewFillTheBlank/POST_ANSWER_UNIT_REVIEW_FILL_THE_BLANK_REQUEST",
  POST_ANSWER_UNIT_REVIEW_FILL_THE_BLANK_SUCCESS = "@unitReviewFillTheBlank/POST_ANSWER_UNIT_REVIEW_FILL_THE_BLANK_SUCCESS",
  POST_ANSWER_UNIT_REVIEW_FILL_THE_BLANK_ERROR = "@unitReviewFillTheBlank/POST_ANSWER_UNIT_REVIEW_FILL_THE_BLANK_ERROR",

  SET_ANSWER_UNIT_REVIEW_FILL_THE_BLANK = "@unitReviewFillTheBlank/ SET_ANSWER_UNIT_REVIEW_FILL_THE_BLANK",
  SET_TIMER_UNIT_REVIEW_FILL_THE_BLANK = "@unitReviewFillTheBlank/ SET_TIMER_UNIT_REVIEW_FILL_THE_BLANK",
  SET_ALTERNATIVE_UNIT_REVIEW_FILL_THE_BLANK = "@unitReviewFillTheBlank/ SET_ALTERNATIVE_UNIT_REVIEW_FILL_THE_BLANK",
  SET_FULL_ALTERNATIVE_UNIT_REVIEW_FILL_THE_BLANK = "@unitReviewFillTheBlank/ SET_FULL_ALTERNATIVE_UNIT_REVIEW_FILL_THE_BLANK",
  ON_NEXT = "@unitReviewFillTheBlank/ ON_NEXT",
  ON_PREVIOUS = "@unitReviewFillTheBlank/ PREVIOUS",
  CLEAR_LIST = "@unitReviewFillTheBlank/CLEAR_LIST",
}

export interface UnitReviewFillTheBlankState
  extends UnitReviewFillTheBlankStateRequestsStatus {
  unitReviewFillTheBlankData: IUnitReviewFillTheBlank | undefined;
  answers: AnswerUnitReviewFillheBlank[];
  alternativeUnitReviewFillTheBlank: string;
  fullAlternativeUnitReviewFillTheBlank: string;
  questionIndex: number;
  dateMileseconds: number;
  questionsModified: IQuestionFillTheBlank[];
}

interface UnitReviewFillTheBlankStateRequestsStatus {
  isLoading: boolean;
}
