import { Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ContainerLesson from "../../components/global/ContainerLesson";
import PageGrid from "../../components/global/PageGrid";
import ProgressBar from "../../components/global/ProgressBar";
import StartLesson from "../../components/lessons/common/StartLesson";
import { usePages } from "../../hooks/usePages";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import GreatJob from "../../components/lessons/common/GreatJob";
import {
  clearPostTestList,
  loadPostTestRequest,
  onNexPostTest,
  setAlternativePostTest,
  setTimerPostTest,
  setfULLAlternativePostTest,
} from "../../stores/ducks/pos-test/actions";
import FillTheGap from "../../components/lessons/FillTheGap";
import { urlsPaths } from "../../utils/Utility";
import { useNavigate } from "react-router-dom";
import Finish from "../../components/lessons/common/Finish";
import { assetsBaseUrl } from "../../utils/assets-helper";
import LaunchLesson from "../../components/lessons/common/LaunchLesson";
import SubtitleComponent from "../../components/global/SubtitleComponent";
import { useAccesibiltyContext } from "../../providers/AccessibilityController";

function PostTestPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentLesson, inAudio } = useAppSelector((i) => i.lesson);
  const { student } = useAppSelector((item) => item.auth);
  const {
    questionIndex,
    pageNumber,
    fullAlternative,
    postTestData,
    alternativePostTest,
    answers,
  } = useAppSelector((item) => item.postTest);
  const { activityData } = useAppSelector((item) => item.dashboard);
  const { setVideoWindow, captionText, accessibility } =
    useAccesibiltyContext();
  const isLifeSkills =
    currentLesson &&
    activityData?.lesson.story.level.program.short_name === "LF";
  const questionsArray = postTestData?.results[0].questions;
  const text = postTestData?.results[0].questions[questionIndex].text;

  const setAlternativeFn = (alternative: string) => {
    dispatch(setAlternativePostTest(alternative));
    if (text) {
      const full = text.replace(/_+/, alternative);
      dispatch(setfULLAlternativePostTest(full));
    }
  };

  useEffect(() => {
    setVideoWindow(true);
    dispatch(setTimerPostTest(Date.now()));
    dispatch(loadPostTestRequest({ activity__id: currentLesson?.id }));
    return () => {
      setVideoWindow(false);
      dispatch(clearPostTestList());
    };
  }, []);

  const initialPage = student?.progress.finished ? "start" : "accessibility";

  const { page, setPage, pageName } = usePages<
    "start" | "content" | "greatJob" | "accessibility"
  >({
    initialPage,
    pages: {
      accessibility: <LaunchLesson onNext={() => setPage("start")} />,

      start: (
        <StartLesson
          title="Lesson type post test"
          onNext={() => setPage("content")}
          button="start"
          introduction={
            isLifeSkills
              ? [
                  `${assetsBaseUrl()}/app-2.0/jr4_html5/audio/ENG/dirs_mp3/WELCPOSTTEST.mp3`,
                  `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/in56.ogg`,
                ]
              : [`${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in1.ogg`]
          }
        />
      ),
      content: questionsArray && (
        <FillTheGap
          alternativeData={alternativePostTest}
          question={questionsArray[questionIndex].text}
          setAlternativeFn={setAlternativeFn}
          optionsRA={questionsArray[questionIndex].alternatives}
          questionIndex={questionIndex}
          audioIntro={
            isLifeSkills
              ? [
                  `${assetsBaseUrl()}/app-2.0/jr4_html5/audio/ENG/dirs_mp3/FILLIN.mp3`,
                  `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/in57.ogg`,
                ]
              : [
                  `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/FILLIN.mp3`,
                  `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in2.ogg`,
                  `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in3.ogg`,
                ]
          }
        />
      ),
      greatJob: <Finish onNext={() => window.location.reload()} />,
    },
  });

  return (
    <PageGrid
      activity="Post Test"
      lesson="Joseph's Readers"
      onHelp={() => {}}
      disabledButton
    >
      {questionsArray && (
        <ContainerLesson>
          {page}
          <SubtitleComponent
            hide={
              !accessibility.subtitles ||
              !inAudio ||
              captionText === "" ||
              pageName === "greatJob"
            }
          />
          <ProgressBar
            hide={pageName !== "content"}
            data={{ max: questionsArray.length, step: questionIndex + 1 }}
            onConfirm={() => dispatch(onNexPostTest(setPage))}
            // onPrevious={() => dispatch(onPreviousPostTest())}
            hideButtonPrevious
            hideButton={alternativePostTest === ""}
          />
        </ContainerLesson>
      )}
    </PageGrid>
  );
}

export default PostTestPage;
