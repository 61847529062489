import { NavigateFunction } from "react-router-dom";
import {
  LoginCredentials,
  LoginResponse,
} from "../../../@types/interfaces/api/login.interface";
import { Student } from "../../../@types/interfaces/api/student.interface";
import { User } from "../../../@types/interfaces/api/user.interface";
import api from "../../../services/api";
import { AuthActionTypes } from "./type";

export const signInRequest = (
  credentials: LoginCredentials,
  navigate: NavigateFunction
) => ({
  type: AuthActionTypes.SIGN_IN_REQUEST,
  payload: { credentials, navigate },
});

export const signInSuccess = (resp: LoginResponse) => {
  api.defaults.headers.common["Authorization"] = `Bearer ${resp.access}`;
  localStorage.setItem("student_token", resp.access);
  localStorage.setItem("student_refresh", resp.refresh);
  return { type: AuthActionTypes.SIGN_IN_ERROR };
};
export const signInError = () => ({
  type: AuthActionTypes.SIGN_IN_ERROR,
});

export const loadTokenRequest = () => {
  const token = localStorage.getItem("student_token");
  if (!token) {
    return { type: AuthActionTypes.LOAD_TOKEN_ERROR };
  }
  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  return { type: AuthActionTypes.LOAD_TOKEN_REQUEST };
};

export const loadTokenSuccess = (user: User) => ({
  type: AuthActionTypes.LOAD_TOKEN_SUCCESS,
  payload: user,
});

export const loadTokenError = () => {
  delete api.defaults.headers.common["Authorization"];
  localStorage.removeItem("student_token");
  localStorage.removeItem("student_refresh");

  return {
    type: AuthActionTypes.LOAD_TOKEN_ERROR,
  };
};

export const logOut = (navigate: NavigateFunction) => {
  localStorage.removeItem("student_token");
  localStorage.removeItem("student_refresh");
  delete api.defaults.headers.common["Authorization"];
  window.location.reload()
  return {
    type: AuthActionTypes.LOG_OUT,
  };
};

export const loadStudentByIdRequest = (id: number) => ({
  type: AuthActionTypes.LOAD_STUDENT_REQUEST,
  payload: id,
});

export const loadStudentByIdSuccess = (student: Student | undefined) => ({
  type: AuthActionTypes.LOAD_STUDENT_SUCCESS,
  payload: student,
});
export const loadStudentByIdError = () => ({
  type: AuthActionTypes.LOAD_STUDENT_ERROR,
});
