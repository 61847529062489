import { Text, TextProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import ComponentProps from "../../../../src/@types/interfaces/app/component-props.interfaces";

// interface Text32pxProps extends ComponentProps {}

const Text32px: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text
      fontSize={["28px", "32px"]}
      fontWeight="700"
      color="gray.700"
      textAlign="center"
      {...rest}
    >
      {children}
    </Text>
  );
};

export default Text32px;
