import bg1 from "../assets/image/game/galaxy4.jpg";
import bg2 from "../assets/image/game/galaxy2.jpg";
import bg3 from "../assets/image/game/space1.jpg";
import bg4 from "../assets/image/game/galaxy3.jpg";
import bg5 from "../assets/image/game/galaxy.jpg";
import bgA1 from "../assets/image/game/bgA3.png";
import bgA2 from "../assets/image/game/bgA2.png";
import bgA3 from "../assets/image/game/bgA1.png";
import bgA4 from "../assets/image/game/bgA4.png";
import bgA5 from "../assets/image/game/bgA5.png";
import bgB4 from "../assets/image/game/jungle4.png";
import bgB1 from "../assets/image/game/sky1.png";
import bgB2 from "../assets/image/game/sky2.png";
import bgB3 from "../assets/image/game/sky3.png";
import bgB5 from "../assets/image/game/sky4.png";

export function getRandomWord() {
  const words = [
    "Window",
    "Visitor",
    "Farm",
    "Animals",
    "Cows",
    "Horses",
    "Chickens",
    "Sheep",
    "Barn",
    "Plane",
    "Haystack",
    "Pond",
    "Picnic",
    "Nature",
    "Scenery",
    "Trees",
    "Trails",
    "Wildlife",
    "Tour",
    "Adventure",
  ];

  const randomIndex = Math.floor(Math.random() * words.length);
  return words[randomIndex];
}

export const ufoDifficulty = [
  {
    ufo: [
      { id: 1, x: 600, y: 200, velocityX: 6, velocityY: 7 },
      { id: 2, x: 200, y: 1, velocityX: 6, velocityY: 7 },
      { id: 3, x: 1, y: 300, velocityX: 6, velocityY: 7 },
      { id: 4, x: 750, y: 400, velocityX: 6, velocityY: 7 },
      { id: 5, x: 900, y: 100, velocityX: 6, velocityY: 7 },
    ],
    asteroid: [
      { id: 1, x: 100, y: 400, velocityX: 6, velocityY: 7, size: 270 },
    ],
  },
  {
    ufo: [
      { id: 1, x: 900, y: 200, velocityX: 7, velocityY: 7 },
      { id: 2, x: 800, y: 19, velocityX: 6, velocityY: 7 },
      { id: 3, x: 1, y: 400, velocityX: 7, velocityY: 7 },
      { id: 4, x: 250, y: 200, velocityX: 6, velocityY: 7 },
      { id: 5, x: 300, y: 500, velocityX: 7, velocityY: 7 },
    ],
    asteroid: [
      { id: 1, x: 100, y: 400, velocityX: 6, velocityY: 8, size: 200 },
      { id: 2, x: 10, y: 600, velocityX: 6, velocityY: 7, size: 300 },
      { id: 3, x: 900, y: 100, velocityX: 6, velocityY: 6, size: 250 },
    ],
  },
  {
    ufo: [
      { id: 1, x: 600, y: 200, velocityX: 7, velocityY: 8 },
      { id: 2, x: 200, y: 80, velocityX: 6, velocityY: 7 },
      { id: 3, x: 100, y: 300, velocityX: 7, velocityY: 8 },
      { id: 4, x: 750, y: 400, velocityX: 7, velocityY: 7 },
      { id: 5, x: 900, y: 100, velocityX: 6, velocityY: 7 },
    ],
    asteroid: [
      { id: 1, x: 100, y: 400, velocityX: 6, velocityY: 8, size: 200 },
      { id: 2, x: 10, y: 600, velocityX: 6, velocityY: 7, size: 300 },
      { id: 3, x: 900, y: 100, velocityX: 6, velocityY: 6, size: 250 },
      { id: 4, x: 200, y: 200, velocityX: 6, velocityY: 7, size: 200 },
      { id: 5, x: 640, y: 500, velocityX: 6, velocityY: 8, size: 220 },
    ],
  },
];

export const dataUfo = [
  {
    id: 1,
    sentence: "I am going to the _____",
    options: ["park", "house", "plane", "shop", "street"],
    answer: "park",
    bg: bg1,
  },
  {
    id: 2,
    sentence: "We will _____ in our car to the park in the country.",
    options: ["sail", "fly", "drive", "park", "street"],
    answer: "drive",
    bg: bg2,
  },
  {
    id: 3,
    sentence: "We can see cars and _____ and trucks on the road.",
    options: ["boats", "buses", "trees", "friends", "fly"],
    answer: "buses",
    bg: bg3,
  },
  {
    id: 3,
    sentence: "I can _____.",
    options: ["bug", "fly", "buss", "car", "sleep"],
    answer: "fly",
    bg: bg4,
  },
  {
    id: 3,
    sentence: "Welcome to the _____.",
    options: ["jungle", "happy", "trees", "friends", "fly"],
    answer: "jungle",
    bg: bg5,
  },
];

export const dataButterfly = [
  {
    id: 1,
    sentence: "I am going to the _____",
    options: ["park", "house", "plane", "shop", "street"],
    answer: "park",
    bg: bgA1,
  },
  {
    id: 2,
    sentence: "We will _____ in our car to the park in the country.",
    options: ["sail", "fly", "drive", "park", "street"],
    answer: "drive",
    bg: bgA2,
  },
  {
    id: 3,
    sentence: "We can see cars and _____ and trucks on the road.",
    options: ["boats", "buses", "trees", "friends", "fly"],
    answer: "buses",
    bg: bgA3,
  },
  {
    id: 3,
    sentence: "I can _____.",
    options: ["bug", "fly", "buss", "car", "sleep"],
    answer: "fly",
    bg: bgA4,
  },
  {
    id: 3,
    sentence: "Welcome to the _____.",
    options: ["jungle", "happy", "trees", "friends", "fly"],
    answer: "jungle",
    bg: bgA5,
  },
];

export const dataDinosaur = [
  {
    id: 1,
    sentence: "I am going to the _____",
    options: ["park", "house", "plane", "shop", "street"],
    answer: "park",
    bg: bgB1,
  },
  {
    id: 2,
    sentence: "We will _____ in our car to the park in the country.",
    options: ["sail", "fly", "drive", "park", "street"],
    answer: "drive",
    bg: bgB2,
  },
  {
    id: 3,
    sentence: "We can see cars and _____ and trucks on the road.",
    options: ["boats", "buses", "trees", "friends", "fly"],
    answer: "buses",
    bg: bgB3,
  },
  {
    id: 3,
    sentence: "I can _____.",
    options: ["bug", "fly", "buss", "car", "sleep"],
    answer: "fly",
    bg: bgB4,
  },
  {
    id: 3,
    sentence: "Welcome to the _____.",
    options: ["jungle", "happy", "trees", "friends", "fly"],
    answer: "jungle",
    bg: bgB5,
  },
];
