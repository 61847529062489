import { Button, ButtonProps, Circle, Flex, Text } from "@chakra-ui/react";
import React from "react";

interface ButtonRadio extends ButtonProps {
  text: string;
  isSelected: boolean;
  handleClick?: () => void;
  inPlaying?: boolean;
}

const ButtonRadio: React.FC<ButtonRadio> = ({
  isSelected,
  handleClick,
  inPlaying,
  text,
  ...rest
}) => {
  return (
    <Button
      py={["24px", "34px"]}
      px={["14px", "24px"]}
      bg="white"
      borderRadius="16px"
      border="1.5px solid #CBD5E0"
      gap="24px"
      cursor="pointer"
      aria-selected={isSelected}
      _selected={{ border: `2px solid #0e62bc ` }}
      _active={{}}
      onClick={handleClick}
      {...rest}
    >
      <Text fontWeight={700} fontSize={["18px", "24px"]} whiteSpace="pre-wrap">
        {text}
      </Text>
      {!inPlaying && (
        <Circle
          size="20px"
          border="1px solid #E2E8F0"
          aria-selected={isSelected}
          _selected={{ border: `6px solid #0e62bc ` }}
          onClick={handleClick}
        />
      )}
    </Button>
  );
};

export default ButtonRadio;
