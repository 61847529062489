import { Button, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { DragOption } from "../../@types/interfaces/api/drag-fill.interface";
import {
  IMatchingAnswerPost,
  MatchingPhrase,
} from "../../@types/interfaces/api/matching-exercse.interface";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useAudio } from "../../hooks/useAudio";
import { usePages } from "../../hooks/usePages";
import {
  clearMatchingList,
  loadMatchingRequest,
  onNextMatching,
  setAnswerMatching,
  setTimerMatching,
  setWrongAnswer,
} from "../../stores/ducks/matching/actions";
import { audioCorrectAnswer } from "../../utils/Utility";
import RegularButton from "../global/RegularButton";
import Subtitle from "../global/text/Subtitle";
import Text32px from "../global/text/Text32px";
import DragDropContextWrap from "./extra/DragDropContextWrap";
import DragOptionsBox from "./extra/DragOptionsBox";
import DropOnBlankSpace from "./extra/DropOnBlankSpace";
import { assetsBaseUrl } from "../../utils/assets-helper";
import { useLanguageContext } from "../../providers/LanguageProvider";
import { useAccesibiltyContext } from "../../providers/AccessibilityController";

interface Props {
  onNext: () => void;
}

function Matching({ onNext }: Props) {
  const { language } = useLanguageContext();
  const { setAudio } = useAudio(
    language,
    `${assetsBaseUrl()}/app-2.0/jr_html5/audio/dirs/ENG/drag_best_word.mp3`
  );
  const wrongAudio = `${assetsBaseUrl()}/app-2.0/jr_html5/audio/dirs/ENG/tryagain.mp3`;
  const dispatch = useAppDispatch();
  const { onPlayAudio } = useAccesibiltyContext();
  const { matchingData, answerMatching, wrongAnswer } = useAppSelector(
    (item) => item.matching
  );
  const { currentLesson, currentProgram } = useAppSelector(
    (item) => item.lesson
  );
  type State = {
    phrases: MatchingPhrase[];
    items: DragOption[];
    option1: DragOption[];
    option2: DragOption[];
    option3: DragOption[];
  };
  const initialState: State = {
    phrases: matchingData?.results[0].phrases ?? [],
    items: matchingData?.results[0].options ?? [],
    option1: [],
    option2: [],
    option3: [],
  };
  const [state, setState] = useState<State>(initialState);
  const [contentAnswer, setContentAnswer] = useState<
    IMatchingAnswerPost[] | []
  >([]);

  useEffect(() => {
    if (currentLesson) {
      dispatch(loadMatchingRequest({ activity__id: currentLesson.id }));
    }
    dispatch(setTimerMatching(Date.now()));
    return () => {
      dispatch(clearMatchingList());
    };
  }, []);

  useEffect(() => {
    if (matchingData) {
      setState(initialState);
    }
  }, [matchingData]);

  useEffect(() => {
    if (matchingData) {
      setContentAnswer([
        {
          answer: getWord(state.option1),
          phrase: state.phrases[0].id,
          history: 0,
        },
        {
          answer: getWord(state.option2),
          phrase: state.phrases[1].id,
          history: 0,
        },
        {
          answer: getWord(state.option3),
          phrase: state.phrases[2].id,
          history: 0,
        },
      ]);
    }
  }, [state.option1, state.option2, state.option3]);

  //function for shuffles words
  // function shuffle(array: any[]) {
  //   for (let i = array.length - 1; i > 0; i--) {
  //     const j = Math.floor(Math.random() * (i + 1));
  //     [array[i], array[j]] = [array[j], array[i]];
  //   }
  //   return array;
  // }

  const getWord = (array: DragOption[]): string => {
    return array.map((t) => t.text).join("");
  };

  function getResponse() {
    return [
      state.option1[0].text,
      state.option2[0].text,
      state.option3[0].text,
    ];
  }

  async function handleConfirm() {
    dispatch(setAnswerMatching(contentAnswer));
    const responseStudent = getResponse();
    if (matchingData) {
      const sortedIds = matchingData?.results[0].options.sort(
        (a, b) => a.id - b.id
      );
      const responseApi = sortedIds?.map((i) => i.text);

      let isCorrect = true;
      for (let i = 0; i < 3; i++) {
        const selected = responseStudent[i];
        const correctAnswer = responseApi[i];
        if (
          selected.toLocaleLowerCase() !== correctAnswer.toLocaleLowerCase()
        ) {
          isCorrect = false;
          break;
        }
      }
      if (!isCorrect) {
        setAudio(wrongAudio);
        setState(initialState);
        dispatch(setWrongAnswer());
        if (wrongAnswer >= 2) {
          onNext();
        }
        return;
      }
    }

    await onPlayAudio(audioCorrectAnswer());
    onNext();
  }

  const question1 = matchingData?.results[0].phrases[0].text.split(/_+/) ?? [];
  const question2 = matchingData?.results[0].phrases[1].text.split(/_+/) ?? [];
  const question3 = matchingData?.results[0].phrases[2].text.split(/_+/) ?? [];

  return (
    <Flex flexDir="column" alignItems={"center"}>
      <Subtitle>SELECT THE WORD AND DRAG TO MATCHING</Subtitle>
      <Text32px>Matching</Text32px>
      {matchingData && (
        <DragDropContextWrap
          alternatives={matchingData.results[0].options}
          state={state}
          setState={setState}
          currentProgram={currentProgram}
        >
          <Flex
            mt={["12px", "64px"]}
            align="center"
            gap={["24px", "100px"]}
            flexDir={["column", "row"]}
          >
            <DragOptionsBox alternatives={state.items} droppableId="items" />

            <Flex flexDir={"column"} align="flex-start" gap={3}>
              <DropOnBlankSpace
                droppableId="option1"
                alternatives={state.option1}
                text={question1}
              />
              <DropOnBlankSpace
                droppableId="option2"
                alternatives={state.option2}
                text={question2}
              />
              <DropOnBlankSpace
                droppableId="option3"
                alternatives={state.option3}
                text={question3}
              />
            </Flex>
          </Flex>
        </DragDropContextWrap>
      )}

      <RegularButton
        mt="60px"
        disabled={
          !(
            state.option1.length === 1 &&
            state.option2.length === 1 &&
            state.option3.length === 1
          )
        }
        onClick={handleConfirm}
      >
        Confirm (ok)
      </RegularButton>
    </Flex>
  );
}

export default Matching;
