import { Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import PageGrid from "../../../../components/global/PageGrid";
import StartLesson from "../../../../components/lessons/common/StartLesson";
import StoryLesson from "../../../../components/lessons/StoryLesson";
import { usePages } from "../../../../hooks/usePages";
import SpellingQuestion from "../../../../components/lessons/word/SpellingQuestion";
import ShowSynonymDefinition from "../../../../components/lessons/word/ShowSynonymDefinition";
import WordSelectQuestion from "../../../../components/lessons/word/WordSelectQuestion";
import WordSentence from "../../../../components/lessons/word/WordSentence";
import LessonSpelling from "../../../../components/lessons/LessonSpelling";
import Finish from "../../../../components/lessons/common/Finish";
import ContainerLesson from "../../../../components/global/ContainerLesson";
import ProgressBar from "../../../../components/global/ProgressBar";
import GreatJob from "../../../../components/lessons/common/GreatJob";
import { StoryFeed } from "../../../../@types/interfaces/api/pre-teaching.interface";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import {
  clearWordVmList,
  loadWordVmRequest,
  postAnswerWordVmRequest,
  setAlternativeWordVm,
  setFullResponseWordVm,
  setTimerWordVm,
} from "../../../../stores/ducks/word-vm/actions";
import { BASE_URL_MIDIA, urlsPaths } from "../../../../utils/Utility";
import { useNavigate } from "react-router-dom";
import { assetsBaseUrl } from "../../../../utils/assets-helper";
import { VerifyButtonHelp } from "../../life-skills/SpellingProgramPage";
import { useAccesibiltyContext } from "../../../../providers/AccessibilityController";
import LaunchLessonSubtitles from "../../../../components/lessons/common/LaunchLessonSubtitles";
import SubtitleComponent from "../../../../components/global/SubtitleComponent";

function WordPage() {
  const navigate = useNavigate();
  const [spellingQuestionHelp, setSpellingQuestionHelp] = useState(false);
  const [wrongAnswer, setWrongAnswer] = useState(false);
  const dispatch = useAppDispatch();
  const { currentLesson, activeButtonHelp, inAudio } = useAppSelector(
    (i) => i.lesson
  );
  const { setVideoWindow, captionText, accessibility } =
    useAccesibiltyContext();
  const { student } = useAppSelector((item) => item.auth);

  const {
    wordVmData,
    wordFeedData,
    spelling,
    spellingQuestionData,
    answers,
    isLoading,
  } = useAppSelector((i) => i.wordVm);

  useEffect(() => {
    dispatch(setTimerWordVm(Date.now()));
    dispatch(loadWordVmRequest({ activity__id: currentLesson?.id }));
    return () => {
      dispatch(clearWordVmList());
    };
  }, []);

  const verifyButtonHelp = (): VerifyButtonHelp => {
    if (pageName === "definition") {
      return {
        inactiveButton: true,
        audios: [""],
      };
    } else if (pageName === "sentence") {
      return {
        inactiveButton: false,
        audios: [
          `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/uh01.mp3`,
        ],
      };
    } else if (pageName === "selectQuestion") {
      return {
        inactiveButton: false,
        audios: [
          `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/uh02.mp3`,
        ],
      };
    } else if (pageName === "spelling") {
      return {
        inactiveButton: false,
        audios: [
          `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/uh04.mp3`,
        ],
      };
    } else if (pageName === "spellingQuestion" && spellingQuestionData) {
      return {
        inactiveButton: false,
        audios: spellingQuestionData.audio,
      };
    } else {
      return { inactiveButton: true, audios: [] };
    }
  };
  const initialPage = student?.progress.finished ? "start" : "accessibility";

  const { page, setPage, pageName } = usePages<
    | "start"
    | "greatJob"
    | "definition"
    | "sentence"
    | "selectQuestion"
    | "spelling"
    | "spellingQuestion"
    | "accessibility"
  >({
    initialPage,
    pages: {
      accessibility: <LaunchLessonSubtitles onNext={() => setPage("start")} />,

      start: wordVmData && (
        <StartLesson
          title={`Word (${wordVmData?.questions[0].word})`}
          onNext={() => setPage("definition")}
          button="start"
          videoExplanationUrl={`${BASE_URL_MIDIA}${wordVmData?.video_intro}`}
          introduction={[
            `${BASE_URL_MIDIA}${wordVmData?.questions[0].word_audio}`,
          ]}
        />
      ),
      definition: wordFeedData && (
        <ShowSynonymDefinition
          data={{ ...wordFeedData }}
          onNext={() => {
            if (wrongAnswer) {
              setPage("selectQuestion");
            } else {
              setPage("sentence");
            }
          }}
        />
      ),
      sentence: wordFeedData && (
        <WordSentence
          data={{ ...wordFeedData }}
          onNext={() => {
            setPage("selectQuestion");
          }}
        />
      ),
      selectQuestion: wordFeedData && (
        <WordSelectQuestion
          inAudio={inAudio}
          data={{ ...wordFeedData }}
          onWrong={() => {
            setWrongAnswer(true);
            setPage("definition");
          }}
          onCorrect={() => {
            setWrongAnswer(false);
            setPage("spelling");
          }}
        />
      ),
      spelling: (
        <LessonSpelling
          story={spelling}
          onFinish={async () => {
            setPage("spellingQuestion");
          }}
        />
      ),
      spellingQuestion: spellingQuestionData && (
        <SpellingQuestion
          data={{ spellingQuestionData, spellingHelp: spellingQuestionHelp }}
          onWrong={() => {
            setPage("spelling");
            setSpellingQuestionHelp(true);
            dispatch(setFullResponseWordVm());
          }}
          onCorrect={() => {
            dispatch(postAnswerWordVmRequest(setPage));
          }}
          saveResponse={(answer) => dispatch(setAlternativeWordVm(answer))}
        />
      ),
      greatJob: (
        <GreatJob
          onNext={() => {
            if (currentLesson) {
              navigate("/dashboard");
              setTimeout(() => {
                navigate(`/lesson/${urlsPaths[currentLesson?.mechanic_type]}`);
              }, 2);
            }
          }}
        />
      ),
    },
  });

  return (
    <PageGrid
      activity="Word"
      lesson="Verbal Masters"
      disabledButton={
        verifyButtonHelp().inactiveButton ||
        pageName === "accessibility" ||
        inAudio
      }
      audioHelp={verifyButtonHelp().audios}
    >
      <ContainerLesson>
        {page}
        <SubtitleComponent
          hide={
            !accessibility.subtitles ||
            !inAudio ||
            captionText === "" ||
            pageName === "greatJob"
          }
        />

        {/* <ProgressBar data={{ max: 12, step: 1 }} hide={pageName === "start"} /> */}
      </ContainerLesson>
    </PageGrid>
  );
}

export default WordPage;
