import { createStandaloneToast } from "@chakra-ui/react";
import theme from "../../../styles/theme";
import { AxiosResponse } from "axios";
import { call, put, select } from "redux-saga/effects";
import api from "../../../services/api";
import { AnyAction } from "redux";
import { ApplicationState, getGlobalState } from "..";
import { postProgress } from "../word-recognition/sagas";
import { ActivityIdFilter } from "../../../@types/interfaces/api/talking-book.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import { IReadingComprehension } from "../../../@types/interfaces/api/reading-comprehension";
import {
  loadRComprehensionError,
  loadRComprehensionSuccess,
  postAnswerRComprehensionSuccess,
  postAnswerRComprehensionError,
} from "./actions";
import {
  HistoryRecognition,
  HistoryRecognitionResponse,
} from "../../../@types/interfaces/api/word-recognition.interface";

const { toast } = createStandaloneToast({ theme });

export function* loadRComprehension(action: AnyAction) {
  const filter: ActivityIdFilter = action.payload;

  try {
    const response: AxiosResponse<PaginationBase<IReadingComprehension[]>> =
      yield call(api.get, `/reading-comprehension`, {
        params: filter,
      });
    if (response.status == 200) {
      yield put(loadRComprehensionSuccess(response.data.results[0]));
    }
  } catch (e: any) {
    yield put(loadRComprehensionError());
  }
}

export function* postHistoryRComprehension() {
  const data: ApplicationState = yield select(getGlobalState);

  const timeLesson = Date.now() - data.readingComprehension.dateMileseconds;

  const dataHitory: HistoryRecognition = {
    time: timeLesson,
    student: data.auth.student?.user,
  };

  try {
    const response: AxiosResponse = yield call(
      api.post,
      "/history",
      dataHitory
    );
    if (response.status == 201) {
      return response.data;
    }
  } catch (e: any) {
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* postAnswerRComprehension(action: AnyAction) {
  const data: ApplicationState = yield select(getGlobalState);
  const setPage = action.payload.setPage;
  const activity = data.readingComprehension.readingComprehensionData?.activity;

  try {
    const responseHistory: HistoryRecognitionResponse = yield call(
      postHistoryRComprehension
    );

    const completeData = data.readingComprehension.answersRC.map((answer) => {
      return { ...answer, history: responseHistory.id };
    });

    const response: AxiosResponse = yield call(
      api.post,
      "/reading-comprehension/answer",
      completeData
    );
    if (response.status === 201) {
      yield put(postAnswerRComprehensionSuccess());
      yield call(postProgress, activity);
    }
  } catch (e: any) {
    yield put(postAnswerRComprehensionError());
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}
