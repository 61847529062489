import store from "..";
import {
  IAlphabetical,
  IAlphabeticalAnswerPost,
} from "../../../@types/interfaces/api/alphabetical-order.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import { IScrambledAnswerPost } from "../../../@types/interfaces/api/scrambled-sentence.interface";

import { ActivityIdFilter } from "../../../@types/interfaces/api/talking-book.interface";
import { AlphabeticalOrderActionTypes } from "./type";

export const loadAlphabeticalRequest = (filter: ActivityIdFilter) => ({
  type: AlphabeticalOrderActionTypes.LOAD_ALPHABETICAL_REQUEST,
  payload: filter,
});

export const loadAlphabeticalSuccess = (
  data: PaginationBase<IAlphabetical>
) => ({
  type: AlphabeticalOrderActionTypes.LOAD_ALPHABETICAL_SUCCESS,
  payload: data,
});

export const loadAlphabeticalError = () => ({
  type: AlphabeticalOrderActionTypes.LOAD_ALPHABETICAL_ERROR,
});

export const clearAlphabeticalList = () => {
  return {
    type: AlphabeticalOrderActionTypes.CLEAR_LIST_ALPHABETICAL,
  };
};

export const setTimerAlphabetical = (date: number) => {
  return {
    type: AlphabeticalOrderActionTypes.SET_TIMER_ALPHABETICAL,
    payload: date,
  };
};

export const setWrongAlphabetical = () => {
  return {
    type: AlphabeticalOrderActionTypes.SET_WRONG_ANSWER_ALPHABETICAL,
  };
};

export const setAnswerAlphabetical = (data: IScrambledAnswerPost[]) => {
  return {
    type: AlphabeticalOrderActionTypes.SET_ANSWER_ALPHABETICAL,
    payload: data,
  };
};

export const onNextAlphabetical = (setPage: (page: any) => void) => {
  const contentListAnswer = store.getState().alphabeticalOrder;

  const contentAnswer = contentListAnswer.answerAlphabetical;
  setPage("greatJob");
  return {
    type: AlphabeticalOrderActionTypes.POST_ALPHABETICAL_REQUEST,
    payload: { contentAnswer, setPage },
  };
};
export const postAlphabeticalSuccess = () => {
  return {
    type: AlphabeticalOrderActionTypes.POST_ALPHABETICAL_SUCCESS,
  };
};

export const postAlphabeticalError = () => {
  return {
    type: AlphabeticalOrderActionTypes.POST_ALPHABETICAL_ERROR,
  };
};
