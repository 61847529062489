import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const ClassIcon: React.FC<IconProps> = (props) => {
  return (
    <Icon
      width="48px"
      height="48px"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="52" height="52" rx="26" fill="#E5F0FD" />
      <path
        d="M31.3333 38V35.3333C31.3333 33.9188 30.7714 32.5623 29.7712 31.5621C28.771 30.5619 27.4145 30 26 30H18C16.5855 30 15.2289 30.5619 14.2288 31.5621C13.2286 32.5623 12.6667 33.9188 12.6667 35.3333V38"
        stroke="#0C4DA2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 24.6667C24.9455 24.6667 27.3333 22.2789 27.3333 19.3333C27.3333 16.3878 24.9455 14 22 14C19.0545 14 16.6667 16.3878 16.6667 19.3333C16.6667 22.2789 19.0545 24.6667 22 24.6667Z"
        stroke="#0C4DA2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.3333 38V35.3333C39.3325 34.1516 38.9392 33.0037 38.2152 32.0698C37.4912 31.1358 36.4775 30.4688 35.3333 30.1733"
        stroke="#0C4DA2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.3333 14.1733C32.4806 14.4671 33.4974 15.1343 34.2235 16.0698C34.9497 17.0052 35.3438 18.1558 35.3438 19.34C35.3438 20.5242 34.9497 21.6748 34.2235 22.6103C33.4974 23.5457 32.4806 24.2129 31.3333 24.5067"
        stroke="#0C4DA2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default ClassIcon;
