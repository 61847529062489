import { Flex, Heading, Text } from "@chakra-ui/react";
import { ArrowRight, X } from "lucide-react";
import React, { useEffect } from "react";
import DoneIcon from "../../../assets/icons/DoneIcon";
import GreatJobIcon from "../../../assets/icons/GreatJobIcon";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { loadStudentByIdRequest } from "../../../stores/ducks/auth/actions";
import {
  loadActivityRequest,
  loadNextActivityLessonRequest,
} from "../../../stores/ducks/dashboard/actions";
import {
  loadCurrentLessonRequest,
  setCurrentLesson,
} from "../../../stores/ducks/lesson/action";
import RegularButton from "../../global/RegularButton";
import Text32px from "../../global/text/Text32px";

interface FinishProps {
  onNext?: () => void;
}

const Finish: React.FC<FinishProps> = ({ onNext }) => {
  const dispatch = useAppDispatch();
  const { student } = useAppSelector((i) => i.auth);

  useEffect(() => {
    if (student) {
      dispatch(loadStudentByIdRequest(student?.user));
      dispatch(loadNextActivityLessonRequest());
    }
  }, []);

  useEffect(() => {
    dispatch(loadCurrentLessonRequest());
    if (student?.progress.activity) {
      dispatch(loadActivityRequest(student.progress.activity));
    }
  }, [student]);

  return (
    <Flex flexDir="column" align="center" justify="center" mt="80px">
      <DoneIcon />
      <Text32px mt="32px">End of this part of the Lesson</Text32px>
      <Text
        color="gray.500"
        fontSize="18px"
        fontWeight="500"
        maxW="676px"
        align="center"
        mt="16px"
      >
        You have completed this bookmark. Please talk with your teacher in order
        to continue to the next bookmark.
      </Text>

      <RegularButton rightIcon={<X />} mt="56px" onClick={onNext}>
        Quit
      </RegularButton>
    </Flex>
  );
};

export default Finish;
