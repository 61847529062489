import { Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContainerLesson from "../../components/global/ContainerLesson";
import PageGrid from "../../components/global/PageGrid";
import ProgressBar from "../../components/global/ProgressBar";
import GreatJob from "../../components/lessons/common/GreatJob";
import StartLesson from "../../components/lessons/common/StartLesson";
import QuestionAndAnswers from "../../components/lessons/QuestionAndAnswers";
import { usePages } from "../../hooks/usePages";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  clearRComprehensionList,
  loadRComprehensionRequest,
  onNextRComprehension,
  setAlternativeRC,
  setAnswerRComprehension,
  setTimerRComprehension,
} from "../../stores/ducks/reading-comprehension/actions";
import {
  BASE_URL_MIDIA,
  audioCorrectAnswer,
  audioCorrectAnswerOgg,
  urlsPaths,
} from "../../utils/Utility";
import { assetsBaseUrl } from "../../utils/assets-helper";
import { useLanguageContext } from "../../providers/LanguageProvider";
import { useAccesibiltyContext } from "../../providers/AccessibilityController";
import LaunchLesson from "../../components/lessons/common/LaunchLesson";
import SubtitleComponent from "../../components/global/SubtitleComponent";

function ReadingComprehensionPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currentLesson, activeButtonHelp, inAudio } = useAppSelector(
    (i) => i.lesson
  );
  const { readingComprehensionData, questionIndex, alternativeRC, answersRC } =
    useAppSelector((i) => i.readingComprehension);
  const { setVideoWindow, playMedias, accessibility, captionText } =
    useAccesibiltyContext();
  const { student } = useAppSelector((b) => b.auth);

  useEffect(() => {
    setVideoWindow(true);
    dispatch(setTimerRComprehension(Date.now()));
    if (currentLesson) {
      dispatch(loadRComprehensionRequest({ activity__id: currentLesson?.id }));
    }
    return () => {
      setVideoWindow(false);

      dispatch(clearRComprehensionList());
    };
  }, []);

  async function checkResponse() {
    const correctAnswer =
      readingComprehensionData?.questions[questionIndex].answer;
    const selectedAnswer = alternativeRC;

    if (
      correctAnswer &&
      correctAnswer.toLocaleLowerCase() !== selectedAnswer.toLocaleLowerCase()
    ) {
      const tryAgainAudio = [
        `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/TRYAGAIN.mp3`,
        `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in74.ogg`,
      ];
      dispatch(setAnswerRComprehension());
      await playMedias([...tryAgainAudio]);
      return;
    }

    await playMedias([audioCorrectAnswer(), audioCorrectAnswerOgg()]);
    dispatch(onNextRComprehension(setPage));
  }
  const initialPage = student?.progress.finished ? "start" : "accessibility";

  const { page, setPage, pageName } = usePages<
    "start" | "question" | "greatJob" | "accessibility"
  >({
    initialPage,
    pages: {
      accessibility: <LaunchLesson onNext={() => setPage("start")} />,
      start: (
        <StartLesson
          title="Reading Comprehension"
          inAudio={inAudio}
          onNext={() => setPage("question")}
          introduction={[
            `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/WELCRCMP.mp3`,
            `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in52.ogg`,
          ]}
          button="start"
        />
      ),
      question: readingComprehensionData && (
        <QuestionAndAnswers
          audio={{
            intro: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/RCOMPDIR.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in53.ogg`,
            ],
            text: [
              `${BASE_URL_MIDIA}${readingComprehensionData?.questions[questionIndex].audio}`,
              `${BASE_URL_MIDIA}${readingComprehensionData?.questions[
                questionIndex
              ].audio.replace(".mp3", ".ogg")}`,
            ],
          }}
          text={readingComprehensionData?.questions[questionIndex].text}
          options={readingComprehensionData?.questions[questionIndex].options}
          selectedAnswer={alternativeRC}
          setAnswer={(answer) => dispatch(setAlternativeRC(answer))}
          questionIndex={questionIndex}
        />
      ),
      greatJob: (
        <GreatJob
          onNext={() => {
            if (currentLesson) {
              navigate(`/lesson/${urlsPaths[currentLesson?.mechanic_type]}`);
            }
          }}
        />
      ),
    },
  });

  return (
    <PageGrid
      activity="Reading Comprehension"
      lesson="Josef's Reading"
      onHelp={() => {}}
      disabledButton={
        !activeButtonHelp || pageName === "accessibility" || inAudio
      }
      audioHelp={[
        `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/RCOMPDIR.mp3`,
        `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in53.ogg`,
      ]}
    >
      <ContainerLesson>
        {page}
        <Flex flexDir="column" gap="4px">
          <SubtitleComponent
            hide={
              !accessibility.subtitles ||
              !inAudio ||
              captionText === "" ||
              pageName === "greatJob"
            }
          />
          {readingComprehensionData && (
            <ProgressBar
              data={{
                max: readingComprehensionData?.questions.length,
                step: questionIndex + 1,
              }}
              hide={pageName !== "question"}
              onConfirm={checkResponse}
              hideButtonPrevious
              hideButton={alternativeRC === "" || inAudio}
            />
          )}
        </Flex>
      </ContainerLesson>
    </PageGrid>
  );
}

export default ReadingComprehensionPage;
