import { Reducer } from "redux";
import { PostTestVmActionTypes, PostTestVmState } from "./type";

const INITIAL_STATE: PostTestVmState = {
  isLoading: false,
  answersPostTestVm: [],
  dateMileseconds: 0,
  postTestVmData: undefined,
  questionIndex: 0,
  typeLesson: "synonym",
  alternativeSnonym: undefined,
  alternativeDefinition: undefined,
};

const reducer: Reducer<PostTestVmState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PostTestVmActionTypes.LOAD_POST_TEST_VM_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case PostTestVmActionTypes.LOAD_POST_TEST_VM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        postTestVmData: action.payload,
      };
    case PostTestVmActionTypes.LOAD_POST_TEST_VM_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case PostTestVmActionTypes.ON_NEXT_SYNONYM:
      return {
        ...state,
        questionIndex: state.questionIndex + 1,
        alternativeSnonym: undefined,
        alternativeDefinition: undefined,
        answersPostTestVm: [
          ...state.answersPostTestVm,
          action.payload.contentAnswer,
        ],
      };
    case PostTestVmActionTypes.ON_NEXT_DEFINITION:
      return {
        ...state,
        questionIndex: state.questionIndex + 1,
        alternativeSnonym: undefined,
        alternativeDefinition: undefined,
        answersPostTestVm: action.payload.contentAnswer,
      };

    case PostTestVmActionTypes.SET_TYPE_POST_TEST_VM:
      return {
        ...state,
        typeLesson: action.payload,
      };

    case PostTestVmActionTypes.SET_TYPE_DEFINITION:
      return {
        ...state,
        typeLesson: "definition",
        questionIndex: 0,
        alternativeSnonym: undefined,
        alternativeDefinition: undefined,
        answersPostTestVm: [
          ...state.answersPostTestVm,
          action.payload.contentAnswer,
        ],
      };
    case PostTestVmActionTypes.SET_ALTERNATIVE_SYNONYM:
      return {
        ...state,
        alternativeSnonym: action.payload,
      };
    case PostTestVmActionTypes.SET_ALTERNATIVE_DEFINITION:
      return {
        ...state,
        alternativeDefinition: action.payload,
      };
    case PostTestVmActionTypes.ON_PREVIOUS_POST_TEST_VM:
      return {
        ...state,
        questionIndex: state.questionIndex - 1,
        alternativeSnonym: undefined,
        alternativeDefinition: undefined,
      };

    case PostTestVmActionTypes.POST_POST_TEST_VM_REQUEST:
      return {
        ...state,
        answersPostTestVm: action.payload.contentAnswer,
        isLoading: true,
      };
    case PostTestVmActionTypes.POST_POST_TEST_VM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        questionIndex: 0,
        alternativeSnonym: undefined,
        alternativeDefinition: undefined,
        answersPostTestVm: [],
      };
    case PostTestVmActionTypes.POST_POST_TEST_VM_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case PostTestVmActionTypes.SET_TIMER_POST_TEST_VM:
      return {
        ...state,
        dateMileseconds: action.payload,
      };

    case PostTestVmActionTypes.CLEAR_LIST:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default reducer;
