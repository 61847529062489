import { Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import PageGrid from "../../../../components/global/PageGrid";
import DragToCorrectPlace, {
  DragToCorrectPlaceData,
  OptionSelected,
} from "../../../../components/lessons/DragToCorrectPlace";
import DragToUncover, {
  DragToUncoverData,
} from "../../../../components/lessons/DragToUncover";

import { usePages } from "../../../../hooks/usePages";
import ContainerLesson from "../../../../components/global/ContainerLesson";
import ProgressBar from "../../../../components/global/ProgressBar";
import Finish from "../../../../components/lessons/common/Finish";
import GreatJob from "../../../../components/lessons/common/GreatJob";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import {
  loadUnitReviewDefinitionRequest,
  clearUnitReviewDefinitionList,
  onNextUnitReviewDefinition1,
  onNextUnitReviewDefinition2,
  setAnswerUnitReviewDefinition,
  setTimerUnitReviewDefinition,
} from "../../../../stores/ducks/unit-review-definition/actions";
import { BASE_URL_MIDIA, urlsPaths } from "../../../../utils/Utility";
import { useNavigate } from "react-router-dom";
import ShowSynonymDefinition, {
  ShowSynonymDefinitionData,
} from "../../../../components/lessons/word/ShowSynonymDefinition";
import { assetsBaseUrl } from "../../../../utils/assets-helper";
import { VerifyButtonHelp } from "../../life-skills/SpellingProgramPage";
import StartLesson from "../../../../components/lessons/common/StartLesson";
import { useAccesibiltyContext } from "../../../../providers/AccessibilityController";
import LaunchLessonSubtitles from "../../../../components/lessons/common/LaunchLessonSubtitles";
import SubtitleComponent from "../../../../components/global/SubtitleComponent";

function UnitReviewDefinition() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [lastPage, setLastPage] = useState("");
  const [hasRendered, setHasRendered] = useState(false);
  const [dataSorted, setDataSorted] = useState<OptionSelected[]>([]);
  const [firstClick, setFirstClick] = useState(true);
  const { currentLesson, inAudio } = useAppSelector((i) => i.lesson);
  const { unitReviewDefinitionData, questionIndex, answers, isLoading } =
    useAppSelector((i) => i.unitReviewDefinition);
  const { setVideoWindow, captionText, accessibility } =
    useAccesibiltyContext();
  const { student } = useAppSelector((item) => item.auth);

  function tryReturnToLastPage() {
    if (lastPage) {
      setPage(lastPage as any);
      setLastPage("");
    }
  }

  useEffect(() => {
    dispatch(setTimerUnitReviewDefinition(Date.now()));
    dispatch(
      loadUnitReviewDefinitionRequest({ activity__id: currentLesson?.id })
    );
    return () => {
      dispatch(clearUnitReviewDefinitionList());
    };
  }, []);

  useEffect(() => {
    if (!hasRendered && unitReviewDefinitionData) {
      setHasRendered(true);
      const sortedOptions = unitReviewDefinitionData.words
        .map((item) => ({
          id: String(item.id),
          text: item.definition,
        }))
        .sort(() => Math.random() - 0.5);

      setDataSorted(sortedOptions);
    }
  }, [unitReviewDefinitionData]);

  useEffect(() => {
    setFirstClick(true);
  }, [questionIndex]);

  let uncoverWordDefinition: DragToUncoverData | undefined = undefined;

  if (unitReviewDefinitionData) {
    uncoverWordDefinition = {
      introduction: {
        audio: `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/d01.mp3`,
      },
      frontCard: {
        text: unitReviewDefinitionData?.words[questionIndex].word,
        audio: `${BASE_URL_MIDIA}${unitReviewDefinitionData?.words[questionIndex].word_audio}`,
      },
      backCard: {
        text: unitReviewDefinitionData?.words[questionIndex].definition,
        audio: `${BASE_URL_MIDIA}${unitReviewDefinitionData?.words[questionIndex].definition_audio}`,
      },
    };
  }

  let dragToCorrectPlace: DragToCorrectPlaceData | undefined = undefined;

  if (unitReviewDefinitionData) {
    dragToCorrectPlace = {
      introduction: {
        audio: `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/d02.mp3`,
      },
      word: {
        text: unitReviewDefinitionData?.words[questionIndex].word,
        audio: `${BASE_URL_MIDIA}${unitReviewDefinitionData?.words[questionIndex].word_audio}`,
      },
      response: {
        id: String(unitReviewDefinitionData?.words[questionIndex].id),
        correct: `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/praise02.mp3`,
        wrong: `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/d18.mp3`,
      },
      options: dataSorted,
    };
  }
  let wordDefinition: ShowSynonymDefinitionData | undefined = undefined;
  if (unitReviewDefinitionData) {
    wordDefinition = {
      word: {
        text: unitReviewDefinitionData?.words[questionIndex].word,
        audio: `${BASE_URL_MIDIA}${unitReviewDefinitionData?.words[questionIndex].word_audio}`,
      },
      synonym: {
        text: unitReviewDefinitionData?.words[questionIndex].synonym,
        audio: `${BASE_URL_MIDIA}${unitReviewDefinitionData?.words[questionIndex].synonym_audio}`,
      },
      definition: {
        text: unitReviewDefinitionData?.words[questionIndex].definition,
        audio: `${BASE_URL_MIDIA}${unitReviewDefinitionData?.words[questionIndex].definition_audio}`,
      },
    };
  }

  const verifyButtonHelp = (): VerifyButtonHelp => {
    if (pageName === "dragViewDefinition") {
      return {
        inactiveButton: false,
        audios: [
          `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/d01.mp3`,
        ],
      };
    } else if (pageName === "dragToDefinitionExercise") {
      return {
        inactiveButton: false,
        audios: [
          `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/d02.mp3`,
        ],
      };
    } else {
      return { inactiveButton: true, audios: [] };
    }
  };
  const initialPage = student?.progress.finished ? "start" : "accessibility";

  const { page, setPage, pageName } = usePages<
    | "start"
    | "greatJob"
    | "wordDefinition"
    | "dragViewDefinition"
    | "dragToDefinitionExercise"
    | "accessibility"
  >({
    initialPage,
    pages: {
      start: (
        <StartLesson
          title="Unit Review Definition"
          onNext={() => setPage("dragViewDefinition")}
          button="start"
        />
      ),
      accessibility: <LaunchLessonSubtitles onNext={() => setPage("start")} />,

      wordDefinition: wordDefinition && (
        <ShowSynonymDefinition
          data={wordDefinition}
          onNext={() => {
            tryReturnToLastPage();
          }}
        />
      ),

      dragViewDefinition: uncoverWordDefinition && (
        <DragToUncover
          data={uncoverWordDefinition}
          questionIndex={questionIndex}
        />
      ),
      dragToDefinitionExercise: dragToCorrectPlace && (
        <DragToCorrectPlace
          questionIndex={questionIndex}
          data={dragToCorrectPlace}
          onWrong={(answer) => {
            setFirstClick(false);
            setLastPage("dragToDefinitionExercise");
            setPage("wordDefinition");
            if (firstClick) {
              dispatch(setAnswerUnitReviewDefinition(answer.text));
            }
          }}
          onNext={(answer) => {
            setFirstClick(false);
            if (firstClick) {
              dispatch(setAnswerUnitReviewDefinition(answer.text));
            }
            dispatch(onNextUnitReviewDefinition2(setPage));
          }}
        />
      ),
      greatJob: (
        <GreatJob
          onNext={() => {
            if (currentLesson) {
              navigate(`/lesson/${urlsPaths[currentLesson?.mechanic_type]}`);
            }
          }}
        />
      ),
    },
  });

  return (
    <PageGrid
      activity="Review"
      lesson="Verbal Masters"
      onHelp={() => {}}
      disabledButton={
        verifyButtonHelp().inactiveButton || pageName === "accessibility"
      }
      audioHelp={verifyButtonHelp().audios}
    >
      <ContainerLesson>
        {page}
        <Flex flexDir="column" gap="4px">
          <SubtitleComponent
            hide={
              !accessibility.subtitles ||
              !inAudio ||
              captionText === "" ||
              pageName === "greatJob"
            }
          />
          {unitReviewDefinitionData && (
            <ProgressBar
              data={{
                max: unitReviewDefinitionData?.words.length,
                step: questionIndex + 1,
              }}
              hide={pageName !== "dragViewDefinition"}
              onConfirm={() => dispatch(onNextUnitReviewDefinition1(setPage))}
              hideButtonPrevious
              hideButton={inAudio}
            />
          )}
        </Flex>
      </ContainerLesson>
    </PageGrid>
  );
}

export default UnitReviewDefinition;
