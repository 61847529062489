import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const LessonRedIcon: React.FC<IconProps> = (props) => {
  return (
    <Icon
      width="48px"
      height="48px"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="48" height="48" rx="24" fill="#EDF2F7" />
      <path
        d="M13.3333 33.9998C13.3333 33.1158 13.6845 32.2679 14.3097 31.6428C14.9348 31.0177 15.7826 30.6665 16.6667 30.6665H34.6667"
        stroke="#E53E3E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6667 10.6665H34.6667V37.3332H16.6667C15.7826 37.3332 14.9348 36.982 14.3097 36.3569C13.6845 35.7317 13.3333 34.8839 13.3333 33.9998V13.9998C13.3333 13.1158 13.6845 12.2679 14.3097 11.6428C14.9348 11.0177 15.7826 10.6665 16.6667 10.6665V10.6665Z"
        stroke="#E53E3E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default LessonRedIcon;
