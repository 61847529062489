import store from "..";
import AnyAction from "redux";
import { UnitReviewComprehensionActionTypes } from "./type";
import UnitReviewComprehensionFilterInterface, {
  IUnitReviewComprehension,
  UnitReviewComprehensionAnswer,
} from "../../../@types/interfaces/api/unit-review-reading-comprehension";

export const loadUnitReviewComprehensionRequest = (
  filter: UnitReviewComprehensionFilterInterface
) => ({
  type: UnitReviewComprehensionActionTypes.LOAD_QUESTION_UNIT_REVIEW_COMPREHENSION_REQUEST,
  payload: filter,
});

export const loadUnitReviewComprehensionSuccess = (
  data: IUnitReviewComprehension
) => {
  return {
    type: UnitReviewComprehensionActionTypes.LOAD_QUESTION_UNIT_REVIEW_COMPREHENSION_SUCCESS,
    payload: data,
  };
};
export const loadUnitReviewComprehensionError = () => ({
  type: UnitReviewComprehensionActionTypes.LOAD_QUESTION_UNIT_REVIEW_COMPREHENSION_ERROR,
});

export const clearUnitReviewComprehensionList = () => {
  return {
    type: UnitReviewComprehensionActionTypes.CLEAR_LIST,
  };
};

export const setAnswerUnitReviewComprehension = () => {
  const contentListAnswer = store.getState().unitReviewReadingCcomprehension;

  const chosenAnswer = contentListAnswer.alternativeUnitReviewComprehension;
  const question =
    contentListAnswer.unitReviewComprehensionData?.questions[
      contentListAnswer.questionIndex
    ].id;
  const contentAnswer = {
    answer: chosenAnswer,
    question,
    history: 0,
  };
  return {
    type: UnitReviewComprehensionActionTypes.SET_ANSWER_UNIT_REVIEW_COMPREHENSION,
    payload: contentAnswer,
  };
};
export const setAlternativeUnitReviewComprehension = (alternative: string) => {
  return {
    type: UnitReviewComprehensionActionTypes.SET_ALTERNATIVE_UNIT_REVIEW_COMPREHENSION,
    payload: alternative,
  };
};

export const onNextUnitReviewComprehension = (setPage: (page: any) => void) => {
  const contentListAnswer = store.getState().unitReviewReadingCcomprehension;

  if (
    contentListAnswer.unitReviewComprehensionData &&
    contentListAnswer.questionIndex <
      contentListAnswer.unitReviewComprehensionData.questions.length - 1
  ) {
    return {
      type: UnitReviewComprehensionActionTypes.ON_NEXT,
      payload: { setPage },
    };
  } else {
    return {
      type: UnitReviewComprehensionActionTypes.POST_ANSWER_UNIT_REVIEW_COMPREHENSION_REQUEST,
      payload: { setPage },
    };
  }
};

export const postAnswerUnitReviewComprehensionRequest = (
  data: UnitReviewComprehensionAnswer[]
) => {
  return {
    type: UnitReviewComprehensionActionTypes.POST_ANSWER_UNIT_REVIEW_COMPREHENSION_REQUEST,
    payload: data,
  };
};

export const postAnswerUnitReviewComprehensionSuccess = () => ({
  type: UnitReviewComprehensionActionTypes.POST_ANSWER_UNIT_REVIEW_COMPREHENSION_SUCCESS,
});
export const postAnswerUnitReviewComprehensionError = () => ({
  type: UnitReviewComprehensionActionTypes.POST_ANSWER_UNIT_REVIEW_COMPREHENSION_ERROR,
});

export const setTimerUnitReviewComprehension = (date: number) => {
  return {
    type: UnitReviewComprehensionActionTypes.SET_TIMER_UNIT_REVIEW_COMPREHENSION,
    payload: date,
  };
};
// export const onPreviousUnitReviewComprehension = () => {
//   const contentListAnswer = store.getState().wordVm;
//   const answerArray = contentListAnswer.answers;

//   answerArray.pop();

//   return {
//     type: UnitReviewComprehensionActionTypes.ON_PREVIOUS,
//     payload: answerArray,
//   };
// };
