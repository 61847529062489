import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import {
  AnswerPretest,
  IPretest,
} from "../../../@types/interfaces/api/pre-test.interface";

export enum PreTestActionTypes {
  LOAD_QUESTIONS_PRE_TEST_REQUEST = "@preTest/LOAD_QUESTIONS_PRE_TEST_REQUEST",
  LOAD_QUESTIONS_PRE_TEST_SUCCESS = "@preTest/LOAD_QUESTIONS_PRE_TEST_SUCCESS",
  LOAD_QUESTIONS_PRE_TEST_ERROR = "@preTest/LOAD_QUESTIONS_PRE_TEST_ERROR",

  POST_ANSWER_PRE_TEST_REQUEST = "@preTest/POST_ANSWER_PRE_TEST_REQUEST",
  POST_ANSWER_PRE_TEST_SUCCESS = "@preTest/POST_ANSWER_PRE_TEST_SUCCESS",
  POST_ANSWER_PRE_TEST_ERROR = "@preTest/POST_ANSWER_PRE_TEST_ERROR",

  POST_ANSWER_PRE_TEST_LIFE_SKILLS_REQUEST = "@preTest/POST_ANSWER_PRE_TEST_LIFE_SKILLS_REQUEST",
  POST_ANSWER_PRE_TEST_LIFE_SKILLS_SUCCESS = "@preTest/POST_ANSWER_PRE_TEST_LIFE_SKILLS_SUCCESS",
  POST_ANSWER_PRE_TEST_LIFE_SKILLS_ERROR = "@preTest/POST_ANSWER_PRE_TEST_LIFE_SKILLS_ERROR",

  SET_ALTERNATIVE_PRE_TEST = "@preTest/ SET_ALTERNATIVE_PRE_TEST",
  SET_FULL_ALTERNATIVE_PRE_TEST = "@preTest/ SET_FULL_ALTERNATIVE_PRE_TEST",
  SET_PAGE_NUMBER_PRE_TEST = "@preTest/ SET_PAGE_NUMBER_PRE_TEST",
  SET_CORRECT_ANSWER_PRE_TEST = "@preTest/ SET_CORRECT_ANSWER_PRE_TES",
  SET_TIMER_PRETEST = "@preTest/ SET_TIMER_PRETEST",
  ON_NEXT = "@preTest/ ON_NEXT",
  DO_NOT_STOP = "@preTest/ DO_NOT_STOP",
  ON_PREVIOUS = "@preTest/ PREVIOUS",
  CLEAR_LIST = "@preTest/CLEAR_LIST",
}

export interface PreTestState extends PreTestStateRequestsStatus {
  preTestData: PaginationBase<IPretest[]> | undefined;
  answers: AnswerPretest[];
  alternativePreTest: string;
  questionIndex: number;
  selectedAnswer: string;
  numberOfWrongAnswers: number;
  numberOfCorrectAnswers: number;
  pageNumber: number;
  levelId: number;
  dateMileseconds: number;
  fullAlternative: string;
  inactiveButton: boolean;
  storyOrder: number;
}

interface PreTestStateRequestsStatus {
  isLoading: boolean;
}
