import { Flex, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IScrambledAnswerPost } from "../../@types/interfaces/api/scrambled-sentence.interface";
import ContainerLesson from "../../components/global/ContainerLesson";
import PageGrid from "../../components/global/PageGrid";
import ProgressBar from "../../components/global/ProgressBar";
import Finish from "../../components/lessons/common/Finish";
import GreatJob from "../../components/lessons/common/GreatJob";
import StartLesson from "../../components/lessons/common/StartLesson";
import ScrambledSentence from "../../components/lessons/ScrambledSentence";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { usePages } from "../../hooks/usePages";
import {
  clearAlphabeticalList,
  loadAlphabeticalRequest,
  onNextAlphabetical,
  setAnswerAlphabetical,
  setTimerAlphabetical,
} from "../../stores/ducks/alphabetical-order/actions";
import { urlsPaths } from "../../utils/Utility";
import { assetsBaseUrl } from "../../utils/assets-helper";

function AlphabeticalOrderPage() {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { alphabeticalData, wrongAnswer, answerAlphabetical, isLoading } =
    useAppSelector((item) => item.alphabeticalOrder);
  const { currentLesson, currentProgram } = useAppSelector((i) => i.lesson);

  useEffect(() => {
    if (currentLesson) {
      dispatch(loadAlphabeticalRequest({ activity__id: currentLesson.id }));
    }
    dispatch(setTimerAlphabetical(Date.now()));
    return () => {
      dispatch(clearAlphabeticalList());
    };
  }, []);

  const setAnswers = (data: IScrambledAnswerPost[]) => {
    dispatch(setAnswerAlphabetical(data));
  };

  const audios = {
    incomplete: `${assetsBaseUrl()}/app-2.0/jr_html5/audio/dirs/ENG/ABC_DIRECTIONS.mp3`,
    intro: `${assetsBaseUrl()}/app-2.0/jr_html5/audio/dirs/ENG/ABC_DIRECTIONS.mp3`,
    wrong: `${assetsBaseUrl()}/app-2.0/jr_html5/audio/dirs/ENG/tryagain.mp3`,
  };
  const { page, setPage, pageName } = usePages<
    "start" | "question" | "greatJob"
  >({
    initialPage: "start",
    pages: {
      start: (
        <StartLesson
          title="Alphabetical Order"
          onNext={() => setPage("question")}
          button="start"
        />
      ),
      question: alphabeticalData && (
        <ScrambledSentence
          title="Alphabetical Order"
          words={alphabeticalData?.results[0].sorted_words}
          wordsShuffled={alphabeticalData?.results[0].words}
          onNext={() => dispatch(onNextAlphabetical(setPage))}
          idSentence={alphabeticalData.results[0].id}
          wrongAnswer={wrongAnswer}
          setAnswer={setAnswers}
          audios={audios}
          currentProgram={currentProgram}
        />
      ),
      greatJob: (
        <GreatJob
          onNext={() => {
            if (currentLesson) {
              navigate(`/lesson/${urlsPaths[currentLesson?.mechanic_type]}`);
            }
          }}
        />
      ),
    },
  });

  return (
    <PageGrid
      activity="Alphabetical Order"
      lesson="Joseph's Readers"
      onHelp={() => {}}
      audioHelp={[audios.intro]}
      disabledButton={pageName === "greatJob" || pageName === "start"}
    >
      <ContainerLesson>
        {page}

        {/* <ProgressBar data={{ max: 12, step: 1 }} hide={pageName === "start"} /> */}
      </ContainerLesson>
    </PageGrid>
  );
}

export default AlphabeticalOrderPage;
