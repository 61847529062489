import { Reducer } from "redux";
import { PreTeachingActionTypes, PreTeachingState } from "./type";

const INITIAL_STATE: PreTeachingState = {
  isLoading: false,
  answerPreTeaching: [],
  dateMileseconds: 0,
  preTeachingFeedData: [],
  preTeachingData: undefined,
  wrongAnswer: 0,
  questionIndex: 0,
  feedNumber: 0,
  storyFeedWords: [],
  finished: "words",
  optionPreTeaching: undefined,
};

const reducer: Reducer<PreTeachingState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PreTeachingActionTypes.LOAD_PRE_TEACHING_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case PreTeachingActionTypes.LOAD_PRE_TEACHING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        preTeachingData: action.payload,
      };
    case PreTeachingActionTypes.LOAD_PRE_TEACHING_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case PreTeachingActionTypes.POST_PRE_TEACHING_REQUEST:
      return {
        ...state,
        wrongAnswer: 0,
        isLoading: true,
      };
    case PreTeachingActionTypes.POST_PRE_TEACHING_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case PreTeachingActionTypes.POST_PRE_TEACHING_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case PreTeachingActionTypes.ON_NEXT_PRE_TEACHING:
      return {
        ...state,
        questionIndex: state.questionIndex + 1,
        feedNumber: 0,
      };
    case PreTeachingActionTypes.ON_NEXT_FEED:
      return {
        ...state,
        feedNumber: state.feedNumber + 1,
      };
    case PreTeachingActionTypes.CLEAR_INDEX:
      return {
        ...state,
        feedNumber: 0,
        questionIndex: 0,
        finished: action.payload,
      };
    case PreTeachingActionTypes.SET_INDEX:
      return {
        ...state,
        feedNumber: 0,
        questionIndex: action.payload.index,
        finished: action.payload.finished,
      };
    case PreTeachingActionTypes.SET_ANSWER_PRE_TEACHING:
      return {
        ...state,
        answerPreTeaching: [
          ...state.answerPreTeaching,
          ...action.payload.fullAnswer,
        ],
      };
    case PreTeachingActionTypes.SET_WRONG_ANSWER_PRE_TEACHING:
      return {
        ...state,
        wrongAnswer: state.wrongAnswer + 1,
      };

    case PreTeachingActionTypes.SET_TIMER_PRE_TEACHING:
      return {
        ...state,
        dateMileseconds: action.payload,
      };
    case PreTeachingActionTypes.GENERATE_STORY_FEED:
      return {
        ...state,
        storyFeedWords: action.payload,
      };
    case PreTeachingActionTypes.GENERATE_STORY_FEED_PHRASES:
      return {
        ...state,
        preTeachingFeedData: action.payload,
      };

    case PreTeachingActionTypes.SET_OPTION_PRE_TEACHING:
      return {
        ...state,
        optionPreTeaching: action.payload,
      };

    case PreTeachingActionTypes.CLEAR_LIST_PRE_TEACHING:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default reducer;
