import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import { TalkingBook } from "../../../@types/interfaces/api/talking-book.interface";

export enum TalkingBookActionTypes {
  LOAD_BOOK_ID_REQUEST = "@talkinBook/LOAD_BOOK_ID_REQUEST",
  LOAD_BOOK_ID_SUCCESS = "@talkinBook/LOAD_BOOK_ID_SUCCESS",
  LOAD_BOOK_ID_ERROR = "@talkinBook/LOAD_BOOK_ID_ERROR",

  LOAD_BOOK_REQUEST = "@talkinBook/LOAD_BOOK_REQUEST",
  LOAD_BOOK_SUCCESS = "@talkinBook/LOAD_BOOK_SUCCESS",
  LOAD_BOOK_ERROR = "@talkinBook/LOAD_BOOK_ERROR",

  POST_TALKING_BOOK_REQUEST = "@talkinBook/POST_TALKING_BOOK_REQUEST",
  POST_TALKING_BOOK_SUCCESS = "@talkinBook/POST_TALKING_BOOK_SUCCESS",
  POST_TALKING_BOOK_ERROR = "@talkinBook/POST_TALKING_BOOK_ERROR",

  ON_NEXT_TB = "@talkinBook/ON_NEXT_TB",
  SET_TIMER_TB = "@talkinBook/SET_TIMER",
}

export interface TalkingBookState extends TalkingBookStateRequestsStatus {
  book: PaginationBase<TalkingBook[]> | undefined;
  bookId: TalkingBook | undefined;
  questionIndex: number;
  dateMileseconds: number;
}

interface TalkingBookStateRequestsStatus {
  isLoading: boolean;
}
