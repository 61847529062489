import { Flex, Image } from "@chakra-ui/react";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { FC, useEffect, useState } from "react";
import { HiPhotograph } from "react-icons/hi";
import { BASE_URL_MIDIA } from "../../utils/Utility";
import RegularButton from "../global/RegularButton";
import Text32px from "../global/text/Text32px";
import { assetsBaseUrl } from "../../utils/assets-helper";
import Subtitle from "../global/text/Subtitle";
import ButtonRadio from "../global/ButtonRadio";
import OutlineButton from "../global/OutlineButton";
import { ReinforcementSpellingOptions } from "../../@types/interfaces/api/reinforcement-activities.interface";
import { useLanguageContext } from "../../providers/LanguageProvider";
import { useAccesibiltyContext } from "../../providers/AccessibilityController";

interface FillTheGapProps {
  onNext: () => void;
  data: ReinforcementSpellingOptions[];
  inAudio: boolean;
  audioIntro: string | string[];
  setAlternative: (alternative: string) => void;
  index: number;
  title: string;
}

const ReinforcementSpelling: FC<FillTheGapProps> = ({
  onNext,
  data,
  inAudio,
  audioIntro,
  setAlternative,
  index,
  title,
}) => {
  const { language } = useLanguageContext();
  const [selectedAnswer, setSelecteddAnswer] = useState<number | null>(null);
  const [selected, setSelected] = useState<ReinforcementSpellingOptions | null>(
    null
  );
  const { playMedias, onPlayAudio } = useAccesibiltyContext();
  const handleClick = () => {
    setSelecteddAnswer(null);
    setSelected(null);
    onNext();
  };

  useEffect(() => {
    if (index === 0) {
      const playSentence = async () => {
        // await onPlayAudio( audioIntro);
        await playMedias(audioIntro);
      };
      playSentence();
    }
  }, [index, audioIntro[0]]);

  const verifyButton = () => {
    if (selected && selected.text !== "") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Flex gap={2} flexDir="column" align="center">
      <Subtitle>{title}</Subtitle>

      <Flex gap="16px" maxW="1500px" flexWrap="wrap" justify="center" mt="56px">
        {data.map((word, index) => {
          return (
            <ButtonRadio
              handleClick={() => {
                setSelecteddAnswer(index);
                setSelected(word);
                setAlternative(word.text);
              }}
              disabled={inAudio}
              inPlaying={inAudio}
              isSelected={selectedAnswer === index}
              text={word.text}
              key={index}
            />
          );
        })}
      </Flex>

      <Flex w="full" align="center" justify="center" gap="16px">
        <Flex align="center" gap="24px" mt="60px" flexDir={["column", "row"]}>
          <Flex
            p="24px"
            bg="white"
            minW="100px"
            minH="97px"
            borderRadius="16px"
            border="1.5px solid #CBD5E0"
          >
            <Text32px color="primary">{selected?.text}</Text32px>
          </Flex>

          <RegularButton
            rightIcon={<ArrowRight size="16px" />}
            onClick={handleClick}
            disabled={!verifyButton() || inAudio}
          >
            Next
          </RegularButton>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ReinforcementSpelling;
