import { Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import PageGrid from "../../../../components/global/PageGrid";

import ContainerLesson from "../../../../components/global/ContainerLesson";
import ProgressBar from "../../../../components/global/ProgressBar";
import ReadParagraph, {
  ReadParagraphData,
} from "../../../../components/lessons/ReadParagraph";
import GreatJob from "../../../../components/lessons/common/GreatJob";
import StartLesson from "../../../../components/lessons/common/StartLesson";
import ShowSynonymDefinition, {
  ShowSynonymDefinitionData,
} from "../../../../components/lessons/word/ShowSynonymDefinition";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { usePages } from "../../../../hooks/usePages";

import { useNavigate } from "react-router-dom";
import SubtitleComponent from "../../../../components/global/SubtitleComponent";
import FillTheGap from "../../../../components/lessons/FillTheGap";
import LaunchLessonSubtitles from "../../../../components/lessons/common/LaunchLessonSubtitles";
import { useAccesibiltyContext } from "../../../../providers/AccessibilityController";
import { useLanguageContext } from "../../../../providers/LanguageProvider";
import {
  clearUnitReviewFillTheBlankList,
  loadUnitReviewFillTheBlankRequest,
  onNextUnitReviewFillTheBlank,
  setAlternativeFillTheBlank,
  setAnswerUnitReviewFillTheBlank,
  setFullAlternativeFillTheBlank,
  setTimerUnitReviewFillTheBlank,
} from "../../../../stores/ducks/unit-review-fill-the-blank/actions";
import {
  BASE_URL_MIDIA,
  audioCorrectAnswer,
  urlsPaths,
} from "../../../../utils/Utility";
import { assetsBaseUrl } from "../../../../utils/assets-helper";
import { VerifyButtonHelp } from "../../life-skills/SpellingProgramPage";

function UnitReviewFillTheBlank() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [lastPage, setLastPage] = useState("");
  const [idHighlight, setIdHighlight] = useState(0);
  const [firstClick, setFirstClick] = useState(true);
  const { currentLesson, activeButtonHelp, inAudio } = useAppSelector(
    (i) => i.lesson
  );
  const { onPlayAudio } = useAccesibiltyContext();
  const {
    unitReviewFillTheBlankData,
    questionIndex,
    answers,
    alternativeUnitReviewFillTheBlank,
    questionsModified,
    fullAlternativeUnitReviewFillTheBlank,
    isLoading,
  } = useAppSelector((i) => i.unitReviewFillTheBlank);
  const { language } = useLanguageContext();

  const { captionText, accessibility } = useAccesibiltyContext();
  const { student } = useAppSelector((item) => item.auth);

  function tryReturnToLastPage() {
    if (lastPage) {
      setPage(lastPage as any);
      setLastPage("");
    }
  }

  useEffect(() => {
    dispatch(setTimerUnitReviewFillTheBlank(Date.now()));
    dispatch(
      loadUnitReviewFillTheBlankRequest({ activity__id: currentLesson?.id })
    );
    return () => {
      dispatch(clearUnitReviewFillTheBlankList());
    };
  }, []);

  useEffect(() => {
    setFirstClick(true);
  }, [questionIndex]);

  const setAlternativeFn = (alternative: string) => {
    const full = questionsModified[questionIndex]?.question.replace(
      "_____",
      alternative
    );
    dispatch(setAlternativeFillTheBlank(alternative));
    dispatch(setFullAlternativeFillTheBlank(full));
  };

  let wordDefinition: ShowSynonymDefinitionData | undefined = undefined;
  if (unitReviewFillTheBlankData) {
    wordDefinition = {
      word: {
        text: unitReviewFillTheBlankData?.words[idHighlight].word,
        audio: `${BASE_URL_MIDIA}${unitReviewFillTheBlankData?.words[idHighlight].word_audio}`,
      },
      synonym: {
        text: unitReviewFillTheBlankData?.words[idHighlight].synonym,
        audio: `${BASE_URL_MIDIA}${unitReviewFillTheBlankData?.words[idHighlight].synonym_audio}`,
      },
      definition: {
        text: unitReviewFillTheBlankData?.words[idHighlight].definition,
        audio: `${BASE_URL_MIDIA}${unitReviewFillTheBlankData?.words[idHighlight].definition_audio}`,
      },
    };
  }

  let readParagraph: ReadParagraphData | undefined = undefined;
  if (unitReviewFillTheBlankData) {
    readParagraph = {
      introduction: {
        audio: [`${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/d13.mp3`],
      },
      paragraph: {
        audio: `${BASE_URL_MIDIA}${unitReviewFillTheBlankData.paragraph[0].text_audio}`,
        text: unitReviewFillTheBlankData.paragraph[0].text,
      },
      highlights: unitReviewFillTheBlankData.words.map((word, index) => ({
        id: word.id,
        text: word.word,
        index,
      })),
    };
  }

  const indexWord = unitReviewFillTheBlankData?.words.findIndex((objeto) =>
    objeto.word.includes(alternativeUnitReviewFillTheBlank)
  );

  const onNextQuestion = async () => {
    setFirstClick(false);
    if (
      alternativeUnitReviewFillTheBlank ===
      questionsModified[questionIndex].answer
    ) {
      await onPlayAudio(audioCorrectAnswer());
      if (firstClick) {
        dispatch(setAnswerUnitReviewFillTheBlank());
      }
      dispatch(onNextUnitReviewFillTheBlank(setPage));
    } else {
      await onPlayAudio(
        `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/d18.mp3`
      );
      if (firstClick) {
        dispatch(setAnswerUnitReviewFillTheBlank());
      }
      setLastPage("fillInTheBlank");
      setPage("wordDefinition");
      setIdHighlight(indexWord ?? 0);
    }
  };

  const verifyButtonHelp = (): VerifyButtonHelp => {
    if (pageName === "readParagraph") {
      return {
        inactiveButton: false,
        audios: [
          `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/d13.mp3`,
        ],
      };
    } else if (pageName === "fillInTheBlank") {
      return {
        inactiveButton: false,
        audios: [
          `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/d08.mp3`,
        ],
      };
    } else {
      return { inactiveButton: true, audios: [] };
    }
  };

  const initialPage = student?.progress.finished ? "start" : "accessibility";

  const { page, setPage, pageName } = usePages<
    | "start"
    | "greatJob"
    | "wordDefinition"
    | "readParagraph"
    | "fillInTheBlank"
    | "accessibility"
  >({
    initialPage,
    pages: {
      accessibility: <LaunchLessonSubtitles onNext={() => setPage("start")} />,
      start: (
        <StartLesson
          title="Unit Review Fill In The Blank"
          onNext={() => setPage("readParagraph")}
          button="start"
        />
      ),

      wordDefinition: wordDefinition && (
        <ShowSynonymDefinition
          data={wordDefinition}
          onNext={() => {
            tryReturnToLastPage();
          }}
        />
      ),

      readParagraph: unitReviewFillTheBlankData && (
        <ReadParagraph
          inAudio={inAudio}
          data={readParagraph}
          onHighlight={(word) => {
            setLastPage("readParagraph");
            setPage("wordDefinition");
            if (readParagraph?.highlights) {
              const indexWord = readParagraph?.highlights.findIndex((obj) =>
                obj.text.includes(word.text)
              );
              setIdHighlight(indexWord);
            }
          }}
          onNext={() => {
            setPage("fillInTheBlank");
          }}
        />
      ),
      fillInTheBlank: questionsModified && (
        <FillTheGap
          question={questionsModified[questionIndex]?.question}
          options={questionsModified[questionIndex]?.possible_answers}
          setAlternativeFn={setAlternativeFn}
          alternativeData={alternativeUnitReviewFillTheBlank}
          audioIntro={`${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/d08.mp3`}
          questionIndex={questionIndex}
        />
      ),
      greatJob: (
        <GreatJob
          onNext={() => {
            if (currentLesson) {
              navigate(`/lesson/${urlsPaths[currentLesson?.mechanic_type]}`);
            }
          }}
        />
      ),
    },
  });

  return (
    <PageGrid
      activity="Review"
      lesson="Verbal Masters"
      disabledButton={
        verifyButtonHelp().inactiveButton ||
        pageName === "accessibility" ||
        inAudio
      }
      audioHelp={verifyButtonHelp().audios}
    >
      <ContainerLesson>
        {page}
        <Flex flexDir="column" gap="4px">
          <SubtitleComponent
            hide={
              !accessibility.subtitles ||
              !inAudio ||
              captionText === "" ||
              pageName === "greatJob"
            }
          />
          {unitReviewFillTheBlankData && (
            <ProgressBar
              data={{
                max: unitReviewFillTheBlankData?.paragraph_parts.length,
                step: questionIndex + 1,
              }}
              hide={pageName !== "fillInTheBlank"}
              onConfirm={onNextQuestion}
              hideButton={alternativeUnitReviewFillTheBlank === "" || inAudio}
              hideButtonPrevious
            />
          )}
        </Flex>
      </ContainerLesson>
    </PageGrid>
  );
}

export default UnitReviewFillTheBlank;
