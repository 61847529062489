import { ISpellingQuestion } from "../../../@types/interfaces/api/pre-teaching.interface";
import {
  AnswerSpelling,
  ISpellingProgram,
  SpellingWordsType,
} from "../../../@types/interfaces/api/spelling-program.interface";
import { StoryFeed } from "../../../components/lessons/LessonSpelling";

export enum SpellingProgramActionTypes {
  LOAD_SPELLING_REQUEST = "@spellingProgram/LOAD_SPELLING_REQUEST",
  LOAD_SPELLING_SUCCESS = "@spellingProgram/LOAD_SPELLING_SUCCESS",
  LOAD_SPELLING_ERROR = "@spellingProgram/LOAD_SPELLING_ERROR",

  POST_ANSWER_SPELLING_REQUEST = "@spellingProgram/POST_ANSWER_SPELLING_REQUEST",
  POST_ANSWER_SPELLING_SUCCESS = "@spellingProgram/POST_ANSWER_SPELLING_SUCCESS",
  POST_ANSWER_SPELLING_ERROR = "@spellingProgram/POST_ANSWER_SPELLING_ERROR",

  ON_NEXT = "@spellingProgram/ ON_NEXT",
  SET_ALTERNATIVE_SPELLING = "@spellingProgram/SET_ALTERNATIVE_SPELLING",
  SET_WORD_FEED_SPELLING = "@spellingProgram/SET_WORD_FEED_SPELLING",
  SET_ANSWER_SPELLING = "@spellingProgram/SET_ANSWER_SPELLING",
  SET_TEXT_SPELLING = "@spellingProgram/SET_TEXT_SPELLING",
  LEARNED_WORD_SPELLING = "@spellingProgram/LEARNED_WORD_SPELLING",
  SET_TIMER_SPELLING = "@spellingProgram/ SET_TIMER_SPELLING",
  CLEAR_SPELLING = "@spellingProgram/ CLEAR_SPELLING",
}

export interface SpellingProgramState
  extends SpellingProgramStateRequestsStatus {
  spellingData: ISpellingProgram | undefined;
  questionIndex: number;
  answers: AnswerSpelling[];
  alternativeSpelling: SpellingWordsType | undefined;
  wordFeed: StoryFeed[];
  dateMileseconds: number;
  spellingQuestionData: ISpellingQuestion | undefined;
  textSpelling: string;
  learnedWords: number[];
}

interface SpellingProgramStateRequestsStatus {
  isLoading: boolean;
}
