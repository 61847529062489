import { Reducer } from "redux";
import { VocabularyBuilderActionTypes, VocabularyBuilderState } from "./type";

const INITIAL_STATE: VocabularyBuilderState = {
  isLoading: false,
  alternativeVocabulary: undefined,
  answers: [],
  dateMileseconds: 0,
  questionIndex: 0,
  spelling: [],
  spellingQuestionData: undefined,
  vocabularyData: undefined,
  wordFeedData: undefined,
  learnedWords: [],
  textVocabulary: "",
};

const reducer: Reducer<VocabularyBuilderState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case VocabularyBuilderActionTypes.LOAD_VOCABULRY_BUILDER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case VocabularyBuilderActionTypes.LOAD_VOCABULRY_BUILDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        vocabularyData: action.payload,
        questionIndex: 0,
      };
    case VocabularyBuilderActionTypes.LOAD_VOCABULRY_BUILDER_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case VocabularyBuilderActionTypes.GET_WORD_VOCABULARY_BUILDER:
      return {
        ...state,
        wordFeedData: action.payload.feed,
        spelling: action.payload.feedSpelling,
        spellingQuestionData: action.payload.spellingQuestion,
      };

    case VocabularyBuilderActionTypes.CLEAR_LIST:
      return INITIAL_STATE;

    case VocabularyBuilderActionTypes.SET_ALTERNATIVE_VOCABULARY_BUILDER:
      return { ...state, alternativeVocabulary: action.payload };

    case VocabularyBuilderActionTypes.ON_NEXT:
      return {
        ...state,
        questionIndex: state.questionIndex + 1,
        answers: [...state.answers, action.payload],
      };

    case VocabularyBuilderActionTypes.POST_ANSWER_VOCABULARY_BUILDER_REQUEST:
      return {
        ...state,
        alternativeVocabulary: undefined,
        isLoading: true,
      };
    case VocabularyBuilderActionTypes.POST_ANSWER_VOCABULARY_BUILDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        answers: [],
      };
    case VocabularyBuilderActionTypes.POST_ANSWER_VOCABULARY_BUILDER_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case VocabularyBuilderActionTypes.LEARNED_WORD_VOCABULARY_BUILDER:
      return {
        ...state,
        learnedWords: [...state.learnedWords, action.payload],
      };

    case VocabularyBuilderActionTypes.ON_PREVIOUS:
      return {
        ...state,
        questionIndex: state.questionIndex - 1,
        alternativeVocabulary: undefined,
        answers: action.payload,
      };

    case VocabularyBuilderActionTypes.SET_TIMER_VOCABULARY_BUILDER:
      return { ...state, dateMileseconds: action.payload };
    case VocabularyBuilderActionTypes.SET_TEXT_VOCABULARY_BUILDER:
      return { ...state, textVocabulary: action.payload };

    default:
      return state;
  }
};
export default reducer;
