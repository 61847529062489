import { Button, Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import PlayIcon from "../../assets/icons/PlayIcon";
import ButtonRadio from "../global/ButtonRadio";
import RegularButton from "../global/RegularButton";
import Subtitle from "../global/text/Subtitle";
import Text32px from "../global/text/Text32px";
import { BASE_URL_MIDIA } from "../../utils/Utility";
import { useAppDispatch } from "../../hooks/redux";
import { setActiveButtonHelp } from "../../stores/ducks/lesson/action";
import { useLanguageContext } from "../../providers/LanguageProvider";
import { useAccesibiltyContext } from "../../providers/AccessibilityController";

type Answer = { id: string; text: string; audio?: string };

interface Props {
  setAnswer: (answer: string) => void;
  text: string;
  audio: { intro: string[]; text: string[] };
  options: { id: number; text: string }[];
  selectedAnswer: string;
  questionIndex: number;
}

function QuestionAndAnswers({
  setAnswer,
  text,
  audio,
  options,
  selectedAnswer,
  questionIndex,
}: Props) {
  const [isPlaying, setIsPlaying] = useState(true);
  const dispatch = useAppDispatch();
  const { language } = useLanguageContext();
  const { onPlayAudio, playMedias } = useAccesibiltyContext();

  useEffect(() => {
    const play = async () => {
      setIsPlaying(true);
      dispatch(setActiveButtonHelp(false));
      await playMedias([...audio.intro, ...audio.text]);
      setIsPlaying(false);
      dispatch(setActiveButtonHelp(true));
    };
    if (questionIndex === 0) {
      play();
    } else {
      playMedias(audio.text);
    }
  }, [questionIndex]);
  return (
    <Flex flexDirection={"column"} align="center">
      <Subtitle>Pick the correct answer to the question asked of you</Subtitle>

      <Flex align="center" mt="20px" flexDir={["column", "row"]}>
        <Text32px>{text}</Text32px>
        <PlayIcon onClick={() => playMedias(audio.text)} disabled={isPlaying} />
      </Flex>

      <Flex gap="16px" mt="56px" flexWrap="wrap" justify="center">
        {options.map((answer) => {
          return (
            <ButtonRadio
              key={answer.id}
              isSelected={answer.text === selectedAnswer}
              text={answer.text}
              disabled={isPlaying}
              inPlaying={isPlaying}
              handleClick={() => {
                setAnswer(answer.text);
              }}
            />
          );
        })}
      </Flex>
    </Flex>
  );
}

export default QuestionAndAnswers;
