import store from "..";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import {
  Question,
  RasAnswer,
} from "../../../@types/interfaces/api/ras.interface";
import { AlternativeBestfit, RasActionTypes } from "./type";

export const loadQuestionRequest = () => ({
  type: RasActionTypes.LOAD_QUESTIONS_REQUEST,
});

export const loadQuestionSuccess = (questions: PaginationBase<Question[]>) => ({
  type: RasActionTypes.LOAD_QUESTIONS_SUCCESS,
  payload: questions,
});
export const loadQuestionError = () => ({
  type: RasActionTypes.LOAD_QUESTIONS_ERROR,
});

export const postAnswerRequest = (data: RasAnswer[]) => ({
  type: RasActionTypes.POST_ANSWER_REQUEST,
  payload: data,
});

export const postAnswerSuccess = () => ({
  type: RasActionTypes.POST_ANSWER_SUCCESS,
});
export const postAnswerError = () => ({
  type: RasActionTypes.POST_ANSWER_ERROR,
});

export const setAlternativeBestFit = (alternative: AlternativeBestfit) => {
  return {
    type: RasActionTypes.SET_ALTERNATIVE_BEST_FIT,
    payload: alternative,
  };
};

export const clearList = () => {
  return {
    type: RasActionTypes.CLEAR_LIST,
  };
};

export const onNext = (setPage: (page: any) => void) => {
  const contentListAnswer = store.getState().ras;
  const studentId = store.getState().auth.student?.user;

  const questionId =
    contentListAnswer.questions?.results[contentListAnswer.questionIndex].id;

  const contentAnswer = {
    answer: contentListAnswer.alternativeBestFit?.answerid,
    question: questionId,
    student: studentId,
  };

  return {
    type:
      questionId === 15
        ? RasActionTypes.POST_ANSWER_REQUEST
        : RasActionTypes.ON_NEXT,
    payload: { contentAnswer, setPage },
  };
};
export const onPrevious = () => {
  const contentListAnswer = store.getState().ras;

  const arrayAnswers = contentListAnswer.answers;
  arrayAnswers.pop();

  return {
    type: RasActionTypes.ON_PREVIOUS,
    payload: arrayAnswers,
  };
};

export const setAudio = (audio: string) => {
  return {
    type: RasActionTypes.SET_AUDIO,
    payload: audio,
  };
};
