import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import {
  AnswerRA,
  IReinforcementActivities,
} from "../../../@types/interfaces/api/reinforcement-activities.interface";

export enum ReinforcementActivitiesActionTypes {
  LOAD_REINFORCEMENT_REQUEST = "@reinforcementActivities/LOAD_REINFORCEMENT_REQUEST",
  LOAD_REINFORCEMENT_SUCCESS = "@reinforcementActivities/LOAD_REINFORCEMENT_SUCCESS",
  LOAD_REINFORCEMENT_ERROR = "@reinforcementActivities/LOAD_REINFORCEMENT_ERROR",

  POST_REINFORCEMENT_REQUEST = "@reinforcementActivities/POST_REINFORCEMENT_REQUEST",
  POST_REINFORCEMENT_LIFE_SKILLS_REQUEST = "@reinforcementActivities/POST_REINFORCEMENT_LIFE_SKILLS_REQUEST",
  POST_REINFORCEMENT_SUCCESS = "@reinforcementActivities/POST_REINFORCEMENT_SUCCESS",
  POST_REINFORCEMENT_ERROR = "@reinforcementActivities/POST_REINFORCEMENT_ERROR",

  ON_NEXT_RA = "@reinforcementActivities/ ON_NEXT_RA",
  ON_NEXT_RA_SPELLING = "@reinforcementActivities/ ON_NEXT_RA_SPELLING",
  ON_NEXT_RA_ALPHABETICAL = "@reinforcementActivities/ ON_NEXT_RA_ALPHABETICAL",
  CLEAR_RA_LIFE_SKILLS = "@reinforcementActivities/ CLEAR_RA_LIFE_SKILLS",
  CLEAR_RA_SPELLING = "@reinforcementActivities/ CLEAR_RA_SPELLING",
  ON_PREIVIOUS_RA = "@reinforcementActivities/ ON_PREIVIOUS_RA",
  SET_ALTERNATIVE_RA = "@reinforcementActivities/ SET_ALTERNATIVE_RA",
  SET_ANSWER_RA = "@reinforcementActivities/ SET_ANSWER_RA",
  SET_ANSWER_RA_SPELLING = "@reinforcementActivities/ SET_ANSWER_RA_SPELLING",
  SET_ANSWER_RA_ALPHABETICAL = "@reinforcementActivities/ SET_ANSWER_RA_ALPHABETICAL",
  CLEAR_LIST = "@reinforcementActivities/ CLEAR_LIST",
  SET_TIMER_RA = "@reinforcementActivities/ SET_TIMER_RA",
}

export interface ReinforcementActivitiesState
  extends ReinforcementActivitiesStateRequestsStatus {
  ReinforcementActivities:
    | PaginationBase<IReinforcementActivities[]>
    | undefined;
  questionIndex: number;
  questionIndexLS: number;
  alternativeRA: string;
  dateMileseconds: number;
  answersRA: AnswerRA[];
  answersRaSpelling: AnswerRA[];
  answersRaAlphabetical: AnswerRA[];
}

interface ReinforcementActivitiesStateRequestsStatus {
  isLoading: boolean;
}
