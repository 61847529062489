import { Button, Flex, Text, Textarea } from "@chakra-ui/react";
import { useAudio } from "../../hooks/useAudio";
import Subtitle from "../global/text/Subtitle";
import Text32px from "../global/text/Text32px";
import { assetsBaseUrl } from "../../utils/assets-helper";
import { useLanguageContext } from "../../providers/LanguageProvider";

interface Props {
  exercise: { id: number; text: string };
  passages: { id: number; text: string }[];
  questionNumber: number;
  alternative: string;
  setAlternativeFn: (text: string) => void;
}
function ShortAnswer({
  exercise,
  passages,
  questionNumber,
  setAlternativeFn,
  alternative,
}: Props) {
  const { language } = useLanguageContext();

  useAudio(
    language,
    `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/answerqscompletesentences.mp3`
  );

  return (
    <Flex flexDir={"column"} align="center" w="full">
      <Subtitle>
        Answer the questions using complete sentences. Use the passage for help
      </Subtitle>
      <Text32px mt="16px">Short Answer Q&A</Text32px>
      <Text fontSize="24px" fontWeight="700" color="primary" mt="32px">
        Passage
      </Text>
      <Flex flexDir={"column"} mb={3} mt="16px" maxW="900px">
        <Flex textAlign="center" flexDir={"column"} w="full">
          {passages.map((passage, index) => {
            return (
              <Text
                color="gray.500"
                fontWeight="600"
                fontSize="18px"
                key={index}
              >
                {passage.text}
              </Text>
            );
          })}
        </Flex>
        <Flex flexDir="column" mt="40px">
          <Flex flexDir={["column", "row"]}>
            <Text fontWeight="700" fontSize="24px" color="primary">
              Question {questionNumber} :{" "}
            </Text>
            <Text fontWeight="700" fontSize="24px" ml="12px">
              {exercise.text}
            </Text>
          </Flex>

          <Textarea
            placeholder="Please Type Your Answer Here"
            bg="white"
            mt="8px"
            value={alternative}
            onChange={({ target }) => setAlternativeFn(target.value)}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ShortAnswer;
