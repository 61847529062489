import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const GreatJobIcon: React.FC<IconProps> = (props) => {
  return (
    <Icon
      width="145px"
      height="145px"
      viewBox="0 0 145 145"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="73" cy="72.5" r="72.5" fill="#0C4DA2" fillOpacity="0.1" />
      <g clipPath="url(#clip0_1588_1730)">
        <path
          d="M102.518 73.2769C102.518 73.2769 104.46 71.335 104.072 67.0626C104.072 61.2367 97.8573 60.8484 97.8573 60.8484H88.5359C87.7591 60.8484 87.3707 60.0716 87.3707 59.2948C88.5359 56.576 90.4778 51.1385 89.701 47.2546C88.5359 41.8171 83.8751 41.4287 82.3216 41.4287C81.9332 41.4287 81.5448 41.8171 81.5448 42.2055L77.6609 53.0805C77.6609 53.0805 77.6609 53.4689 77.2725 53.4689L67.1742 64.3439C66.7859 64.7323 66.3975 64.7323 66.0091 64.7323H65.2323V91.9198H66.0091C68.7278 91.9198 78.4376 99.6876 86.9823 99.6876C95.5269 99.6876 97.4689 98.5225 99.0225 95.8037C100.576 93.085 99.4109 90.7546 99.4109 90.7546C99.4109 90.7546 101.353 89.5894 101.741 86.8707C102.13 84.1519 101.353 82.5984 101.353 82.5984C101.353 82.5984 103.295 81.0448 103.295 77.9376C103.683 74.4421 102.518 73.2769 102.518 73.2769ZM41.9287 95.8037H61.3484V64.7323H41.9287V95.8037ZM51.6385 82.21C53.9689 82.21 55.5225 83.7635 55.5225 86.0939C55.5225 88.4242 53.9689 89.9778 51.6385 89.9778C49.3082 89.9778 47.7546 88.4242 47.7546 86.0939C47.7546 83.7635 49.3082 82.21 51.6385 82.21Z"
          fill="#0C4DA2"
        />
      </g>
      <defs>
        <clipPath id="clip0_1588_1730">
          <rect
            width="62.1429"
            height="62.1429"
            fill="white"
            transform="translate(41.9287 41.4287)"
          />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default GreatJobIcon;
