import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import {
  AnswerPostTest,
  IPostTest,
} from "../../../@types/interfaces/api/post-test.interface";

export enum PostTestActionTypes {
  LOAD_QUESTIONS_POST_TEST_REQUEST = "@postTest/LOAD_QUESTIONS_POST_TEST_REQUEST",
  LOAD_QUESTIONS_POST_TEST_SUCCESS = "@postTest/LOAD_QUESTIONS_POST_TEST_SUCCESS",
  LOAD_QUESTIONS_POST_TEST_ERROR = "@postTest/LOAD_QUESTIONS_POST_TEST_ERROR",

  POST_ANSWER_POST_TEST_REQUEST = "@postTest/POST_ANSWER_POST_TEST_REQUEST",
  POST_ANSWER_POST_TEST_SUCCESS = "@postTest/POST_ANSWER_POST_TEST_SUCCESS",
  POST_ANSWER_POST_TEST_ERROR = "@postTest/POST_ANSWER_POST_TEST_ERROR",

  SET_ALTERNATIVE_POST_TEST = "@postTest/ SET_ALTERNATIVE_POST_TEST",
  SET_FULL_ALTERNATIVE_POST_TEST = "@postTest/ SET_FULL_ALTERNATIVE_POST_TEST",
  SET_PAGE_NUMBER_POST_TEST = "@postTest/ SET_PAGE_NUMBER_POST_TEST",
  SET_CORRECT_ANSWER_POST_TEST = "@postTest/ SET_CORRECT_ANSWER_POST_TES",
  SET_TIMER_POST_TEST = "@postTest/ SET_TIMER_POST_TEST",
  ON_NEXT = "@postTest/ ON_NEXT",
  DO_NOT_STOP = "@postTest/ DO_NOT_STOP",
  ON_PREVIOUS = "@postTest/ PREVIOUS",
  CLEAR_LIST = "@postTest/CLEAR_LIST",
}

export interface PostTestState extends PostTestStateRequestsStatus {
  postTestData: PaginationBase<IPostTest[]> | undefined;
  answers: AnswerPostTest[];
  alternativePostTest: string;
  questionIndex: number;
  selectedAnswer: string;
  numberOfWrongAnswers: number;
  numberOfCorrectAnswers: number;
  pageNumber: number;
  levelId: number;
  dateMileseconds: number;
  fullAlternative: string;
}

interface PostTestStateRequestsStatus {
  isLoading: boolean;
}
