import store from "..";
import {
  IActivityData,
  IClassroomData,
  INextLessonData,
} from "../../../@types/interfaces/api/dashboard.interface";
import { DashboardActionTypes } from "./type";

export const loadClassroomRequest = (id: number) => ({
  type: DashboardActionTypes.LOAD_CLASSROOM_REQUEST,
  payload: id,
});

export const loadClassroomSuccess = (data: IClassroomData) => ({
  type: DashboardActionTypes.LOAD_CLASSROOM_SUCCESS,
  payload: data,
});
export const loadClassroomError = () => ({
  type: DashboardActionTypes.LOAD_CLASSROOM_ERROR,
});

export const loadActivityRequest = (id: number) => ({
  type: DashboardActionTypes.LOAD_ACTIVITY_REQUEST,
  payload: id,
});

export const loadActivitySuccess = (data: IActivityData) => ({
  type: DashboardActionTypes.LOAD_ACTIVITY_SUCCESS,
  payload: data,
});
export const loadActivityError = () => ({
  type: DashboardActionTypes.LOAD_ACTIVITY_ERROR,
});

export const loadNextActivityLessonRequest = () => ({
  type: DashboardActionTypes.LOAD_NEXT_LESSON_REQUEST,
});

export const loadNextActivityLessonSuccess = (
  data: IActivityData | undefined
) => ({
  type: DashboardActionTypes.LOAD_NEXT_LESSON_SUCCESS,
  payload: data,
});
export const loadNextActivityLessonError = () => ({
  type: DashboardActionTypes.LOAD_NEXT_LESSON_ERROR,
});

export const clearDashboard = () => ({
  type: DashboardActionTypes.CLEAR_LIST,
});
