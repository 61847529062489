import { Button, Flex, Text } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import {
  options_answers,
  Question,
} from "../../@types/interfaces/api/ras.interface";
import PlayIcon from "../../assets/icons/PlayIcon";
import { AlternativeBestfit } from "../../stores/ducks/ras/type";
import ButtonRadio from "../global/ButtonRadio";
import Subtitle from "../global/text/Subtitle";
import Text32px from "../global/text/Text32px";
import { useAccesibiltyContext } from "../../providers/AccessibilityController";

interface Props {
  question: string;
  alternativeData: string | undefined;
  alternativeDataId: number | undefined;
  questionAudio?: string;
  urlAudio: string;
  optionsAnswer: options_answers[] | undefined;
  selectedAudio: string;
  setAudioFn: (audio: string) => void;
  setAlternativeFn: (alternative: AlternativeBestfit) => void;
}

const BestFit: FC<Props> = ({
  urlAudio,
  questionAudio,
  question,
  alternativeData,
  alternativeDataId,
  selectedAudio,
  optionsAnswer,
  setAudioFn,
  setAlternativeFn,
}) => {
  const { onPlayAudio } = useAccesibiltyContext();
  useEffect(() => {
    if (questionAudio) {
      onPlayAudio(questionAudio);
      setAudioFn(questionAudio);
    }
  }, [questionAudio]);

  const clickButton = (alternative: AlternativeBestfit, audio: string) => {
    setAlternativeFn(alternative);
    setAudioFn(urlAudio + audio);
    onPlayAudio(urlAudio + audio);
  };

  //capitalize the first letter of the sentence
  const title =
    question
      .replace(/_+/, alternativeData ?? "")
      .charAt(0)
      .toUpperCase() + question.replace(/_+/, alternativeData ?? "").slice(1);

  return (
    <Flex flexDir="column" align="center" w="full" flex={1}>
      <Subtitle color="gray.500" fontWeight={700} letterSpacing="2px">
        CHOOSE THE ONE THAT BEST FITS
      </Subtitle>
      <Flex align="center" mt="16px">
        <Text32px>{title}</Text32px>
        <PlayIcon onClick={() => onPlayAudio(selectedAudio)} />
      </Flex>

      <Flex gap="16px" mt="56px" flexDir={["column", "row"]}>
        {optionsAnswer?.map((alternative, index) => {
          return (
            <ButtonRadio
              key={index}
              isSelected={alternativeDataId === alternative.id}
              text={alternative.label}
              handleClick={() => {
                clickButton(
                  {
                    textReplace: alternative.replace.toLowerCase(),
                    answerid: alternative.id,
                  },
                  alternative.audio
                );
              }}
            />
          );
        })}
      </Flex>
    </Flex>
  );
};

export default BestFit;
