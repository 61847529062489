import { createStandaloneToast } from "@chakra-ui/react";

import theme from "../../../styles/theme";
import axios, { AxiosResponse } from "axios";
import { call, put, select } from "redux-saga/effects";
import api from "../../../services/api";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import { AnyAction } from "redux";
import store, { ApplicationState, getGlobalState } from "..";

import WordVmFilterInterface, {
  IWordVm,
} from "../../../@types/interfaces/api/word-vm.interface";
import {
  HistoryRecognition,
  HistoryRecognitionResponse,
} from "../../../@types/interfaces/api/word-recognition.interface";
import { postProgress } from "../word-recognition/sagas";
import {
  loadUnitReviewDefinitionError,
  loadUnitReviewDefinitionSuccess,
  postAnswerUnitReviewDefinitionError,
  postAnswerUnitReviewDefinitionSuccess,
} from "./actions";
import UnitReviewDefinitionFilterInterface, {
  IUnitReviewDefinition,
} from "../../../@types/interfaces/api/unit-review-definiton.interface";

const { toast } = createStandaloneToast({ theme });

export function* loadQuestionUnitReviewDefinition(action: AnyAction) {
  const filters: UnitReviewDefinitionFilterInterface = action.payload;
  try {
    const response: AxiosResponse<PaginationBase<IUnitReviewDefinition[]>> =
      yield call(api.get, `/unit-review-definition`, {
        params: filters,
      });
    if (response.status == 200) {
      yield put(loadUnitReviewDefinitionSuccess(response.data.results[0]));
    }
  } catch (e: any) {
    yield put(loadUnitReviewDefinitionError());
  }
}

export function* postHistoryUnitReview() {
  const data: ApplicationState = yield select(getGlobalState);

  const timeLesson = Date.now() - data.unitReviewDefinition.dateMileseconds;

  const dataHitory: HistoryRecognition = {
    time: timeLesson,
    student: data.auth.student?.user,
  };

  try {
    const response: AxiosResponse = yield call(
      api.post,
      "/history",
      dataHitory
    );
    if (response.status == 201) {
      return response.data;
    }
  } catch (e: any) {
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* postAnswerUnitReviewDefinition(action: AnyAction) {
  const data: ApplicationState = yield select(getGlobalState);
  const setPage = action.payload.setPage;
  const activity = data.unitReviewDefinition.unitReviewDefinitionData?.activity;

  try {
    const responseHistory: HistoryRecognitionResponse = yield call(
      postHistoryUnitReview
    );

    const completeData = data.unitReviewDefinition.answers.map((answer) => {
      return { ...answer, history: responseHistory.id };
    });

    const response: AxiosResponse = yield call(
      api.post,
      "/unit-review-definition/answer",
      completeData
    );
    if (response.status === 201) {
      yield put(postAnswerUnitReviewDefinitionSuccess());
      yield call(postProgress, activity);
      setPage("greatJob");
    }
  } catch (e: any) {
    yield put(postAnswerUnitReviewDefinitionError());
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}
