import store from "..";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import PreTestVmFilterInterface, {
  AnswerPreTestVm,
  IPreTestVm,
  QuestionPreTestVm,
} from "../../../@types/interfaces/api/pre-test-vm.interface";
import {
  AnswerRC,
  IReadingComprehension,
} from "../../../@types/interfaces/api/reading-comprehension";

import { ActivityIdFilter } from "../../../@types/interfaces/api/talking-book.interface";
import { AnswerPreTestComponent } from "../../../components/lessons/SynonymAndDefinition";
import { PreTestVmActionTypes } from "./type";

export const loadPreTestVmRequest = (filter: PreTestVmFilterInterface) => ({
  type: PreTestVmActionTypes.LOAD_PRE_TEST_VM_REQUEST,
  payload: filter,
});

export const loadPreTestVmSuccess = (data: IPreTestVm) => ({
  type: PreTestVmActionTypes.LOAD_PRE_TEST_VM_SUCCESS,
  payload: data,
});

export const loadPreTestVmError = () => ({
  type: PreTestVmActionTypes.LOAD_PRE_TEST_VM_ERROR,
});

export const setAlternativeSynonym = (alternative: AnswerPreTestComponent) => {
  return {
    type: PreTestVmActionTypes.SET_ALTERNATIVE_SYNONYM,
    payload: alternative,
  };
};
export const setAlternativeDefinition = (
  alternative: AnswerPreTestComponent
) => {
  return {
    type: PreTestVmActionTypes.SET_ALTERNATIVE_DEFINITION,
    payload: alternative,
  };
};

export const clearPreTestVmList = () => {
  return {
    type: PreTestVmActionTypes.CLEAR_LIST,
  };
};

export const onPreviousPreTestVm = () => {
  const contentListAnswer = store.getState().preTestVm;
  const answerArray = contentListAnswer.answersPreTestVm;

  answerArray.pop();
  return {
    type: PreTestVmActionTypes.ON_PREIVIOUS_PRE_TEST_VM,
  };
};

export const onNextSynonym = (setPage: (page: any) => void) => {
  const contentListAnswer = store.getState().preTestVm;
  const typeLesson = contentListAnswer.typeLesson;

  const chosenAnswerSynonym = contentListAnswer.alternativeSnonym?.answer ?? "";

  const questionId =
    contentListAnswer.preTestVmData?.questions[contentListAnswer.questionIndex]
      .id;

  const contentAnswer: AnswerPreTestVm = {
    synonym: chosenAnswerSynonym,
    definition: "",
    question: questionId,
    history: 0,
  };

  if (
    contentListAnswer.preTestVmData &&
    contentListAnswer.questionIndex <
      contentListAnswer.preTestVmData.questions.length - 1
  ) {
    return {
      type: PreTestVmActionTypes.ON_NEXT_SYNONYM,
      payload: { contentAnswer, setPage },
    };
  } else {
    setPage("do_not_stop");
    return {
      type: PreTestVmActionTypes.SET_TYPE_DEFINITION,
      payload: { contentAnswer, setPage },
    };
  }
};
export const onNextDefinition = (setPage: (page: any) => void) => {
  const contentListAnswer = store.getState().preTestVm;
  const typeLesson = contentListAnswer.typeLesson;
  const chosenAnswerDefinition =
    contentListAnswer.alternativeDefinition?.answer ?? "";

  const arrayOfAnswers = contentListAnswer.answersPreTestVm;

  arrayOfAnswers[contentListAnswer.questionIndex].definition =
    chosenAnswerDefinition;

  if (
    contentListAnswer.preTestVmData &&
    contentListAnswer.questionIndex <
      contentListAnswer.preTestVmData.questions.length - 1
  ) {
    return {
      type: PreTestVmActionTypes.ON_NEXT_DEFINITION,
      payload: { contentAnswer: arrayOfAnswers, setPage },
    };
  } else {
    return {
      type: PreTestVmActionTypes.POST_PRE_TEST_VM_REQUEST,
      payload: { contentAnswer: arrayOfAnswers, setPage },
    };
  }
};

export const setTypLessonPreTestVm = (type: "synonym" | "definition" | "") => {
  return {
    type: PreTestVmActionTypes.SET_TYPE_PRE_TEST_VM,
    payload: type,
  };
};

export const postAnswerPreTestVmRequest = (data: AnswerPreTestVm[]) => ({
  type: PreTestVmActionTypes.POST_PRE_TEST_VM_REQUEST,
  payload: data,
});

export const postAnswerPreTestVmSuccess = () => ({
  type: PreTestVmActionTypes.POST_PRE_TEST_VM_SUCCESS,
});
export const postAnswerPreTestVmError = () => ({
  type: PreTestVmActionTypes.POST_PRE_TEST_VM_ERROR,
});

export const setTimerPreTestVm = (date: number) => ({
  type: PreTestVmActionTypes.SET_TIMER_PRE_TEST_VM,
  payload: date,
});
