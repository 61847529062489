import { useEffect, useState } from "react";
import { AudioMedia } from "./AudioMedia";
import { LanguageType } from "../providers/LanguageProvider";
import Language from "../components/lessons/common/Language";
import { useAccesibiltyContext } from "../providers/AccessibilityController";

type Url = string[] | string | Array<string | undefined>;
export function useAudio(language: LanguageType, url?: Url) {
  const convert = (url?: Url): string[] | undefined => {
    if (!url) {
      return;
    }
    if (typeof url === "string") {
      return [url];
    } else {
      return url.filter((e) => e !== undefined) as string[];
    }
  };
  const [audioUrl, setAudioUrl] = useState<string[] | undefined>(convert(url));
  const { onPlayAudio } = useAccesibiltyContext();

  useEffect(() => {
    const play = async () => {
      if (audioUrl) {
        await onPlayAudio([...audioUrl]);
        setAudioUrl(undefined);
      }
    };

    play();
  }, [audioUrl]);

  return {
    setAudio: (url?: Url) => setAudioUrl(convert(url)),
  };
}
