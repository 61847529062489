import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import {
  IPreTeaching,
  IPreTeachingAnswerPost,
  OptionsPreTeaching,
  StoryFeed,
} from "../../../@types/interfaces/api/pre-teaching.interface";

export enum PreTeachingActionTypes {
  LOAD_PRE_TEACHING_REQUEST = "@preTeaching/LOAD_PRE_TEACHING_REQUEST",
  LOAD_PRE_TEACHING_SUCCESS = "@preTeaching/LOAD_PRE_TEACHING_SUCCESS",
  LOAD_PRE_TEACHING_ERROR = "@preTeaching/LOAD_PRE_TEACHING_ERROR",

  POST_PRE_TEACHING_REQUEST = "@preTeaching/ POST_PRE_TEACHING_REQUEST",
  POST_PRE_TEACHING_SUCCESS = "@preTeaching/ POST_PRE_TEACHING_SUCCESS",
  POST_PRE_TEACHING_ERROR = "@preTeaching/ POST_PRE_TEACHING_ERROR",

  SET_ANSWER_PRE_TEACHING = "@preTeaching/SET_ANSWER_PRE_TEACHING",
  SET_OPTION_PRE_TEACHING = "@preTeaching/SET_OPTION_PRE_TEACHING",
  ON_NEXT_PRE_TEACHING = "@preTeaching/ON_NEXT_PRE_TEACHING",
  ON_NEXT_FEED = "@preTeaching/ON_NEXT_FEED",
  GENERATE_STORY_FEED = "@preTeaching/GENERATE_STORY_FEED",
  GENERATE_STORY_FEED_PHRASES = "@preTeaching/GENERATE_STORY_FEED_PHRASES",
  CLEAR_LIST_PRE_TEACHING = "@preTeaching/ CLEAR_LIST_PRE_TEACHING",
  CLEAR_INDEX = "@preTeaching/ CLEAR_INDEX",
  SET_INDEX = "@preTeaching/ SET_INDEX",
  SET_TIMER_PRE_TEACHING = "@preTeaching/ SET_TIMER_PRE_TEACHING",
  SET_WRONG_ANSWER_PRE_TEACHING = "@preTeaching/ SET_WRONG_ANNSWER_PRE_TEACHING",
}

export interface PreTeachingState extends PreTeachingStateRequestsStatus {
  preTeachingFeedData: StoryFeed[][] | [];
  preTeachingData: IPreTeaching | undefined;
  answerPreTeaching: IPreTeachingAnswerPost[];
  wrongAnswer: number;
  dateMileseconds: number;
  questionIndex: number;
  feedNumber: number;
  storyFeedWords: StoryFeed[];
  finished: "words" | "phrases" | "questions";
  optionPreTeaching: OptionsPreTeaching | undefined;
}

interface PreTeachingStateRequestsStatus {
  isLoading: boolean;
}
