import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const DoneIcon: React.FC<IconProps> = (props) => {
  return (
    <Icon
      width="145px"
      height="145px"
      viewBox="0 0 145 145"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="73" cy="72.5" r="72.5" fill="#0C4DA2" fillOpacity="0.1" />
      <path
        d="M72.9998 101.333C88.6483 101.333 101.333 88.6483 101.333 72.9998C101.333 57.3513 88.6483 44.6665 72.9998 44.6665C57.3513 44.6665 44.6665 57.3513 44.6665 72.9998C44.6665 88.6483 57.3513 101.333 72.9998 101.333Z"
        fill="#0C4DA2"
        stroke="#0C4DA2"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M64.5 73.0002L70.1667 78.6668L81.5 67.3335" fill="#0C4DA2" />
      <path
        d="M64.5 73.0002L70.1667 78.6668L81.5 67.3335"
        stroke="#FEFEFE"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default DoneIcon;
