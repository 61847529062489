import { Text, TextProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import ComponentProps from "../../../../src/@types/interfaces/app/component-props.interfaces";

// interface Text32pxProps extends ComponentProps {}

const Subtitle: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text
      color="gray.500"
      fontWeight={700}
      letterSpacing="2px"
      textAlign="center"
      {...rest}
    >
      {children}
    </Text>
  );
};

export default Subtitle;
