import { useEffect, useState } from "react";
import PageGrid from "../../../../components/global/PageGrid";
import DragToCorrectPlace, {
  DragToCorrectPlaceData,
} from "../../../../components/lessons/DragToCorrectPlace";
import DragToUncover, {
  DragToUncoverData,
} from "../../../../components/lessons/DragToUncover";
import StartLesson from "../../../../components/lessons/common/StartLesson";
import ShowSynonymDefinition, {
  ShowSynonymDefinitionData,
} from "../../../../components/lessons/word/ShowSynonymDefinition";
import { usePages } from "../../../../hooks/usePages";
import ContainerLesson from "../../../../components/global/ContainerLesson";
import ProgressBar from "../../../../components/global/ProgressBar";
import GreatJob from "../../../../components/lessons/common/GreatJob";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import {
  BASE_URL_MIDIA,
  audioCorrectAnswer,
  urlsPaths,
} from "../../../../utils/Utility";
import { useNavigate } from "react-router-dom";
import {
  clearUnitReviewComprehensionList,
  loadUnitReviewComprehensionRequest,
  onNextUnitReviewComprehension,
  setAlternativeUnitReviewComprehension,
  setAnswerUnitReviewComprehension,
  setTimerUnitReviewComprehension,
} from "../../../../stores/ducks/unit-review-reading-comprehension/actions";
import QuestionAndAnswers from "../../../../components/lessons/QuestionAndAnswers";
import { assetsBaseUrl } from "../../../../utils/assets-helper";
import { VerifyButtonHelp } from "../../life-skills/SpellingProgramPage";
import { useLanguageContext } from "../../../../providers/LanguageProvider";
import { useAccesibiltyContext } from "../../../../providers/AccessibilityController";
import LaunchLessonSubtitles from "../../../../components/lessons/common/LaunchLessonSubtitles";
import { Flex } from "@chakra-ui/react";
import SubtitleComponent from "../../../../components/global/SubtitleComponent";

function UnitReviewReadingComprehension() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [firstClick, setFirstClick] = useState(true);
  const { currentLesson, activeButtonHelp, inAudio, isLoading } =
    useAppSelector((i) => i.lesson);
  const {
    unitReviewComprehensionData,
    questionIndex,
    answers,
    alternativeUnitReviewComprehension,
  } = useAppSelector((i) => i.unitReviewReadingCcomprehension);
  const { language } = useLanguageContext();
  const { onPlayAudio, accessibility, captionText } = useAccesibiltyContext();

  const { student } = useAppSelector((item) => item.auth);
  useEffect(() => {
    dispatch(setTimerUnitReviewComprehension(Date.now()));
    dispatch(
      loadUnitReviewComprehensionRequest({ activity__id: currentLesson?.id })
    );
    return () => {
      dispatch(clearUnitReviewComprehensionList());
    };
  }, []);

  useEffect(() => {
    setFirstClick(true);
  }, [questionIndex]);

  async function checkResponse() {
    setFirstClick(false);
    const correctAnswer =
      unitReviewComprehensionData?.questions[questionIndex].answer;
    const selectedAnswer = alternativeUnitReviewComprehension;

    if (correctAnswer?.toLowerCase() !== selectedAnswer?.toLowerCase()) {
      const tryAgainAudio = [
        `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/TRYAGAIN.mp3`,
      ];
      if (firstClick) {
        dispatch(setAnswerUnitReviewComprehension());
      }
      await onPlayAudio([...tryAgainAudio]);
      return;
    }
    if (firstClick) {
      dispatch(setAnswerUnitReviewComprehension());
    }
    await onPlayAudio(audioCorrectAnswer());
    dispatch(onNextUnitReviewComprehension(setPage));
  }

  const verifyButtonHelp = (): VerifyButtonHelp => {
    if (pageName === "comprehension") {
      return {
        inactiveButton: false,
        audios: [
          `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/d23.mp3`,
          `${BASE_URL_MIDIA}${unitReviewComprehensionData?.questions[questionIndex].audio}`,
        ],
      };
    } else {
      return { inactiveButton: true, audios: [] };
    }
  };
  const initialPage = student?.progress.finished ? "start" : "accessibility";

  const { page, setPage, pageName } = usePages<
    "start" | "greatJob" | "comprehension" | "accessibility"
  >({
    initialPage,
    pages: {
      accessibility: <LaunchLessonSubtitles onNext={() => setPage("start")} />,
      start: (
        <StartLesson
          title="Unit Review Reading Comprehension"
          onNext={() => setPage("comprehension")}
          button="start"
        />
      ),
      comprehension: unitReviewComprehensionData && (
        <QuestionAndAnswers
          audio={{
            intro: [
              `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/d23.mp3`,
            ],
            text: [
              `${BASE_URL_MIDIA}${unitReviewComprehensionData?.questions[questionIndex].audio}`,
            ],
          }}
          selectedAnswer={alternativeUnitReviewComprehension}
          text={unitReviewComprehensionData.questions[questionIndex].text}
          options={unitReviewComprehensionData.questions[questionIndex].options}
          questionIndex={questionIndex}
          setAnswer={(answer) =>
            dispatch(setAlternativeUnitReviewComprehension(answer))
          }
        />
      ),

      greatJob: (
        <GreatJob
          onNext={() => {
            if (currentLesson) {
              navigate(`/lesson/${urlsPaths[currentLesson?.mechanic_type]}`);
            }
          }}
        />
      ),
    },
  });

  return (
    <PageGrid
      activity="Review"
      lesson="Verbal Masters"
      disabledButton={
        verifyButtonHelp().inactiveButton ||
        pageName === "accessibility" ||
        inAudio
      }
      audioHelp={verifyButtonHelp().audios}
    >
      <ContainerLesson>
        {page}
        <Flex flexDir="column" gap="4px">
          <SubtitleComponent
            hide={
              !accessibility.subtitles ||
              !inAudio ||
              captionText === "" ||
              pageName === "greatJob"
            }
          />
          {unitReviewComprehensionData && (
            <ProgressBar
              data={{
                max: unitReviewComprehensionData?.questions.length,
                step: questionIndex + 1,
              }}
              hide={pageName !== "comprehension"}
              onConfirm={checkResponse}
              hideButtonPrevious
              hideButton={alternativeUnitReviewComprehension === "" || inAudio}
            />
          )}
        </Flex>
      </ContainerLesson>
    </PageGrid>
  );
}

export default UnitReviewReadingComprehension;
