import { Reducer } from "redux";
import {
  ReadingComprehensionActionTypes,
  ReadingComprehensionState,
} from "./type";

const INITIAL_STATE: ReadingComprehensionState = {
  isLoading: false,
  alternativeRC: "",
  answersRC: [],
  dateMileseconds: 0,
  questionIndex: 0,
  readingComprehensionData: undefined,
};

const reducer: Reducer<ReadingComprehensionState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case ReadingComprehensionActionTypes.LOAD_RCOMPREHENSION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ReadingComprehensionActionTypes.LOAD_RCOMPREHENSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        readingComprehensionData: action.payload,
      };
    case ReadingComprehensionActionTypes.LOAD_RCOMPREHENSION_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ReadingComprehensionActionTypes.ON_NEXT_RC:
      return {
        ...state,
        questionIndex: state.questionIndex + 1,
        alternativeRC: "",
        answersRC: [...state.answersRC, action.payload.contentAnswer],
      };

    case ReadingComprehensionActionTypes.SET_ANSWER_RC:
      return {
        ...state,
        alternativeRC: "",
        answersRC: [...state.answersRC, action.payload],
      };
    case ReadingComprehensionActionTypes.SET_ALTERNATIVE_RC:
      return {
        ...state,
        alternativeRC: action.payload,
      };
    case ReadingComprehensionActionTypes.ON_PREIVIOUS_RC:
      return {
        ...state,
        questionIndex: state.questionIndex - 1,
        alternativeRC: "",
      };

    case ReadingComprehensionActionTypes.POST_RCOMPREHENSION_REQUEST:
      return {
        ...state,
        answersRC: [...state.answersRC, action.payload.contentAnswer],
        isLoading: true,
      };
    case ReadingComprehensionActionTypes.POST_RCOMPREHENSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ReadingComprehensionActionTypes.POST_RCOMPREHENSION_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ReadingComprehensionActionTypes.SET_TIMER_RC:
      return {
        ...state,
        dateMileseconds: action.payload,
      };

    case ReadingComprehensionActionTypes.CLEAR_LIST:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default reducer;
