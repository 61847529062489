import {
  AnswerUnitReviewDefinition,
  IUnitReviewDefinition,
} from "../../../@types/interfaces/api/unit-review-definiton.interface";
import {
  AnswerUnitReviewSynonym,
  IUnitReviewSynonym,
} from "../../../@types/interfaces/api/unit-review-synonym.interface";

export enum UnitReviewDefinitionActionTypes {
  LOAD_QUESTION_UNIT_REVIEW_DEFINITION_REQUEST = "@unitReviewDefinition/LOAD_QUESTION_UNIT_REVIEW_DEFINITION_REQUEST",
  LOAD_QUESTION_UNIT_REVIEW_DEFINITION_SUCCESS = "@unitReviewDefinition/LOAD_QUESTION_UNIT_REVIEW_DEFINITION_SUCCESS",
  LOAD_QUESTION_UNIT_REVIEW_DEFINITION_ERROR = "@unitReviewDefinition/LOAD_QUESTION_UNIT_REVIEW_DEFINITION_ERROR",

  POST_ANSWER_UNIT_REVIEW_DEFINITION_REQUEST = "@unitReviewDefinition/POST_ANSWER_UNIT_REVIEW_DEFINITION_REQUEST",
  POST_ANSWER_UNIT_REVIEW_DEFINITION_SUCCESS = "@unitReviewDefinition/POST_ANSWER_UNIT_REVIEW_DEFINITION_SUCCESS",
  POST_ANSWER_UNIT_REVIEW_DEFINITION_ERROR = "@unitReviewDefinition/POST_ANSWER_UNIT_REVIEW_DEFINITION_ERROR",

  SET_ANSWER_UNIT_REVIEW_DEFINITION = "@unitReviewDefinition/ SET_ANSWER_UNIT_REVIEW_DEFINITION",
  SET_TIMER_UNIT_REVIEW_DEFINITION = "@unitReviewDefinition/ SET_TIMER_UNIT_REVIEW_DEFINITION",
  ON_NEXT = "@unitReviewDefinition/ ON_NEXT",
  ON_NEXT_2 = "@unitReviewDefinition/ ON_NEXT_2",
  ON_PREVIOUS = "@unitReviewDefinition/ PREVIOUS",
  CLEAR_LIST = "@unitReviewDefinition/CLEAR_LIST",
  RESET_INDEX = "@unitReviewDefinition/RESET_INDEX",
}

export interface UnitReviewDefinitionState
  extends UnitReviewDefinitionStateRequestsStatus {
  unitReviewDefinitionData: IUnitReviewDefinition | undefined;
  answers: AnswerUnitReviewDefinition[];
  alternativeUnitReviewDefinition: string;
  questionIndex: number;
  dateMileseconds: number;
}

interface UnitReviewDefinitionStateRequestsStatus {
  isLoading: boolean;
}
