import store from "..";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import { ISpellingQuestion } from "../../../@types/interfaces/api/pre-teaching.interface";
import {
  AnswerSpelling,
  ISpellingProgram,
  SpellingWordsType,
} from "../../../@types/interfaces/api/spelling-program.interface";
import {
  ActivityIdFilter,
  TalkingBook,
} from "../../../@types/interfaces/api/talking-book.interface";
import { StoryFeed } from "../../../components/lessons/LessonSpelling";
import { assetsBaseUrl } from "../../../utils/assets-helper";

import { SpellingProgramActionTypes } from "./type";

export const loadSpellingProgramRequest = (filter: ActivityIdFilter) => ({
  type: SpellingProgramActionTypes.LOAD_SPELLING_REQUEST,
  payload: filter,
});

export const loadSpellingProgramSuccess = (data: ISpellingProgram) => ({
  type: SpellingProgramActionTypes.LOAD_SPELLING_SUCCESS,
  payload: data,
});

export const loadSpellingProgramError = () => ({
  type: SpellingProgramActionTypes.LOAD_SPELLING_ERROR,
});

export const setAlternativeSpelling = (alternative: SpellingWordsType) => {
  return {
    type: SpellingProgramActionTypes.SET_ALTERNATIVE_SPELLING,
    payload: alternative,
  };
};
export const setWordFeedSpelling = () => {
  const contentData = store.getState().spellingProgram.alternativeSpelling;

  let wordFeed: StoryFeed[] = [];

  if (contentData) {
    wordFeed.push({
      type: "text",
      content: contentData?.text,
    });
    wordFeed.push({
      type: "audio",
      content: "",
      contentArray: [
        `${assetsBaseUrl()}${contentData.audio}`,
        `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/common_ogg/${contentData.text.toLowerCase()}.ogg`,
      ],
    });
    let currentWord = "";
    for (let i = 0; i < contentData.text.length; i++) {
      currentWord += contentData.text[i];
      wordFeed.push({
        type: "text",
        content: currentWord,
      });
      const audioUrl = `${assetsBaseUrl()}/app-2.0/vm_html5/audio/alpha_mp3/${
        contentData.text[i]
      }.mp3`;
      const audioUrlOgg = `${assetsBaseUrl()}/app-2.0/jr4_html5/audio/alpha_ogg/${
        contentData.text[i]
      }.ogg`;
      wordFeed.push({
        type: "audio",
        content: "",
        contentArray: [audioUrl, audioUrlOgg],
      });
    }
    wordFeed.push({
      type: "audio",
      content: "",
      contentArray: [
        `${assetsBaseUrl()}${contentData.audio}`,
        `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/common_ogg/${contentData.text.toLowerCase()}.ogg`,
      ],
    });
    wordFeed.push({
      type: "text",
      content: contentData.text,
    });
    wordFeed.push({
      type: "audio",
      content: "",
      contentArray: [
        `${assetsBaseUrl()}/app-2.0/jr4_html5/audio/ENG/dirs_mp3/SPELLING_DIRECTIONS_1.mp3`,
        `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/in83.ogg`,
      ],
    });
  }

  const spellingQuestionData: ISpellingQuestion = {
    audio: [
      `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/uh05.mp3`,
      `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/in46.ogg`,
      `${assetsBaseUrl()}${contentData?.audio}`,
      `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/common_ogg/${contentData?.text.toLowerCase()}.ogg`,
      `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/uh06.mp3`,
      `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/in84.ogg`,
    ],
    response: contentData?.text ?? "",
    correct: [
      `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/praise03.mp3`,
      `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/in67.ogg`,
    ],
    wrong: [
      `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/uh07.mp3`,
      `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/in74.ogg`,
    ],
  };

  return {
    type: SpellingProgramActionTypes.SET_WORD_FEED_SPELLING,
    payload: { wordFeed, spellingQuestionData },
  };
};
export const postAnswerSpellingRequest = (data: AnswerSpelling[]) => ({
  type: SpellingProgramActionTypes.POST_ANSWER_SPELLING_REQUEST,
  payload: data,
});

export const postAnswerSpellingSuccess = () => ({
  type: SpellingProgramActionTypes.POST_ANSWER_SPELLING_SUCCESS,
});
export const postAnswerSpellingError = () => ({
  type: SpellingProgramActionTypes.POST_ANSWER_SPELLING_ERROR,
});

export const setTimerSpelling = (date: number) => ({
  type: SpellingProgramActionTypes.SET_TIMER_SPELLING,
  payload: date,
});
export const setTextSpelling = (text: string) => ({
  type: SpellingProgramActionTypes.SET_TEXT_SPELLING,
  payload: text,
});
export const clearSpelling = () => ({
  type: SpellingProgramActionTypes.CLEAR_SPELLING,
});

export const onNextSpelling = (setPage: (page: any) => void) => {
  setPage("greatJob");
  return {
    type: SpellingProgramActionTypes.POST_ANSWER_SPELLING_REQUEST,
    payload: setPage,
  };
};

export const setAnswerSpelling = () => {
  const contentListAnswer = store.getState().spellingProgram;
  const chosenAnswer = contentListAnswer.textSpelling;
  const questionId = contentListAnswer.alternativeSpelling?.id;

  const contentAnswer = {
    answer: chosenAnswer,
    question: questionId,
    history: 0,
  };
  return {
    type: SpellingProgramActionTypes.SET_ANSWER_SPELLING,
    payload: contentAnswer,
  };
};

export const learnedWordSpelling = () => {
  const contentListAnswer =
    store.getState().spellingProgram.alternativeSpelling?.id;

  return {
    type: SpellingProgramActionTypes.LEARNED_WORD_SPELLING,
    payload: contentListAnswer,
  };
};
