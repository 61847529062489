import { createStandaloneToast } from "@chakra-ui/react";
import theme from "../../../styles/theme";
import { AxiosResponse } from "axios";
import { call, put, select } from "redux-saga/effects";
import api from "../../../services/api";
import { AnyAction } from "redux";
import {
  loadShortAnswerError,
  loadShortAnswerSuccess,
  postAnswerShortAnswerError,
  postAnswerShortAnswerSuccess,
} from "./actions";
import {
  HistoryRecognitionResponse,
  IProgress,
} from "../../../@types/interfaces/api/word-recognition.interface";
import { ApplicationState, getGlobalState } from "..";
import { IShortAnswer } from "../../../@types/interfaces/api/short-answer.interface";
import { postProgress } from "../word-recognition/sagas";
import { ActivityIdFilter } from "../../../@types/interfaces/api/talking-book.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";

const { toast } = createStandaloneToast({ theme });

export function* loadShortAnswerId(action: AnyAction) {
  const filter: ActivityIdFilter = action.payload;
  try {
    const response: AxiosResponse<PaginationBase<IShortAnswer>> = yield call(
      api.get,
      `/short-answer`,
      {
        params: filter,
      }
    );
    if (response.status == 200) {
      yield put(loadShortAnswerSuccess(response.data));
    }
  } catch (e: any) {
    yield put(loadShortAnswerError());
  }
}

export function* postHistorySA() {
  const data: ApplicationState = yield select(getGlobalState);

  const timeLesson = Date.now() - data.shortAnswer.dateMileseconds;

  const dataHitory = {
    time: timeLesson,
    student: data.auth.student?.user,
  };

  try {
    const response: AxiosResponse = yield call(
      api.post,
      "/history",
      dataHitory
    );
    if (response.status == 201) {
      return response.data;
    }
  } catch (e: any) {
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* postAnswerSA(action: AnyAction) {
  const data: ApplicationState = yield select(getGlobalState);
  const setPage = action.payload.setPage;
  const activity = data.shortAnswer.shortAnswerData?.results[0].activity;

  try {
    const responseHistory: HistoryRecognitionResponse = yield call(
      postHistorySA
    );

    const completeData = data.shortAnswer.answersSA.map((answer) => {
      return { ...answer, history: responseHistory.id };
    });

    const response: AxiosResponse = yield call(
      api.post,
      "/short-answer/answer",
      completeData
    );
    if (response.status == 201) {
      yield put(postAnswerShortAnswerSuccess());
      yield call(postProgress, activity);
    }
  } catch (e: any) {
    yield put(postAnswerShortAnswerError());
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}
