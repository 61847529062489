import { createStandaloneToast } from "@chakra-ui/react";

import { AxiosResponse } from "axios";
import { call, put, select } from "redux-saga/effects";
import api from "../../../services/api";
import theme from "../../../styles/theme";

import { AnyAction } from "redux";
import { ApplicationState, getGlobalState } from "..";
import DiagnosticFilterInterface, {
  HistoryDiagnostic,
  HistoryDiagnosticResponse,
  QuestionDiagnostic,
} from "../../../@types/interfaces/api/diagnostic.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import { loadStudent } from "../auth/sagas";
import { setPageNumberPreTest } from "../pre-test/actions";
import {
  loadQuestionDiagnosticError,
  loadQuestionDiagnosticSuccess,
  postAnswerDiagnosticError,
  postAnswerDiagnosticSuccess,
  setAdditional,
} from "./actions";

const { toast } = createStandaloneToast({ theme });

export function* loadQuestionsDiagnostic(action: AnyAction) {
  const filter: DiagnosticFilterInterface = action.payload;
  try {
    const response: AxiosResponse<PaginationBase<QuestionDiagnostic[]>> =
      yield call(api.get, "/diagnostic/question", { params: filter });
    if (response.status == 200) {
      yield put(loadQuestionDiagnosticSuccess(response.data));
      yield put(setPageNumberPreTest(response.data.results[0].level));
      yield put(setAdditional(response.data.results[0].aditional));
    }
  } catch (e: any) {
    yield put(loadQuestionDiagnosticError());
  }
}

export function* postHistoryDignostic() {
  const data: ApplicationState = yield select(getGlobalState);
  const level = data.diagnostic.questions?.results[0].level;

  const timeLesson = Date.now() - data.diagnostic.dateMileseconds;

  const dataHitory: HistoryDiagnostic = {
    time: timeLesson,
    student: data.auth.student?.user,
    level: level ?? 0,
    grade: data.auth.student?.grade ?? "",
    additional: data.diagnostic.additional,
  };

  try {
    const response: AxiosResponse = yield call(
      api.post,
      "/diagnostic/history",
      dataHitory
    );
    if (response.status == 201) {
      return response.data;
    }
  } catch (e: any) {
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* postAnswerDiagnostic(action: AnyAction) {
  const data: ApplicationState = yield select(getGlobalState);
  const setPage = action.payload.setPage;
  const correctAnswer = data.diagnostic.numberOfCorrectAnswers;

  try {
    const responseHistory: HistoryDiagnosticResponse = yield call(
      postHistoryDignostic
    );

    const completeData = data.diagnostic.answers.map((answer) => {
      return { ...answer, diagnostic_history: responseHistory.id };
    });

    const response: AxiosResponse = yield call(
      api.post,
      "/diagnostic/answer",
      completeData
    );
    if (response.status == 201) {
      if (correctAnswer >= 3) {
        yield put(postAnswerDiagnosticSuccess());
        yield call(loadStudent, responseHistory.student);
      } else {
        yield put(postAnswerDiagnosticSuccess());
        yield call(loadStudent, responseHistory.student);
      }
    }
  } catch (e: any) {
    yield put(postAnswerDiagnosticError());
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}
