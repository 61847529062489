import { Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { useLocation } from "react-router-dom";
import { DragOption } from "../../../@types/interfaces/api/drag-fill.interface";
import PlayIcon from "../../../assets/icons/PlayIcon";
import Text32px from "../../global/text/Text32px";

interface Props {
  droppableId: string;
  text: string[];
  alternatives: DragOption[];
  disabledMultDrop?: boolean;
}
function DropOnBlankSpace({
  alternatives,
  text,
  droppableId,
  disabledMultDrop = false,
}: Props) {
  return (
    <Flex alignItems={"center"} mt="16px" flexWrap="wrap" gap="8px">
      {text[0].split(" ").map((w, index) => (
        <Text32px key={index}>{w}</Text32px>
      ))}

      <Droppable
        droppableId={droppableId}
        direction="horizontal"
        isDropDisabled={alternatives.length > 0 && disabledMultDrop}
      >
        {(provided) => (
          <Flex
            ref={provided.innerRef}
            minWidth="70px"
            minHeight="40px"
            bg="white"
            borderRadius="8px"
            border="1.5px solid #CBD5E0"
            mx="8px"
            alignItems={"center"}
            justifyContent={"center"}
            gap={1}
          >
            {alternatives.map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={String(item.id)}
                index={index}
              >
                {(provided, snapshot) => (
                  <Flex
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    bg={snapshot.isDragging ? "primary" : "white"}
                    p="8px 16px"
                    borderRadius="8px"
                  >
                    <Text32px color={snapshot.isDragging ? "white" : "primary"}>
                      {item.text}
                    </Text32px>
                  </Flex>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Flex>
        )}
      </Droppable>

      {text[1].split(" ").map((w, index) => (
        <Text32px key={index}>{w}</Text32px>
      ))}
      {/* <PlayIcon /> */}
    </Flex>
  );
}

export default DropOnBlankSpace;
