import { Button, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  DragAlternative,
  DragOption,
} from "../../@types/interfaces/api/drag-fill.interface";
import RegularButton from "../global/RegularButton";
import Subtitle from "../global/text/Subtitle";
import DragDropContextWrap from "./extra/DragDropContextWrap";
import DragOptionsBox from "./extra/DragOptionsBox";
import DropOnBlankSpace from "./extra/DropOnBlankSpace";
import { assetsBaseUrl } from "../../utils/assets-helper";
import { useLanguageContext } from "../../providers/LanguageProvider";
import { useAccesibiltyContext } from "../../providers/AccessibilityController";

interface Props {
  question: string;
  options: DragOption[];
  state: DragAlternative;
  setState: React.Dispatch<React.SetStateAction<DragAlternative>>;
  skip?: boolean;
  onNext?: () => void;
  inactiveDrag?: boolean;
  currentProgram: "JR" | "LF" | "VM" | null;
}

function DragFill({
  question,
  options,
  setState,
  state,
  skip,
  onNext,
  inactiveDrag,
  currentProgram,
}: Props) {
  const initialState = {
    items: options,
    selected: [],
  };
  const { language } = useLanguageContext();
  const { onPlayAudio } = useAccesibiltyContext();
  useEffect(() => {
    if (question) {
      onPlayAudio([
        `${assetsBaseUrl()}/app-2.0/jr_html5/audio/dirs/ENG/drag_best_word.mp3`,
      ]);
    }
  }, []);

  useEffect(() => {
    if (question) {
      setState(initialState);
    }
  }, [question]);

  const questionArray = question.split(/_+/);

  return (
    <Flex flexDir="column" alignItems={"center"}>
      <Subtitle>DRAG AND FILL WITH THE ONE THAT BEST FITS</Subtitle>
      <DragDropContextWrap
        alternatives={options}
        state={state}
        setState={setState}
        currentProgram={currentProgram}
      >
        <DropOnBlankSpace
          disabledMultDrop
          droppableId="selected"
          alternatives={state.selected}
          text={questionArray}
        />

        <DragOptionsBox
          alternatives={state.items}
          droppableId="items"
          inactiveDrag={inactiveDrag}
        />
      </DragDropContextWrap>
      <RegularButton
        onClick={onNext}
        w="100px"
        mt="100px"
        display={skip ? "initial" : "none"}
      >
        skip
      </RegularButton>
    </Flex>
  );
}

export default DragFill;
