import { Avatar, Flex, Image, Text, Tooltip } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import MenuHeader from "../global/MenuHeader";
import logo from "../../assets/image/logo.png";
import { useNavigate } from "react-router-dom";
import OutlineButton from "../global/OutlineButton";
import { Accessibility, ChevronDown, Settings, X } from "lucide-react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { logOut } from "../../stores/ducks/auth/actions";
import { loadLessonIDRequest } from "../../stores/ducks/lesson/action";
import { mechanicTypeWithAccessibility } from "../../utils/Utility";
import ModalLaunch from "../modals/ModalLaunch";
import ModalLaunchSubtitles from "../modals/ModalLaunchSubtitles";

const HeaderDashboard: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user, student } = useAppSelector((i) => i.auth);
  const { activityData, nextLessonFullData } = useAppSelector(
    (i) => i.dashboard
  );
  const { currentProgram, currentLesson } = useAppSelector((i) => i.lesson);
  const [openModal, setOpenModal] = useState({
    launch: false,
    lanuchSubtitles: false,
  });

  const currentMechanicType = student?.progress.finished
    ? nextLessonFullData?.mechanic_type
    : activityData?.mechanic_type;

  const verifyMechanicType = () => {
    const has = mechanicTypeWithAccessibility.some((item) =>
      item.includes(currentMechanicType ?? "")
    );
    return has;
  };

  const activeButtonSettings = currentProgram === "JR" && !verifyMechanicType();

  useEffect(() => {
    verifyMechanicType();
    if (currentLesson) {
      dispatch(loadLessonIDRequest(currentLesson?.lesson));
    }
  }, [currentLesson]);

  return (
    <Flex
      display={["none", "flex"]}
      flexDir={["column", "row", "row"]}
      alignItems="center"
      py="16px"
      px="32px"
      borderBottom="1px solid #EDF2F7"
      justify="space-between"
      bg="#fff"
    >
      <Image src={logo} onClick={() => navigate("/")} />
      <Flex flexDir={["column", "row", "row"]} gap="16px" mt={["16px", "0"]}>
        <Flex h="auto" px="0">
          <Flex align="center" gap="12px">
            <Avatar
              name={`${user?.first_name} ${user?.last_name}`}
              boxSize="48px"
            />
            <Flex align="flex-start" flexDir="column" gap="8px">
              <Text fontSize="14px" fontWeight="600" color="#475569">
                {user?.first_name}
              </Text>
              <Text
                fontWeight="500"
                lineHeight="12px"
                fontSize="12px"
                color="#A0AEC0"
                textTransform="capitalize"
              >
                {student?.grade} Grade
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <OutlineButton
          disabled={activeButtonSettings}
          rightIcon={<Accessibility size="16px" />}
          ml={["0", "16px"]}
          fontSize={["14px", "16px"]}
          h={["36px", "48px"]}
          onClick={() => {
            if (currentProgram === "VM") {
              setOpenModal({ lanuchSubtitles: true, launch: false });
              return;
            }
            if (verifyMechanicType()) {
              setOpenModal({ lanuchSubtitles: false, launch: true });
            }
          }}
        >
          <Tooltip
            borderRadius="8px"
            bg="gray.600"
            label="This activity does not contain accessibility."
            isDisabled={verifyMechanicType()}
          >
            Settings
          </Tooltip>
        </OutlineButton>

        <OutlineButton
          rightIcon={<X size="16px" />}
          ml={["0", "16px"]}
          fontSize={["14px", "16px"]}
          h={["36px", "48px"]}
          onClick={() => dispatch(logOut(navigate))}
        >
          Sign Out
        </OutlineButton>
      </Flex>
      <ModalLaunch
        isOpen={openModal.launch}
        onClose={() => setOpenModal({ lanuchSubtitles: false, launch: false })}
      />
      <ModalLaunchSubtitles
        isOpen={openModal.lanuchSubtitles}
        onClose={() => setOpenModal({ lanuchSubtitles: false, launch: false })}
      />
    </Flex>
  );
};

export default HeaderDashboard;
