import {
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Image,
  InputGroup,
  InputLeftElement,
  Link as ChakraLink,
  Text,
  useMediaQuery,
  InputRightElement,
} from "@chakra-ui/react";
import { Eye, EyeOff, Key, Mail, User } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { LoginCredentials } from "../@types/interfaces/api/login.interface";
import arrowIcon from "../assets/icons/arrow-rigth.svg";
import logo from "../assets/image/logo.png";
import bgImage from "../assets/image/studant-bg.png";
import InputLogin from "../components/form/InputLogin";
import RegularButton from "../components/global/RegularButton";
import ModalLogin from "../components/modals/ModalLogin";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { signInRequest } from "../stores/ducks/auth/actions";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form } from "../styles/global";
import * as yup from "yup";
import packageJson from "../../package.json";
import { HiEyeOff } from "react-icons/hi";

const LoginStudants: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isSignInLoading } = useAppSelector((i) => i.auth);
  const [modalLoginOpen, setModalLoginOpen] = useState({ isOpen: false });
  const [checked, setChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLargerThan768, isLargerThan480] = useMediaQuery([
    "(min-width: 768px)",
    "(min-width: 480px)",
  ]);
  const navigate = useNavigate();
  const loginFormSchema = yup.object().shape({
    username: yup.string().min(3).required("This field is required"),
    password: yup.string().required("This field is required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<LoginCredentials>({
    resolver: yupResolver(loginFormSchema),
  });

  const submitForm = (data: LoginCredentials) => {
    const fullCredentials: LoginCredentials = { ...data, platform: "student" };
    dispatch(signInRequest(fullCredentials, navigate));

    if (checked) {
      localStorage.setItem("defx", data.username);
      localStorage.setItem("jkl", data.password);
    } else {
      localStorage.removeItem("defx");
      localStorage.removeItem("jkl");
    }
  };

  useEffect(() => {
    const dataLocalUsername = localStorage.getItem("defx");
    const dataLocalPassword = localStorage.getItem("jkl");
    if (dataLocalUsername && dataLocalPassword) {
      setChecked(true);
      reset({
        username: dataLocalUsername,
        password: dataLocalPassword,
      });
    }
  }, []);
  return (
    <Flex w="full" h="100vh" justify="center" align="center">
      <Flex
        w="full"
        h="100vh"
        align="center"
        justify={isLargerThan768 ? "center" : ""}
        flexDir="column"
        bg="white"
      >
        <Flex
          hidden={isLargerThan768}
          w="full"
          bgImage={bgImage}
          bgRepeat="no-repeat"
          bgPosition="top"
          backgroundSize="cover"
          mt={["-70px", "-70px", "0px"]}
          h="400px"
        />
        <Flex
          p="20px"
          borderTopRadius="16px"
          bg="white"
          position={isLargerThan768 ? "initial" : "absolute"}
          bottom={0}
          w={isLargerThan768 ? "" : "full"}
        >
          <Form
            onSubmit={handleSubmit(submitForm)}
            style={{ alignItems: "center" }}
          >
            <Image src={logo} />
            <Heading fontSize={["24px", "32px"]} mt="40px">
              Student’s Platform
            </Heading>
            <Text
              color="gray.500"
              mt="14px"
              fontSize={["14px", "16px"]}
              textAlign="center"
            >
              Access the student's lessons within the platform
            </Text>
            <Flex flexDir="column" gap="16px" w="full" mt="40px">
              <FormControl isInvalid={!!errors.username}>
                <InputGroup>
                  <InputLeftElement
                    color="gray.400"
                    children={<User size="20" />}
                    pl="16px"
                    pt="8px"
                  />
                  <InputLogin
                    placeholder="Username"
                    type="text"
                    {...register("username")}
                  />
                </InputGroup>
                <FormErrorMessage>{errors.username?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.password}>
                <InputGroup>
                  <InputLeftElement
                    color="gray.400"
                    children={<Key size="20" />}
                    pl="16px"
                    pt="8px"
                  />
                  <InputLogin
                    placeholder="PIN Code"
                    type={showPassword ? "text" : "password"}
                    {...register("password")}
                  />
                  <InputRightElement
                    cursor="pointer"
                    onClick={() => setShowPassword(!showPassword)}
                    color="gray.400"
                    mt="6px"
                    mr="12px"
                    children={
                      showPassword ? <Eye size="20" /> : <EyeOff size="20" />
                    }
                  />
                </InputGroup>
                <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
              </FormControl>
            </Flex>

            <Flex
              justify="space-between"
              align="center"
              w="full"
              mt="14px"
              flexDir={["column", "row"]}
            >
              <Checkbox
                color="gray.500"
                colorScheme={"tagScheme"}
                isChecked={checked}
                onChange={(e: any) => setChecked(e.target.checked)}
              >
                <Text fontSize="14px">Remember me</Text>
              </Checkbox>
              <ChakraLink
                textDecoration="underline"
                color="blue.300"
                as={Link}
                to={"#"}
                fontSize="14px"
                alignSelf="center"
                onClick={() => setModalLoginOpen({ isOpen: true })}
              >
                Forgot Your Password?
              </ChakraLink>
            </Flex>
            <RegularButton
              w="full"
              mt="32px"
              rightIcon={<Image src={arrowIcon} />}
              type="submit"
              isLoading={isSignInLoading}
            >
              Sign in
            </RegularButton>
            <Text mt="40px" color="gray.400" fontSize="12px" p="10px">
              © 2005-2022 Failure Free Reading. All rights reserved.
            </Text>
            <Text mb="40px" color="gray.400" fontSize="12px">
              Version {packageJson.version}
            </Text>
          </Form>
        </Flex>
      </Flex>
      <Flex
        hidden={!isLargerThan768}
        w="full"
        bgImage={bgImage}
        bgRepeat="no-repeat"
        bgPosition="center"
        backgroundSize="cover"
        h="100vh"
      />
      <ModalLogin
        isOpen={modalLoginOpen.isOpen}
        onClose={() => setModalLoginOpen({ isOpen: false })}
      />
    </Flex>
  );
};

export default LoginStudants;
