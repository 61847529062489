import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import reactStringReplace from "react-string-replace";

export type HighlightText = {
  id: number;
  text: string;
};
interface Props {
  text: string | undefined;
  highlights?: Array<HighlightText>;
  handleClick?: (selected: HighlightText) => void;
}
function TextHighlightWord({ text, highlights, handleClick }: Props) {
  let displayedText: React.ReactNode = text;

  if (highlights?.length) {
    let enhancedText;
    for (const highlight of highlights) {
      enhancedText = reactStringReplace(
        enhancedText ?? text,
        highlight.text,
        (match, index, offset) => (
          <span
            key={index + "-" + offset}
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => handleClick && handleClick(highlight)}
          >
            {match}
          </span>
        )
      );
    }
    displayedText = enhancedText;
  }

  return (
    <Flex flexWrap={"wrap"}>
      <Text color="gray.500" fontSize="20px">
        {displayedText}
      </Text>
    </Flex>
  );
}

export default TextHighlightWord;
