import { Button, Flex, Image, Text } from "@chakra-ui/react";
import { ArrowRight } from "lucide-react";
import { useEffect, useState } from "react";
import {
  WordRecognitionData,
  WordSelect,
} from "../../@types/interfaces/api/word-recognition.interface";
import PlayIcon from "../../assets/icons/PlayIcon";
import {
  audioCorrectAnswer,
  audioCorrectAnswerOgg,
  BASE_URL_MIDIA,
} from "../../utils/Utility";
import RegularButton from "../global/RegularButton";
import Subtitle from "../global/text/Subtitle";
import Text32px from "../global/text/Text32px";
import { assetsBaseUrl } from "../../utils/assets-helper";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { setActiveButtonHelp } from "../../stores/ducks/lesson/action";
import { useLanguageContext } from "../../providers/LanguageProvider";
import SignLanguage from "../global/SignLanguage";
import { useAccesibiltyContext } from "../../providers/AccessibilityController";
import SubtitleComponent from "../global/SubtitleComponent";
import { AlternativeIds } from "../../stores/ducks/word-recognition/type";

const selectedInitialState = {
  id: 0,
  text: "",
  audio: "",
  sentence: 0,
};
interface Props {
  onNext: () => Promise<void>;
  sentence: WordRecognitionData | undefined;
  correctAnswer: WordSelect | undefined;
  questionIndex: number;
  setAlternativeFn: (alternativeIds: AlternativeIds) => void;
  setAnswerFn: () => void;
}
function WordRecognitionQuestion({
  sentence,
  onNext,
  correctAnswer,
  questionIndex,
  setAlternativeFn,
  setAnswerFn,
}: Props) {
  const [highlight, setHighlight] = useState("");
  const dispatch = useAppDispatch();
  const { playMedias, accessibility, captionText } = useAccesibiltyContext();
  const [lockContinue, setLockContinue] = useState(true);
  const [firstClick, setFirstClick] = useState(true);
  const [selectedWord, setSelectedWord] = useState(selectedInitialState);
  const wordForOgg = correctAnswer?.audio
    .split("/")
    .pop()
    ?.split(".")[0]
    .replace(/[.,]+/g, ".");

  const introAudio = [
    `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/WRECFIND.mp3`,
    `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in40.ogg`,
  ];
  const selectWordAudio = [
    `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/PLEASLCT.mp3`,
    `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in6.ogg`,

    BASE_URL_MIDIA + correctAnswer?.audio,
    `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/common_ogg/${wordForOgg}.ogg`,
  ];

  const wrongAudioPre = [
    `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/YOUCHOSE.mp3`,
    `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in70.ogg`,
  ];
  const wrongAudioPos = [
    `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/ASKEDFOR.mp3`,
    `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in_blank.ogg`,
  ];

  useEffect(() => {
    const init = async () => {
      dispatch(setActiveButtonHelp(false));
      if (questionIndex !== 0) {
        await playMedias(selectWordAudio);

        // await onPlayAudio( ...selectWordAudio);
      } else {
        await playMedias([...introAudio, ...selectWordAudio]);
        // await onPlayAudio( introAudio, ...selectWordAudio);
      }
      setLockContinue(false);
      dispatch(setActiveButtonHelp(true));
    };

    init();
  }, [correctAnswer]);

  useEffect(() => {
    setFirstClick(true);
  }, [questionIndex]);

  async function handleOkButton() {
    const isCorrect = checkAnswer();
    setFirstClick(false);

    if (!isCorrect && correctAnswer) {
      await playMedias(wrongAudioPre);
      setHighlight(selectedWord.text);
      await playMedias([
        BASE_URL_MIDIA + selectedWord.audio.replace(/[.,]+/g, "."),
        `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/common_ogg/${selectedWord.text.replace(
          /[.,]+/g,
          "."
        )}.ogg`,
      ]);

      setHighlight("");
      await playMedias(wrongAudioPos);
      setHighlight(correctAnswer.text);
      await playMedias([
        BASE_URL_MIDIA + correctAnswer?.audio,
        `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/common_ogg/${wordForOgg}.ogg`,
      ]);

      setHighlight("");
      if (firstClick) {
        setAnswerFn();
      }
    } else {
      await playMedias([audioCorrectAnswer(), audioCorrectAnswerOgg()]);
      setSelectedWord(selectedInitialState);
      onNext();
      if (firstClick) {
        setAnswerFn();
      }
    }
  }

  function checkAnswer(): boolean | undefined {
    if (correctAnswer) {
      return (
        selectedWord.text.replace(/[.,]+/g, "").toLowerCase() ===
        correctAnswer.text.replace(/[.,]+/g, "").toLowerCase()
      );
    }
  }

  const windowHeight = window.innerHeight;
  const selectedAdio =
    BASE_URL_MIDIA + selectedWord.audio.replace(/[.,]+/g, ".").toLowerCase();

  return (
    <Flex w="full" px={["0px", "100px"]} flexDir="column" align="center">
      <Subtitle>WORD REGOGNITION EXERCISES</Subtitle>
      <Text32px mt="16px">{sentence?.title_lesson}</Text32px>

      <Flex mt="56px" gap={["24px", "140px"]} flexDir={["column", "row"]}>
        <Flex flexDir="column" h="100%" justifyContent="flex-end">
          <Flex gap="24px" align="center">
            <PlayIcon
              disabled={lockContinue}
              onClick={async () => {
                setLockContinue(true);
                await playMedias([
                  BASE_URL_MIDIA + correctAnswer?.audio,
                  `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/common_ogg/${wordForOgg}.ogg`,
                ]);
                setLockContinue(false);
              }}
            />

            <Flex
              mt="16px"
              flexWrap={"wrap"}
              gap="3px"
              maxW="442px"
              p="24px"
              bg="white"
              borderRadius="16px"
              border="1.5px solid #CBD5E0"
            >
              {sentence?.sentence.words.map((word, index) => {
                return (
                  <Text
                    mx={1}
                    key={index}
                    letterSpacing={0.3}
                    fontWeight={highlight === word.text ? "600" : "400"}
                    textColor={highlight === word.text ? "#0C4DA2" : "#718096"}
                    onClick={() => {
                      setSelectedWord(word);
                      setAlternativeFn({
                        question: correctAnswer?.id ?? 0,
                        word: word.id,
                      });
                    }}
                    cursor="pointer"
                    _hover={{
                      textColor: "#0C4DA2",
                      fontWeight: "600",
                      letterSpacing: 0.1,
                    }}
                  >
                    {word.text}
                  </Text>
                );
              })}
            </Flex>
          </Flex>
          <Flex gap="24px" align="center">
            <PlayIcon
              disabled={lockContinue}
              onClick={async () => {
                setLockContinue(true);
                await playMedias([
                  selectedAdio,
                  `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/common_ogg/${selectedWord.text
                    .replace(/[.,]+/g, ".")
                    .toLowerCase()}.ogg`,
                ]);
                setLockContinue(false);
              }}
            />

            <Flex
              mt="16px"
              flexWrap={"wrap"}
              gap="3px"
              maxW="442px"
              p="24px"
              bg="white"
              borderRadius="16px"
              border="1.5px solid #CBD5E0"
              w={selectedWord.text === "" ? "100px" : "initial"}
              h={selectedWord.text === "" ? "75px" : "initial"}
            >
              <Text fontWeight="600" color="primary">
                {selectedWord.text}
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Image
          borderRadius="16px"
          maxW="583px"
          src={BASE_URL_MIDIA + sentence?.image}
        />
      </Flex>
      <SubtitleComponent
        hide={!accessibility.subtitles || captionText === ""}
        mt="8px"
      />
      <Flex mt={windowHeight < 800 ? "30px" : "100px"}>
        <RegularButton
          rightIcon={<ArrowRight />}
          onClick={handleOkButton}
          disabled={lockContinue || selectedWord.id === 0}
        >
          Ok
        </RegularButton>
      </Flex>
    </Flex>
  );
}

export default WordRecognitionQuestion;
