import { Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContainerLesson from "../../components/global/ContainerLesson";
import PageGrid from "../../components/global/PageGrid";
import ProgressBar from "../../components/global/ProgressBar";
import Finish from "../../components/lessons/common/Finish";
import GreatJob from "../../components/lessons/common/GreatJob";
import StartLesson from "../../components/lessons/common/StartLesson";
import ShortAnswer from "../../components/lessons/ShortAnswer";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { usePages } from "../../hooks/usePages";
import { loadActivityRequest } from "../../stores/ducks/dashboard/actions";
import {
  clearListSA,
  loadShortAnswerRequest,
  onNextSA,
  onPreviousSA,
  setAlternativeSA,
  setTimerShortAnswer,
} from "../../stores/ducks/short-answer/actions";
import { urlsPaths } from "../../utils/Utility";
import { assetsBaseUrl } from "../../utils/assets-helper";

function ShortAnswerPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { shortAnswerData, questionIndex, alternativeSA } = useAppSelector(
    (item) => item.shortAnswer
  );
  const { activityData } = useAppSelector((item) => item.dashboard);
  const { currentLesson } = useAppSelector((item) => item.lesson);

  useEffect(() => {
    if (shortAnswerData) {
      dispatch(loadActivityRequest(shortAnswerData?.results[0].activity));
    }
    if (currentLesson) {
      dispatch(loadShortAnswerRequest({ activity__id: currentLesson.id }));
    }

    dispatch(setTimerShortAnswer(Date.now()));
    return () => {
      dispatch(clearListSA());
    };
  }, []);

  const setAlternativeFn = (text: string) => {
    dispatch(setAlternativeSA(text));
  };
  const onConfirm = () => {
    if (!alternativeSA) {
      window.alert("This field needs to be filled");
    } else {
      dispatch(onNextSA(setPage));
    }
  };

  const { page, setPage, pageName } = usePages<"start" | "question" | "finish">(
    {
      initialPage: "start",
      pages: {
        start: (
          <StartLesson
            title="Short Answer Q&A"
            onNext={() => setPage("question")}
            button="start"
            videoExplanationUrl={
              activityData?.lesson.story.level.program.id === 3
                ? `${assetsBaseUrl()}/app-2.0/vm_html5/vids/all_units/short_answer_qa/video.mp4`
                : ""
            }
          />
        ),
        question: shortAnswerData && (
          <ShortAnswer
            exercise={shortAnswerData?.results[0].questions[questionIndex]}
            passages={shortAnswerData?.results[0].passages}
            questionNumber={questionIndex + 1}
            setAlternativeFn={setAlternativeFn}
            alternative={alternativeSA}
          />
        ),
        finish: (
          <Finish
            onNext={() => {
              if (currentLesson) {
                navigate(`/lesson/${urlsPaths[currentLesson?.mechanic_type]}`);
              }
            }}
          />
        ),
      },
    }
  );
  return (
    <PageGrid
      activityData={activityData}
      activity="Short Answer Q&A"
      lesson="Verbal Masters"
      onHelp={() => {}}
      audioHelp={[
        `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/answerqscompletesentences.mp3`,
      ]}
      disabledButton={pageName === "finish" || pageName === "start"}
    >
      {shortAnswerData && (
        <ContainerLesson>
          {page}

          <ProgressBar
            data={{
              max: shortAnswerData?.results[0].questions.length,
              step: questionIndex + 1,
            }}
            hide={pageName !== "question"}
            onConfirm={onConfirm}
            onPrevious={() => dispatch(onPreviousSA())}
          />
        </ContainerLesson>
      )}
    </PageGrid>
  );
}

export default ShortAnswerPage;
