import { NavigateFunction } from "react-router-dom";
import {
  FilterMinigames,
  IMinigameData,
  IMinigmeQuestions,
  IMinigmeQuestionsVM,
} from "../../../@types/interfaces/api/minigame.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import { AudioMedia } from "../../../hooks/AudioMedia";
import { MinigameActionTypes } from "./types";

export const loadMinigameRequest = (filter: FilterMinigames) => ({
  type: MinigameActionTypes.LOAD_MINIGAME_REQUEST,
  payload: filter,
});

export const loadMinigameSuccess = (data: IMinigameData) => {
  return {
    type: MinigameActionTypes.LOAD_MINIGAME_SUCCESS,
    payload: data,
  };
};

export const loadMinigameError = () => ({
  type: MinigameActionTypes.LOAD_MINIGAME_ERROR,
});

export const saveMinigameRequest = (navigate: NavigateFunction) => ({
  payload: { navigate },
  type: MinigameActionTypes.POST_MINIGAME_REQUEST,
});

export const saveMinigameSuccess = () => {
  return {
    type: MinigameActionTypes.POST_MINIGAME_SUCCESS,
  };
};

export const saveMinigameError = () => ({
  type: MinigameActionTypes.POST_MINIGAME_ERROR,
});

export const onNext = () => {
  return {
    type: MinigameActionTypes.ON_NEXT,
  };
};
export const setAlternativeGame = (alternative: string) => {
  return {
    type: MinigameActionTypes.SET_ALTERNATIVE_MINIGAME,
    payload: alternative,
  };
};

export const targetsHit = () => {
  return {
    type: MinigameActionTypes.TARGETS_HIT,
  };
};

export const missShot = () => {
  return {
    type: MinigameActionTypes.MISSES,
  };
};

export const otherHits = () => {
  return {
    type: MinigameActionTypes.OTHER_HITS,
  };
};

export const shotFired = () => {
  return {
    type: MinigameActionTypes.SHOT_FIRED,
  };
};

export const setDifficulty = (number: 0 | 1 | 2) => {
  return {
    type: MinigameActionTypes.SET_DIFFICULTY,
    payload: number,
  };
};
export const setTimerMinigame = (date: number) => ({
  type: MinigameActionTypes.SET_TIMER_MINIGAME,
  payload: date,
});
export const clearGame = () => {
  return {
    type: MinigameActionTypes.CLEAR_GAME,
  };
};
