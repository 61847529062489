import { Reducer } from "redux";
import { DragFillActionTypes, DragFillState } from "./type";

const INITIAL_STATE: DragFillState = {
  isLoading: false,
  questionIndex: 0,
  DragFillData: undefined,
  alternativeDrag: { items: [], selected: [] },
  answerDrag: [],
  dateMileseconds: 0,
  wordSelected: "",
  skip: false,
  wrongAnswer: 0,
  inactiveDrag: false,
};

const reducer: Reducer<DragFillState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DragFillActionTypes.LOAD_DRAG_FILL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DragFillActionTypes.LOAD_DRAG_FILL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        DragFillData: action.payload,
      };
    case DragFillActionTypes.LOAD_DRAG_FILL_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case DragFillActionTypes.SET_SKIP_FALSE:
      return {
        ...state,
        wrongAnswer: state.wrongAnswer + 1,
        skip: false,
      };
    case DragFillActionTypes.SET_SKIP_TRUE:
      return {
        ...state,
        skip: true,
      };
    case DragFillActionTypes.SKIP_QUESTION:
      return {
        ...state,
        questionIndex: state.questionIndex + 1,
        alternativeDrag: { items: [], selected: [] },
        wordSelected: "",
        skip: false,
        wrongAnswer: 0,
      };

    case DragFillActionTypes.ON_NEXT_DRAG:
      return {
        ...state,
        questionIndex: state.questionIndex + 1,
        alternativeDrag: { items: [], selected: [] },
        answerDrag: [...state.answerDrag, action.payload.contentAnswer],
        wordSelected: "",
        skip: false,
        wrongAnswer: 0,
      };

    case DragFillActionTypes.SET_ANSWER_DRAG:
      return {
        ...state,
        answerDrag: [...state.answerDrag, action.payload],
      };

    case DragFillActionTypes.ON_PREIVIOUS_DRAG:
      return {
        ...state,
        questionIndex: state.questionIndex - 1,
        alternativeDrag: { items: [], selected: [] },
        answerDrag: action.payload,
      };

    case DragFillActionTypes.POST_ANSWER_DRAG_REQUEST:
      return {
        ...state,
        inactiveDrag: true,
        answerDrag: [...state.answerDrag, action.payload.contentAnswer],
        isLoading: true,
      };
    case DragFillActionTypes.POST_ANSWER_DRAG_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case DragFillActionTypes.POST_ANSWER_DRAG_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case DragFillActionTypes.SET_ALTERNATIVE_DRAG:
      return {
        ...state,
        alternativeDrag: action.payload,
      };
    case DragFillActionTypes.SET_WORD_DRAG:
      return {
        ...state,
        wordSelected: action.payload,
      };

    case DragFillActionTypes.CLEAR_LIST_DRAG:
      return INITIAL_STATE;

    case DragFillActionTypes.SET_TIMER_DRAG:
      return {
        ...state,
        dateMileseconds: action.payload,
      };

    default:
      return state;
  }
};
export default reducer;
