import { Reducer } from "redux";
import { PreTestVmActionTypes, PreTestVmState } from "./type";

const INITIAL_STATE: PreTestVmState = {
  isLoading: false,
  answersPreTestVm: [],
  dateMileseconds: 0,
  preTestVmData: undefined,
  questionIndex: 0,
  typeLesson: "synonym",
  alternativeSnonym: undefined,
  alternativeDefinition: undefined,
};

const reducer: Reducer<PreTestVmState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PreTestVmActionTypes.LOAD_PRE_TEST_VM_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case PreTestVmActionTypes.LOAD_PRE_TEST_VM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        preTestVmData: action.payload,
      };
    case PreTestVmActionTypes.LOAD_PRE_TEST_VM_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case PreTestVmActionTypes.ON_NEXT_SYNONYM:
      return {
        ...state,
        questionIndex: state.questionIndex + 1,
        alternativeSnonym: undefined,
        alternativeDefinition: undefined,
        answersPreTestVm: [
          ...state.answersPreTestVm,
          action.payload.contentAnswer,
        ],
      };
    case PreTestVmActionTypes.ON_NEXT_DEFINITION:
      return {
        ...state,
        questionIndex: state.questionIndex + 1,
        alternativeSnonym: undefined,
        alternativeDefinition: undefined,
        answersPreTestVm: action.payload.contentAnswer,
      };

    case PreTestVmActionTypes.SET_TYPE_PRE_TEST_VM:
      return {
        ...state,
        typeLesson: action.payload,
      };

    case PreTestVmActionTypes.SET_TYPE_DEFINITION:
      return {
        ...state,
        typeLesson: "definition",
        questionIndex: 0,
        alternativeSnonym: undefined,
        alternativeDefinition: undefined,
        answersPreTestVm: [
          ...state.answersPreTestVm,
          action.payload.contentAnswer,
        ],
      };
    case PreTestVmActionTypes.SET_ALTERNATIVE_SYNONYM:
      return {
        ...state,
        alternativeSnonym: action.payload,
      };
    case PreTestVmActionTypes.SET_ALTERNATIVE_DEFINITION:
      return {
        ...state,
        alternativeDefinition: action.payload,
      };
    case PreTestVmActionTypes.ON_PREIVIOUS_PRE_TEST_VM:
      return {
        ...state,
        questionIndex: state.questionIndex - 1,
        alternativeSnonym: undefined,
        alternativeDefinition: undefined,
      };

    case PreTestVmActionTypes.POST_PRE_TEST_VM_REQUEST:
      return {
        ...state,
        answersPreTestVm: action.payload.contentAnswer,
        isLoading: true,
      };
    case PreTestVmActionTypes.POST_PRE_TEST_VM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        questionIndex: 0,
        alternativeSnonym: undefined,
        alternativeDefinition: undefined,
        answersPreTestVm: [],
      };
    case PreTestVmActionTypes.POST_PRE_TEST_VM_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case PreTestVmActionTypes.SET_TIMER_PRE_TEST_VM:
      return {
        ...state,
        dateMileseconds: action.payload,
      };

    case PreTestVmActionTypes.CLEAR_LIST:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default reducer;
