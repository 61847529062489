import { Reducer } from "redux";
import { AuthActionTypes, AuthState } from "./type";

const INITIAL_STATE: AuthState = {
  authenticated: false,
  isSignInLoading: false,
  isTokenLoading: true,
  user: undefined,
  student: undefined,
};

const reducer: Reducer<AuthState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthActionTypes.SIGN_IN_REQUEST:
      return {
        ...state,
        isSignInLoading: true,
      };
    case AuthActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        isSignInLoading: false,
      };
    case AuthActionTypes.SIGN_IN_ERROR:
      return {
        ...INITIAL_STATE,
        isSignInLoading: false,
        isTokenLoading: false,
      };

    case AuthActionTypes.LOG_OUT:
      return {
        ...INITIAL_STATE,
        isTokenLoading: false,
      };
    case AuthActionTypes.LOAD_TOKEN_REQUEST:
      return {
        ...state,
        isTokenLoading: true,
      };
    case AuthActionTypes.LOAD_TOKEN_SUCCESS:
      return {
        ...state,
        isTokenLoading: false,
        authenticated: true,
        user: action.payload,
      };
    case AuthActionTypes.LOAD_TOKEN_ERROR:
      return {
        ...state,
        isTokenLoading: false,
        authenticated: false,
      };

    case AuthActionTypes.LOAD_STUDENT_REQUEST:
      return {
        ...state,
        isSignInLoading: true,
      };
    case AuthActionTypes.LOAD_STUDENT_SUCCESS:
      return {
        ...state,
        isSignInLoading: false,
        student: action.payload,
      };
    case AuthActionTypes.LOAD_STUDENT_ERROR:
      return {
        ...state,
        isSignInLoading: false,
      };

    default:
      return state;
  }
};
export default reducer;
