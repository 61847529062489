import { createStandaloneToast } from "@chakra-ui/react";
import theme from "../../../styles/theme";
import { AxiosResponse } from "axios";
import { call, put, select } from "redux-saga/effects";
import api from "../../../services/api";
import { AnyAction } from "redux";
import { IReinforcementActivities } from "../../../@types/interfaces/api/reinforcement-activities.interface";
import {
  loadReinforcementError,
  loadReinforcementSuccess,
  postAnswerReinforcementError,
  postAnswerReinforcementSuccess,
} from "./actions";
import { ApplicationState, getGlobalState } from "..";
import {
  HistoryRecognition,
  HistoryRecognitionResponse,
} from "../../../@types/interfaces/api/word-recognition.interface";
import { postProgress } from "../word-recognition/sagas";
import { ActivityIdFilter } from "../../../@types/interfaces/api/talking-book.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";

const { toast } = createStandaloneToast({ theme });

export function* loadReinforcementId(action: AnyAction) {
  const filter: ActivityIdFilter = action.payload;

  try {
    const response: AxiosResponse<PaginationBase<IReinforcementActivities>> =
      yield call(api.get, `/reinforcement-activities`, {
        params: filter,
      });
    if (response.status === 200) {
      yield put(loadReinforcementSuccess(response.data));
    }
  } catch (e: any) {
    yield put(loadReinforcementError());
  }
}

export function* postHistoryReinforcement() {
  const data: ApplicationState = yield select(getGlobalState);

  const timeLesson = Date.now() - data.reinforcementActivities.dateMileseconds;

  const dataHitory: HistoryRecognition = {
    time: timeLesson,
    student: data.auth.student?.user,
  };

  try {
    const response: AxiosResponse = yield call(
      api.post,
      "/history",
      dataHitory
    );
    if (response.status === 201) {
      return response.data;
    }
  } catch (e: any) {
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* postAnswerReinforcement(action: AnyAction) {
  const data: ApplicationState = yield select(getGlobalState);
  const activity =
    data.reinforcementActivities.ReinforcementActivities?.results[0].activity;

  // const isLifeSkills =
  //   data.dashboard.activityData?.lesson.story.level.program.short_name;

  try {
    const responseHistory: HistoryRecognitionResponse = yield call(
      postHistoryReinforcement
    );

    const completeData = data.reinforcementActivities.answersRA.map(
      (answer) => {
        return { ...answer, history: responseHistory.id };
      }
    );

    const response: AxiosResponse = yield call(
      api.post,
      "/reinforcement-activities/answer",
      completeData
    );
    if (response.status === 201) {
      yield put(postAnswerReinforcementSuccess());
      yield call(postProgress, activity);
    }
  } catch (e: any) {
    yield put(postAnswerReinforcementError());
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* postAnswerReinforcementLifeSkills(action: AnyAction) {
  const data: ApplicationState = yield select(getGlobalState);
  const activity =
    data.reinforcementActivities.ReinforcementActivities?.results[0].activity;

  const responseHistory: HistoryRecognitionResponse = yield call(
    postHistoryReinforcement
  );

  try {
    const dataFill = data.reinforcementActivities.answersRA.map((answer) => {
      return { ...answer, history: responseHistory.id };
    });

    const response: AxiosResponse = yield call(
      api.post,
      "/reinforcement-activities/answer",
      dataFill
    );
    if (response.status === 201) {
      yield put(postAnswerReinforcementSuccess());
      // yield call(postProgress, activity);
    }
  } catch (e: any) {
    yield put(postAnswerReinforcementError());
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }

  try {
    const dataSpelling = data.reinforcementActivities.answersRaSpelling.map(
      (answer) => {
        return { ...answer, history: responseHistory.id };
      }
    );

    const response: AxiosResponse = yield call(
      api.post,
      "/reinforcement-activities/answer-spelling",
      dataSpelling
    );
    if (response.status === 201) {
      yield put(postAnswerReinforcementSuccess());
      // yield call(postProgress, activity);
    }
  } catch (e: any) {
    yield put(postAnswerReinforcementError());
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }

  try {
    const dataAlphabetical =
      data.reinforcementActivities.answersRaAlphabetical.map((answer) => {
        return { ...answer, history: responseHistory.id };
      });

    const response: AxiosResponse = yield call(
      api.post,
      "/reinforcement-activities/answer-alphabetical",
      dataAlphabetical
    );
    if (response.status === 201) {
      yield put(postAnswerReinforcementSuccess());
      yield call(postProgress, activity);
    }
  } catch (e: any) {
    yield put(postAnswerReinforcementError());
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}
