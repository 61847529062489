import { Flex, Heading, Text } from "@chakra-ui/react";
import { ArrowRight } from "lucide-react";
import React from "react";
import GreatJobIcon from "../../../assets/icons/GreatJobIcon";
import RegularButton from "../../global/RegularButton";
import Text32px from "../../global/text/Text32px";

interface DonNotStopProps {
  onNext: () => void;
}

const DonNotStop: React.FC<DonNotStopProps> = ({ onNext }) => {
  return (
    <Flex flexDir="column" align="center" mt="80px">
      <GreatJobIcon />
      <Text32px mt="32px">Don’t stop now, you are doing great! </Text32px>
      <Text
        color="gray.500"
        fontSize="18px"
        fontWeight="500"
        mt="16px"
        textAlign="center"
      >
        Please continue the diagostic
      </Text>

      <RegularButton rightIcon={<ArrowRight />} mt="56px" onClick={onNext}>
        Next
      </RegularButton>
    </Flex>
  );
};

export default DonNotStop;
