import { Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  OptionsPreTeaching,
  StoryFeed,
} from "../../@types/interfaces/api/pre-teaching.interface";
import ContainerLesson from "../../components/global/ContainerLesson";
import PageGrid from "../../components/global/PageGrid";
import ProgressBar from "../../components/global/ProgressBar";
import GreatJob from "../../components/lessons/common/GreatJob";
import StartLesson from "../../components/lessons/common/StartLesson";
import StoryLesson from "../../components/lessons/StoryLesson";

import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { usePages } from "../../hooks/usePages";
import {
  clearIndexPreTeaching,
  clearPreTeachingList,
  generateStoryFeed,
  generateStoryFeedPhrases,
  loadPreTeachingRequest,
  loadPreTeachingSuccess,
  onNextFeed,
  onNextPreTeaching,
  postPreTeachingRequest,
  setAnswerPreTeaching,
  setIndexPreTeaching,
  setOptionPreTeaching,
  setTimerPreTeaching,
} from "../../stores/ducks/pre-teaching/actions";
import {
  BASE_URL_MIDIA,
  audioCorrectAnswer,
  audioCorrectAnswerOgg,
  urlsPaths,
} from "../../utils/Utility";
import StoryQuestion from "../../components/lessons/StoryQuestion";
import { loadActivityRequest } from "../../stores/ducks/dashboard/actions";
import { postAnswerPreTeaching } from "../../stores/ducks/pre-teaching/sagas";
import { setActiveButtonHelp } from "../../stores/ducks/lesson/action";
import { assetsBaseUrl } from "../../utils/assets-helper";
import { useLanguageContext } from "../../providers/LanguageProvider";
import LaunchLesson from "../../components/lessons/common/LaunchLesson";
import { useAccesibiltyContext } from "../../providers/AccessibilityController";
import SubtitleComponent from "../../components/global/SubtitleComponent";

const PreTeachingLesson = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentLesson, inAudio } = useAppSelector((b) => b.lesson);
  const { student } = useAppSelector((b) => b.auth);
  const {
    preTeachingData,
    preTeachingFeedData,
    questionIndex,
    finished,
    optionPreTeaching,
    storyFeedWords,
    feedNumber,
    isLoading,
    answerPreTeaching,
  } = useAppSelector((b) => b.preTeaching);

  const [lastPage, setLastPage] = useState(false);
  const [firstClick, setFirstClick] = useState(true);
  const [pageContent, setPageContent] = useState({
    audio: "",
    text: "",
    image: "",
  });
  const [verifyQuestion, setVerifyQuestion] = useState({
    correct: 1,
    wrong: 1,
  });
  const [totalIndex, setTotalIndex] = useState(1);
  const wordsData = preTeachingData?.words;
  const phrasesData = preTeachingFeedData;
  const questionData = preTeachingData?.questions;
  const videoIntro = BASE_URL_MIDIA + preTeachingData?.video_intro;

  const { language } = useLanguageContext();
  const { setVideoWindow, playMedias, accessibility, captionText } =
    useAccesibiltyContext();

  const INITIAL_STATE: OptionsPreTeaching = {
    audio: (questionData ?? [])[questionIndex]?.audio,
    id: 0,
    pre_teaching_question: 0,
    text: "",
  };

  useEffect(() => {
    setVideoWindow(true);
    if (currentLesson) {
      dispatch(loadPreTeachingRequest({ activity__id: currentLesson?.id }));
    }
    dispatch(setTimerPreTeaching(Date.now()));
    return () => {
      setVideoWindow(false);
      dispatch(clearPreTeachingList());
      setTotalIndex(1);
    };
  }, []);

  useEffect(() => {
    if (feedNumber === 1) dispatch(generateStoryFeedPhrases());
    setFirstClick(true);
  }, [questionIndex, feedNumber]);

  useEffect(() => {
    dispatch(generateStoryFeed());
    dispatch(setOptionPreTeaching(INITIAL_STATE));
  }, [preTeachingData, questionIndex]);

  const successAudio = [
    audioCorrectAnswer(),
    audioCorrectAnswerOgg(),
    `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/THEPHRSE.mp3`,
    `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in12.ogg`,
    `${assetsBaseUrl()}${optionPreTeaching?.audio}`,
    `${assetsBaseUrl()}${optionPreTeaching?.audio?.replace(".mp3", ".ogg")}`,
    `${assetsBaseUrl()}${(questionData ?? [])[questionIndex]?.audio_yes}`,
    `${assetsBaseUrl()}${(questionData ?? [])[questionIndex]?.audio_yes.replace(
      ".mp3",
      ".ogg"
    )}`,
  ];
  const tryAgainAudio = [
    `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/PTSORRY.mp3`,
    `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in74.ogg`,
    `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/THEPHRSE.mp3`,
    `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in12.ogg`,
    `${assetsBaseUrl()}${optionPreTeaching?.audio}`,
    `${assetsBaseUrl()}${optionPreTeaching?.audio?.replace(".mp3", ".ogg")}`,
    `${assetsBaseUrl()}${(questionData ?? [])[questionIndex]?.audio_no}`,
    `${assetsBaseUrl()}${(questionData ?? [])[questionIndex]?.audio_no.replace(
      ".mp3",
      ".ogg"
    )}`,
  ];

  async function checkResponse() {
    if (
      questionData &&
      questionData[questionIndex].answer.toLowerCase() ===
        optionPreTeaching?.text.toLowerCase()
    ) {
      await setVerifyQuestion({
        ...verifyQuestion,
        correct: verifyQuestion.correct + 1,
      });
      // await onPlayAudio( ...successAudio);
      await playMedias([...successAudio]);
      await dispatch(onNextPreTeaching());
      setTotalIndex(totalIndex + 1);
    } else {
      await setVerifyQuestion({
        ...verifyQuestion,
        wrong: verifyQuestion.wrong + 1,
      });
      // await onPlayAudio( ...tryAgainAudio);
      await playMedias([...tryAgainAudio]);
    }
  }

  async function checkLastResponse() {
    if (
      questionData &&
      questionData[questionIndex].answer.toLowerCase() ===
        optionPreTeaching?.text.toLowerCase()
    ) {
      await setVerifyQuestion({
        ...verifyQuestion,
        correct: verifyQuestion.correct + 1,
      });
      // await onPlayAudio( ...successAudio);
      await playMedias([...successAudio]);

      setVerifyQuestion({
        wrong: 1,
        correct: 1,
      });
      dispatch(clearIndexPreTeaching("words"));
      dispatch(postPreTeachingRequest(setPage));
      setTotalIndex(totalIndex + 1);
    } else {
      await setVerifyQuestion({
        ...verifyQuestion,
        wrong: verifyQuestion.wrong + 1,
      });
      await playMedias([...tryAgainAudio]);
      // await onPlayAudio( ...tryAgainAudio);
    }
  }

  const onNextPreTeach = () => {
    if (
      wordsData &&
      finished === "words" &&
      questionIndex < wordsData.length - 1
    ) {
      if (lastPage) {
        getLastPage();
      } else {
        dispatch(onNextPreTeaching());
        setTotalIndex(totalIndex + 1);
      }
      setPageContent({
        audio: "",
        image: "",
        text: "",
      });
    }
    if (
      wordsData &&
      finished === "words" &&
      questionIndex >= wordsData.length - 1
    ) {
      if (lastPage) {
        getLastPage();
      } else {
        dispatch(clearIndexPreTeaching("phrases"));
        setTotalIndex(totalIndex + 1);
      }
      setPageContent({
        audio: "",
        image: "",
        text: "",
      });
    }
    if (
      phrasesData &&
      finished === "phrases" &&
      questionIndex < phrasesData.length - 1
    ) {
      dispatch(onNextPreTeaching());
      setTotalIndex(totalIndex + 1);
      setPageContent({
        audio: "",
        image: "",
        text: "",
      });
    }
    if (
      phrasesData &&
      finished === "phrases" &&
      questionIndex >= phrasesData.length - 1
    ) {
      dispatch(clearIndexPreTeaching("questions"));
      setTotalIndex(totalIndex + 1);
      setPageContent({
        audio: "",
        image: "",
        text: "",
      });
      setPage("storyQuestion");
    }
    setFirstClick(false);
    if (
      questionData &&
      finished === "questions" &&
      questionIndex < questionData.length - 1 &&
      questionData[questionIndex + 1] !== undefined // a próxima questão existe
    ) {
      checkResponse();
      if (firstClick) {
        dispatch(setAnswerPreTeaching());
      }
    } else if (
      questionData &&
      finished === "questions" &&
      questionIndex === questionData.length - 1
    ) {
      checkLastResponse();
      if (firstClick) {
        dispatch(setAnswerPreTeaching());
      }
    }
  };

  const showWordClicked = (word: string) => {
    const getIndex = wordsData?.findIndex((i) => i.text === word);
    if (getIndex) {
      setPageContent({
        audio: "",
        image: "",
        text: "",
      });
      dispatch(setIndexPreTeaching(getIndex, "words"));
      setLastPage(true);
    }
  };
  const getLastPage = () => {
    const getIndex = phrasesData?.length - 1;
    dispatch(setIndexPreTeaching(getIndex, "phrases"));
    setLastPage(false);
  };

  const verifyAudioHelp = (): string[] => {
    if (finished === "words") {
      return [
        `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/PTWCONT.mp3`,
        `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in10.ogg`,
      ];
    } else if (finished === "phrases") {
      return [
        `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/PTPHCONT.mp3`,
        `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in14.ogg`,
      ];
    } else {
      return [
        `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/QNA4.mp3`,
        `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in28.ogg`,
      ];
    }
  };

  const initialPage = student?.progress.finished ? "start" : "accessibility";
  // const initialPage = "storyQuestion"

  const { page, setPage, pageName } = usePages<
    "start" | "story" | "storyQuestion" | "greatJob" | "accessibility"
  >({
    initialPage,
    pages: {
      accessibility: <LaunchLesson onNext={() => setPage("start")} />,

      start: preTeachingData && (
        <StartLesson
          title="Pre Teaching Lesson"
          inAudio={inAudio}
          introduction={[
            `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/PTEACH_INTRO.mp3`,
            `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in8.ogg`,
          ]}
          onNext={() => setPage("story")}
          videoExplanationUrl={videoIntro}
          button="Start"
        />
      ),

      story: preTeachingFeedData && storyFeedWords && (
        <StoryLesson
          pageContent={pageContent}
          setPageContent={setPageContent}
          nextFeed={() => dispatch(onNextFeed())}
          totalIndex={totalIndex}
          clickWord={(word) => showWordClicked(word)}
        />
      ),
      storyQuestion: questionData && (
        <StoryQuestion
          questionIndex={questionIndex}
          question={questionData[questionIndex]}
          selectedOption={optionPreTeaching ?? undefined}
        />
      ),
      greatJob: (
        <GreatJob
          onNext={() => {
            if (currentLesson) {
              navigate(`/lesson/${urlsPaths[currentLesson?.mechanic_type]}`);
            }
          }}
        />
      ),
    },
  });

  const maxPages =
    phrasesData.length + (wordsData?.length ?? 0) + (questionData?.length ?? 0);

  const hiddenButton = (): boolean => {
    if (
      (finished === "words" || finished === "phrases") &&
      feedNumber < storyFeedWords.length - 1
    ) {
      return true;
    } else if (
      finished === "questions" &&
      questionData &&
      optionPreTeaching?.text === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <PageGrid
      activity="Pre Teaching Lesson"
      lesson="Joseph's Readers"
      onHelp={() => {}}
      disabledButton={
        pageName === "greatJob" ||
        pageName === "start" ||
        pageName === "accessibility" ||
        inAudio
      }
      audioHelp={verifyAudioHelp()}
    >
      <ContainerLesson>
        {page}
        <Flex flexDir="column">
          <SubtitleComponent
            hide={
              !accessibility.subtitles ||
              captionText === "" ||
              pageName === "greatJob" ||
              !inAudio
            }
          />
          <ProgressBar
            data={{ max: maxPages, step: totalIndex }}
            hide={
              pageName === "start" ||
              pageName === "greatJob" ||
              pageName === "accessibility"
            }
            hideButtonPrevious
            hideButton={hiddenButton() || inAudio}
            isLoading={isLoading}
            onConfirm={() => {
              dispatch(setActiveButtonHelp(false));
              onNextPreTeach();
            }}
          />
        </Flex>
      </ContainerLesson>
    </PageGrid>
  );
};

export default PreTeachingLesson;
