import { ObjectKeys } from "react-hook-form/dist/types/path/common";
import store from "..";
import { ActivityIdFilter } from "../../../@types/interfaces/api/talking-book.interface";
import {
  IPreTeaching,
  IPreTeachingAnswerPost,
  OptionsPreTeaching,
  StoryFeed,
} from "../../../@types/interfaces/api/pre-teaching.interface";
import { PreTeachingActionTypes } from "./type";
import { assetsBaseUrl } from "../../../utils/assets-helper";

export const loadPreTeachingRequest = (filter: ActivityIdFilter) => ({
  type: PreTeachingActionTypes.LOAD_PRE_TEACHING_REQUEST,
  payload: filter,
});

export const loadPreTeachingSuccess = (data: IPreTeaching) => {
  return {
    type: PreTeachingActionTypes.LOAD_PRE_TEACHING_SUCCESS,
    payload: data,
  };
};

export const loadPreTeachingError = () => ({
  type: PreTeachingActionTypes.LOAD_PRE_TEACHING_ERROR,
});

export const clearPreTeachingList = () => {
  return {
    type: PreTeachingActionTypes.CLEAR_LIST_PRE_TEACHING,
  };
};
export const setTimerPreTeaching = (date: number) => {
  return {
    type: PreTeachingActionTypes.SET_TIMER_PRE_TEACHING,
    payload: date,
  };
};

export const setAnswerPreTeaching = () => {
  const contentData = store.getState().preTeaching;

  const fullAnswer: IPreTeachingAnswerPost[] = [
    {
      answer: contentData.optionPreTeaching?.text ?? "",
      question: contentData.optionPreTeaching?.id ?? 0,
      history: 0,
    },
  ];

  return {
    type: PreTeachingActionTypes.SET_ANSWER_PRE_TEACHING,
    payload: { fullAnswer },
  };
};
export const setOptionPreTeaching = (data: OptionsPreTeaching) => {
  return {
    type: PreTeachingActionTypes.SET_OPTION_PRE_TEACHING,
    payload: data,
  };
};
export const generateStoryFeedPhrases = () => {
  const data = store.getState().preTeaching.preTeachingData;
  if (data) {
    const currentLevel =
      store.getState().dashboard.activityData?.lesson.story.level.level_order;
    const baseUrlhtml5 = `${assetsBaseUrl()}/app-2.0/jr_html5`;
    // const baseUrl = `${assetsBaseUrl()}`

    const generatePhrases = (
      index: number,
      type: ObjectKeys<
        Omit<
          IPreTeaching,
          "id" | "audio_intro" | "video_intro" | "activity" | "questions"
        >
      >
    ): StoryFeed[] => {
      let currentPhrase = "";

      const feedWords = [];
      for (const word of data[type][index].text.split(" ")) {
        currentPhrase += word + " ";
        const textObj: StoryFeed = {
          type: "text",
          content: currentPhrase,
        };
        feedWords.push(textObj);
        const audioUrl = `${baseUrlhtml5}/audio/common_mp3/${word
          .toLowerCase()
          .replace(/[.,]/g, "")}.mp3`;
        const audioUrlOgg = `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/common_ogg/${word
          .toLowerCase()
          .replace(/[.,]/g, "")}.ogg`;
        const audioObj: StoryFeed = {
          type: "audio",
          content: "",
          contentArray: [audioUrl, audioUrlOgg],
        };
        feedWords.push(audioObj);
      }
      return feedWords;
    };

    let feed: StoryFeed[][] = [];
    if (currentLevel && currentLevel <= 2) {
      feed = [
        [
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}${data.audio_intro}`,
              `${assetsBaseUrl()}${data.audio_intro.replace(".mp3", ".ogg")}`,
            ],
          },
          {
            type: "text",
            content: data.phrases[0].text,
          },
          {
            type: "audio",
            content: ``,
            contentArray: [
              `${assetsBaseUrl()}${data.phrases[0].audio_phrase}`,
              `${assetsBaseUrl()}${data.phrases[0].audio_phrase.replace(
                ".mp3",
                ".ogg"
              )}`,
            ],
          },
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/THEPHRSE.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in12.ogg`,
            ],
          },
          {
            type: "audio",
            content: ``,
            contentArray: [
              `${assetsBaseUrl()}${data.phrases[0].audio_phrase}`,
              `${assetsBaseUrl()}${data.phrases[0].audio_phrase.replace(
                ".mp3",
                ".ogg"
              )}`,
            ],
          },
          {
            type: "audio",
            content: ``,
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/MADEOF.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in13.ogg`,
            ],
          },
          ...generatePhrases(0, "phrases"),
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}${data.phrases[0].audio_definition}`,
              `${assetsBaseUrl()}${data.phrases[0].audio_definition.replace(
                ".mp3",
                ".ogg"
              )}`,
            ],
          },
          {
            type: "audio",
            content: ``,
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/PTPHCONT.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in14.ogg`,
            ],
          },
        ],
        [
          {
            type: "text",
            content: data.phrases[0].text,
          },
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/COMBPHRS.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in15.ogg`,
            ],
          },
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}${data.phrases[0].audio_phrase}`,
              `${assetsBaseUrl()}${data.phrases[0].audio_phrase.replace(
                ".mp3",
                ".ogg"
              )}`,
            ],
          },
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/WITHPHRS.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in18.ogg`,
            ],
          },
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/NEWPHRSE.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in16.ogg`,
            ],
          },
          {
            type: "text",
            content: data.phrases[1].text,
          },
          {
            type: "audio",
            content: ``,
            contentArray: [
              `${assetsBaseUrl()}${data.phrases[1].audio_phrase}`,
              `${assetsBaseUrl()}${data.phrases[1].audio_phrase.replace(
                ".mp3",
                ".ogg"
              )}`,
            ],
          },
          {
            type: "audio",
            content: ``,
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/MADEOF.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in13.ogg`,
            ],
          },
          ...generatePhrases(1, "phrases"),
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/THEPHRSE.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in12.ogg`,
            ],
          },
          {
            type: "text",
            content: data.phrases[1].text,
          },
          {
            type: "audio",
            content: ``,
            contentArray: [
              `${assetsBaseUrl()}${data.phrases[1].audio_phrase}`,
              `${assetsBaseUrl()}${data.phrases[1].audio_phrase.replace(
                ".mp3",
                ".ogg"
              )}`,
            ],
          },
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}${data.phrases[1].audio_definition}`,
              `${assetsBaseUrl()}${data.phrases[1].audio_definition.replace(
                ".mp3",
                ".ogg"
              )}`,
            ],
          },
          {
            type: "audio",
            content: ``,
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/PTPHCONT.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in14.ogg`,
            ],
          },
        ],
        [
          {
            type: "text",
            content: data.phrases[0].text,
          },
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/WHENPUT.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in17.ogg`,
            ],
          },
          {
            type: "audio",
            content: ``,
            contentArray: [
              `${assetsBaseUrl()}${data.phrases[0].audio_phrase}`,
              `${assetsBaseUrl()}${data.phrases[0].audio_phrase.replace(
                ".mp3",
                ".ogg"
              )}`,
            ],
          },
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/WITHTHE.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in18.ogg`,
            ],
          },
          {
            type: "text",
            content: data.phrases[1].text,
          },
          {
            type: "audio",
            content: ``,
            contentArray: [
              `${assetsBaseUrl()}${data.phrases[1].audio_phrase}`,
              `${assetsBaseUrl()}${data.phrases[1].audio_phrase.replace(
                ".mp3",
                ".ogg"
              )}`,
            ],
          },
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/GETSENT.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in19.ogg`,
            ],
          },
          {
            type: "text",
            content: data.sentences[0].text,
          },
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}${data.sentences[0].audio_sentence}`,
              `${assetsBaseUrl()}${data.sentences[0].audio_sentence.replace(
                ".mp3",
                ".ogg"
              )}`,
            ],
          },
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/THESENT.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in20.ogg`,
            ],
          },
          {
            type: "audio",
            content: ``,
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/MADEOF.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in13.ogg`,
            ],
          },
          ...generatePhrases(0, "sentences"),
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/THESENT.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in20.ogg`,
            ],
          },
          {
            type: "text",
            content: data.sentences[0].text,
          },
          {
            type: "audio",
            content: ``,
            contentArray: [
              `${assetsBaseUrl()}${data.sentences[0].audio_sentence}`,
              `${assetsBaseUrl()}${data.sentences[0].audio_sentence.replace(
                ".mp3",
                ".ogg"
              )}`,
            ],
          },
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}${data.phrases[0].audio_definition}`,
              `${assetsBaseUrl()}${data.sentences[0].audio_definition.replace(
                ".mp3",
                ".ogg"
              )}`,
            ],
          },
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/PTSCONT.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in14.ogg`,
            ],
          },
        ],
        [
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/PUTSENT.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in21.ogg`,
            ],
          },
          {
            type: "text",
            content: data.sentences[0].text,
          },
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}${data.sentences[0].audio_sentence}`,
              `${assetsBaseUrl()}${data.sentences[0].audio_sentence.replace(
                ".mp3",
                ".ogg"
              )}`,
            ],
          },
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/WITHPHRS.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in18.ogg`,
            ],
          },
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/NEWPHRSE.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in16.ogg`,
            ],
          },
          {
            type: "text",
            content: data.phrases[2].text,
          },
          {
            type: "audio",
            content: ``,
            contentArray: [
              `${assetsBaseUrl()}${data.phrases[2].audio_phrase}`,
              `${assetsBaseUrl()}${data.phrases[2].audio_phrase.replace(
                ".mp3",
                ".ogg"
              )}`,
            ],
          },
          {
            type: "audio",
            content: ``,
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/MADEOF.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in13.ogg`,
            ],
          },
          ...generatePhrases(2, "phrases"),
          {
            type: "text",
            content: data.phrases[2].text,
          },
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/THEPHRSE.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in12.ogg`,
            ],
          },
          {
            type: "audio",
            content: ``,
            contentArray: [
              `${assetsBaseUrl()}${data.phrases[2].audio_phrase}`,
              `${assetsBaseUrl()}${data.phrases[2].audio_phrase.replace(
                ".mp3",
                ".ogg"
              )}`,
            ],
          },
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}${data.phrases[2].audio_definition}`,
              `${assetsBaseUrl()}${data.phrases[2].audio_definition.replace(
                ".mp3",
                ".ogg"
              )}`,
            ],
          },
          {
            type: "audio",
            content: ``,
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/PTPHCONT.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in14.ogg`,
            ],
          },
        ],
        [
          {
            type: "text",
            content: data.sentences[0].text,
          },
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/WHENPUT.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in17.ogg`,
            ],
          },
          {
            type: "audio",
            content: ``,
            contentArray: [
              `${assetsBaseUrl()}${data.sentences[0].audio_sentence}`,
              `${assetsBaseUrl()}${data.sentences[0].audio_sentence.replace(
                ".mp3",
                ".ogg"
              )}`,
            ],
          },
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/WITHTHE.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in18.ogg`,
            ],
          },
          {
            type: "text",
            content: data.phrases[2].text,
          },
          {
            type: "audio",
            content: ``,
            contentArray: [
              `${assetsBaseUrl()}${data.phrases[2].audio_phrase}`,
              `${assetsBaseUrl()}${data.phrases[2].audio_phrase.replace(
                ".mp3",
                ".ogg"
              )}`,
            ],
          },
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/GETSENT.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in19.ogg`,
            ],
          },
          {
            type: "text",
            content: data.sentences[1].text,
          },
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}${data.sentences[1].audio_sentence}`,
              `${assetsBaseUrl()}${data.sentences[1].audio_sentence.replace(
                ".mp3",
                ".ogg"
              )}`,
            ],
          },
          {
            type: "audio",
            content: ``,
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/MADEOF.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in13.ogg`,
            ],
          },
          ...generatePhrases(1, "sentences"),
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/THESENT.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in20.ogg`,
            ],
          },
          {
            type: "audio",
            content: ``,
            contentArray: [
              `${assetsBaseUrl()}${data.sentences[1].audio_sentence}`,
              `${assetsBaseUrl()}${data.sentences[1].audio_sentence.replace(
                ".mp3",
                ".ogg"
              )}`,
            ],
          },
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}${data.phrases[1].audio_definition}`,
              `${assetsBaseUrl()}${data.phrases[1].audio_definition.replace(
                ".mp3",
                ".ogg"
              )}`,
            ],
          },
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/PTSCONT.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in14.ogg`,
            ],
          },
        ],
        [
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/PLSLSTN.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in24.ogg`,
            ],
          },
          {
            type: "text",
            content: data.sentences[1].text,
          },
          {
            type: "audio",
            content: ``,
            contentArray: [
              `${assetsBaseUrl()}${data.sentences[1].audio_sentence}`,
              `${assetsBaseUrl()}${data.sentences[1].audio_sentence.replace(
                ".mp3",
                ".ogg"
              )}`,
            ],
          },
          {
            type: "audio",
            content: ``,
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/PLAYPAGE.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in25.ogg`,
            ],
          },
        ],
      ];
    } else {
      for (let i = 0; i < data.sentences.length; i++) {
        const sentence = data.sentences[i];
        feed.push([
          {
            type: "audio",
            content: ``,
            contentArray: [
              `${assetsBaseUrl()}${sentence.audio_definition}`,
              `${assetsBaseUrl()}${sentence.audio_definition.replace(
                ".mp3",
                ".ogg"
              )}`,
            ],
          },
          {
            type: "text",
            content: sentence.text,
          },
          {
            type: "audio",
            content: ``,
            contentArray: [
              `${assetsBaseUrl()}${sentence.audio_sentence}`,
              `${assetsBaseUrl()}${sentence.audio_sentence.replace(
                ".mp3",
                ".ogg"
              )}`,
            ],
          },
          {
            type: "audio",
            content: ``,
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/THESENT.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in20.ogg`,
            ],
          },
          {
            type: "audio",
            content: ``,
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/MADEOF.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in13.ogg`,
            ],
          },
          ...generatePhrases(i, "sentences"),
          {
            type: "audio",
            content: "",
            contentArray: [
              `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/PTSCONT.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in14.ogg`,
            ],
          },
        ]);
      }
      const allSentences = data.sentences.map(
        (s) => `${assetsBaseUrl()}${s.audio_sentence}`
      );
      const allSentencesOgg = data.sentences.map(
        (s) => `${assetsBaseUrl()}${s.audio_sentence.replace(".mp3", ".ogg")}`
      );
      feed.push([
        {
          type: "audio",
          content: "",
          contentArray: [
            `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/PLSLSTN.mp3`,
            `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in24.ogg`,
          ],
        },
        {
          type: "text",
          content: data.sentences.map((s) => s.text).join(" "),
        },
        {
          type: "audio",
          content: "",
          contentArray: [...allSentences, ...allSentencesOgg],
        },
        {
          type: "audio",
          content: "",
          contentArray: [
            `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/STRYAGIN.mp3`,
            `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in25.ogg`,
          ],
        },
      ]);
      //];
    }
    return {
      type: PreTeachingActionTypes.GENERATE_STORY_FEED_PHRASES,
      payload: feed,
    };
  } else {
    return {
      type: PreTeachingActionTypes.GENERATE_STORY_FEED_PHRASES,
      payload: [],
    };
  }
};
export const generateStoryFeed = () => {
  const contentListAnswer = store.getState().preTeaching;

  const feedPhrases = contentListAnswer.preTeachingFeedData;

  const finished = contentListAnswer.finished;
  const questionIndex = contentListAnswer.questionIndex;
  const wordData =
    contentListAnswer.preTeachingData?.words[questionIndex]?.text;
  const audioDefinition =
    contentListAnswer.preTeachingData?.words[questionIndex]?.audio_definition;
  const imageText =
    contentListAnswer.preTeachingData?.words[questionIndex]?.image_text;
  const audioText =
    contentListAnswer.preTeachingData?.words[questionIndex]?.audio_text;

  const generateFeedWord = (): StoryFeed[] => {
    const baseUrl = `${assetsBaseUrl()}/app-2.0/jr_html5`;
    const feed: StoryFeed[] = [];

    const wordSplited = wordData
      ?.split(" ")
      .map((word) => `${baseUrl}/audio/common_mp3/${word}.mp3`);
    const wordSplitedOg = wordData
      ?.split(" ")
      .map(
        (word) =>
          `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/common_ogg/${word}.ogg`
      );

    if (wordData) {
      // Add the initial audio clip for the first word
      feed.push({
        type: "audio",
        content: "",
        contentArray: [
          `${baseUrl}/audio/ENG/dirs_mp3/NEXTWORD.mp3`,
          `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in11.ogg`,
        ],
      });
      feed.push({
        type: "text",
        content: wordData,
      });
      if (wordData.includes(" ")) {
        feed.push({
          type: "audio",
          content: "",
          contentArray: [
            `${assetsBaseUrl()}${audioText}`,
            `${assetsBaseUrl()}${audioText?.replace(".mp3", ".ogg")}`,
          ],
        });
      } else {
        if (wordSplited && wordSplitedOg)
          feed.push({
            type: "audio",
            content: ``,
            contentArray: [...wordSplited, ...wordSplitedOg],
          });
      }

      let currentWord = "";

      if (wordData.includes(" ")) {
        const words = wordData.split(" ");
        for (let i = 0; i < words.length; i++) {
          currentWord += words[i] + " ";
          feed.push({
            type: "text",
            content: currentWord,
          });
          feed.push({
            type: "audio",
            content: ``,
            contentArray: [
              `${baseUrl}/audio/common_mp3/${words[i]}.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/common_ogg/${
                words[i]
              }.ogg`,
            ],
          });
        }
      } else {
        for (let i = 0; i < wordData.length; i++) {
          currentWord += wordData[i];
          feed.push({
            type: "text",
            content: currentWord,
          });
          const audioUrl = `${baseUrl}/audio/alpha_mp3/${wordData[i]
            .toLowerCase()
            .replace(/[.,]/g, "")}.mp3`;
          const audioUrlOgg = `${baseUrl}/audio/alpha_ogg/${wordData[i]
            .toLowerCase()
            .replace(/[.,]/g, "")}.ogg`;
          feed.push({
            type: "audio",
            content: "",
            contentArray: [audioUrl, audioUrlOgg],
          });
        }
      }

      if (wordData.includes(" ")) {
        feed.push({
          type: "audio",
          content: "",
          contentArray: [
            `${assetsBaseUrl()}${audioText}`,
            `${assetsBaseUrl()}${audioText?.replace(".mp3", ".ogg")}`,
          ],
        });
      } else {
        feed.push({
          type: "audio",
          content: ``,
          contentArray: [
            `${baseUrl}/audio/common_mp3/${wordData}.mp3`,
            `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/common_ogg/${wordData}.ogg`,
          ],
        });
      }

      feed.push({
        type: "image",
        content: `${assetsBaseUrl()}${imageText}`,
      });
      feed.push({
        type: "audio",
        content: "",
        contentArray: [
          `${assetsBaseUrl()}${audioDefinition}`,
          `${assetsBaseUrl()}${audioDefinition?.replace(".mp3", ".ogg")}`,
        ],
      });
      feed.push({
        type: "audio",
        content: "",
        contentArray: [
          `${baseUrl}/audio/ENG/dirs_mp3/PTWCONT.mp3`,
          `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in10.ogg`,
        ],
      });
    }

    return feed;
  };

  if (finished === "words") {
    return {
      type: PreTeachingActionTypes.GENERATE_STORY_FEED,
      payload: generateFeedWord(),
    };
  } else {
    return {
      type: PreTeachingActionTypes.GENERATE_STORY_FEED,
      payload: feedPhrases[questionIndex],
    };
  }
};

export const onNextFeed = () => {
  return {
    type: PreTeachingActionTypes.ON_NEXT_FEED,
  };
};

export const onNextPreTeaching = () => {
  return {
    type: PreTeachingActionTypes.ON_NEXT_PRE_TEACHING,
  };
};
export const setIndexPreTeaching = (
  index: number,
  finished: "words" | "phrases" | "questions"
) => {
  return {
    type: PreTeachingActionTypes.SET_INDEX,
    payload: { index, finished },
  };
};
export const clearIndexPreTeaching = (
  text: "words" | "phrases" | "questions"
) => {
  return {
    type: PreTeachingActionTypes.CLEAR_INDEX,
    payload: text,
  };
};
export const postPreTeachingRequest = (setPage: (page: any) => void) => {
  setPage("greatJob");
  return {
    type: PreTeachingActionTypes.POST_PRE_TEACHING_REQUEST,
    payload: { setPage },
  };
};
export const postPreTeachingSuccess = () => {
  return {
    type: PreTeachingActionTypes.POST_PRE_TEACHING_SUCCESS,
  };
};

export const postPreTeachingError = () => {
  return {
    type: PreTeachingActionTypes.POST_PRE_TEACHING_ERROR,
  };
};

export const setWrongAnswer = () => {
  return {
    type: PreTeachingActionTypes.SET_WRONG_ANSWER_PRE_TEACHING,
  };
};
