import store from "..";
import {
  DragAlternative,
  IDragAnswerPost,
  IDragFill,
} from "../../../@types/interfaces/api/drag-fill.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import { ActivityIdFilter } from "../../../@types/interfaces/api/talking-book.interface";
import { DragFillActionTypes } from "./type";

export const loadDragFillRequest = (filter: ActivityIdFilter) => ({
  type: DragFillActionTypes.LOAD_DRAG_FILL_REQUEST,
  payload: filter,
});

export const loadDragFillSuccess = (data: PaginationBase<IDragFill>) => ({
  type: DragFillActionTypes.LOAD_DRAG_FILL_SUCCESS,
  payload: data,
});

export const loadDragFillError = () => ({
  type: DragFillActionTypes.LOAD_DRAG_FILL_ERROR,
});

export const setAlternativeDrag = (alternative: DragAlternative) => {
  return {
    type: DragFillActionTypes.SET_ALTERNATIVE_DRAG,
    payload: alternative,
  };
};
export const setWordDrag = (alternative: string) => {
  return {
    type: DragFillActionTypes.SET_WORD_DRAG,
    payload: alternative,
  };
};

export const clearDragFillList = () => {
  return {
    type: DragFillActionTypes.CLEAR_LIST_DRAG,
  };
};

export const onPreviousDragFill = () => {
  const contentListAnswer = store.getState().dragFill;
  const answerArray = contentListAnswer.answerDrag;

  answerArray.pop();

  return {
    type: DragFillActionTypes.ON_PREIVIOUS_DRAG,
    payload: answerArray,
  };
};

export const onNextDragFill = (setPage: (page: any) => void) => {
  const contentListAnswer = store.getState().dragFill;

  const chosenAnswer = contentListAnswer.wordSelected;
  const questionId =
    contentListAnswer.DragFillData?.results[0].questions[
      contentListAnswer.questionIndex
    ].id;

  const text =
    contentListAnswer.DragFillData?.results[0].questions[
      contentListAnswer.questionIndex
    ].text;

  const fullAlternative = text?.replace(/_+/, chosenAnswer) ?? "";

  const contentAnswer = {
    word: fullAlternative,
    question: questionId,
    history: 0,
  };

  if (
    contentListAnswer.DragFillData &&
    contentListAnswer.questionIndex <
      contentListAnswer.DragFillData.results[0].questions.length - 1
  ) {
    return {
      type: DragFillActionTypes.ON_NEXT_DRAG,
      payload: { contentAnswer, setPage },
    };
  } else {
    setPage("greatJob");
    return {
      type: DragFillActionTypes.POST_ANSWER_DRAG_REQUEST,
      payload: { contentAnswer, setPage },
    };
  }
};
export const setAnswerDragFill = () => {
  const contentListAnswer = store.getState().dragFill;

  const chosenAnswer = contentListAnswer.wordSelected;
  const questionId =
    contentListAnswer.DragFillData?.results[0].questions[
      contentListAnswer.questionIndex
    ].id;

  const text =
    contentListAnswer.DragFillData?.results[0].questions[
      contentListAnswer.questionIndex
    ].text;

  const fullAlternative = text?.replace(/_+/, chosenAnswer) ?? "";
  const contentAnswer = {
    word: fullAlternative,
    question: questionId,
    history: 0,
  };

  return {
    type: DragFillActionTypes.SET_ANSWER_DRAG,
    payload: contentAnswer,
  };
};

export const setTimerDrag = (date: number) => ({
  type: DragFillActionTypes.SET_TIMER_DRAG,
  payload: date,
});

export const postAnswerDragFillRequest = (data: IDragAnswerPost[]) => ({
  type: DragFillActionTypes.POST_ANSWER_DRAG_REQUEST,
  payload: data,
});

export const postAnswerDragFillSuccess = () => ({
  type: DragFillActionTypes.POST_ANSWER_DRAG_SUCCESS,
});

export const postAnswerDragFillError = () => ({
  type: DragFillActionTypes.POST_ANSWER_DRAG_ERROR,
});

export const setSkipDrag = () => {
  const data = store.getState().dragFill;
  const wrongAnswer = data.wrongAnswer;

  if (wrongAnswer >= 2) {
    return {
      type: DragFillActionTypes.SET_SKIP_TRUE,
    };
  } else {
    return {
      type: DragFillActionTypes.SET_SKIP_FALSE,
    };
  }
};

export const skipQuestion = (setPage: (page: any) => void) => {
  const contentListAnswer = store.getState().dragFill;

  const chosenAnswer = contentListAnswer.wordSelected;
  const questionId = contentListAnswer.DragFillData?.results[0].id;

  const contentAnswer = {
    word: chosenAnswer === "" ? "null" : chosenAnswer,
    question: questionId,
    history: 0,
  };

  if (
    contentListAnswer.DragFillData &&
    contentListAnswer.questionIndex <
      contentListAnswer.DragFillData.results[0].questions.length - 1
  ) {
    return {
      type: DragFillActionTypes.SKIP_QUESTION,
    };
  } else {
    return {
      type: DragFillActionTypes.POST_ANSWER_DRAG_REQUEST,
      payload: { contentAnswer, setPage },
    };
  }
};
