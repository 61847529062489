import store from "..";
import AnyAction from "redux";
import DiagnosticFilterInterface, {
  AnswerDiagnostic,
  QuestionDiagnostic,
} from "../../../@types/interfaces/api/diagnostic.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";

import { PostTestActionTypes } from "./type";
import PostTestFilterInterface, {
  AnswerPostTest,
  IPostTest,
} from "../../../@types/interfaces/api/post-test.interface";

export const loadPostTestRequest = (filter: PostTestFilterInterface) => ({
  type: PostTestActionTypes.LOAD_QUESTIONS_POST_TEST_REQUEST,
  payload: filter,
});

export const loadPostTestSuccess = (questions: PaginationBase<IPostTest>) => ({
  type: PostTestActionTypes.LOAD_QUESTIONS_POST_TEST_SUCCESS,
  payload: questions,
});
export const loadPostTestError = () => ({
  type: PostTestActionTypes.LOAD_QUESTIONS_POST_TEST_ERROR,
});

export const clearPostTestList = () => {
  return {
    type: PostTestActionTypes.CLEAR_LIST,
  };
};

export const setAlternativePostTest = (alternative: string) => {
  return {
    type: PostTestActionTypes.SET_ALTERNATIVE_POST_TEST,
    payload: alternative,
  };
};
export const setfULLAlternativePostTest = (alternative: string) => {
  return {
    type: PostTestActionTypes.SET_FULL_ALTERNATIVE_POST_TEST,
    payload: alternative,
  };
};

export const setCorrectAnswerPostTest = (alternative: string) => {
  return {
    type: PostTestActionTypes.SET_CORRECT_ANSWER_POST_TEST,
    payload: alternative,
  };
};
export const postAnswerPostTestRequest = (data: AnswerPostTest[]) => ({
  type: PostTestActionTypes.POST_ANSWER_POST_TEST_REQUEST,
  payload: data,
});

export const postAnswerPostTestSuccess = () => ({
  type: PostTestActionTypes.POST_ANSWER_POST_TEST_SUCCESS,
});
export const postAnswerPostTestError = () => ({
  type: PostTestActionTypes.POST_ANSWER_POST_TEST_ERROR,
});

export const setPageNumberPostTest = (page: number) => {
  localStorage.setItem("pageNumberPT", page.toString());
  return {
    type: PostTestActionTypes.SET_PAGE_NUMBER_POST_TEST,
    payload: page,
  };
};

export const onNexPostTest = (setPage: (page: any) => void) => {
  const contentListAnswer = store.getState().postTest;

  const questionId =
    contentListAnswer.postTestData?.results[0].questions[
      contentListAnswer.questionIndex
    ].id;

  const contentAnswer = {
    answer: contentListAnswer.fullAlternative,
    question: questionId,
    history: 0,
  };

  const isNotEndOfQuestion: boolean =
    (contentListAnswer.postTestData?.results[0].questions.length ?? 1) - 1 !==
    contentListAnswer.questionIndex;

  if (isNotEndOfQuestion) {
    return {
      type: PostTestActionTypes.ON_NEXT,
      payload: { contentAnswer, setPage },
    };
  } else {
    setPage("greatJob");
    return {
      type: PostTestActionTypes.POST_ANSWER_POST_TEST_REQUEST,
      payload: { contentAnswer, setPage },
    };
  }
};

export const onPreviousPostTest = () => {
  const contentListAnswer = store.getState().postTest;
  const answerArray = contentListAnswer.answers;

  answerArray.pop();

  return {
    type: PostTestActionTypes.ON_PREVIOUS,
    payload: answerArray,
  };
};

export const setTimerPostTest = (date: number) => {
  return {
    type: PostTestActionTypes.SET_TIMER_POST_TEST,
    payload: date,
  };
};
