import { createStandaloneToast } from "@chakra-ui/react";

import theme from "../../../styles/theme";
import axios, { AxiosResponse } from "axios";
import { call, put, select } from "redux-saga/effects";
import api from "../../../services/api";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import { AnyAction } from "redux";
import store, { ApplicationState, getGlobalState } from "..";
import {
  HistoryRecognition,
  HistoryRecognitionResponse,
} from "../../../@types/interfaces/api/word-recognition.interface";
import { postProgress } from "../word-recognition/sagas";
import VocabularyBuilderFilterInterface, {
  IVocabularyBuilder,
} from "../../../@types/interfaces/api/vocabulary-builder.interface";
import {
  loadVocabularyBuilderError,
  loadVocabularyBuilderSuccess,
  postAnswerVocabularyBuilderError,
  postAnswerVocabularyBuilderSuccess,
} from "./actions";

const { toast } = createStandaloneToast({ theme });

export function* loadQuestionVocabularyBuilder(action: AnyAction) {
  const filters: VocabularyBuilderFilterInterface = action.payload;
  try {
    const response: AxiosResponse<PaginationBase<IVocabularyBuilder[]>> =
      yield call(api.get, `/vocabulary-builder`, {
        params: filters,
      });
    if (response.status == 200) {
      yield put(loadVocabularyBuilderSuccess(response.data.results[0]));
    }
  } catch (e: any) {
    yield put(loadVocabularyBuilderError());
  }
}

export function* postHistoryVocabularyBuilder() {
  const data: ApplicationState = yield select(getGlobalState);

  const timeLesson = Date.now() - data.vocabularyBuilder.dateMileseconds;

  const dataHitory: HistoryRecognition = {
    time: timeLesson,
    student: data.auth.student?.user,
  };

  try {
    const response: AxiosResponse = yield call(
      api.post,
      "/history",
      dataHitory
    );
    if (response.status == 201) {
      return response.data;
    }
  } catch (e: any) {
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* postAnswerVocabularyBuilder() {
  const data: ApplicationState = yield select(getGlobalState);
  const activity = data.vocabularyBuilder.vocabularyData?.activity;

  if (data.vocabularyBuilder.answers.length === 0) {
    yield call(postProgress, activity);
  } else {
    try {
      const responseHistory: HistoryRecognitionResponse = yield call(
        postHistoryVocabularyBuilder
      );

      const completeData = data.vocabularyBuilder.answers.map((answer) => {
        return { ...answer, history: responseHistory.id };
      });

      const response: AxiosResponse = yield call(
        api.post,
        "/vocabulary-builder/answer",
        completeData
      );
      if (response.status === 201) {
        yield put(postAnswerVocabularyBuilderSuccess());
        yield call(postProgress, activity);
      }
    } catch (e: any) {
      yield put(postAnswerVocabularyBuilderError());
      const errors = e.response.data;
      toast({
        title: Object.keys(errors),
        description: errors[Object.keys(errors)[0]],
        status: "error",
      });
    }
  }
}
