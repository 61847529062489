import { Button, ButtonProps } from "@chakra-ui/react";
import React, { LegacyRef } from "react";

const OutlineButton = ({ children, ...rest }: ButtonProps) => {
  return (
    <Button
      color="gray.500"
      borderRadius="full"
      fontSize="16px"
      lineHeight="28px"
      fontWeight="600"
      border="1.5px solid #E2E8F0"
      p="8px 24px"
      h="48px"
      bg="bg"
      _hover={{}}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default OutlineButton;
