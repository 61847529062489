import { Button, Flex, Image, Text } from "@chakra-ui/react";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import {
  StoryFeed,
  WordsPreTeaching,
} from "../../@types/interfaces/api/pre-teaching.interface";
import PlayIcon from "../../assets/icons/PlayIcon";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import RegularButton from "../global/RegularButton";
import Subtitle from "../global/text/Subtitle";
import Text32px from "../global/text/Text32px";
import AudioWave from "../global/AudioWave";
import { setActiveButtonHelp } from "../../stores/ducks/lesson/action";
import { motion } from "framer-motion";
import { useLanguageContext } from "../../providers/LanguageProvider";
import { useAccesibiltyContext } from "../../providers/AccessibilityController";
import SubtitleComponent from "../global/SubtitleComponent";
import { assetsBaseUrl } from "../../utils/assets-helper";

interface Props {
  totalIndex: number;
  pageContent: {
    audio: string;
    text: string;
    image: string;
  };
  setPageContent: React.Dispatch<
    React.SetStateAction<{
      audio: string;
      text: string;
      image: string;
    }>
  >;
  nextFeed: () => void;
  clickWord: (word: string) => void;
}
const StoryLesson = (props: Props) => {
  const dispatch = useAppDispatch();
  const { nextFeed, pageContent, setPageContent, totalIndex, clickWord } =
    props;
  const [activeButton, setActiveButton] = useState(false);
  const [isPlaying, setisPlaying] = useState(false);
  const {
    feedNumber,
    storyFeedWords,
    questionIndex,
    finished,
    preTeachingFeedData,
    preTeachingData,
  } = useAppSelector((b) => b.preTeaching);
  const { activityData } = useAppSelector((b) => b.dashboard);
  const { inAudio } = useAppSelector((b) => b.lesson);
  const { language } = useLanguageContext();
  const { playMedias, onPlayAudio } = useAccesibiltyContext();

  const handleNext = useCallback(() => {
    if (feedNumber < storyFeedWords.length - 1) {
      nextFeed();
    }
    if (feedNumber === 0) {
      setActiveButton(false);
      dispatch(setActiveButtonHelp(false));
    }
    if (feedNumber === storyFeedWords.length - 2) {
      setActiveButton(true);
      dispatch(setActiveButtonHelp(true));
    }
  }, [feedNumber, storyFeedWords]);

  const isFirstAudio = totalIndex === 1 && feedNumber === 0;
  const firstAudio = [
    `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/1STWORD.mp3`,
    `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in9.ogg`,
  ];
  useEffect(() => {
    async function precess() {
      const feed = storyFeedWords[feedNumber];

      if (feed.type === "audio") {
        const contentToPlay = feed.contentArray ?? [feed.content];
        const flattenedContent = ([] as string[]).concat(...contentToPlay);
        setisPlaying(true);
        await playMedias(isFirstAudio ? firstAudio : flattenedContent);
        setPageContent((prev) => ({ ...prev, audio: feed.content }));
        handleNext();
        setisPlaying(false);
      }
      if (feed.type === "text") {
        setPageContent((prev) => ({ ...prev, text: feed.content }));
        handleNext();
      }
      if (feed.type === "image") {
        setPageContent((prev) => ({ ...prev, image: feed.content }));
        handleNext();
        setActiveButton(true);
      }
    }
    precess();
  }, [feedNumber, handleNext, storyFeedWords]);

  const currentLevel = activityData?.lesson.story.level.level_order;
  const words = preTeachingData?.words.map((i) => i.text);
  const phrase = pageContent.text; //all sentences

  const highlightText = () => {
    if (
      finished === "phrases" &&
      questionIndex === preTeachingFeedData.length - 1 &&
      currentLevel &&
      words &&
      currentLevel > 2
    ) {
      const highlightedWords = words.filter((word) => phrase.includes(word));
      if (highlightedWords.length > 0) {
        const parts = phrase.split(
          new RegExp(
            `(${highlightedWords.join("|").replace(/[.,]/g, " -")})`,
            "gi"
          )
        );

        return (
          <span>
            {parts.map((part, index) => {
              if (highlightedWords.includes(part.toLowerCase())) {
                return (
                  <span
                    key={index}
                    style={{
                      padding: "4px",
                      borderRadius: "16px",
                      border: "1.5px solid #CBD5E0",
                      background: "#fff9b5",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      clickWord(part);
                    }}
                  >
                    {part}
                  </span>
                );
              } else {
                return <span key={index}>{part}</span>;
              }
            })}
          </span>
        );
      }
    }

    return <span>{phrase}</span>;
  };

  const windowHeight = window.innerHeight;

  return (
    <Flex flexDir={"column"} align="center">
      {/* <Text32px mt="16px">Title page</Text32px> */}
      <Flex align="center" pos="relative">
        <Subtitle>LEARNING WORDS</Subtitle>
        <Flex position="absolute" hidden={!inAudio} right={-70}>
          <AudioWave />
        </Flex>
      </Flex>

      <Flex
        align="center"
        mt={windowHeight < 800 ? "24px" : "56px"}
        gap="24px"
        flexDir={["column", "row"]}
      >
        {activeButton && feedNumber === storyFeedWords.length - 1 && (
          <PlayIcon
            onClick={async () => {
              let feed = storyFeedWords[2];

              if (currentLevel && currentLevel <= 2 && totalIndex === 5) {
                feed = storyFeedWords[6];
              } else if (
                currentLevel &&
                currentLevel <= 2 &&
                totalIndex === 6
              ) {
                feed = storyFeedWords[8];
              } else if (
                currentLevel &&
                currentLevel <= 2 &&
                totalIndex === 7
              ) {
                feed = storyFeedWords[6];
              } else if (
                currentLevel &&
                currentLevel <= 2 &&
                totalIndex === 8
              ) {
                feed = storyFeedWords[8];
              } else {
                feed = storyFeedWords[2];
              }
              if (feed.type === "audio") {
                setisPlaying(true);
                const contentToPlay = feed.contentArray ?? [feed.content];
                const contentAudio = ([] as string[]).concat(...contentToPlay);
                await onPlayAudio([...contentAudio]);
                setisPlaying(false);
              }
            }}
          />
        )}

        <Flex
          minH="80px"
          minW="100px"
          p="16px"
          bg="white"
          borderRadius="16px"
          border="1.5px solid #CBD5E0"
        >
          <Text32px color="primary">{highlightText()}</Text32px>
        </Flex>
      </Flex>

      {pageContent.image && (
        <Image
          mt={windowHeight < 800 ? "24px" : "56px"}
          fallbackSrc={""}
          maxW="500px"
          borderRadius="16px"
          boxShadow="8px 16px 20px rgba(119, 119, 119, 0.137)"
          src={pageContent.image}
        />
      )}
    </Flex>
  );
};

export default StoryLesson;
