import {
  AnswerUnitReviewSynonym,
  IUnitReviewSynonym,
} from "../../../@types/interfaces/api/unit-review-synonym.interface";

export enum UnitReviewSynonymActionTypes {
  LOAD_QUESTION_UNIT_REVIEW_SYNONYM_REQUEST = "@unitReviewSynonym/LOAD_QUESTION_UNIT_REVIEW_SYNONYM_REQUEST",
  LOAD_QUESTION_UNIT_REVIEW_SYNONYM_SUCCESS = "@unitReviewSynonym/LOAD_QUESTION_UNIT_REVIEW_SYNONYM_SUCCESS",
  LOAD_QUESTION_UNIT_REVIEW_SYNONYM_ERROR = "@unitReviewSynonym/LOAD_QUESTION_UNIT_REVIEW_SYNONYM_ERROR",

  POST_ANSWER_UNIT_REVIEW_SYNONYM_REQUEST = "@unitReviewSynonym/POST_ANSWER_UNIT_REVIEW_SYNONYM_REQUEST",
  POST_ANSWER_UNIT_REVIEW_SYNONYM_SUCCESS = "@unitReviewSynonym/POST_ANSWER_UNIT_REVIEW_SYNONYM_SUCCESS",
  POST_ANSWER_UNIT_REVIEW_SYNONYM_ERROR = "@unitReviewSynonym/POST_ANSWER_UNIT_REVIEW_SYNONYM_ERROR",

  SET_ANSWER_UNIT_REVIEW_SYNONYM = "@unitReviewSynonym/ SET_ANSWER_UNIT_REVIEW_SYNONYM",
  SET_TIMER_UNIT_REVIEW_SYNONYM = "@unitReviewSynonym/ SET_TIMER_UNIT_REVIEW_SYNONYM",
  ON_NEXT = "@unitReviewSynonym/ ON_NEXT",
  ON_NEXT_2 = "@unitReviewSynonym/ ON_NEXT_2",
  ON_PREVIOUS = "@unitReviewSynonym/ PREVIOUS",
  CLEAR_LIST = "@unitReviewSynonym/CLEAR_LIST",
  RESET_INDEX = "@unitReviewSynonym/RESET_INDEX",
}

export interface UnitReviewSynonymState
  extends UnitReviewSynonymStateRequestsStatus {
  unitReviewSynonymData: IUnitReviewSynonym | undefined;
  answers: AnswerUnitReviewSynonym[];
  alternativeUnitReviewSynonym: string;
  questionIndex: number;
  dateMileseconds: number;
}

interface UnitReviewSynonymStateRequestsStatus {
  isLoading: boolean;
}
