import { assetsBaseUrl } from "./assets-helper";

export namespace Utility {
  export function reorder<T>(
    list: Array<T>,
    startIndex: number,
    endIndex: number
  ) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }
}

export const BASE_URL_MIDIA = `${assetsBaseUrl()}`;
export const BASE_URL_WORD_PATH = `${assetsBaseUrl()}/app-2.0/jr_html5/audio/`;

export const audioCorrectAnswer = (): string => {
  const audios = [
    `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/TERRIFIC.mp3`,
    `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/FANTAST.mp3`,
    `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/EXCELLNT.mp3`,
  ];

  const audioRandomIndex = Math.floor(Math.random() * audios.length);

  return audios[audioRandomIndex];
};

export const audioCorrectAnswerOgg = (): string => {
  const audios = [
    `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in67.ogg`,
    `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in69.ogg`,
  ];

  const audioRandomIndex = Math.floor(Math.random() * audios.length);

  return audios[audioRandomIndex];
};

type UrlPaths = {
  [key: string]: string;
};

export const urlsPaths: UrlPaths = {
  pre_teaching_lesson: "pre-teach",
  pre_test: "pre-test",
  post_test: "post-test",
  talking_book: "talking-book",
  word_recognition_exercises: "word-recognition",
  reinforcement_activities: "reinforcement-activities",
  "independent_activities_drag_&_fill_exercises": "drag-fill-exercise",
  independent_activities_matching: "matching",
  independent_activities_scrambled_sentence: "scrambled-sentence",
  independent_activities_alphabetical_order: "alphabetical-order",
  "independent_activities_short_answer_q&a": "short-answer",
  reading_comprehension: "reading-comprehension",

  pre_test_verbal_master: "pre-test-verbal-master",
  post_test_verbal_master: "Post-test-verbal-master",
  word_verbal_master: "word-verbal-master",
  unit_review_synonym: "unit-review-synonym",
  unit_review_definition: "unit-review-definition",
  unit_review_fill_the_blank: "unit-review-fill-the-blank",
  unit_review_comprehension: "unit-review-reading-comprehension",

  spelling_program: "spelling-program",
  vocabulary_builder: "vocabulary-builder",
  minigame: "minigame",
};

export const mechanicTypeName: UrlPaths = {
  pre_teaching_lesson: "Pre Teaching Lesson",
  pre_test: "Pre Test",
  post_test: "Post Test",
  talking_book: "Talking Book",
  word_recognition_exercises: "Word Recognition",
  reinforcement_activities: "Reinforcement Activities",
  "independent_activities_drag_&_fill_exercises": "Drag and fill exercise",
  independent_activities_matching: "Matching Exercise",
  independent_activities_scrambled_sentence: "Scrambled sentence",
  independent_activities_alphabetical_order: "Alphabetical Order",
  "independent_activities_short_answer_q&a": "Short  Answer",
  reading_comprehension: "Reading Comprehension",

  pre_test_verbal_master: "Pre Test Verbal Master",
  post_test_verbal_master: "Post Test Verbal Master",
  word_verbal_master: "Word Verbal Master",
  unit_review_synonym: "Unit Review Synonym",
  unit_review_definition: "Unit Review Definition",
  unit_review_fill_the_blank: "Unit Review Fill the Blank",
  unit_review_comprehension: "Unit Review Comprehension",

  spelling_program: "Spelling Program",
  vocabulary_builder: "Vocabulary Builder",
  minigame: "Mini Game",
};

export const mechanicTypeWithAccessibility = [
  "pre_teaching_lesson",
  "pre_test",
  "post_test",
  "talking_book",
  "word_recognition_exercises",
  "reinforcement_activities",
  "reading_comprehension",
  "spelling_program",
  "vocabulary_builder",
];
