import {
  AnswerPreTestVm,
  IPreTestVm,
  QuestionPreTestVm,
} from "../../../@types/interfaces/api/pre-test-vm.interface";
import { AnswerPreTestComponent } from "../../../components/lessons/SynonymAndDefinition";

export enum PreTestVmActionTypes {
  LOAD_PRE_TEST_VM_REQUEST = "@preTestVm/LOAD_PRE_TEST_VM_REQUEST",
  LOAD_PRE_TEST_VM_SUCCESS = "@preTestVm/LOAD_PRE_TEST_VM_SUCCESS",
  LOAD_PRE_TEST_VM_ERROR = "@preTestVm/LOAD_PRE_TEST_VM_ERROR",

  POST_PRE_TEST_VM_REQUEST = "@preTestVm/POST_PRE_TEST_VM_REQUEST",
  POST_PRE_TEST_VM_SUCCESS = "@preTestVm/POST_PRE_TEST_VM_SUCCESS",
  POST_PRE_TEST_VM_ERROR = "@preTestVm/POST_PRE_TEST_VM_ERROR",

  SET_ALTERNATIVE_SYNONYM = "@preTestVm/ SET_ALTERNATIVE_SYNONYM",
  SET_ALTERNATIVE_DEFINITION = "@preTestVm/ SET_ALTERNATIVE_DEFINITION",
  ON_NEXT_SYNONYM = "@preTestVm/ ON_NEXT_SYNONYM",
  ON_NEXT_DEFINITION = "@preTestVm/ ON_NEXT_DEFINITION",
  ON_PREIVIOUS_PRE_TEST_VM = "@preTestVm/ ON_PREIVIOUS_PRE_TEST_VM",
  SET_TYPE_PRE_TEST_VM = "@preTestVm/ SET_TYPE_PRE_TEST_VM",
  SET_TYPE_DEFINITION = "@preTestVm/ SET_TYPE_DEFINITION",
  SET_TIMER_PRE_TEST_VM = "@preTestVm/ SET_TIMER_PRE_TEST_VM",
  CLEAR_LIST = "@preTestVm/ CLEAR_LIST",
}

export interface PreTestVmState extends PreTestVmStateRequestsStatus {
  preTestVmData: IPreTestVm | undefined;
  questionIndex: number;
  alternativeSnonym: AnswerPreTestComponent | undefined;
  alternativeDefinition: AnswerPreTestComponent | undefined;
  dateMileseconds: number;
  answersPreTestVm: AnswerPreTestVm[];
  typeLesson: "synonym" | "definition" | "";
}

interface PreTestVmStateRequestsStatus {
  isLoading: boolean;
}
