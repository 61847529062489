import { Flex } from "@chakra-ui/react";
import React from "react";

function ContainerLesson({ children }: React.PropsWithChildren) {
  return (
    <Flex
      height="100%"
      justifyContent="space-between"
      flexDirection="column"
      position="relative"
      flex={1}
    >
      {children}
    </Flex>
  );
}

export default ContainerLesson;
