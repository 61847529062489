import { Reducer } from "redux";
import {
  UnitReviewComprehensionActionTypes,
  UnitReviewComprehensionState,
} from "./type";

const INITIAL_STATE: UnitReviewComprehensionState = {
  isLoading: false,
  alternativeUnitReviewComprehension: "",
  answers: [],
  dateMileseconds: 0,
  questionIndex: 0,
  unitReviewComprehensionData: undefined,
};

const reducer: Reducer<UnitReviewComprehensionState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case UnitReviewComprehensionActionTypes.LOAD_QUESTION_UNIT_REVIEW_COMPREHENSION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UnitReviewComprehensionActionTypes.LOAD_QUESTION_UNIT_REVIEW_COMPREHENSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        unitReviewComprehensionData: action.payload,
        questionIndex: 0,
      };
    case UnitReviewComprehensionActionTypes.LOAD_QUESTION_UNIT_REVIEW_COMPREHENSION_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case UnitReviewComprehensionActionTypes.CLEAR_LIST:
      return INITIAL_STATE;

    case UnitReviewComprehensionActionTypes.SET_ANSWER_UNIT_REVIEW_COMPREHENSION:
      return {
        ...state,
        answers: [...state.answers, action.payload],
        alternativeUnitReviewComprehension: "",
      };
    case UnitReviewComprehensionActionTypes.SET_ALTERNATIVE_UNIT_REVIEW_COMPREHENSION:
      return {
        ...state,
        alternativeUnitReviewComprehension: action.payload,
      };

    case UnitReviewComprehensionActionTypes.ON_NEXT:
      return {
        ...state,
        questionIndex: state.questionIndex + 1,
        alternativeUnitReviewComprehension: "",
      };

    case UnitReviewComprehensionActionTypes.POST_ANSWER_UNIT_REVIEW_COMPREHENSION_REQUEST:
      return {
        ...state,
        alternativeUnitReviewComprehension: "",
        isLoading: true,
      };
    case UnitReviewComprehensionActionTypes.POST_ANSWER_UNIT_REVIEW_COMPREHENSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        answers: [],
      };
    case UnitReviewComprehensionActionTypes.POST_ANSWER_UNIT_REVIEW_COMPREHENSION_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case UnitReviewComprehensionActionTypes.ON_PREVIOUS:
      return {
        ...state,
        questionIndex: state.questionIndex - 1,
        alternativeUnitReviewComprehension: "",
        answers: action.payload,
      };

    case UnitReviewComprehensionActionTypes.SET_TIMER_UNIT_REVIEW_COMPREHENSION:
      return { ...state, dateMileseconds: action.payload };

    default:
      return state;
  }
};
export default reducer;
