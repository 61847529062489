import { type } from "os";
import React, { useEffect } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { DragOption } from "../../../@types/interfaces/api/drag-fill.interface";
import {
  BASE_URL_MIDIA,
  BASE_URL_WORD_PATH,
  Utility,
} from "../../../utils/Utility";
import { assetsBaseUrl } from "../../../utils/assets-helper";
import { useAppSelector } from "../../../hooks/redux";
import { useLanguageContext } from "../../../providers/LanguageProvider";
import { useAccesibiltyContext } from "../../../providers/AccessibilityController";

interface Prop<T> {
  children: React.ReactNode;
  alternatives: DragOption[];
  state: T;
  setState: React.Dispatch<React.SetStateAction<T>>;
  currentProgram?: "JR" | "LF" | "VM" | null;
}
function DragDropContextWrap<T extends Object>({
  alternatives,
  children,
  state,
  setState,
  currentProgram,
}: Prop<T>) {
  const { language } = useLanguageContext();
  const { onPlayAudio } = useAccesibiltyContext();
  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    const sourceKey = source.droppableId as keyof T;
    const destKey = destination.droppableId as keyof T;

    if (source.droppableId === destination.droppableId) {
      const items = Utility.reorder<DragOption>(
        state[sourceKey] as any,
        source.index,
        destination.index
      );

      setState((prev) => ({ ...prev, [sourceKey]: items }));
    } else {
      const sourceClone = Array.from(state[sourceKey] as any);
      const destClone = Array.from(state[destKey] as any);
      const [removed] = sourceClone.splice(source.index, 1);
      destClone.splice(destination.index, 0, removed);

      setState((prev) => ({
        ...prev,
        [sourceKey]: sourceClone,
        [destKey]: destClone,
      }));
    }
  };

  const verifyProgram = (): string => {
    if (currentProgram === "JR") {
      return "/app-2.0/jr_html5/audio/common/";
    } else {
      return "/app-2.0/jr4_html5/audio/common_mp3/";
    }
  };

  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      onDragStart={(dragStart) => {
        // if (dragStart.source.droppableId === "items") {
        const alternative = alternatives.find(
          (a) => a.id === Number(dragStart.draggableId)
        );
        if (alternative?.audios) {
          const audios = alternative?.audios.map(
            (audio) => BASE_URL_MIDIA + audio
          );
          onPlayAudio([...audios]);
        }

        if (alternative?.audio) {
          const audiosTextSplited = alternative.text
            .split(" ")
            .map(
              (w) =>
                assetsBaseUrl() +
                verifyProgram() +
                w.replace(/[.,]/g, "").toLowerCase().trim() +
                ".mp3"
            );

          onPlayAudio([...audiosTextSplited]);
        }
        // }
      }}
    >
      {children}
    </DragDropContext>
  );
}

export default DragDropContextWrap;
