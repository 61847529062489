import { Flex, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IScrambledAnswerPost } from "../../@types/interfaces/api/scrambled-sentence.interface";
import ContainerLesson from "../../components/global/ContainerLesson";
import PageGrid from "../../components/global/PageGrid";
import ProgressBar from "../../components/global/ProgressBar";
import GreatJob from "../../components/lessons/common/GreatJob";
import StartLesson from "../../components/lessons/common/StartLesson";
import ScrambledSentence from "../../components/lessons/ScrambledSentence";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { usePages } from "../../hooks/usePages";
import {
  clearScrambledList,
  loadScrambledRequest,
  onNextScrambled,
  setAnswerScrambled,
  setTimerScrambled,
} from "../../stores/ducks/scrambled-sentence/actions";
import { urlsPaths } from "../../utils/Utility";
import { assetsBaseUrl } from "../../utils/assets-helper";
import ScrambledSentenceOnTouch from "../../components/lessons/ScrambledSentenceOnTouch";

function ScrambledSentencePage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { scrambledData, wrongAnswer, answerScrambled, isLoading } =
    useAppSelector((item) => item.scrambledSentence);
  const { currentLesson, currentProgram } = useAppSelector((i) => i.lesson);

  useEffect(() => {
    if (currentLesson) {
      dispatch(loadScrambledRequest({ activity__id: currentLesson.id }));
    }

    dispatch(setTimerScrambled(Date.now()));
    return () => {
      dispatch(clearScrambledList());
    };
  }, []);

  const setAnswers = (data: IScrambledAnswerPost[]) => {
    dispatch(setAnswerScrambled(data));
  };

  const audios = {
    incomplete: `${assetsBaseUrl()}/app-2.0/jr_html5/audio/dirs/ENG/unscrambleincomplete.mp3`,
    intro: `${assetsBaseUrl()}/app-2.0/jr_html5/audio/dirs/ENG/scrambleinstructions.mp3`,
    wrong: `${assetsBaseUrl()}/app-2.0/jr_html5/audio/dirs/ENG/unscrambleincorrect.mp3`,
  };

  const verifyScrambled = (): boolean => {
    if (scrambledData && scrambledData?.results[0].words.length >= 12) {
      return true;
    } else {
      return false;
    }
  };

  const { page, setPage, pageName } = usePages<
    "start" | "question" | "greatJob"
  >({
    initialPage: "start",
    pages: {
      start: (
        <StartLesson
          title="Scrambled Sentence"
          onNext={() => setPage("question")}
          button="start"
        />
      ),
      question:
        scrambledData && verifyScrambled() ? (
          <ScrambledSentenceOnTouch
            title="Scrambled Sentence"
            words={scrambledData?.results[0].words}
            wordsShuffled={scrambledData?.results[0].words_shuffled}
            onNext={() => dispatch(onNextScrambled(setPage))}
            idSentence={scrambledData.results[0].id}
            wrongAnswer={wrongAnswer}
            setAnswer={setAnswers}
            audios={audios}
            currentProgram={currentProgram}
          />
        ) : (
          scrambledData && (
            <ScrambledSentence
              title="Scrambled Sentence"
              words={scrambledData?.results[0].words}
              wordsShuffled={scrambledData?.results[0].words_shuffled}
              onNext={() => dispatch(onNextScrambled(setPage))}
              idSentence={scrambledData.results[0].id}
              wrongAnswer={wrongAnswer}
              setAnswer={setAnswers}
              audios={audios}
              currentProgram={currentProgram}
            />
          )
        ),
      greatJob: (
        <GreatJob
          onNext={() => {
            if (currentLesson) {
              navigate(`/lesson/${urlsPaths[currentLesson?.mechanic_type]}`);
            }
          }}
        />
      ),
    },
  });

  return (
    <PageGrid
      activity="Scrambled Sentence"
      lesson="Joseph's Readers"
      onHelp={() => {}}
      audioHelp={[
        `${assetsBaseUrl()}/app-2.0/jr_html5/audio/dirs/ENG/scrambleinstructions.mp3`,
      ]}
      disabledButton={pageName === "start" || pageName === "greatJob"}
    >
      <ContainerLesson>
        {page}

        {/* <ProgressBar data={{ max: 12, step: 1 }} hide={pageName === "start"} /> */}
      </ContainerLesson>
    </PageGrid>
  );
}

export default ScrambledSentencePage;
