import {
  Drawer,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerFooter,
  Stack,
  Avatar,
  Flex,
  Text,
  IconButton,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Menu as ChakraMenu,
} from "@chakra-ui/react";
import { ChevronDown, ChevronsDown, HelpCircle, Menu, X } from "lucide-react";
import React, { useEffect } from "react";
import { BsCircleFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  loadStudentByIdRequest,
  logOut,
} from "../../stores/ducks/auth/actions";
import { loadLessonIDRequest } from "../../stores/ducks/lesson/action";
import { HeaderProps } from "./Header";
import OutlineButton from "./OutlineButton";

interface MenuDrawerProps extends HeaderProps {
  isOpen: boolean;
  onClose: () => void;
  lesson?: string;
  activity?: string;
  onHelp?: () => void;
}

const MenuDrawer: React.FC<MenuDrawerProps> = ({
  isOpen,
  onClose,
  lesson,
  activity,
  onHelp,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user, student } = useAppSelector((i) => i.auth);
  const { nextLessonFullData } = useAppSelector((i) => i.dashboard);
  const { lessonIdData } = useAppSelector((i) => i.lesson);

  useEffect(() => {
    if (student) {
      dispatch(loadStudentByIdRequest(student?.user));
    }
    if (nextLessonFullData) {
      dispatch(loadLessonIDRequest(nextLessonFullData?.lesson.id));
    }
  }, []);
  return (
    <Drawer isOpen={isOpen} placement="left" size="sm" onClose={onClose}>
      <DrawerContent bg="primary" p="96px 20px 48px">
        <DrawerCloseButton top="16px" left="20px" color="white" size="lg" />

        <DrawerBody p="0">
          {lessonIdData && lessonIdData?.lesson_order !== 0 ? (
            <Flex
              gap="8px"
              alignItems="center"
              borderBottom="1px solid #dddddd"
              pb="16px"
            >
              <BsCircleFill size={12} color={lessonIdData?.story.level.color} />

              <Text fontSize="16px" fontWeight={700} color="white">
                {lessonIdData?.story.level.program.name}
              </Text>

              <Text fontSize="14px" fontWeight={600} color={"gray.200"}>
                ({lessonIdData?.story.name}) Lesson {lessonIdData?.lesson_order}{" "}
                de {lessonIdData?.total_story_lessons}
              </Text>
            </Flex>
          ) : (
            <Flex
              gap="8px"
              alignItems="center"
              borderBottom="1px solid #dddddd"
              pb="16px"
            >
              <BsCircleFill size={12} color={lessonIdData?.story.level.color} />

              <Text fontSize="16px" fontWeight={700} color="white">
                {lessonIdData?.story.level.program.name}
              </Text>

              <Text fontSize="14px" fontWeight={600} color={"gray.200"}>
                ({lessonIdData?.story.name}) Lesson {lessonIdData?.lesson_order}{" "}
                de {lessonIdData?.total_story_lessons}
              </Text>
            </Flex>
          )}

          <Stack spacing="34px" mt="32px">
            <OutlineButton
              color="white"
              rightIcon={<X size="16px" />}
              onClick={() => dispatch(logOut(navigate))}
            >
              Sign Out
            </OutlineButton>
            <OutlineButton
              rightIcon={<HelpCircle size="16px" />}
              onClick={onHelp}
              color="white"
              disabled
            >
              Help
            </OutlineButton>
            <ChakraMenu>
              <MenuButton
                disabled
                as={Button}
                rightIcon={<ChevronDown />}
                borderRadius="full"
                fontSize="16px"
                lineHeight="28px"
                fontWeight="600"
                border="1.5px solid #E2E8F0"
                p="8px 24px"
                h="48px"
                backgroundColor="transparent"
                color="white"
                _expanded={{ bg: "primary" }}
              >
                Languages
              </MenuButton>
              <MenuList
                w="250px"
                display="flex"
                flexDir="column"
                mt="-4px"
                fontWeight="600"
                borderRadius="12px"
                border="1.5px solid #E2E5EE"
                borderTop="none"
                p="8px"
                boxShadow="8px 16px 64px rgba(4, 15, 58, 0.236)"
              >
                <MenuItem
                  fontWeight="500"
                  value="1"
                  color="gray.500"
                  borderRadius=" 12px"
                >
                  English
                </MenuItem>
                <MenuItem
                  fontWeight="500"
                  value="2"
                  color="gray.500"
                  borderTop="1.5px solid #E2E8F0"
                >
                  Spanish
                </MenuItem>
              </MenuList>
            </ChakraMenu>
          </Stack>
        </DrawerBody>

        <DrawerFooter
          p="32px 0 0 0"
          justifyContent="flex-start"
          alignItems="flex-start"
          borderTop="1px solid #dddddd"
          flexDir="column"
        >
          <Flex w="full">
            <Avatar
              name={`${user?.first_name} ${user?.last_name}`}
              boxSize="48px"
            />
            <Flex
              align="flex-start"
              alignSelf="center"
              flexDir="column"
              pl="12px"
              flex={1}
            >
              <Text fontSize="14px" lineHeight="14px" mb="6px" color="white">
                Hello,
              </Text>
              <Text
                fontWeight="medium"
                mt="8px"
                lineHeight="16px"
                color="white"
              >
                {user?.first_name}
              </Text>
            </Flex>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default MenuDrawer;
