import { Reducer } from "redux";
import { TalkingBookActionTypes, TalkingBookState } from "./type";

const INITIAL_STATE: TalkingBookState = {
  isLoading: false,
  book: undefined,
  bookId: undefined,
  questionIndex: 0,
  dateMileseconds: 0,
};

const reducer: Reducer<TalkingBookState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TalkingBookActionTypes.LOAD_BOOK_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case TalkingBookActionTypes.LOAD_BOOK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        book: action.payload,
      };
    case TalkingBookActionTypes.LOAD_BOOK_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case TalkingBookActionTypes.LOAD_BOOK_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case TalkingBookActionTypes.LOAD_BOOK_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        bookId: action.payload,
      };
    case TalkingBookActionTypes.LOAD_BOOK_ID_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case TalkingBookActionTypes.SET_TIMER_TB:
      return {
        ...state,
        dateMileseconds: action.payload,
      };
    case TalkingBookActionTypes.POST_TALKING_BOOK_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case TalkingBookActionTypes.POST_TALKING_BOOK_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case TalkingBookActionTypes.POST_TALKING_BOOK_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case TalkingBookActionTypes.ON_NEXT_TB:
      return {
        ...state,
      };

    default:
      return state;
  }
};
export default reducer;
