import { Global } from "@emotion/react";
import MontserratRegular from "../assets/fonts/Montserrat-Regular.otf";
import MontserratMedium from "../assets/fonts/Montserrat-Medium.otf";
import MontserratSemiBold from "../assets/fonts/Montserrat-SemiBold.otf";
import MontserratBold from "../assets/fonts/Montserrat-Bold.otf";

const GlobalStyle = () => (
  <Global
    styles={`
      @font-face {
        font-weight: 400;
        font-family: Montserrat;
        src: url(${MontserratRegular}) format("opentype");
        font-display: swap;
      }
      @font-face {
        font-weight: 500;
        font-family: Montserrat;
        src: url(${MontserratMedium}) format("opentype");
        font-display: swap;
      }
      @font-face {
        font-weight: 600;
        font-family: Montserrat;
        src: url(${MontserratSemiBold}) format("opentype");
        font-display: swap;
      }
      @font-face {
        font-weight: 700;
        font-family: Montserrat;
        src: url(${MontserratBold}) format("opentype");
        font-display: swap;
      }
    `}
  />
);
export default GlobalStyle;
