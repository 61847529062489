import { Reducer } from "redux";
import { DiagnosticActionTypes, DiagnosticState } from "./type";

const INITIAL_STATE: DiagnosticState = {
  isLoading: false,
  questions: undefined,
  answers: [],
  alternativeDiagnostic: "",
  questionIndex: 0,
  selectedAudio: "",
  dateMileseconds: 0,
  numberOfCorrectAnswers: 0,
  numberOfWrongAnswers: 0,
  pageNumber: 1,
  diagnosticKey: "",
  additional: "",
};

const reducer: Reducer<DiagnosticState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DiagnosticActionTypes.LOAD_QUESTIONS_DIAGNOSTIC_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DiagnosticActionTypes.LOAD_QUESTIONS_DIAGNOSTIC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        questions: action.payload,
        numberOfCorrectAnswers: 0,
        numberOfWrongAnswers: 0,
        questionIndex: 0,
      };
    case DiagnosticActionTypes.LOAD_QUESTIONS_DIAGNOSTIC_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case DiagnosticActionTypes.CLEAR_LIST:
      return INITIAL_STATE;

    case DiagnosticActionTypes.SET_ALTERNATIVE_DIAGNOSTIC:
      return { ...state, alternativeDiagnostic: action.payload };

    case DiagnosticActionTypes.ON_NEXT:
      return {
        ...state,
        questionIndex: state.questionIndex + 1,
        alternativeDiagnostic: "",
        selectedAudio: "",
        answers: [...state.answers, action.payload.contentAnswer],
      };

    case DiagnosticActionTypes.POST_ANSWER_DIAGNOSTIC_REQUEST:
      return {
        ...state,
        alternativeDiagnostic: "",
        answers: [...state.answers, action.payload.contentAnswer],
        isLoading: true,
      };
    case DiagnosticActionTypes.POST_ANSWER_DIAGNOSTIC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        answers: [],
      };
    case DiagnosticActionTypes.POST_ANSWER_DIAGNOSTIC_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case DiagnosticActionTypes.ON_PREVIOUS:
      return {
        ...state,
        questionIndex: state.questionIndex - 1,
        alternativeDiagnostic: "",
        selectedAudio: "",
        answers: action.payload,
      };

    case DiagnosticActionTypes.SET_TIMER_DIAGNOSTIC:
      return { ...state, dateMileseconds: action.payload };

    case DiagnosticActionTypes.SET_DIAGNOSTIC_KEY:
      return { ...state, diagnosticKey: action.payload };
    case DiagnosticActionTypes.SET_ADDITIONAL:
      return { ...state, additional: action.payload };

    // case RasActionTypes.SET_AUDIO:
    //   return {
    //     ...state,
    //     selectedAudio: action.payload,
    //   };

    default:
      return state;
  }
};
export default reducer;
