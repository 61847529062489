import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
interface LanguageProviderProps {
  children: ReactNode;
}
interface LanguageProps {
  language: LanguageType;
  changeLanguage: (lang: LanguageType) => void;
}
export type LanguageType = "ENG" | "SP";

export const LanguageContext = createContext<LanguageProps>({
  changeLanguage: () => {},
  language: "ENG",
});

export const LanguageProvider = ({ children }: LanguageProviderProps) => {
  const [language, setLanguage] = useState<LanguageType>("ENG");

  const changeLanguage = (lang: LanguageType) => {
    setLanguage(lang);
    localStorage.setItem("language", lang);
  };

  useEffect(() => {
    const storeLang = localStorage.getItem("language");
    const storedLanguage =
      storeLang === "ENG" || storeLang === "SP" ? storeLang : "ENG";
    setLanguage(storedLanguage);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
export const useLanguageContext = () => {
  const context = useContext(LanguageContext);
  return context;
};
