import { LanguageType } from "../providers/LanguageProvider";
import store from "../stores/ducks";
import { setInAudio } from "../stores/ducks/lesson/action";

export namespace AudioMedia {
  let audios: HTMLAudioElement[] = [];

  export async function pauseSequence() {
    if (audios.length) {
      audios.map((audio) => audio.pause());
      audios = [];
    }
  }

  export async function playSequence(
    language: LanguageType,
    ...rawUrls: string[] | Array<string | undefined>
  ) {
    store.dispatch(setInAudio(true));

    return new Promise(async (resolve, reject) => {
      const urls = rawUrls
        .filter((element) => element !== undefined)
        .filter((element) => element !== "");
      if (!urls.length) {
        return reject();
      }

      pauseSequence();

      audios = urls.map((url) => {
        if (url && url.includes("ENG")) {
          return new Audio(url.replace("ENG", language));
        } else {
          return new Audio(url);
        }
      });

      for (let i = 0; i < audios.length; i++) {
        const audio = audios[i];
        const nextAudio = audios[i + 1];

        if (nextAudio) {
          audio.onended = async () => {
            nextAudio?.play();
          };
          audio.onerror = () => {
            nextAudio?.play();
          };
        }
      }

      audios[0]?.play();

      const lastAudio = audios[audios.length - 1];
      lastAudio.onended = () => {
        audios = [];

        return resolve(lastAudio);
      };

      lastAudio.onerror = () => {
        return resolve(lastAudio);
      };
    }).finally(() => store.dispatch(setInAudio(false)));
  }
}
