import { Reducer } from "redux";
import { MatchingActionTypes, MatchingState } from "./type";

const INITIAL_STATE: MatchingState = {
  isLoading: false,
  matchingData: undefined,
  answerMatching: [],
  dateMileseconds: 0,
  wrongAnswer: 0,
};

const reducer: Reducer<MatchingState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MatchingActionTypes.LOAD_MATCHING_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case MatchingActionTypes.LOAD_MATCHING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        matchingData: action.payload,
      };
    case MatchingActionTypes.LOAD_MATCHING_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case MatchingActionTypes.POST_MATCHING_REQUEST:
      return {
        ...state,
        answerMatching: action.payload.contentAnswer,
        wrongAnswer: 0,
        isLoading: true,
      };
    case MatchingActionTypes.POST_MATCHING_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case MatchingActionTypes.POST_MATCHING_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case MatchingActionTypes.SET_ANSWER_MATCHING:
      return {
        ...state,
        answerMatching: [...state.answerMatching, ...action.payload],
      };
    case MatchingActionTypes.SET_WRONG_ANSWER_MATCHING:
      return {
        ...state,
        wrongAnswer: state.wrongAnswer + 1,
      };

    case MatchingActionTypes.SET_TIMER_MATCHING:
      return {
        ...state,
        dateMileseconds: action.payload,
      };

    case MatchingActionTypes.CLEAR_LIST_MATCHING:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default reducer;
