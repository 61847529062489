import { Flex, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CardLaunchLesson from "../../cards/CardLaunchLesson";
import ButtonRadio from "../../global/ButtonRadio";
import ButtonRadioLaunchLesson from "../../global/ButtonRadioLaunchLesson";
import RegularButton from "../../global/RegularButton";
import { useLanguageContext } from "../../../providers/LanguageProvider";
import { useAppDispatch } from "../../../hooks/redux";
import { useAccesibiltyContext } from "../../../providers/AccessibilityController";

interface LaunchLessonProps {
  introduction?: string[];
  button?: string | boolean;
  onNext?: () => void;
  subTitle?: string;
  titleHelp?: string;
}

const launchLessonNames = [
  { id: 1, title: "Without Subtitles", subtitle: "" },
  { id: 2, title: "Subtitles", subtitle: "(CC/Closed Captioning)" },
];

const LaunchLessonSubtitles: React.FC<LaunchLessonProps> = ({
  subTitle,
  onNext,
  button,
}) => {
  const {
    handleSignLanguage,
    accessibility,
    handleBothAccessebility,
    handleSubTitles,
    setVideoWindow,
  } = useAccesibiltyContext();
  const [selectedItem, setSelectedItem] = useState({
    itemIndex: accessibility.subtitles ? 1 : 0,
    subTitle: "",
  });

  useEffect(() => {
    const initialTitle = launchLessonNames.find(
      (item, index) => index === selectedItem.itemIndex
    );
    setSelectedItem({
      ...selectedItem,
      subTitle: initialTitle?.subtitle ?? "",
    });
  }, []);

  return (
    <Flex flexDir="column" align="center" w="full">
      <Text color="gray.500" fontWeight="700" letterSpacing="3px">
        {subTitle}
      </Text>
      <Heading fontSize="32px" mt="16px">
        Launch Lesson
      </Heading>
      <Flex mt="32px" gap="24px" flexDir={["column", "row"]}>
        {launchLessonNames?.map((item, index) => (
          <ButtonRadioLaunchLesson
            text={item.title}
            key={item.id}
            subTitle={item.subtitle}
            isSelected={selectedItem.itemIndex === index}
            handleClick={() => {
              setSelectedItem({ itemIndex: index, subTitle: item.subtitle });
              if (item.id === 2) {
                handleSubTitles(true);
              } else {
                handleSubTitles(false);
              }
            }}
          />
        ))}
      </Flex>
      <Text mt="40px" fontSize="18px" fontWeight="500" textAlign="center">
        {selectedItem.subTitle === ""
          ? "Without Subtitles"
          : `${selectedItem.subTitle}  are provided when available within online program
        lesson software.`}
      </Text>
      <RegularButton onClick={onNext} mt="48px">
        Launch this Failure Free Reading Lesson
      </RegularButton>
      {/* <Text mt="40px" fontSize="18px" fontWeight="500" textAlign="center">
        Downloadable Educational Materials for this Story
      </Text>
      <Flex gap="24px" mt="32px" flexWrap="wrap" justify="center">
        <CardLaunchLesson />
        <CardLaunchLesson />
        <CardLaunchLesson />
        <CardLaunchLesson />
        <CardLaunchLesson />
      </Flex> */}
    </Flex>
  );
};

export default LaunchLessonSubtitles;
