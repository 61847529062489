import React from "react";
import PageGrid from "../components/global/PageGrid";
import LaunchLesson from "../components/lessons/common/LaunchLesson";
import StartLesson from "../components/lessons/common/StartLesson";
import { usePages } from "../hooks/usePages";

function CommonPages() {
  type Pages =
    | "firstScreen"
    | "startLesson"
    | "launch"
    | "readers"
    // | "greatJob"
    // | "finish"
    // | "language"
    // | "dontStop";
  const { page, setPage } = usePages<Pages>({
    initialPage: "firstScreen",
    pages: {
      firstScreen: (
        <StartLesson
          title="Failure Free Reading Lesson"
          subTitle="DIAGNOSTIC PRESCRIPTIVE"
          onNext={() => setPage("startLesson")}
        />
      ),
      startLesson: (
        <StartLesson
          title="Failure Free Reading Lesson"
          titleHelp="(Speakers are NOT needed for this part.)"
          onNext={() => setPage("readers")}
        />
      ),
      launch: (
        <LaunchLesson />
      ),
      readers: (
        <StartLesson
          title="Joseph's Readers"
          subTitle="The Farm Visit"
          onNext={() => setPage("startLesson")}
        />
      )
    },
  });
  return (
    <PageGrid
      activity="Word Recognition"
      lesson="Joseph's Readers"
      onHelp={() => {}}
    >
      {page}
    </PageGrid>
  );
}

export default CommonPages;
