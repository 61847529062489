import { Button, ButtonProps, Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const PlayIcon: React.FC<ButtonProps> = (props) => {
  return (
    <Button p="0" variant="ghost" _hover={{}} _active={{}} {...props}>
      <Icon
        width="40px"
        height="40px"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ml={["0px", "24px"]}
        cursor="pointer"
      >
        <rect
          x="0.75"
          y="0.75"
          width="38.5"
          height="38.5"
          rx="19.25"
          fill="#FEFEFE"
        />
        <path
          d="M23.5355 16.4645C25.4881 18.4171 25.4881 21.5829 23.5355 23.5355M26.364 13.636C29.8787 17.1507 29.8787 22.8492 26.364 26.3639M13.5858 23.0001H12C11.4477 23.0001 11 22.5523 11 22.0001V18.0001C11 17.4478 11.4477 17.0001 12 17.0001H13.5858L18.2929 12.2929C18.9229 11.663 20 12.1091 20 13.0001V27.0001C20 27.891 18.9229 28.3371 18.2929 27.7072L13.5858 23.0001Z"
          stroke="#718096"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="0.75"
          y="0.75"
          width="38.5"
          height="38.5"
          rx="19.25"
          stroke="#CBD5E0"
          strokeWidth="1.5"
        />
      </Icon>
    </Button>
  );
};

export default PlayIcon;
