import { createStandaloneToast } from "@chakra-ui/react";
import theme from "../../../styles/theme";
import axios, { AxiosResponse } from "axios";
import { call, put, select } from "redux-saga/effects";
import api from "../../../services/api";
import store, { ApplicationState, getGlobalState } from "..";
import {
  FullResponseActivity,
  IActivityData,
  IClassroomData,
  ICurrentLessonData,
  IlessonById,
  INextLessonData,
} from "../../../@types/interfaces/api/dashboard.interface";
import {
  loadActivityError,
  loadActivitySuccess,
  loadClassroomError,
  loadClassroomSuccess,
  loadNextActivityLessonError,
  loadNextActivityLessonSuccess,
} from "./actions";
import { AnyAction } from "redux";
import { loadTokenRequest } from "../auth/actions";
import { setCurrentLesson } from "../lesson/action";

const { toast } = createStandaloneToast({ theme });

export function* loadClassroom(action: AnyAction) {
  const id: number = action.payload;
  try {
    const response: AxiosResponse<IClassroomData> = yield call(
      api.get,
      `/classroom/${id}/student`
    );
    if (response.status == 200) {
      yield put(loadClassroomSuccess(response.data));
    }
  } catch (e: any) {
    yield put(loadClassroomError());
  }
}

export function* loadActivity(action: AnyAction) {
  const id: number = action.payload;
  try {
    const response: AxiosResponse<IActivityData> = yield call(
      api.get,
      `/activity/${id}`
    );
    const responseNext: INextLessonData | undefined = yield call(
      loadNextLesson
    );
    if (responseNext !== undefined) {
      const responseNextFull: AxiosResponse<IActivityData> = yield call(
        api.get,
        `/activity/${responseNext.id}`
      );
      if (responseNextFull.status === 200) {
        yield put(loadNextActivityLessonSuccess(responseNextFull.data));
        yield put(loadActivitySuccess(response.data));

        const fullResponse: FullResponseActivity = {
          currentActivity: response.data,
          nextActivity: responseNextFull.data,
        };
        yield put(setCurrentLesson(fullResponse));
      }
    } else {
      yield put(loadActivitySuccess(response.data));

      const fullResponse: FullResponseActivity = {
        currentActivity: response.data,
        nextActivity: undefined,
      };
      yield put(setCurrentLesson(fullResponse));
    }
  } catch (e: any) {
    yield put(loadActivityError());
  }
}

export function* loadNextLesson() {
  try {
    const response: AxiosResponse = yield call(api.get, `/activity/next`);
    if (response.status == 200) {
      return response.data;
    }
  } catch (e: any) {
    const errors = e.response.data;

    if (e.response.data.finished) {
      toast({
        title: "Last Activity",
        description:
          "You have reached the end of the program, when you finish the activity, contact your teacher.",
        status: "warning",
      });
    } else {
      toast({
        title: `error: ${e.response.status}`,
        description: e.response.statusText,
        status: "error",
      });
    }
  }
}

export function* loadNextActivity() {
  try {
    const responseNext: INextLessonData = yield call(loadNextLesson);
    const response: AxiosResponse<IActivityData> = yield call(
      api.get,
      `/activity/${responseNext.id}`
    );
    if (response.status == 200) {
      yield put(loadNextActivityLessonSuccess(response.data));
    }
  } catch (e: any) {
    yield put(loadNextActivityLessonError());
    window.location.reload();
  }
}
