import { Reducer } from "redux";
import {
  UnitReviewFillTheBlankActionTypes,
  UnitReviewFillTheBlankState,
} from "./type";

const INITIAL_STATE: UnitReviewFillTheBlankState = {
  isLoading: false,
  alternativeUnitReviewFillTheBlank: "",
  fullAlternativeUnitReviewFillTheBlank: "",
  answers: [],
  dateMileseconds: 0,
  questionIndex: 0,
  unitReviewFillTheBlankData: undefined,
  questionsModified: [],
};

const reducer: Reducer<UnitReviewFillTheBlankState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case UnitReviewFillTheBlankActionTypes.LOAD_QUESTION_UNIT_REVIEW_FILL_THE_BLANK_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UnitReviewFillTheBlankActionTypes.LOAD_QUESTION_UNIT_REVIEW_FILL_THE_BLANK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        unitReviewFillTheBlankData: action.payload.data,
        questionsModified: action.payload.questionModify,
        questionIndex: 0,
      };
    case UnitReviewFillTheBlankActionTypes.LOAD_QUESTION_UNIT_REVIEW_FILL_THE_BLANK_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case UnitReviewFillTheBlankActionTypes.CLEAR_LIST:
      return INITIAL_STATE;

    case UnitReviewFillTheBlankActionTypes.SET_ANSWER_UNIT_REVIEW_FILL_THE_BLANK:
      return {
        ...state,
        answers: [...state.answers, action.payload],
      };
    case UnitReviewFillTheBlankActionTypes.SET_ALTERNATIVE_UNIT_REVIEW_FILL_THE_BLANK:
      return {
        ...state,
        alternativeUnitReviewFillTheBlank: action.payload,
      };
    case UnitReviewFillTheBlankActionTypes.SET_FULL_ALTERNATIVE_UNIT_REVIEW_FILL_THE_BLANK:
      return {
        ...state,
        fullAlternativeUnitReviewFillTheBlank: action.payload,
      };

    case UnitReviewFillTheBlankActionTypes.ON_NEXT:
      return {
        ...state,
        questionIndex: state.questionIndex + 1,
        alternativeUnitReviewFillTheBlank: "",
        fullAlternativeUnitReviewFillTheBlank: "",
      };

    case UnitReviewFillTheBlankActionTypes.POST_ANSWER_UNIT_REVIEW_FILL_THE_BLANK_REQUEST:
      return {
        ...state,
        alternativeUnitReviewFillTheBlank: "",
        fullAlternativeUnitReviewFillTheBlank: "",
        isLoading: true,
      };
    case UnitReviewFillTheBlankActionTypes.POST_ANSWER_UNIT_REVIEW_FILL_THE_BLANK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        answers: [],
      };
    case UnitReviewFillTheBlankActionTypes.POST_ANSWER_UNIT_REVIEW_FILL_THE_BLANK_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case UnitReviewFillTheBlankActionTypes.ON_PREVIOUS:
      return {
        ...state,
        questionIndex: state.questionIndex - 1,
        alternativeUnitReviewFillTheBlank: "",
        fullAlternativeUnitReviewFillTheBlank: "",
        answers: action.payload,
      };

    case UnitReviewFillTheBlankActionTypes.SET_TIMER_UNIT_REVIEW_FILL_THE_BLANK:
      return { ...state, dateMileseconds: action.payload };

    default:
      return state;
  }
};
export default reducer;
