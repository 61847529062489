import { createStandaloneToast } from "@chakra-ui/react";
import theme from "../../../styles/theme";
import { AxiosResponse } from "axios";
import { call, put, select } from "redux-saga/effects";
import api from "../../../services/api";
import { AnyAction } from "redux";
import { ApplicationState, getGlobalState } from "..";
import { postProgress } from "../word-recognition/sagas";
import { ActivityIdFilter } from "../../../@types/interfaces/api/talking-book.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import {
  loadPostTestVmError,
  loadPostTestVmSuccess,
  postAnswerPostTestVmError,
  postAnswerPostTestVmSuccess,
  setTypLessonPostTestVm,
} from "./actions";
import {
  HistoryRecognition,
  HistoryRecognitionResponse,
} from "../../../@types/interfaces/api/word-recognition.interface";
import { IPostTestVm } from "../../../@types/interfaces/api/post-test-vm.interface";

const { toast } = createStandaloneToast({ theme });

export function* loadPostTestVm(action: AnyAction) {
  const filter: ActivityIdFilter = action.payload;

  try {
    const response: AxiosResponse<PaginationBase<IPostTestVm[]>> = yield call(
      api.get,
      `/post-test-verbal-master`,
      {
        params: filter,
      }
    );
    if (response.status == 200) {
      yield put(loadPostTestVmSuccess(response.data.results[0]));
    }
  } catch (e: any) {
    yield put(loadPostTestVmError());
  }
}

export function* postHistoryPostTestVm() {
  const data: ApplicationState = yield select(getGlobalState);

  const timeLesson = Date.now() - data.postTestVm.dateMileseconds;

  const dataHitory: HistoryRecognition = {
    time: timeLesson,
    student: data.auth.student?.user,
  };

  try {
    const response: AxiosResponse = yield call(
      api.post,
      "/history",
      dataHitory
    );
    if (response.status == 201) {
      return response.data;
    }
  } catch (e: any) {
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* postAnswerPostTestVm(action: AnyAction) {
  const data: ApplicationState = yield select(getGlobalState);
  const setPage = action.payload.setPage;
  const activity = data.postTestVm.postTestVmData?.activity;
  const typeLesson = data.postTestVm.typeLesson;
  const currentLesson = data.lesson.currentLesson;

  try {
    const responseHistory: HistoryRecognitionResponse = yield call(
      postHistoryPostTestVm
    );

    const completeData = data.postTestVm.answersPostTestVm.map((answer) => {
      return { ...answer, history: responseHistory.id };
    });

    const response: AxiosResponse = yield call(
      api.post,
      "/post-test-verbal-master/answer",
      completeData
    );
    if (response.status === 201) {
      if (typeLesson === "definition") {
        if (currentLesson) {
          yield put(postAnswerPostTestVmSuccess());
          yield call(postProgress, activity);
          yield put(setTypLessonPostTestVm("synonym"));
          setPage("greatJob");
        } else {
          setPage("greatJob");
          yield put(postAnswerPostTestVmSuccess());
          yield call(postProgress, activity);
          yield put(setTypLessonPostTestVm("synonym"));
        }
      } else {
        setPage("do_not_stop");
        yield put(setTypLessonPostTestVm("definition"));
      }
    }
  } catch (e: any) {
    yield put(postAnswerPostTestVmError());
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}
