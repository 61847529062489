import {
  ISpellingQuestion,
  StoryFeed,
} from "../../../@types/interfaces/api/pre-teaching.interface";
import {
  AnswerWordVm,
  IWordFeed,
  IWordVm,
} from "../../../@types/interfaces/api/word-vm.interface";

export enum WordVmActionTypes {
  LOAD_QUESTION_WORD_VM_REQUEST = "@wordVm/LOAD_QUESTION_WORD_VM_REQUEST",
  LOAD_QUESTION_WORD_VM_SUCCESS = "@wordVm/LOAD_QUESTION_WORD_VM_SUCCESS",
  LOAD_QUESTION_WORD_VM_ERROR = "@wordVm/LOAD_QUESTION_WORD_VM_ERROR",

  POST_ANSWER_WORD_VM_REQUEST = "@wordVm/POST_ANSWER_WORD_VM_REQUEST",
  POST_ANSWER_WORD_VM_SUCCESS = "@wordVm/POST_ANSWER_WORD_VM_SUCCESS",
  POST_ANSWER_WORD_VM_ERROR = "@wordVm/POST_ANSWER_WORD_VM_ERROR",

  SET_ALTERNATIVE_WORD_VM = "@wordVm/ SET_ALTERNATIVE_WORD_VM",
  SET_TIMER_WORD_VM = "@wordVm/ SET_TIMER_WORD_VM",
  ON_NEXT = "@wordVm/ ON_NEXT",
  ON_PREVIOUS = "@wordVm/ PREVIOUS",
  CLEAR_LIST = "@wordVm/CLEAR_LIST",
}

export interface WordVmState extends WordVmStateRequestsStatus {
  wordVmData: IWordVm | undefined;
  answers: AnswerWordVm[];
  alternativeWordVm: string;
  questionIndex: number;
  dateMileseconds: number;
  wordFeedData: IWordFeed | undefined;
  spelling: StoryFeed[];
  spellingQuestionData: ISpellingQuestion | undefined;
}

interface WordVmStateRequestsStatus {
  isLoading: boolean;
}
