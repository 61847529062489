import { createStandaloneToast } from "@chakra-ui/react";

import theme from "../../../styles/theme";
import axios, { AxiosResponse } from "axios";
import { call, put, select } from "redux-saga/effects";
import api from "../../../services/api";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import { AnyAction } from "redux";
import store, { ApplicationState, getGlobalState } from "..";

import WordVmFilterInterface, {
  IWordVm,
} from "../../../@types/interfaces/api/word-vm.interface";
import {
  HistoryRecognition,
  HistoryRecognitionResponse,
} from "../../../@types/interfaces/api/word-recognition.interface";
import { postProgress } from "../word-recognition/sagas";
import UnitReviewSynonymFilterInterface, {
  IUnitReviewSynonym,
} from "../../../@types/interfaces/api/unit-review-synonym.interface";
import {
  loadUnitReviewSynonymError,
  loadUnitReviewSynonymSuccess,
  postAnswerUnitReviewSynonymError,
  postAnswerUnitReviewSynonymSuccess,
} from "./actions";

const { toast } = createStandaloneToast({ theme });

export function* loadQuestionUnitReviewSynonym(action: AnyAction) {
  const filters: UnitReviewSynonymFilterInterface = action.payload;
  try {
    const response: AxiosResponse<PaginationBase<IUnitReviewSynonym[]>> =
      yield call(api.get, `/unit-review-synonym`, {
        params: filters,
      });
    if (response.status == 200) {
      yield put(loadUnitReviewSynonymSuccess(response.data.results[0]));
    }
  } catch (e: any) {
    yield put(loadUnitReviewSynonymError());
  }
}

export function* postHistoryUnitReview() {
  const data: ApplicationState = yield select(getGlobalState);

  const timeLesson = Date.now() - data.unitReviewSynonym.dateMileseconds;

  const dataHitory: HistoryRecognition = {
    time: timeLesson,
    student: data.auth.student?.user,
  };

  try {
    const response: AxiosResponse = yield call(
      api.post,
      "/history",
      dataHitory
    );
    if (response.status == 201) {
      return response.data;
    }
  } catch (e: any) {
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* postAnswerUnitReviewSynonym(action: AnyAction) {
  const data: ApplicationState = yield select(getGlobalState);
  const setPage = action.payload.setPage;
  const activity = data.unitReviewSynonym.unitReviewSynonymData?.activity;

  try {
    const responseHistory: HistoryRecognitionResponse = yield call(
      postHistoryUnitReview
    );

    const completeData = data.unitReviewSynonym.answers.map((answer) => {
      return { ...answer, history: responseHistory.id };
    });

    const response: AxiosResponse = yield call(
      api.post,
      "/unit-review-synonym/answer",
      completeData
    );
    if (response.status === 201) {
      yield put(postAnswerUnitReviewSynonymSuccess());
      yield call(postProgress, activity);
      setPage("greatJob");
    }
  } catch (e: any) {
    yield put(postAnswerUnitReviewSynonymError());
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}
