import store from "..";
import AnyAction from "redux";
import { WordVmActionTypes } from "./type";
import WordVmFilterInterface, {
  AnswerWordVm,
  IWordFeed,
  IWordVm,
} from "../../../@types/interfaces/api/word-vm.interface";
import { StoryFeed } from "../../../@types/interfaces/api/pre-teaching.interface";
import { assetsBaseUrl } from "../../../utils/assets-helper";

export const loadWordVmRequest = (filter: WordVmFilterInterface) => ({
  type: WordVmActionTypes.LOAD_QUESTION_WORD_VM_REQUEST,
  payload: filter,
});

export const loadWordVmSuccess = (data: IWordVm) => {
  const contentData = data.questions[0];
  const sentence = contentData.context;
  const quiz = contentData.quiz;
  const separator = contentData.word;

  const matchVerifyQuiz = () => {
    const partsQ = quiz.split(separator);
    if (quiz.indexOf(separator) === -1) {
      return [quiz];
    } else {
      const sentenceSplitedQ = [partsQ[0].trim(), separator, partsQ[1].trim()];
      return sentenceSplitedQ;
    }
  };
  const matchVerifySentence = () => {
    const partsS = sentence.split(separator);
    if (sentence.indexOf(separator) === -1) {
      return [sentence];
    } else {
      const sentenceSplitedS = [partsS[0].trim(), separator, partsS[1].trim()];
      return sentenceSplitedS;
    }
  };

  const feed: IWordFeed = {
    word: {
      text: contentData.word,
      audio: `${assetsBaseUrl()}${contentData.word_audio}`,
    },
    synonym: {
      text: contentData.synonym,
      audio: `${assetsBaseUrl()}${contentData.synonym_audio}`,
    },
    definition: {
      text: contentData.definition,
      audio: `${assetsBaseUrl()}${contentData.definition_audio}`,
    },
    sentence: {
      text: matchVerifySentence(),
      audio: `${assetsBaseUrl()}${contentData.context_audio}`,
    },
    selectQuestion: {
      text: matchVerifyQuiz(),
      audio: `${assetsBaseUrl()}${contentData.quiz_audio}`,
      response: contentData.quiz_answer,
      correct: `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/praise02.mp3`,
      wrong: `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/uh03.mp3`,
    },
  };

  const feedSpelling: StoryFeed[] = [];

  feedSpelling.push({
    type: "text",
    content: contentData.word,
  });
  feedSpelling.push({
    type: "audio",
    content: "",
    contentArray: [`${assetsBaseUrl()}${contentData.word_audio}`],
  });
  let currentWord = "";
  for (let i = 0; i < contentData.word.length; i++) {
    currentWord += contentData.word[i];
    feedSpelling.push({
      type: "text",
      content: currentWord,
    });
    const audioUrl = `${assetsBaseUrl()}/app-2.0/vm_html5/audio/alpha_mp3/${
      contentData.word[i]
    }.mp3`;
    feedSpelling.push({
      type: "audio",
      content: "",
      contentArray: [audioUrl],
    });
  }
  feedSpelling.push({
    type: "audio",
    content: "",
    contentArray: [`${assetsBaseUrl()}${contentData.word_audio}`],
  });
  feedSpelling.push({
    type: "text",
    content: contentData.word,
  });

  const spellingQuestion = {
    audio: [
      `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/uh05.mp3`,
      `${assetsBaseUrl()}${contentData.word_audio}`,
      `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/uh06.mp3`,
    ],
    response: contentData.word,
    correct: `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/FANTAST.mp3`,
    wrong: `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/uh07.mp3`,
  };
  return {
    type: WordVmActionTypes.LOAD_QUESTION_WORD_VM_SUCCESS,
    payload: { data, feed, feedSpelling, spellingQuestion },
  };
};
export const loadWordVmError = () => ({
  type: WordVmActionTypes.LOAD_QUESTION_WORD_VM_ERROR,
});

export const clearWordVmList = () => {
  return {
    type: WordVmActionTypes.CLEAR_LIST,
  };
};

export const setAlternativeWordVm = (alternative: string) => {
  return {
    type: WordVmActionTypes.SET_ALTERNATIVE_WORD_VM,
    payload: alternative,
  };
};

export const postAnswerWordVmRequest = (setPage: (page: any) => void) => {
  const contentListAnswer = store.getState().wordVm;

  const questionId = contentListAnswer.wordVmData?.questions[0].id;

  const contentAnswer = {
    answer: contentListAnswer.alternativeWordVm ?? "empty",
    question: questionId,
    history: 0,
  };
  return {
    type: WordVmActionTypes.POST_ANSWER_WORD_VM_REQUEST,
    payload: { contentAnswer, setPage },
  };
};

export const postAnswerWordVmSuccess = () => ({
  type: WordVmActionTypes.POST_ANSWER_WORD_VM_SUCCESS,
});
export const postAnswerWordVmError = () => ({
  type: WordVmActionTypes.POST_ANSWER_WORD_VM_ERROR,
});

export const setFullResponseWordVm = () => {
  const contentListAnswer = store.getState().wordVm;

  const questionId = contentListAnswer.wordVmData?.questions[0].id;

  const contentAnswer = {
    answer: contentListAnswer.alternativeWordVm ?? "empty",
    question: questionId,
    history: 0,
  };

  return {
    type: WordVmActionTypes.ON_NEXT,
    payload: contentAnswer,
  };
};

export const setTimerWordVm = (date: number) => {
  return {
    type: WordVmActionTypes.SET_TIMER_WORD_VM,
    payload: date,
  };
};
// export const onPreviousWordVm = () => {
//   const contentListAnswer = store.getState().wordVm;
//   const answerArray = contentListAnswer.answers;

//   answerArray.pop();

//   return {
//     type: WordVmActionTypes.ON_PREVIOUS,
//     payload: answerArray,
//   };
// };
