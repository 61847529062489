import React, { useEffect, useMemo } from "react";
import "../../styles/style.css";

interface AudioWave {}

const AudioWave: React.FC<AudioWave> = ({}) => {
  return (
    <div className="music">
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
    </div>
  );
};

export default AudioWave;
