import { Button, Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import PlayIcon from "../../../assets/icons/PlayIcon";
import ButtonRadio from "../../global/ButtonRadio";
import RegularButton from "../../global/RegularButton";
import Subtitle from "../../global/text/Subtitle";
import Text32px from "../../global/text/Text32px";
import { useLanguageContext } from "../../../providers/LanguageProvider";
import { useAccesibiltyContext } from "../../../providers/AccessibilityController";
import { IWordFeedOgg } from "../../../@types/interfaces/api/vocabulary-builder.interface";

interface Props {
  inAudio?: boolean;
  data: IWordFeedOgg;
  onCorrect: () => void;
  onWrong: () => void;
}

function WordSelectQuestionLS({ data, onCorrect, onWrong, inAudio }: Props) {
  const [disabled, setDisabled] = useState(true);
  const { playMedias } = useAccesibiltyContext();
  const [selectedAnswer, setSelectedAnswer] = useState<boolean | null>(null);
  async function handleResponse(selected: boolean) {
    if (selected === data.selectQuestion.response) {
      await playMedias(data.selectQuestion.correct);
      onCorrect();
    } else {
      await playMedias(data.selectQuestion.wrong);
      onWrong();
    }
  }

  useEffect(() => {
    async function play() {
      await playMedias(data.selectQuestion.audio);
      setDisabled(false);
    }

    play();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex flexDirection={"column"} alignItems="center">
      <Subtitle>PRESS ‘YES’ OR ‘NO’ TO ANSWER THE QUESTION</Subtitle>

      <Flex align="center" mt="16px">
        <Text32px>{data.word.text}</Text32px>
        <PlayIcon
          disabled={disabled}
          onClick={() => playMedias(data.word.audio)}
        />
      </Flex>
      <Flex align="center" mt="60px" flexDir={["column", "row"]}>
        <PlayIcon
          disabled={disabled}
          onClick={() => playMedias(data.selectQuestion.audio)}
        />
        <Text ml="16px" fontSize="20px" color="gray.500">
          {data.selectQuestion.text[0]}
        </Text>
        <Text fontWeight="700" ml="10px" color="primary" fontSize="20px">
          {data.selectQuestion.text[1]}
        </Text>
        <Text fontSize="20px" color="gray.500" ml="10px">
          {data.selectQuestion.text[2]}
        </Text>
      </Flex>
      <Flex mt="48px" gap="24px">
        <ButtonRadio
          text={"Yes"}
          isSelected={selectedAnswer === true}
          disabled={inAudio}
          inPlaying={inAudio}
          handleClick={() => {
            handleResponse(true);
            setSelectedAnswer(true);
          }}
        />
        <ButtonRadio
          text={"No"}
          disabled={inAudio}
          inPlaying={inAudio}
          isSelected={selectedAnswer === false}
          handleClick={() => {
            handleResponse(false);
            setSelectedAnswer(false);
          }}
        />
      </Flex>

      {/* <RegularButton mt="60px" disabled={disabled} onClick={handleContinue}>
        Continue
      </RegularButton> */}
    </Flex>

    //   <Flex>
    //     <Button
    //       onClick={() => onPlayAudio(data.selectQuestion.audio)}
    //     >
    //       Play
    //     </Button>
    //     <Flex>
    //       <Text me="1">{data.selectQuestion.text[0]}</Text>
    //       <Text color={"red"}>{data.selectQuestion.text[1]}</Text>
    //       <Text ms="1">{data.selectQuestion.text[2]}</Text>
    //     </Flex>
    //   </Flex>

    // </Flex>
  );
}

export default WordSelectQuestionLS;
