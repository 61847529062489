import { Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { WordSelect } from "../../@types/interfaces/api/word-recognition.interface";
import ContainerLesson from "../../components/global/ContainerLesson";
import PageGrid from "../../components/global/PageGrid";
import ProgressBar from "../../components/global/ProgressBar";
import GreatJob from "../../components/lessons/common/GreatJob";
import StartLesson from "../../components/lessons/common/StartLesson";
import WordRecognitionQuestion from "../../components/lessons/WordRecognitionQuestion";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { usePages } from "../../hooks/usePages";
import {
  loadWordRecognitionRequest,
  onNextRecognition,
  setAlternativeRecognition,
  setAnswerRecognition,
  setTimerRecognition,
} from "../../stores/ducks/word-recognition/actions";
import { urlsPaths } from "../../utils/Utility";
import { assetsBaseUrl } from "../../utils/assets-helper";
import SignLanguage from "../../components/global/SignLanguage";
import LaunchLesson from "../../components/lessons/common/LaunchLesson";
import { useAccesibiltyContext } from "../../providers/AccessibilityController";
import SubtitleComponent from "../../components/global/SubtitleComponent";
import { AlternativeIds } from "../../stores/ducks/word-recognition/type";

function WordRecognition() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { recognitionData, questionIndex, answers, isLoading } = useAppSelector(
    (item) => item.wordRecognition
  );
  const { student } = useAppSelector((item) => item.auth);
  const { currentLesson, activeButtonHelp, inAudio } = useAppSelector(
    (item) => item.lesson
  );
  const { setVideoWindow, accessibility, captionText } =
    useAccesibiltyContext();

  useEffect(() => {
    setVideoWindow(true);
    if (currentLesson) {
      dispatch(loadWordRecognitionRequest({ activity__id: currentLesson?.id })); // word_recognition_exercises
    }
    dispatch(setTimerRecognition(Date.now()));
    return () => {
      setVideoWindow(false);
    };
  }, []);

  const correctAnswer = recognitionData?.results[0].select[questionIndex];

  const setAlternativeFn = (alternativeIds: AlternativeIds) => {
    dispatch(setAlternativeRecognition(alternativeIds));
  };

  const setAnswerFn = () => {
    dispatch(setAnswerRecognition());
  };

  const initialPage = student?.progress.finished ? "start" : "accessibility";

  const { page, setPage, pageName } = usePages<
    "start" | "question" | "greatJob" | "accessibility"
  >({
    initialPage,
    pages: {
      accessibility: <LaunchLesson onNext={() => setPage("start")} />,
      start: (
        <StartLesson
          title="Word Recognition"
          onNext={() => setPage("question")}
          button="Start"
        />
      ),

      question: (
        <WordRecognitionQuestion
          setAnswerFn={setAnswerFn}
          sentence={recognitionData?.results[0]}
          correctAnswer={correctAnswer}
          questionIndex={questionIndex}
          setAlternativeFn={setAlternativeFn}
          onNext={async () => {
            dispatch(onNextRecognition(setPage));
          }}
        />
      ),
      greatJob: (
        <GreatJob
          onNext={() => {
            if (currentLesson) {
              navigate(`/lesson/${urlsPaths[currentLesson?.mechanic_type]}`);
            }
          }}
        />
      ),
    },
  });
  return (
    <PageGrid
      activity="Word Recognition"
      lesson="Joseph's Readers"
      onHelp={() => {}}
      audioHelp={[
        `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/WRECFIND.mp3`,
        `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in40.ogg`,
      ]}
      disabledButton={
        pageName === "start" ||
        pageName === "greatJob" ||
        pageName === "accessibility" ||
        inAudio
      }
    >
      {/* <SignLanguage /> */}
      <ContainerLesson>
        {page}
        <SubtitleComponent
          hide={
            !accessibility.subtitles ||
            captionText === "" ||
            pageName !== "start"
          }
        />

        {/* <ProgressBar data={{ max: 12, step: 1 }} hide={pageName === "start"} /> */}
      </ContainerLesson>
    </PageGrid>
  );
}

export default WordRecognition;
