import {
  Avatar,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { ChevronDown, User, LogOut, X } from "lucide-react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { logOut } from "../../stores/ducks/auth/actions";
import MenuListItem from "./MenuListItem";

const MenuHeader: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user, student } = useAppSelector((i) => i.auth);
  return (
    <Menu>
      <MenuButton
        as={Button}
        fontSize="14px"
        bg="transparent"
        h="auto"
        px="0"
        _hover={{}}
        _active={{}}
      >
        <Flex align="center" gap="12px">
          <Avatar name={`${user?.first_name} ${user?.last_name}`} />

          <Flex align="flex-start" flexDir="column" gap="8px">
            <Text fontSize="14px" lineHeight="14px" color="#475569">
              {user?.first_name}
            </Text>
            <Text
              fontWeight="500"
              lineHeight="12px"
              fontSize="12px"
              color="#A0AEC0"
              textTransform="capitalize"
            >
              {student?.grade} Grade
            </Text>
          </Flex>
          <ChevronDown size="20px" color="#64748B" />
        </Flex>
      </MenuButton>
      <MenuList
        minW="0"
        w="160px"
        maxW="160px"
        borderRadius="24px 24px 24px 24px"
        border="1.5px solid #E2E5EE"
        p="0"
        boxShadow="8px 16px 20px rgba(119, 119, 119, 0.219)"
      >
        <Link to="#">
          {/* <MenuListItem color="#A0AEC0" icon={<User color="#A0AEC0" />}>
            Profile
          </MenuListItem> */}
          <MenuListItem
            color="#A0AEC0"
            icon={<X color="#A0AEC0" />}
            onClick={() => dispatch(logOut(navigate))}
          >
            Sign Out
          </MenuListItem>
        </Link>
      </MenuList>
    </Menu>
  );
};

export default MenuHeader;
