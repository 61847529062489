import { Button, Flex, Heading, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ModalOpened from "../../global/ModalOpened";
import RegularButton from "../../global/RegularButton";
import Text32px from "../../global/text/Text32px";
import axios from "axios";
import api from "../../../services/api";
import { useLanguageContext } from "../../../providers/LanguageProvider";
import { useAppSelector } from "../../../hooks/redux";
import { assetsBaseUrl } from "../../../utils/assets-helper";
import useMediaPlayer from "../../../hooks/useMediaPlayer";
import SignLanguage from "../../global/SignLanguage";
import { useAccesibiltyContext } from "../../../providers/AccessibilityController";
import SubtitleComponent from "../../global/SubtitleComponent";
import AudioWave from "../../global/AudioWave";

interface Props {
  introduction?: string[];
  button?: string | boolean;
  onNext: () => void;
  title: string;
  subTitle?: string;
  titleHelp?: string;
  videoExplanationUrl?: string;
  inAudio?: boolean;
}
function StartLesson(props: Props) {
  const {
    onNext,
    button = true,
    introduction,
    title,
    subTitle,
    titleHelp,
    videoExplanationUrl,
    inAudio,
  } = props;
  const [hasError, setHasError] = useState({
    errorUrlVideo: true,
    errorUrlVideo2: false,
    inactiveStartButton: true,
  });
  const { language } = useLanguageContext();
  const { playMedias, accessibility, videoWindow } = useAccesibiltyContext();

  useEffect(() => {
    if (videoExplanationUrl) {
      //if video was erro the modal was closed
      const videoComponent = document.createElement("video");
      videoComponent.src = videoExplanationUrl;

      videoComponent.onerror = () => {
        setHasError({
          inactiveStartButton: false,
          errorUrlVideo: true,
          errorUrlVideo2: true,
        });
      };
      videoComponent.oncanplay = () => {
        setHasError({
          inactiveStartButton: false,
          errorUrlVideo: false,
          errorUrlVideo2: false,
        });
      };
    } else {
      setHasError({ ...hasError, inactiveStartButton: false });
    }

    const intro = async () => {
      const hasVideo = introduction && !videoExplanationUrl;
      const hasVideo2 =
        introduction && videoExplanationUrl && hasError.errorUrlVideo2;
      if (hasVideo || hasVideo2) {
        await playMedias(introduction);

        !button && onNext();
      }
    };
    intro();
  }, [hasError.errorUrlVideo2, videoWindow]);

  return (
    <Flex alignItems="center" justifyContent="center" flexDir="column">
      <Flex
        gap="16px"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        {subTitle && (
          <Text color="gray.500" fontWeight="700" textAlign="center">
            {subTitle}
          </Text>
        )}

        <Text32px>{title}</Text32px>

        {titleHelp && (
          <Text color="gray.500" fontWeight="700" textAlign="center">
            {titleHelp}
          </Text>
        )}
      </Flex>
      <Flex position="absolute" hidden={!inAudio}>
        <AudioWave />
      </Flex>

      {button && (
        <RegularButton
          mt="64px"
          onClick={onNext}
          disabled={inAudio || hasError.inactiveStartButton}
          isLoading={hasError.inactiveStartButton}
        >
          {typeof button === "string" ? button : "Start Lesson"}
        </RegularButton>
      )}

      {!hasError.errorUrlVideo && (
        <ModalOpened
          onCloseModal={async () => {
            if (introduction) {
              await playMedias(introduction);
            }

            !button && onNext();
          }}
          url={videoExplanationUrl ?? ""}
        />
      )}
    </Flex>
  );
}

export default StartLesson;
