import { Button, Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { DragOption } from "../../@types/interfaces/api/drag-fill.interface";
import { IScrambledAnswerPost } from "../../@types/interfaces/api/scrambled-sentence.interface";
import { useAppDispatch } from "../../hooks/redux";
import { useAudio } from "../../hooks/useAudio";
import {
  setAnswerScrambled,
  setWrongScrambled,
} from "../../stores/ducks/scrambled-sentence/actions";
import { BASE_URL_MIDIA, audioCorrectAnswer } from "../../utils/Utility";
import RegularButton from "../global/RegularButton";
import Subtitle from "../global/text/Subtitle";
import Text32px from "../global/text/Text32px";
import DragDropContextWrap from "./extra/DragDropContextWrap";
import DragOptionsBox from "./extra/DragOptionsBox";
import { assetsBaseUrl } from "../../utils/assets-helper";
import { useLanguageContext } from "../../providers/LanguageProvider";
import { useAccesibiltyContext } from "../../providers/AccessibilityController";

interface Props {
  onNext: () => void;
  words: DragOption[];
  wordsShuffled: DragOption[];
  title: string;
  idSentence: number;
  wrongAnswer: number;
  setAnswer: (data: IScrambledAnswerPost[]) => void;
  currentProgram?: "JR" | "LF" | "VM" | null;
  audios: {
    incomplete: string;
    intro: string;
  };
}

function ScrambledSentenceOnTouch({
  onNext,
  words,
  wordsShuffled,
  title,
  idSentence,
  wrongAnswer,
  setAnswer,
  audios,
  currentProgram,
}: Props) {
  const { language } = useLanguageContext();

  const dispatch = useAppDispatch();
  const { setAudio } = useAudio(language, audios.intro);

  type State = {
    items: DragOption[];
    sentence: DragOption[];
  };
  const initialState: State = {
    items: wordsShuffled,
    sentence: [],
  };
  const [prevState, setPrevState] = useState<State>(initialState);
  const [state, setState] = useState<State>(initialState);
  const [selectedOption, setSelectedOption] = useState<DragOption>();
  const [inactiveDrag, setInactiveDrag] = useState(false);
  const { onPlayAudio } = useAccesibiltyContext();

  useEffect(() => {
    if (words) {
      setState(initialState);
      setPrevState(initialState);
    }
  }, [words]);

  const verifyProgram = (): string => {
    if (currentProgram === "JR") {
      return "/app-2.0/jr_html5/audio/common/";
    } else {
      return "/app-2.0/jr4_html5/audio/common_mp3/";
    }
  };

  async function handleConfirm() {
    const response = state.sentence.map((word) => word.text);

    const arrayAnswer = state.sentence.map((w) => w.text);

    const data: IScrambledAnswerPost[] = [
      {
        sentence: idSentence,
        history: 0,
        answer: arrayAnswer.join(" "),
      },
    ];

    setAnswer(data);

    if (response.length !== words.length) {
      setAudio(audios.incomplete);
      return;
    }

    let isCorrect = true;
    for (let i = 0; i < words.length; i++) {
      const selected = response[i];
      const correctAnswer = words[i].text;
      if (selected.toLowerCase() !== correctAnswer.toLowerCase()) {
        isCorrect = false;
        break;
      }
    }

    if (!isCorrect) {
      setAudio(
        `${assetsBaseUrl()}/app-2.0/jr_html5/audio/dirs/ENG/unscrambleincorrect.mp3`
      );
      dispatch(setWrongScrambled());
      if (wrongAnswer >= 2) {
        onNext();
      }
      return;
    }

    setInactiveDrag(true);
    onNext();
    await onPlayAudio(audioCorrectAnswer());
  }

  const emptyField = state.sentence.some((text) => text.text);

  const handlePressItem = (clicked: DragOption) => {
    const itemsSentence = [...state.sentence];
    const itemsState = [...state.items];

    const hasItemInSentence = itemsSentence.some((i) => i.id === clicked.id);

    if (!hasItemInSentence) {
      itemsSentence.push(clicked);
      const filtred = itemsState.filter((i) => i.id !== clicked.id);
      setState({ items: filtred, sentence: itemsSentence });
      const wordAudio = words.find((word) => word.id === clicked.id);
      if (wordAudio?.audios) {
        const audios = wordAudio?.audios.map((audio) => BASE_URL_MIDIA + audio);
        onPlayAudio([...audios]);
      }

      if (wordAudio?.audio) {
        const audiosTextSplited = wordAudio.text
          .split(" ")
          .map(
            (w) =>
              assetsBaseUrl() +
              verifyProgram() +
              w.replace(/[.,]/g, "").toLowerCase() +
              ".mp3"
          );

        onPlayAudio([...audiosTextSplited]);
      }
    }
  };
  const handleSwitchItem = (clicked: DragOption) => {
    const itemsSentence = [...state.sentence];

    if (selectedOption) {
      if (selectedOption.id === clicked.id) {
        setSelectedOption(undefined);
      } else {
        const selectedIndex = state.sentence.findIndex(
          (i) => i.id === selectedOption.id
        );
        const pressedIndex = state.sentence.findIndex(
          (i) => i.id === clicked.id
        );

        itemsSentence[selectedIndex] = clicked;
        itemsSentence[pressedIndex] = selectedOption;
        setState((prev) => ({ ...prev, sentence: itemsSentence }));
        setSelectedOption(undefined);
      }
    } else {
      setSelectedOption(clicked);
    }
  };

  return (
    <Flex flexDir="column" alignItems={"center"}>
      <Subtitle>unscramble the sentence</Subtitle>
      <Text32px>{title}</Text32px>

      <Flex mt="32px" gap="24px">
        <RegularButton
          onMouseDown={() => {
            setPrevState(state);
            setState({ items: [], sentence: words });
          }}
          onMouseUp={() => {
            setState(prevState);
          }}
          onMouseLeave={() => {
            setState(prevState);
          }}
        >
          Review
        </RegularButton>
      </Flex>

      <Flex flexDir={"column"} gap={3} mt="32px">
        <Flex
          minW={["300px", "925px"]}
          minH={"70px"}
          bg="white"
          p="8px 16px"
          borderRadius="8px"
          border="1.5px solid #CBD5E0"
          flexWrap="wrap"
          alignItems="flex-start"
        >
          {state.sentence.map((item, index) => (
            <Flex
              key={item.id}
              p="8px 8px"
              borderRadius="8px"
              minWidth="fit-content"
              onClick={() => handleSwitchItem(item)}
              cursor="pointer"
              // bg={selectedOption?.id === item.id ? "#524e4d11" : "initial"}
              border={
                selectedOption?.id === item.id
                  ? "1.5px dashed #7e858b"
                  : "initial"
              }
            >
              <Text32px borderRadius="8px" userSelect="none" color="primary">
                {item.text}
              </Text32px>
            </Flex>
          ))}
        </Flex>
      </Flex>
      <Text color="gray.400">
        Click to select the word, then click to swap the word's position.
      </Text>

      <Flex gap="16px" mt="32px" flexWrap="wrap" justify="center">
        {state.items.map((item, index) => (
          <Flex
            key={item.id}
            bg="white"
            p="8px 16px"
            borderRadius="8px"
            border="1.5px solid #CBD5E0"
            whiteSpace="nowrap"
          >
            <Flex onClick={() => handlePressItem(item)} cursor="pointer">
              <Text
                fontSize="20px"
                fontWeight="700"
                color="#2D3748"
                userSelect="none"
              >
                {item.text}
              </Text>
            </Flex>
          </Flex>
        ))}
      </Flex>

      <RegularButton mt="60px" onClick={handleConfirm} disabled={!emptyField}>
        Confirm (ok)
      </RegularButton>
    </Flex>
  );
}

export default ScrambledSentenceOnTouch;
