import { Button, Flex, Square, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { motion } from "framer-motion";
import { select } from "redux-saga/effects";
import { useAppSelector } from "../../hooks/redux";

interface StartPageProps {
  setPage: () => void;
  postGame: () => void;
  score: number;
  misses: number;
  otherHits: number;
  shotsFired: number;
  targetsHit: number;
}

const FinishPageGame: React.FC<StartPageProps> = ({
  setPage,
  postGame,
  misses,
  otherHits,
  score,
  shotsFired,
  targetsHit,
}) => {
  const { isLoading } = useAppSelector((i) => i.minigame);
  return (
    <Flex
      w="full"
      h="100vh"
      flexDir="column"
      align="center"
      p="40px"
      style={{ background: "linear-gradient(45deg, #10111f, #3e2958)" }}
    >
      <Text color="white" fontSize="36px" fontWeight="semibold">
        Status
      </Text>

      <Text color="white" fontSize="28px" mt="40px">
        Score: {score}
      </Text>
      <Text color="white" fontSize="28px" mt="20px">
        Shots Fired: {shotsFired}
      </Text>
      <Text color="white" fontSize="28px" mt="20px">
        Targets Hit: {targetsHit}
      </Text>
      <Text color="white" fontSize="28px" mt="20px">
        Misses: {misses}
      </Text>
      <Text color="white" fontSize="28px" mt="20px">
        Other Hits: {otherHits}
      </Text>

      <Button
        mt="40px"
        color="white"
        fontWeight="semibold"
        bg="primary"
        w="200px"
        _hover={{ bg: "#4164ff" }}
        onClick={setPage}
      >
        Restart Game
      </Button>
      <Button
        mt="20px"
        color="white"
        fontWeight="semibold"
        bg="#f12e2e"
        w="200px"
        _hover={{ bg: "#ad0000" }}
        onClick={postGame}
        isLoading={isLoading}
      >
        Finish Game
      </Button>
    </Flex>
  );
};

export default FinishPageGame;
