import store from "..";
import {
  IMatching,
  IMatchingAnswerPost,
} from "../../../@types/interfaces/api/matching-exercse.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import { ActivityIdFilter } from "../../../@types/interfaces/api/talking-book.interface";
import { MatchingActionTypes } from "./type";

export const loadMatchingRequest = (filter: ActivityIdFilter) => ({
  type: MatchingActionTypes.LOAD_MATCHING_REQUEST,
  payload: filter,
});

export const loadMatchingSuccess = (data: PaginationBase<IMatching>) => ({
  type: MatchingActionTypes.LOAD_MATCHING_SUCCESS,
  payload: data,
});

export const loadMatchingError = () => ({
  type: MatchingActionTypes.LOAD_MATCHING_ERROR,
});

export const clearMatchingList = () => {
  return {
    type: MatchingActionTypes.CLEAR_LIST_MATCHING,
  };
};
export const setTimerMatching = (date: number) => {
  return {
    type: MatchingActionTypes.SET_TIMER_MATCHING,
    payload: date,
  };
};

export const setAnswerMatching = (data: IMatchingAnswerPost[]) => {
  return {
    type: MatchingActionTypes.SET_ANSWER_MATCHING,
    payload: data,
  };
};

export const onNextMatching = (setPage: (page: any) => void) => {
  const contentListAnswer = store.getState().matching;

  const contentAnswer = contentListAnswer.answerMatching;

  setPage("greatJob");
  return {
    type: MatchingActionTypes.POST_MATCHING_REQUEST,
    payload: { contentAnswer, setPage },
  };
};
export const postMatchingSuccess = () => {
  return {
    type: MatchingActionTypes.POST_MATCHING_SUCCESS,
  };
};

export const postMatchingError = () => {
  return {
    type: MatchingActionTypes.POST_MATCHING_ERROR,
  };
};

export const setWrongAnswer = () => {
  return {
    type: MatchingActionTypes.SET_WRONG_ANSWER_MATCHING,
  };
};
