import { Reducer } from "redux";
import { LessonsActionTypes, LessonState } from "./types";
import { AuthActionTypes } from "../auth/type";

const INITIAL_STATE: LessonState = {
  currentLessonList: [],
  currentLesson: undefined,
  lessonIdData: undefined,
  isLoading: false,
  activeButtonHelp: false,
  currentProgram: null,
  inAudio: false,
};

const reducer: Reducer<LessonState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LessonsActionTypes.LOAD_CURRENT_LESSON_REQUEST:
      return { ...state, isLoading: true };

    case LessonsActionTypes.LOAD_CURRENT_LESSON_SUCCESS:
      return { ...state, isLoading: false, currentLessonList: action.payload };

    case LessonsActionTypes.LOAD_CURRENT_LESSON_ERROR:
      return { ...state, isLoading: false };

    case LessonsActionTypes.LOAD_LESSON_REQUEST:
      return { ...state, isLoading: true };

    case LessonsActionTypes.LOAD_LESSON_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lessonIdData: action.payload.lessonId,
        currentProgram: action.payload.currentProgram,
      };

    case LessonsActionTypes.LOAD_CURRENT_LESSON_ERROR:
      return { ...state, isLoading: false };

    case LessonsActionTypes.SET_IN_AUDIO:
      return { ...state, inAudio: action.payload };

    case LessonsActionTypes.SET_ACTIVE_BUTTON_HELP:
      return { ...state, activeButtonHelp: action.payload };

    case LessonsActionTypes.SET_CURRENT_LESSON:
      return { ...state, currentLesson: action.payload };

    case LessonsActionTypes.CLEAR_LESSON:
      return {
        ...state,
        lessonIdData: undefined,
        isLoading: false,
        activeButtonHelp: false,
      };
    case AuthActionTypes.LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
