import {
  Flex,
  Heading,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import OutlineButton from "../global/OutlineButton";
import Ximage from "../../assets/image/x-circle.png";
import RegularButton from "../global/RegularButton";
import { useNavigate } from "react-router-dom";
import { useAccesibiltyContext } from "../../providers/AccessibilityController";
import ButtonRadioLaunchLesson from "../global/ButtonRadioLaunchLesson";

interface ModalPrintAccessProps {
  onClose(): void;
  isOpen: boolean;
  subTitle?: string;
}

const launchLessonNames = [
  { id: 1, title: "Without Subtitles", subtitle: "" },
  { id: 2, title: "Subtitles", subtitle: "(CC/Closed Captioning)" },
  { id: 3, title: "Sign Language ", subtitle: "(ASL)" },
  { id: 4, title: "Both", subtitle: "(CC and ASL)" },
];

const ModalLaunch: React.FC<ModalPrintAccessProps> = ({
  onClose,
  isOpen,
  subTitle,
}) => {
  const {
    handleSignLanguage,
    accessibility,
    handleBothAccessebility,
    handleSubTitles,
    setVideoWindow,
  } = useAccesibiltyContext();
  const [selectedItem, setSelectedItem] = useState({
    itemIndex: verifyAccessibility(),
    subTitle: "",
  });

  useEffect(() => {
    const initialTitle = launchLessonNames.find(
      (item, index) => index === selectedItem.itemIndex
    );
    setSelectedItem({
      ...selectedItem,
      subTitle: initialTitle?.subtitle ?? "",
    });
  }, []);

  function verifyAccessibility(): number {
    if (accessibility.subtitles && !accessibility.signLanguage) {
      return 1;
    } else if (accessibility.signLanguage && !accessibility.subtitles) {
      return 2;
    } else if (accessibility.signLanguage && accessibility.subtitles) {
      return 3;
    } else return 0;
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="6xl">
      <ModalOverlay />

      <ModalContent
        p={["24px", "32px"]}
        // w={["803px", "803px"]}
        textAlign="center"
        alignItems="center"
        borderRadius="16px"
        mt="14%"
      >
        <ModalCloseButton />
        <Flex flexDir="column" align="center" w="full">
          <Text color="gray.500" fontWeight="700" letterSpacing="3px">
            {subTitle}
          </Text>
          <Heading fontSize="32px" mt="16px">
            Settings
          </Heading>
          <Flex mt="32px" gap="24px" flexDir={["column", "row"]}>
            {launchLessonNames?.map((item, index) => (
              <ButtonRadioLaunchLesson
                text={item.title}
                key={item.id}
                subTitle={item.subtitle}
                isSelected={selectedItem.itemIndex === index}
                handleClick={() => {
                  setSelectedItem({
                    itemIndex: index,
                    subTitle: item.subtitle,
                  });
                  if (item.id === 2) {
                    handleSubTitles(true);
                  } else if (item.id === 3) {
                    handleSignLanguage(true);
                  } else if (item.id === 4) {
                    handleBothAccessebility(true);
                  } else if (item.id === 1) {
                    handleBothAccessebility(false);
                  }
                }}
              />
            ))}
          </Flex>
          <Text mt="40px" fontSize="18px" fontWeight="500" textAlign="center">
            {selectedItem.subTitle === ""
              ? "Without Subtitles"
              : `${selectedItem.subTitle}  are provided when available within online program
        lesson software.`}
          </Text>
        </Flex>
        <RegularButton w="169px" onClick={onClose} mt="36px">
          Save Settings
        </RegularButton>
      </ModalContent>
    </Modal>
  );
};

export default ModalLaunch;
