import { Flex, FlexProps, Text } from "@chakra-ui/react";
import React from "react";
import { useAccesibiltyContext } from "../../providers/AccessibilityController";

interface ISubtitle extends FlexProps {
  hide?: boolean;
}

const SubtitleComponent: React.FC<ISubtitle> = ({ hide, ...rest }) => {
  const { captionText, onPlayAudio } = useAccesibiltyContext();

  if (hide) {
    return <></>;
  }
  return (
    <Flex w="100%" justify="center" position="static" {...rest}>
      <Flex
        bg="#2d3748a7"
        marginX="30px"
        color="white"
        borderRadius="8px"
        justifyContent="center"
        align="center"
        minH="50px"
        zIndex={999}
      >
        <Text
          fontSize="18px"
          fontWeight="500"
          m="10px"
          // lineHeight="150%"
          textAlign="center"
        >
          {captionText}
        </Text>
      </Flex>
    </Flex>
  );
};

export default SubtitleComponent;
