import { Button, Flex, Heading } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import RegularButton from "../components/global/RegularButton";

const ControlPainel: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Flex w="full" justify="center">
      <Flex p={5} flexDir={"column"} gap="24px" w="400px">
        <Heading as="h2">Diagnostic</Heading>
        <Flex flexDir={"column"} gap="16px">
          <RegularButton onClick={() => navigate("/")}>login</RegularButton>
          <RegularButton onClick={() => navigate("/dashboard")}>
            Dashboard
          </RegularButton>

          <RegularButton
            onClick={() => navigate("/lesson/reading-attitude-survey")}
          >
            Reading attitude survey (RAS) +
          </RegularButton>
          <RegularButton
            onClick={() => navigate("/lesson/diagnostic-prescriptive")}
          >
            Diagnostic prescriptive +
          </RegularButton>
        </Flex>

        <Heading as="h2">Josephs Reader</Heading>
        <Flex flexDir={"column"} gap="16px">
          <RegularButton onClick={() => navigate("/lesson/pre-test")}>
            Story Pre-test
          </RegularButton>
          <br />

          <RegularButton onClick={() => navigate("/lesson/pre-teach")}>
            Pre teach
          </RegularButton>

          <RegularButton onClick={() => navigate("/lesson/talking-book")}>
            Talking book +
          </RegularButton>

          <RegularButton onClick={() => navigate("/lesson/word-recognition")}>
            Word Recognition +
          </RegularButton>

          <RegularButton
            onClick={() => navigate("/lesson/reinforcement-activities")}
          >
            Reinforcement Activities +
          </RegularButton>

          <RegularButton onClick={() => navigate("/lesson/drag-fill-exercise")}>
            Drag & Fill Exercise +
          </RegularButton>

          <RegularButton onClick={() => navigate("/lesson/matching")}>
            Matching +
          </RegularButton>

          <RegularButton onClick={() => navigate("/lesson/scrambled-sentence")}>
            Scrambled Sentence +
          </RegularButton>

          <RegularButton onClick={() => navigate("/lesson/short-answer")}>
            Short Answer Q&A +
          </RegularButton>

          <RegularButton onClick={() => navigate("/lesson/alphabetical-order")}>
            Alphabetical Order
          </RegularButton>

          <br />
          <RegularButton
            onClick={() => navigate("/lesson/reading-comprehension")}
          >
            Reading Comprehension
          </RegularButton>

          <RegularButton onClick={() => navigate("/lesson/post-test")}>
            Story Post test
          </RegularButton>
        </Flex>

        <Heading as="h2">Life Skills</Heading>
        <Flex flexDir={"column"} gap="16px">
          <RegularButton onClick={() => navigate("/lesson/pre-test")}>
            Story Pre-test *
          </RegularButton>
          <br />

          <RegularButton onClick={() => navigate("/lesson/talking-book")}>
            Talking book *
          </RegularButton>
          <RegularButton onClick={() => navigate("/lesson/word-recognition")}>
            Word Recognition *
          </RegularButton>
          <RegularButton
            onClick={() => navigate("/lesson/reinforcement-activities")}
          >
            Reinforcement Activities *
          </RegularButton>
          <RegularButton onClick={() => navigate("/lesson/drag-fill-exercise")}>
            Drag & Fill Exercise *
          </RegularButton>
          <RegularButton onClick={() => navigate("/lesson/matching")}>
            Matching *
          </RegularButton>
          <RegularButton onClick={() => navigate("/lesson/scrambled-sentence")}>
            Scrambled Sentence *
          </RegularButton>
          <RegularButton onClick={() => navigate("/lesson/alphabetical-order")}>
            Alphabetical Order *
          </RegularButton>
          <RegularButton onClick={() => navigate("/lesson/short-answer")}>
            Short Answer Q&A *
          </RegularButton>

          <br />
          <RegularButton onClick={() => navigate("/lesson/spelling-program")}>
            Spelling Program
          </RegularButton>

          <RegularButton onClick={() => navigate("/lesson/vocabulary-builder")}>
            Vocabulary Builder ***
          </RegularButton>

          <RegularButton onClick={() => navigate("/lesson/post-test")}>
            Story Post test *
          </RegularButton>
        </Flex>

        <Heading as="h2">Verbal Master</Heading>
        <Flex flexDir={"column"} gap="16px">
          <Heading as="h2" size={"sm"}>
            Cluster pre/post Test
          </Heading>

          <RegularButton onClick={() => navigate("/lesson/test-synonym")}>
            Synonym
          </RegularButton>

          <RegularButton onClick={() => navigate("/lesson/test-definition")}>
            Definition
          </RegularButton>

          <RegularButton
            onClick={() => navigate("/lesson/test-fill-in-the-blank")}
          >
            Fill in the Blank
          </RegularButton>

          <RegularButton onClick={() => navigate("/lesson/test-comprehension")}>
            Comprehension
          </RegularButton>
        </Flex>

        <Flex flexDir={"column"} gap="16px">
          <Heading as="h2" size={"sm"}>
            Unit
          </Heading>

          <RegularButton onClick={() => navigate("/lesson/word")}>
            Word n (accustomed) ***
          </RegularButton>

          <RegularButton onClick={() => navigate("/lesson/review-definition")}>
            Unit Review (Synonym) **
          </RegularButton>

          <RegularButton onClick={() => navigate("/lesson/review-definition")}>
            Unit Review (Definition) **
          </RegularButton>

          <RegularButton
            onClick={() => navigate("/lesson/review-fill-in-the-blank")}
          >
            Unit Review (Fill in the Blank)
          </RegularButton>

          <RegularButton
            onClick={() => navigate("/lesson/review-comprehension")}
          >
            Unit Review (Comprehension)
          </RegularButton>

          <RegularButton onClick={() => navigate("/lesson/short-answer")}>
            Short Answer Q&A *
          </RegularButton>
        </Flex>

        <Flex flexDir={"column"} gap="16px">
          <Heading as="h2" size={"sm"}>
            Cluster Story
          </Heading>

          <RegularButton onClick={() => navigate("/lesson/key-phases")}>
            Key Phases
          </RegularButton>

          <RegularButton onClick={() => navigate("/lesson/passage")}>
            Passage
          </RegularButton>

          <RegularButton onClick={() => navigate("/lesson/fill-in-the-blank")}>
            Fill in the Blank
          </RegularButton>

          <RegularButton onClick={() => navigate("/lesson/comprehension")}>
            Comprehension
          </RegularButton>
        </Flex>

        <RegularButton mt={9} onClick={() => navigate("/common")}>
          common
        </RegularButton>
      </Flex>
    </Flex>
  );
};

export default ControlPainel;
