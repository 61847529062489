import {
  Avatar,
  Flex,
  IconButton,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import { Menu, X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/image/logo.png";
import MenuDrawer from "./MenuDrawer";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { logOut } from "../../stores/ducks/auth/actions";
import { useState } from "react";
import ModalCloseLesson from "../modals/ModalCloseLesson";

export interface HeaderProps {
  lesson?: string;
  activity?: string;
  onHelp?: () => void;
}
function HeaderMobile({ lesson, activity, onHelp }: HeaderProps) {
  const navigate = useNavigate();
  const { user } = useAppSelector((i) => i.auth);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const dispatch = useAppDispatch();
  const [isOpenModal, setisOpenModal] = useState(false);

  return (
    <>
      <Flex w="full" display={["flex", "none"]}>
        <Flex
          w="full"
          alignItems="center"
          justifyContent="space-between"
          py="16px"
          px="32px"
          borderBottom="1px solid #EDF2F7"
        >
          <IconButton aria-label="menu" variant="ghost" onClick={onOpen}>
            <Menu color="#718096" />
          </IconButton>
          <Image src={logo} w="100px" onClick={() => setisOpenModal(true)} />
          <Avatar name={`${user?.first_name} ${user?.last_name}`} />
        </Flex>
      </Flex>
      <MenuDrawer
        lesson={lesson}
        activity={activity}
        onHelp={onHelp}
        isOpen={isOpen}
        onClose={onClose}
      />
      <ModalCloseLesson
        isOpen={isOpenModal}
        onClose={() => setisOpenModal(false)}
      />
    </>
  );
}

export default HeaderMobile;
