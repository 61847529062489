import {
  IActivityData,
  IClassroomData,
  ICurrentLessonData,
  IlessonById,
  INextLessonData,
} from "../../../@types/interfaces/api/dashboard.interface";

export enum DashboardActionTypes {
  LOAD_CLASSROOM_REQUEST = "@dashboard/LOAD_CLASSROOM_REQUEST",
  LOAD_CLASSROOM_SUCCESS = "@dashboard/LOAD_CLASSROOM_SUCCESS",
  LOAD_CLASSROOM_ERROR = "@dashboard/LOAD_CLASSROOM_ERROR",

  LOAD_ACTIVITY_REQUEST = "@dashboard/LOAD_ACTIVITY_REQUEST",
  LOAD_ACTIVITY_SUCCESS = "@dashboard/LOAD_ACTIVITY_SUCCESS",
  LOAD_ACTIVITY_ERROR = "@dashboard/LOAD_ACTIVITY_ERROR",

  LOAD_NEXT_LESSON_REQUEST = "@dashboard/LOAD_NEXT_LESSON_REQUEST",
  LOAD_NEXT_LESSON_SUCCESS = "@dashboard/LOAD_NEXT_LESSON_SUCCESS",
  LOAD_NEXT_LESSON_ERROR = "@dashboard/LOAD_NEXT_LESSON_ERROR",

  CLEAR_LIST = "@dashboard/CLEAR_LIST",
}

export interface DashboardState extends DashboardStateRequestsStatus {
  classroomData: IClassroomData | undefined;
  activityData: IActivityData | undefined;
  nextLessonId: INextLessonData | undefined;
  nextLessonFullData: IActivityData | undefined;
}

interface DashboardStateRequestsStatus {
  isLoading: boolean;
}
