import { Flex, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ContainerLesson from "../../components/global/ContainerLesson";
import PageGrid from "../../components/global/PageGrid";
import ProgressBar from "../../components/global/ProgressBar";
import GreatJob from "../../components/lessons/common/GreatJob";
import StartLesson from "../../components/lessons/common/StartLesson";
import Matching from "../../components/lessons/Matching";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { usePages } from "../../hooks/usePages";
import {
  loadMatchingRequest,
  onNextMatching,
} from "../../stores/ducks/matching/actions";
import { urlsPaths } from "../../utils/Utility";
import { assetsBaseUrl } from "../../utils/assets-helper";

/**
 * @description In this exercise is displayed three sentences with blank spaces and three options.
 * The student must match each option to the correct sentence, filling the gap.
 * This lesson is corrected when the user click on ok button, playing the correct audio or try again audio.
 * If the user click ok and there is any gap in the sentence, play introduction audio
 */
function MatchingExercise() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currentLesson, currentProgram } = useAppSelector((i) => i.lesson);
  const { isLoading } = useAppSelector((i) => i.matching);

  const { page, setPage, pageName } = usePages<
    "start" | "question" | "greatJob"
  >({
    initialPage: "start",
    pages: {
      start: (
        <StartLesson
          title="Matching"
          onNext={() => setPage("question")}
          button="start"
        />
      ),
      question: (
        <Matching
          onNext={() => {
            dispatch(onNextMatching(setPage));
          }}
        />
      ),
      greatJob: (
        <GreatJob
          onNext={() => {
            if (currentLesson) {
              navigate(`/lesson/${urlsPaths[currentLesson?.mechanic_type]}`);
            }
          }}
        />
      ),
    },
  });

  return (
    <PageGrid
      activity="Matching"
      lesson="Joseph's Readers"
      onHelp={() => {}}
      audioHelp={[
        `${assetsBaseUrl()}/app-2.0/jr_html5/audio/dirs/ENG/drag_best_word.mp3`,
      ]}
      disabledButton={pageName === "start" || pageName === "greatJob"}
    >
      <ContainerLesson>
        {page}

        {/* <ProgressBar data={{ max: 1, step: 1 }} hide={pageName === "start"} /> */}
      </ContainerLesson>
    </PageGrid>
  );
}

export default MatchingExercise;
