import { Button, Flex, Progress, Text } from "@chakra-ui/react";
import RegularButton from "./RegularButton";
import "../../styles/progressBar.css";

interface ProgressBarProps {
  data?: {
    max: number;
    step: number;
  };
  hideButton?: boolean;
  hideButtonPrevious?: boolean;
  hide?: boolean;
  onPrevious?: () => void;
  onConfirm?: () => void;
  isLoading?: boolean;
}
function ProgressBar(props: ProgressBarProps) {
  const {
    data,
    hide = false,
    hideButton,
    onConfirm,
    onPrevious,
    hideButtonPrevious,
    isLoading,
  } = props;

  if (hide || !data) {
    return <></>;
  }

  return (
    <Flex
      flexDir="row"
      justifyContent="space-between"
      gap={["10px", "10px", "78px"]}
      alignItems="center"
    >
      <RegularButton
        fontSize={["12px", "12px", "18px"]}
        p={["4px 10px", "4px 10px", "10px 24px"]}
        disabled={hideButtonPrevious ? hideButtonPrevious : data.step <= 1}
        onClick={onPrevious}
      >
        previous
      </RegularButton>

      <Flex flex={1} alignItems="center" gap="20px">
        <Progress
          flex={1}
          value={data.step}
          borderRadius="16px"
          min={0}
          max={data.max}
          size="sm"
          sx={{
            "& > div:first-child": {
              transitionProperty: "width",
            },
          }}
        />

        <Flex gap="6px">
          <Text
            fontWeight="700"
            color="primary"
            fontSize={["12px", "12px", "18px"]}
          >
            {data.step}
          </Text>
          <Text
            fontWeight="700"
            color="gray.500"
            fontSize={["12px", "12px", "18px"]}
          >
            / {data.max}
          </Text>
        </Flex>
      </Flex>

      <RegularButton
        fontSize={["12px", "12px", "18px"]}
        p={["4px 10px", "4px 10px", "10px 24px"]}
        onClick={onConfirm}
        disabled={hideButton}
        isLoading={isLoading}
      >
        Confirm
      </RegularButton>
    </Flex>
  );
}

export default ProgressBar;
