import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useLocation, useParams } from "react-router-dom";
import { DragOption } from "../../../@types/interfaces/api/drag-fill.interface";
import Text32px from "../../global/text/Text32px";

type Alternative = {
  id: string;
  text: string;
  audio: string[];
};

interface Props {
  droppableId: string;
  alternatives: DragOption[];
  inactiveDrag?: boolean;
}
function DragOptionsBox({ alternatives, droppableId, inactiveDrag }: Props) {
  const { pathname } = useLocation();

  return (
    <Droppable droppableId={droppableId} direction="horizontal">
      {(provided) => (
        <Flex
          ref={provided.innerRef}
          gap="16px"
          mt="32px"
          flexWrap="wrap"
          justify="center"
          flexDir={pathname === "/lesson/matching" ? "column" : "row"}
        >
          {alternatives.map((item, index) => (
            <Draggable
              key={item.id}
              draggableId={String(item.id)}
              index={index}
              isDragDisabled={inactiveDrag}
            >
              {(provided, snapshot) => (
                <Flex
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  bg={snapshot.isDragging ? "primary" : "white"}
                  p="8px 16px"
                  borderRadius="8px"
                  border="1.5px solid #CBD5E0"
                  whiteSpace="nowrap"
                >
                  <Text
                    fontSize="20px"
                    fontWeight="700"
                    color={snapshot.isDragging ? "white" : "#2D3748"}
                    onClick={() => console.log(item.id)}
                  >
                    {item.text}
                  </Text>
                </Flex>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </Flex>
      )}
    </Droppable>
  );
}

export default DragOptionsBox;
