import { createStandaloneToast } from "@chakra-ui/react";
import theme from "../../../styles/theme";
import { AxiosResponse } from "axios";
import { call, put, select } from "redux-saga/effects";
import api from "../../../services/api";
import { AnyAction } from "redux";
import {
  loadMatchingError,
  loadMatchingSuccess,
  postMatchingError,
  postMatchingSuccess,
} from "./actions";
import { IMatching } from "../../../@types/interfaces/api/matching-exercse.interface";
import { ApplicationState, getGlobalState } from "..";
import { HistoryRecognitionResponse } from "../../../@types/interfaces/api/word-recognition.interface";
import { postProgress } from "../word-recognition/sagas";
import { ActivityIdFilter } from "../../../@types/interfaces/api/talking-book.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";

const { toast } = createStandaloneToast({ theme });

export function* loadMachingId(action: AnyAction) {
  const filter: ActivityIdFilter = action.payload;
  try {
    const response: AxiosResponse<PaginationBase<IMatching>> = yield call(
      api.get,
      `/matching-exercise`,
      {
        params: filter,
      }
    );
    if (response.status == 200) {
      yield put(loadMatchingSuccess(response.data));
    }
  } catch (e: any) {
    yield put(loadMatchingError());
  }
}

export function* postHistoryMatching() {
  const data: ApplicationState = yield select(getGlobalState);

  const timeLesson = Date.now() - data.matching.dateMileseconds;

  const dataHitory = {
    time: timeLesson,
    student: data.auth.student?.user,
  };

  try {
    const response: AxiosResponse = yield call(
      api.post,
      "/history",
      dataHitory
    );
    if (response.status == 201) {
      return response.data;
    }
  } catch (e: any) {
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* postAnswerMatching(action: AnyAction) {
  const data: ApplicationState = yield select(getGlobalState);
  const setPage = action.payload.setPage;
  const activity = data.matching.matchingData?.results[0].activity;

  try {
    const responseHistory: HistoryRecognitionResponse = yield call(
      postHistoryMatching
    );

    const completeData = data.matching.answerMatching.map((answer) => {
      return { ...answer, history: responseHistory.id };
    });

    const response: AxiosResponse = yield call(
      api.post,
      "/matching-exercise/answer",
      completeData
    );
    if (response.status == 201) {
      yield put(postMatchingSuccess());
      yield call(postProgress, activity);
    }
  } catch (e: any) {
    yield put(postMatchingError());
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}
