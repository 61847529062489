import { Reducer } from "redux";
import { PreTestActionTypes, PreTestState } from "./type";

const INITIAL_STATE: PreTestState = {
  isLoading: false,
  alternativePreTest: "",
  answers: [],
  preTestData: undefined,
  questionIndex: 0,
  selectedAnswer: "",
  numberOfCorrectAnswers: 0,
  numberOfWrongAnswers: 0,
  pageNumber: 1,
  levelId: 1,
  dateMileseconds: 0,
  fullAlternative: "",
  inactiveButton: false,
  storyOrder: 1,
};

const reducer: Reducer<PreTestState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PreTestActionTypes.LOAD_QUESTIONS_PRE_TEST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case PreTestActionTypes.LOAD_QUESTIONS_PRE_TEST_SUCCESS:
      return {
        ...state,
        inactiveButton: false,
        isLoading: false,
        preTestData: action.payload,
        questionIndex: 0,
        numberOfCorrectAnswers: 0,
        numberOfWrongAnswers: 0,
      };
    case PreTestActionTypes.LOAD_QUESTIONS_PRE_TEST_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case PreTestActionTypes.CLEAR_LIST:
      return INITIAL_STATE;

    case PreTestActionTypes.SET_ALTERNATIVE_PRE_TEST:
      return { ...state, alternativePreTest: action.payload };

    case PreTestActionTypes.SET_FULL_ALTERNATIVE_PRE_TEST:
      return { ...state, fullAlternative: action.payload };

    case PreTestActionTypes.SET_CORRECT_ANSWER_PRE_TEST:
      return { ...state, selectedAnswer: action.payload };

    case PreTestActionTypes.ON_NEXT:
      return {
        ...state,
        questionIndex: state.questionIndex + 1,
        alternativePreTest: "",
        answers: [...state.answers, action.payload.contentAnswer],
      };
    case PreTestActionTypes.DO_NOT_STOP:
      return {
        ...state,
        alternativePreTest: "",
        answers: [...state.answers, action.payload.contentAnswer],
      };
    case PreTestActionTypes.POST_ANSWER_PRE_TEST_REQUEST:
      return {
        ...state,
        inactiveButton: true,
        alternativePreTest: "",
        answers: [...state.answers, action.payload.contentAnswer],
        isLoading: true,
      };
    case PreTestActionTypes.POST_ANSWER_PRE_TEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        answers: [],
      };
    case PreTestActionTypes.POST_ANSWER_PRE_TEST_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case PreTestActionTypes.POST_ANSWER_PRE_TEST_LIFE_SKILLS_REQUEST:
      return {
        ...state,
        inactiveButton: true,
        alternativePreTest: "",
        answers: [...state.answers, action.payload.contentAnswer],
        isLoading: true,
      };
    case PreTestActionTypes.POST_ANSWER_PRE_TEST_LIFE_SKILLS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        answers: [],
      };
    case PreTestActionTypes.POST_ANSWER_PRE_TEST_LIFE_SKILLS_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case PreTestActionTypes.ON_PREVIOUS:
      return {
        ...state,
        questionIndex: state.questionIndex - 1,
        alternativePreTest: "",
        answers: action.payload,
      };

    case PreTestActionTypes.SET_PAGE_NUMBER_PRE_TEST:
      return { ...state, pageNumber: action.payload >= 4 ? 4 : action.payload };

    case PreTestActionTypes.SET_TIMER_PRETEST:
      return { ...state, dateMileseconds: action.payload };

    default:
      return state;
  }
};
export default reducer;
