import {
  Avatar,
  Flex,
  IconButton,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import MenuHeader from "../global/MenuHeader";
import logo from "../../assets/image/logo.png";
import { useNavigate } from "react-router-dom";
import OutlineButton from "../global/OutlineButton";
import { ChevronDown, Menu, X } from "lucide-react";
import MenuDrawer from "../global/MenuDrawer";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";

const HeaderDashboardMobile: React.FC = () => {
  const navigate = useNavigate();
  const { user, student } = useAppSelector((i) => i.auth);
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <Flex
        display={["flex", "none"]}
        flexDir={["column", "row", "row"]}
        alignItems="center"
        py="16px"
        px="32px"
        justify="space-between"
        bg="primary"
      >
        {/* <Image src={logo} onClick={() => navigate("/")} /> */}
        <Flex gap="16px" mt="16px" w="full" justify="space-between">
          <IconButton aria-label="menu" variant="ghost" onClick={onOpen}>
            <Menu color="white" />
          </IconButton>
          <Flex h="auto" px="0">
            <Flex align="center" gap="12px">
              <Avatar name={`${user?.first_name} ${user?.last_name}`} />
              <Flex align="flex-start" flexDir="column" gap="8px">
                <Text fontSize="14px" fontWeight="600" color="white">
                  {user?.first_name}
                </Text>
                <Text
                  fontWeight="500"
                  lineHeight="12px"
                  fontSize="12px"
                  color="white"
                  textTransform="capitalize"
                >
                  {student?.grade} Grade
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <MenuDrawer isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default HeaderDashboardMobile;
