import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";

const RegularButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
  return (
    <Button
      bgColor="primary"
      lineHeight="16px"
      borderRadius="100px"
      padding="10px 24px"
      color="white"
      fontSize="18px"
      h="48px"
      _hover={{ bgColor: "blue.500" }}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default RegularButton;
