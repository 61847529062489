import { Reducer } from "redux";
import { ShortAnswerActionTypes, ShortAnswerState } from "./type";

const INITIAL_STATE: ShortAnswerState = {
  isLoading: false,
  alternativeSA: "",
  answersSA: [],
  dateMileseconds: 0,
  questionIndex: 0,
  shortAnswerData: undefined,
};

const reducer: Reducer<ShortAnswerState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ShortAnswerActionTypes.LOAD_SHORT_ANSWER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ShortAnswerActionTypes.LOAD_SHORT_ANSWER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        shortAnswerData: action.payload,
      };
    case ShortAnswerActionTypes.LOAD_SHORT_ANSWER_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ShortAnswerActionTypes.SET_ALTERNATIVE_SA:
      return { ...state, alternativeSA: action.payload };

    case ShortAnswerActionTypes.ON_NEXT:
      return {
        ...state,
        questionIndex: state.questionIndex + 1,
        alternativeSA: "",
        answersSA: [...state.answersSA, action.payload.contentAnswer],
      };
    case ShortAnswerActionTypes.ON_PREVIOUS:
      return {
        ...state,
        questionIndex: state.questionIndex - 1,
        alternativeSA: "",
        answersSA: action.payload,
      };
    case ShortAnswerActionTypes.POST_SHORT_ANSWER_REQUEST:
      return {
        ...state,
        answersSA: [...state.answersSA, action.payload.contentAnswer],
        isLoading: true,
      };

    case ShortAnswerActionTypes.POST_SHORT_ANSWER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ShortAnswerActionTypes.POST_SHORT_ANSWER_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ShortAnswerActionTypes.SET_TIMER_SA:
      return {
        ...state,
        dateMileseconds: action.payload,
      };
    case ShortAnswerActionTypes.CLEAR_LIST_SA:
      return INITIAL_STATE;

    default:
      return state;
  }
};
export default reducer;
