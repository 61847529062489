import { Flex, Image } from "@chakra-ui/react";
import { ArrowRight } from "lucide-react";
import { FC, useEffect } from "react";
import { HiPhotograph } from "react-icons/hi";
import { BASE_URL_MIDIA } from "../../utils/Utility";
import RegularButton from "../global/RegularButton";
import Text32px from "../global/text/Text32px";
import { assetsBaseUrl } from "../../utils/assets-helper";
import { useLanguageContext } from "../../providers/LanguageProvider";
import { useAccesibiltyContext } from "../../providers/AccessibilityController";

interface FillTheGapProps {
  text: string | undefined;
  image: string | undefined;
  getData: () => void;
  setPage: () => void;
}

const PrintFinalLesson: FC<FillTheGapProps> = ({
  text,
  image,
  getData,
  setPage,
}) => {
  const { language } = useLanguageContext();
  const { onPlayAudio } = useAccesibiltyContext();
  useEffect(() => {
    const playSentence = async () => {
      await onPlayAudio(
        `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/PRINTBOOK.mp3`
      );
    };
    playSentence();
    getData();
  }, []);

  return (
    <Flex align="center" flexDir="column" w="full" flex={1}>
      <Text32px>{text}</Text32px>
      <Image
        borderRadius="16px"
        maxW="583px"
        src={BASE_URL_MIDIA + image}
        mt="40px"
      />
      <Flex mt="70px" gap="40px">
        <RegularButton
          rightIcon={<HiPhotograph />}
          onClick={() => window.print()}
        >
          Print
        </RegularButton>
        <RegularButton rightIcon={<ArrowRight />} onClick={setPage}>
          Next
        </RegularButton>
      </Flex>
    </Flex>
  );
};

export default PrintFinalLesson;
