import {
  AnswerDiagnostic,
  QuestionDiagnostic,
} from "../../../@types/interfaces/api/diagnostic.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";

export enum DiagnosticActionTypes {
  LOAD_QUESTIONS_DIAGNOSTIC_REQUEST = "@diagnostic/LOAD_QUESTIONS_DIAGNOSTIC_REQUEST",
  LOAD_QUESTIONS_DIAGNOSTIC_SUCCESS = "@diagnostic/LOAD_QUESTIONS_DIAGNOSTIC_SUCCESS",
  LOAD_QUESTIONS_DIAGNOSTIC_ERROR = "@diagnostic/LOAD_QUESTIONS_DIAGNOSTIC_ERROR",

  POST_ANSWER_DIAGNOSTIC_REQUEST = "@diagnostic/POST_ANSWER_DIAGNOSTIC_REQUEST",
  POST_ANSWER_DIAGNOSTIC_SUCCESS = "@diagnostic/POST_ANSWER_DIAGNOSTIC_SUCCESS",
  POST_ANSWER_DIAGNOSTIC_ERROR = "@diagnostic/POST_ANSWER_DIAGNOSTIC_ERROR",

  SET_ALTERNATIVE_DIAGNOSTIC = "@diagnostic/ SET_ALTERNATIVE_DIAGNOSTIC",
  SET_ADDITIONAL = "@diagnostic/ SET_ADDITIONAL",
  SET_TIMER_DIAGNOSTIC = "@diagnostic/ SET_TIMER_DIAGNOSTIC",
  SET_DIAGNOSTIC_KEY = "@diagnostic/ SET_DIAGNOSTIC_KEY",
  ON_NEXT = "@diagnostic/ ON_NEXT",
  ON_PREVIOUS = "@diagnostic/ PREVIOUS",
  CLEAR_LIST = "@diagnostic/CLEAR_LIST",

  // SET_AUDIO = "@diagnostic/ SET_AUDIO",
}

export interface DiagnosticState extends DiagnosticStateRequestsStatus {
  questions: PaginationBase<QuestionDiagnostic[]> | undefined;
  answers: AnswerDiagnostic[];
  alternativeDiagnostic: string;
  questionIndex: number;
  selectedAudio: string;
  dateMileseconds: number;
  numberOfCorrectAnswers: number;
  numberOfWrongAnswers: number;
  pageNumber: number;
  diagnosticKey: string;
  additional: string;
}

interface DiagnosticStateRequestsStatus {
  isLoading: boolean;
}
