import { createStandaloneToast } from "@chakra-ui/react";

import theme from "../../../styles/theme";
import axios, { AxiosResponse } from "axios";
import { call, put, select } from "redux-saga/effects";
import api from "../../../services/api";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import { AnyAction } from "redux";
import store, { ApplicationState, getGlobalState } from "..";

import WordVmFilterInterface, {
  IWordVm,
} from "../../../@types/interfaces/api/word-vm.interface";
import {
  HistoryRecognition,
  HistoryRecognitionResponse,
} from "../../../@types/interfaces/api/word-recognition.interface";
import { postProgress } from "../word-recognition/sagas";
import {
  loadUnitReviewFillTheBlankError,
  loadUnitReviewFillTheBlankSuccess,
  postAnswerUnitReviewFillTheBlankError,
  postAnswerUnitReviewFillTheBlankSuccess,
} from "./actions";
import UnitReviewDefinitionFilterInterface, {
  IUnitReviewDefinition,
} from "../../../@types/interfaces/api/unit-review-definiton.interface";
import UnitReviewFillheBlankFilterInterface, {
  IUnitReviewFillTheBlank,
} from "../../../@types/interfaces/api/unit-review-fill-the-blank.interface";

const { toast } = createStandaloneToast({ theme });

export function* loadQuestionUnitReviewFillTheBlank(action: AnyAction) {
  const filters: UnitReviewFillheBlankFilterInterface = action.payload;
  try {
    const response: AxiosResponse<PaginationBase<IUnitReviewFillTheBlank[]>> =
      yield call(api.get, `/unit-review-fill-in-the-blank`, {
        params: filters,
      });
    if (response.status == 200) {
      yield put(loadUnitReviewFillTheBlankSuccess(response.data.results[0]));
    }
  } catch (e: any) {
    yield put(loadUnitReviewFillTheBlankError());
  }
}

export function* postHistoryUnitReview() {
  const data: ApplicationState = yield select(getGlobalState);

  const timeLesson = Date.now() - data.unitReviewFillTheBlank.dateMileseconds;

  const dataHitory: HistoryRecognition = {
    time: timeLesson,
    student: data.auth.student?.user,
  };

  try {
    const response: AxiosResponse = yield call(
      api.post,
      "/history",
      dataHitory
    );
    if (response.status == 201) {
      return response.data;
    }
  } catch (e: any) {
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* postAnswerUnitReviewFillTheBlank(action: AnyAction) {
  const data: ApplicationState = yield select(getGlobalState);
  const setPage = action.payload.setPage;
  const activity =
    data.unitReviewFillTheBlank.unitReviewFillTheBlankData?.activity;

  try {
    const responseHistory: HistoryRecognitionResponse = yield call(
      postHistoryUnitReview
    );

    const completeData = data.unitReviewFillTheBlank.answers.map((answer) => {
      return { ...answer, history: responseHistory.id };
    });

    const response: AxiosResponse = yield call(
      api.post,
      "/unit-review-fill-in-the-blank/answer",
      completeData
    );
    if (response.status === 201) {
      yield put(postAnswerUnitReviewFillTheBlankSuccess());
      yield call(postProgress, activity);
    }
  } catch (e: any) {
    yield put(postAnswerUnitReviewFillTheBlankError());
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}
