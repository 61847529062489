import { Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ContainerLesson from "../../components/global/ContainerLesson";
import PageGrid from "../../components/global/PageGrid";
import ProgressBar from "../../components/global/ProgressBar";
import StartLesson from "../../components/lessons/common/StartLesson";
import { usePages } from "../../hooks/usePages";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  clearPreTestList,
  loadPreTestRequest,
  onNexPreTest,
  onPreviousPreTest,
  setAlternativePreTest,
  setCorrectAnswerPreTest,
  setfULLAlternativePreTest,
  setTimerPreTest,
} from "../../stores/ducks/pre-test/actions";
import FillTheGap from "../../components/lessons/FillTheGap";
import { useNavigate } from "react-router-dom";
import DonNotStop from "../../components/lessons/common/DoNotStop";
import GreatJob from "../../components/lessons/common/GreatJob";
import { urlsPaths } from "../../utils/Utility";
import { assetsBaseUrl } from "../../utils/assets-helper";
import { useAccesibiltyContext } from "../../providers/AccessibilityController";
import LaunchLesson from "../../components/lessons/common/LaunchLesson";
import SubtitleComponent from "../../components/global/SubtitleComponent";

const PreTest = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    preTestData,
    questionIndex,
    alternativePreTest,
    pageNumber,
    inactiveButton,
    isLoading,
    numberOfWrongAnswers,
    storyOrder,
  } = useAppSelector((item) => item.preTest);
  const { nextLessonFullData, activityData } = useAppSelector(
    (item) => item.dashboard
  );

  const { setVideoWindow, captionText, accessibility } =
    useAccesibiltyContext();

  const { currentLesson, inAudio } = useAppSelector((item) => item.lesson);

  const { student } = useAppSelector((item) => item.auth);

  const questionsArray = preTestData?.results[0].questions;

  const text = preTestData?.results[0].questions[questionIndex].text;

  const formatedText = text?.replace(/_+/, alternativePreTest);

  const isLifeSkills =
    currentLesson &&
    activityData?.lesson.story.level.program.short_name === "LF";

  const setAlternativeFn = (alternative: string) => {
    dispatch(setAlternativePreTest(alternative));
    if (text) {
      const full = text.replace(/_+/, alternative);
      dispatch(setfULLAlternativePreTest(full));
    }
  };

  useEffect(() => {
    if (formatedText) {
      dispatch(setCorrectAnswerPreTest(formatedText));
    }
  }, [formatedText]);

  const pageNumberPT = parseInt(localStorage.getItem("pageNumberPT") || "0");

  useEffect(() => {
    setVideoWindow(true);
    dispatch(setTimerPreTest(Date.now()));
    if (student?.in_diagnostic_part_2) {
      dispatch(
        loadPreTestRequest({
          activity__lesson__story__level__id: pageNumberPT
            ? pageNumberPT
            : pageNumber,
          activity__lesson__story__story_order: storyOrder,
        })
      );
    }
    if (isLifeSkills) {
      dispatch(
        loadPreTestRequest({
          activity__id: currentLesson.id,
        })
      );
      return;
    }
    if (currentLesson && nextLessonFullData) {
      localStorage.setItem("pageNumberPT", "1");
      dispatch(
        loadPreTestRequest({
          activity__lesson__story__story_order:
            nextLessonFullData.lesson.story.story_order,
          activity__id: currentLesson.id,
        })
      );
    }

    return () => {
      setVideoWindow(false);
      dispatch(clearPreTestList());
    };
  }, []);

  const initialPage = student?.progress.finished ? "start" : "accessibility";

  const { page, setPage, pageName } = usePages<
    "start" | "content" | "do_not_stop" | "greatJob" | "accessibility"
  >({
    initialPage,
    pages: {
      accessibility: <LaunchLesson onNext={() => setPage("start")} />,
      start: (
        <StartLesson
          title={isLifeSkills ? "Welcome Life Skills" : "Pre-test"}
          onNext={() => setPage("content")}
          inAudio={inAudio}
          button="Start"
          introduction={
            isLifeSkills
              ? [
                  `${assetsBaseUrl()}/app-2.0/jr4_html5/audio/ENG/dirs_mp3/WELCPRETEST.mp3`,
                  `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/in56.ogg`,
                ]
              : [`${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in1.ogg`]
          }
        />
      ),
      content: questionsArray && (
        <FillTheGap
          alternativeData={alternativePreTest}
          question={questionsArray[questionIndex].text}
          setAlternativeFn={setAlternativeFn}
          optionsRA={questionsArray[questionIndex].alternatives}
          inactiveButton={inactiveButton}
          questionIndex={questionIndex}
          audioIntro={
            isLifeSkills
              ? [
                  `${assetsBaseUrl()}/app-2.0/jr4_html5/audio/ENG/dirs_mp3/FILLIN.mp3`,
                  `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/in57.ogg`,
                ]
              : [
                  `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/FILLIN.mp3`,
                  `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in2.ogg`,
                  `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in3.ogg`,
                ]
          }
        />
      ),
      do_not_stop: (
        <DonNotStop
          onNext={() => {
            dispatch(
              loadPreTestRequest({
                activity__lesson__story__level__id: pageNumberPT
                  ? pageNumberPT
                  : pageNumber,
                activity__lesson__story__story_order: storyOrder,
              })
            );
            currentLesson && pageNumberPT >= 4
              ? navigate(`/lesson/${urlsPaths[currentLesson?.mechanic_type]}`)
              : setPage("content");
          }}
        />
      ),
      greatJob: (
        <GreatJob
          onNext={() => {
            if (currentLesson) {
              navigate(`/lesson/${urlsPaths[currentLesson?.mechanic_type]}`);
            } else if (pageNumber >= 4 && numberOfWrongAnswers < 4) {
              navigate("/lesson/pre-test-verbal-master");
            }
          }}
        />
      ),
    },
  });

  return (
    <PageGrid
      activity="PreTest"
      lesson="Joseph's Readers"
      onHelp={() => {}}
      disabledButton={!isLifeSkills || pageName === "accessibility"}
      audioHelp={
        isLifeSkills
          ? [
              `${assetsBaseUrl()}/app-2.0/jr4_html5/audio/ENG/dirs_mp3/FILLIN.mp3`,
              `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/in57.ogg`,
            ]
          : [""]
      }
    >
      {questionsArray && (
        <ContainerLesson>
          {page}
          <Flex flexDir="column" gap="4px">
            <SubtitleComponent
              hide={
                !accessibility.subtitles ||
                !inAudio ||
                captionText === "" ||
                pageName === "greatJob"
              }
            />
            <ProgressBar
              hide={pageName !== "content"}
              data={{ max: questionsArray.length, step: questionIndex + 1 }}
              onConfirm={() => dispatch(onNexPreTest(setPage))}
              onPrevious={() => dispatch(onPreviousPreTest())}
              hideButton={alternativePreTest === ""}
            />
          </Flex>
        </ContainerLesson>
      )}
    </PageGrid>
  );
};

export default PreTest;
