import store from "..";
import AnyAction from "redux";
import { UnitReviewDefinitionActionTypes } from "./type";
import UnitReviewDefinitionFilterInterface, {
  AnswerUnitReviewDefinition,
  IUnitReviewDefinition,
} from "../../../@types/interfaces/api/unit-review-definiton.interface";

export const loadUnitReviewDefinitionRequest = (
  filter: UnitReviewDefinitionFilterInterface
) => ({
  type: UnitReviewDefinitionActionTypes.LOAD_QUESTION_UNIT_REVIEW_DEFINITION_REQUEST,
  payload: filter,
});

export const loadUnitReviewDefinitionSuccess = (
  data: IUnitReviewDefinition
) => {
  return {
    type: UnitReviewDefinitionActionTypes.LOAD_QUESTION_UNIT_REVIEW_DEFINITION_SUCCESS,
    payload: data,
  };
};
export const loadUnitReviewDefinitionError = () => ({
  type: UnitReviewDefinitionActionTypes.LOAD_QUESTION_UNIT_REVIEW_DEFINITION_ERROR,
});

export const clearUnitReviewDefinitionList = () => {
  return {
    type: UnitReviewDefinitionActionTypes.CLEAR_LIST,
  };
};

export const setAnswerUnitReviewDefinition = (alternative: string) => {
  const contentListAnswer = store.getState().unitReviewDefinition;
  const question =
    contentListAnswer.unitReviewDefinitionData?.words[
      contentListAnswer.questionIndex
    ].id;
  const contentAnswer = {
    answer: alternative,
    question,
    history: 0,
  };
  return {
    type: UnitReviewDefinitionActionTypes.SET_ANSWER_UNIT_REVIEW_DEFINITION,
    payload: contentAnswer,
  };
};

export const onNextUnitReviewDefinition1 = (setPage: (page: any) => void) => {
  const contentListAnswer = store.getState().unitReviewDefinition;

  if (
    contentListAnswer.unitReviewDefinitionData &&
    contentListAnswer.questionIndex <
      contentListAnswer.unitReviewDefinitionData.words.length - 1
  ) {
    return {
      type: UnitReviewDefinitionActionTypes.ON_NEXT,
      payload: { setPage },
    };
  } else {
    setPage("dragToDefinitionExercise");
    return {
      type: UnitReviewDefinitionActionTypes.RESET_INDEX,
    };
  }
};
export const onNextUnitReviewDefinition2 = (setPage: (page: any) => void) => {
  const contentListAnswer = store.getState().unitReviewDefinition;

  if (
    contentListAnswer.unitReviewDefinitionData &&
    contentListAnswer.questionIndex <
      contentListAnswer.unitReviewDefinitionData.words.length - 1
  ) {
    return {
      type: UnitReviewDefinitionActionTypes.ON_NEXT_2,
      payload: { setPage },
    };
  } else {
    return {
      type: UnitReviewDefinitionActionTypes.POST_ANSWER_UNIT_REVIEW_DEFINITION_REQUEST,
      payload: { setPage },
    };
  }
};

export const postAnswerUnitReviewDefinitionRequest = (
  data: AnswerUnitReviewDefinition[]
) => {
  return {
    type: UnitReviewDefinitionActionTypes.POST_ANSWER_UNIT_REVIEW_DEFINITION_REQUEST,
    payload: data,
  };
};

export const postAnswerUnitReviewDefinitionSuccess = () => ({
  type: UnitReviewDefinitionActionTypes.POST_ANSWER_UNIT_REVIEW_DEFINITION_SUCCESS,
});
export const postAnswerUnitReviewDefinitionError = () => ({
  type: UnitReviewDefinitionActionTypes.POST_ANSWER_UNIT_REVIEW_DEFINITION_ERROR,
});

export const setTimerUnitReviewDefinition = (date: number) => {
  return {
    type: UnitReviewDefinitionActionTypes.SET_TIMER_UNIT_REVIEW_DEFINITION,
    payload: date,
  };
};
// export const onPreviousUnitReviewDefinition = () => {
//   const contentListAnswer = store.getState().wordVm;
//   const answerArray = contentListAnswer.answers;

//   answerArray.pop();

//   return {
//     type: UnitReviewDefinitionActionTypes.ON_PREVIOUS,
//     payload: answerArray,
//   };
// };
