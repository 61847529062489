import { Flex, Heading, Text } from "@chakra-ui/react";
import { ArrowRight } from "lucide-react";
import React, { useEffect, useState } from "react";
import GreatJobIcon from "../../../assets/icons/GreatJobIcon";
import ButtonRadio from "../../global/ButtonRadio";
import RegularButton from "../../global/RegularButton";
import Text32px from "../../global/text/Text32px";
import { useLanguageContext } from "../../../providers/LanguageProvider";
import { LanguageType } from "../../../providers/LanguageProvider";
import { assetsBaseUrl } from "../../../utils/assets-helper";
import { useAccesibiltyContext } from "../../../providers/AccessibilityController";
import { useAppSelector } from "../../../hooks/redux";

interface LanguageProps {
  onNext: () => void;
}
interface LanguageArray {
  id: LanguageType;
  name: string;
}
const languageArray: LanguageArray[] = [
  { id: "ENG", name: "English" },
  { id: "SP", name: "Espanol" },
];

const Language: React.FC<LanguageProps> = ({ onNext }) => {
  const [selectedLanguage, setSelectedLanguage] = useState<number | null>(null);
  const { language, changeLanguage } = useLanguageContext();
  const { onPlayAudio } = useAccesibiltyContext();
  const { inAudio } = useAppSelector((i) => i.lesson);

  const onClick = (id: LanguageType, index: number) => {
    changeLanguage(id);
    setSelectedLanguage(index);
    const audio =
      id === "ENG"
        ? `${assetsBaseUrl()}/app-2.0/dp2_html5/audio/dirsEnglish.mp3`
        : `${assetsBaseUrl()}/app-2.0/dp2_html5/audio/dirsSpanish.mp3`;
    onPlayAudio(audio);
  };

  useEffect(() => {
    onPlayAudio(`${assetsBaseUrl()}/app-2.0/dp2_html5/audio/dirsLang.mp3`);
    return () => {
      onPlayAudio("");
    };
  }, []);

  return (
    <Flex flexDir="column" align="center" mt="80px">
      <Text32px mt="32px">Choose a language</Text32px>
      <Flex gap="24px" mt="32px" flexDir={["column", "row"]}>
        {languageArray.map((item, index) => (
          <ButtonRadio
            key={item.id}
            isSelected={selectedLanguage === index}
            text={item.name}
            handleClick={() => onClick(item.id, index)}
          />
        ))}
      </Flex>
      <RegularButton
        rightIcon={<ArrowRight />}
        mt="56px"
        onClick={onNext}
        disabled={selectedLanguage === null || inAudio}
        isLoading={inAudio}
      >
        Next
      </RegularButton>
    </Flex>
  );
};

export default Language;
