import store from "..";
import AnyAction from "redux";
import DiagnosticFilterInterface, {
  AnswerDiagnostic,
  QuestionDiagnostic,
} from "../../../@types/interfaces/api/diagnostic.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import {
  PreTestFilterInterface,
  AnswerPretest,
  IPretest,
} from "../../../@types/interfaces/api/pre-test.interface";
import { PreTestActionTypes } from "./type";

export const loadPreTestRequest = (filter: PreTestFilterInterface) => ({
  type: PreTestActionTypes.LOAD_QUESTIONS_PRE_TEST_REQUEST,
  payload: filter,
});

export const loadPreTestSuccess = (questions: PaginationBase<IPretest>) => ({
  type: PreTestActionTypes.LOAD_QUESTIONS_PRE_TEST_SUCCESS,
  payload: questions,
});
export const loadPreTestError = () => ({
  type: PreTestActionTypes.LOAD_QUESTIONS_PRE_TEST_ERROR,
});

export const clearPreTestList = () => {
  return {
    type: PreTestActionTypes.CLEAR_LIST,
  };
};

export const setAlternativePreTest = (alternative: string) => {
  return {
    type: PreTestActionTypes.SET_ALTERNATIVE_PRE_TEST,
    payload: alternative,
  };
};
export const setfULLAlternativePreTest = (alternative: string) => {
  return {
    type: PreTestActionTypes.SET_FULL_ALTERNATIVE_PRE_TEST,
    payload: alternative,
  };
};

export const setCorrectAnswerPreTest = (alternative: string) => {
  return {
    type: PreTestActionTypes.SET_CORRECT_ANSWER_PRE_TEST,
    payload: alternative,
  };
};
export const postAnswerPreTestRequest = (data: AnswerPretest[]) => ({
  type: PreTestActionTypes.POST_ANSWER_PRE_TEST_REQUEST,
  payload: data,
});

export const postAnswerPreTestSuccess = () => ({
  type: PreTestActionTypes.POST_ANSWER_PRE_TEST_SUCCESS,
});
export const postAnswerPreTestError = () => ({
  type: PreTestActionTypes.POST_ANSWER_PRE_TEST_ERROR,
});
export const postAnswerPreTestLifeSkillsRequest = (data: AnswerPretest[]) => ({
  type: PreTestActionTypes.POST_ANSWER_PRE_TEST_LIFE_SKILLS_REQUEST,
  payload: data,
});

export const postAnswerPreTestLifeSkillsSuccess = () => ({
  type: PreTestActionTypes.POST_ANSWER_PRE_TEST_LIFE_SKILLS_SUCCESS,
});
export const postAnswerPreTestLifeSkillsError = () => ({
  type: PreTestActionTypes.POST_ANSWER_PRE_TEST_LIFE_SKILLS_ERROR,
});

export const setPageNumberPreTest = (page: number) => {
  localStorage.setItem("pageNumberPT", page.toString());
  return {
    type: PreTestActionTypes.SET_PAGE_NUMBER_PRE_TEST,
    payload: page,
  };
};

export const onNexPreTest = (setPage: (page: any) => void) => {
  const contentListAnswer = store.getState().preTest;
  const { activityData } = store.getState().dashboard;
  const { student } = store.getState().auth;

  const questionId =
    contentListAnswer.preTestData?.results[0].questions[
      contentListAnswer.questionIndex
    ].id;

  const contentAnswer = {
    answer: contentListAnswer.fullAlternative,
    question: questionId,
    history: 0,
  };

  const correctAnswer =
    contentListAnswer.preTestData?.results[0].questions[
      contentListAnswer.questionIndex
    ].answer;
  const selectedAnswer = contentListAnswer.selectedAnswer;

  if (correctAnswer !== selectedAnswer) {
    contentListAnswer.numberOfWrongAnswers =
      contentListAnswer.numberOfWrongAnswers + 1;
  } else {
    contentListAnswer.numberOfCorrectAnswers =
      contentListAnswer.numberOfCorrectAnswers + 1;
  }

  const isNotEndOfQuestion: boolean =
    (contentListAnswer.preTestData?.results[0].questions.length ?? 1) - 1 !==
    contentListAnswer.questionIndex;

  if (isNotEndOfQuestion) {
    return {
      type: PreTestActionTypes.ON_NEXT,
      payload: { contentAnswer, setPage },
    };
  }
  if (activityData?.lesson.story.level.program.short_name === "LF") {
    // setPage("greatJob");
    return {
      type: PreTestActionTypes.POST_ANSWER_PRE_TEST_LIFE_SKILLS_REQUEST,
      payload: { contentAnswer, setPage },
    };
  }

  if (
    contentListAnswer.preTestData &&
    contentListAnswer.numberOfWrongAnswers >= 4
  ) {
    // setPage("greatJob");
    return {
      type: PreTestActionTypes.POST_ANSWER_PRE_TEST_REQUEST,
      payload: { contentAnswer, setPage },
    };
  } else {
    let pageNumberPT = parseInt(localStorage.getItem("pageNumberPT") || "0");
    //verify is user to be in sublevels orange[a], orange[b]...
    if (
      contentListAnswer.pageNumber === 3 &&
      contentListAnswer.storyOrder === 1
    ) {
      contentListAnswer.pageNumber = 3;
      localStorage.setItem(
        "pageNumberPT",
        contentListAnswer.pageNumber.toString()
      );
      contentListAnswer.storyOrder = 6;
    } else if (
      contentListAnswer.pageNumber === 3 &&
      contentListAnswer.storyOrder === 6
    ) {
      contentListAnswer.pageNumber = 4;
      localStorage.setItem(
        "pageNumberPT",
        contentListAnswer.pageNumber.toString()
      );
      contentListAnswer.storyOrder = 1;
    } else if (
      contentListAnswer.pageNumber === 4 &&
      contentListAnswer.storyOrder === 1
    ) {
      contentListAnswer.pageNumber = 4;
      localStorage.setItem(
        "pageNumberPT",
        contentListAnswer.pageNumber.toString()
      );
      contentListAnswer.storyOrder = 4;
    } else if (
      contentListAnswer.pageNumber === 4 &&
      contentListAnswer.storyOrder === 4
    ) {
      contentListAnswer.pageNumber = 4;
      localStorage.setItem(
        "pageNumberPT",
        contentListAnswer.pageNumber.toString()
      );
      contentListAnswer.storyOrder = 6;
    } else if (
      contentListAnswer.pageNumber === 4 &&
      contentListAnswer.storyOrder === 6
    ) {
      contentListAnswer.pageNumber = 5;
      localStorage.setItem(
        "pageNumberPT",
        contentListAnswer.pageNumber.toString()
      );
      contentListAnswer.storyOrder = 1;
    } else {
      contentListAnswer.pageNumber = pageNumberPT >= 5 ? 4 : pageNumberPT + 1;
      localStorage.setItem(
        "pageNumberPT",
        contentListAnswer.pageNumber.toString()
      );
    }

    if (student?.progress.activity) {
      return {
        type: PreTestActionTypes.POST_ANSWER_PRE_TEST_REQUEST,
        payload: { contentAnswer, setPage },
      };
    }

    if (!isNotEndOfQuestion && contentListAnswer.pageNumber >= 5) {
      return {
        type: PreTestActionTypes.POST_ANSWER_PRE_TEST_REQUEST,
        payload: { contentAnswer, setPage },
      };
    }

    return {
      type: PreTestActionTypes.POST_ANSWER_PRE_TEST_REQUEST,
      payload: { contentAnswer, setPage },
    };
  }
};

export const onPreviousPreTest = () => {
  const contentListAnswer = store.getState().preTest;
  const answerArray = contentListAnswer.answers;

  answerArray.pop();

  return {
    type: PreTestActionTypes.ON_PREVIOUS,
    payload: answerArray,
  };
};

export const setTimerPreTest = (date: number) => {
  return {
    type: PreTestActionTypes.SET_TIMER_PRETEST,
    payload: date,
  };
};
