import { Flex, Text } from "@chakra-ui/react";
import ContainerLesson from "../../components/global/ContainerLesson";
import PageGrid from "../../components/global/PageGrid";
import ProgressBar from "../../components/global/ProgressBar";
import BookSentence from "../../components/lessons/BookSentence";
import StartLesson from "../../components/lessons/common/StartLesson";
import { usePages } from "../../hooks/usePages";
import imgTier from "../../assets/image/Tier.png";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  loadTalkingBookRequest,
  postTalkingBookRequest,
  setTimerTB,
} from "../../stores/ducks/talking-book/actions";
import { useNavigate } from "react-router-dom";
import GreatJob from "../../components/lessons/common/GreatJob";
import { urlsPaths } from "../../utils/Utility";
import { assetsBaseUrl } from "../../utils/assets-helper";
import { loadLessonIDRequest } from "../../stores/ducks/lesson/action";
import LaunchLesson from "../../components/lessons/common/LaunchLesson";
import useMediaPlayer from "../../hooks/useMediaPlayer";
import { useAccesibiltyContext } from "../../providers/AccessibilityController";
import SubtitleComponent from "../../components/global/SubtitleComponent";
import { error } from "console";

const TalkingBook = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { book } = useAppSelector((b) => b.talkingBook);
  const { student } = useAppSelector((b) => b.auth);
  const { setVideoWindow, accessibility, captionText } =
    useAccesibiltyContext();
  const {
    currentLesson,
    activeButtonHelp,
    lessonIdData,
    currentProgram,
    inAudio,
  } = useAppSelector((b) => b.lesson);
  const audioIntro = [
    `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/TBPLAYPAGE.mp3`,
    `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in37.ogg`,
  ];
  const isLifeSkills = currentProgram === "LF";

  useEffect(() => {
    setVideoWindow(true);
    if (currentLesson) {
      dispatch(loadTalkingBookRequest({ activity__id: currentLesson.id }));
    }
    dispatch(setTimerTB(Date.now()));
    return () => {
      setVideoWindow(false);
    };
  }, []);

  const initialPage = student?.progress.finished ? "start" : "accessibility";

  const { page, setPage, pageName } = usePages<
    "start" | "content" | "greatJob" | "accessibility"
  >({
    initialPage,
    pages: {
      accessibility: <LaunchLesson onNext={() => setPage("start")} />,
      start: book && (
        <StartLesson
          title="Talking book"
          onNext={() => setPage("content")}
          inAudio={inAudio}
          introduction={
            isLifeSkills
              ? [
                  `${assetsBaseUrl()}/app-2.0/jr4_html5/audio/ENG/dirs_mp3/WELTBOOK.mp3`,
                  `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/in36.ogg`,
                ]
              : [
                  `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/WELTBOOK.mp3`,
                  `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in36.ogg`,
                ]
          }
          videoExplanationUrl={
            isLifeSkills
              ? `${assetsBaseUrl()}${book.results[0].video_intro}`
              : ""
          }
        />
      ),
      content: (
        <BookSentence
          audioIntro={audioIntro}
          book={book?.results[0]}
          onNext={() => dispatch(postTalkingBookRequest(setPage))}
        />
      ),
      greatJob: (
        <GreatJob
          onNext={() => {
            if (currentLesson) {
              navigate(`/lesson/${urlsPaths[currentLesson?.mechanic_type]}`);
            }
          }}
        />
      ),
    },
  });

  return (
    <PageGrid
      activity="TalkingBook"
      lesson="Joseph's Readers"
      onHelp={() => {}}
      audioHelp={[
        `${assetsBaseUrl()}/app-2.0/jr_html5/audio/ENG/dirs_mp3/WRECDIR.mp3`,
        `${assetsBaseUrl()}/app-2.0/jr_html5/vsubt/eng/ogg/in37.ogg`,
      ]}
      disabledButton={
        pageName === "start" ||
        pageName === "greatJob" ||
        pageName === "accessibility" ||
        inAudio
      }
    >
      <ContainerLesson>
        {page}{" "}
        <SubtitleComponent
          hide={
            !accessibility.subtitles ||
            captionText === "" ||
            pageName !== "start"
          }
        />
      </ContainerLesson>
    </PageGrid>
  );
};

export default TalkingBook;
