import { Button, Flex, Image, Text } from "@chakra-ui/react";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import PlayIcon from "../../assets/icons/PlayIcon";
import RegularButton from "../global/RegularButton";
import Subtitle from "../global/text/Subtitle";
import Text32px from "../global/text/Text32px";
import { useLanguageContext } from "../../providers/LanguageProvider";
import { useAccesibiltyContext } from "../../providers/AccessibilityController";

export interface StoryFeed {
  type: "audio" | "text" | "image";
  content: string;
  contentArray?: string[];
}
interface Props {
  story: StoryFeed[];
  onFinish?: () => Promise<void>;
}
const LessonSpelling = (props: Props) => {
  const { story, onFinish } = props;
  const { language } = useLanguageContext();
  const { playMedias } = useAccesibiltyContext();

  const [feedNumber, setFeedNumber] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [pageContent, setPageContent] = useState({
    audio: "",
    text: "",
    image: "",
  });

  const nextFeed = useCallback(() => {
    if (feedNumber < story.length - 1) {
      setFeedNumber((prev) => prev + 1);
    }
  }, [feedNumber, story]);

  const repeatText = async () => {
    if (feedNumber >= story.length - 1) {
      const feed = story[feedNumber];
      if (feed.type === "audio" && feed.contentArray) {
        await playMedias(feed.contentArray);
        // console.log(feed.content);
      } else {
        const audioWord = story[story.length - 2];
        if (audioWord.contentArray) await playMedias(audioWord.contentArray);
      }
    }
  };

  useEffect(() => {
    async function precess() {
      const feed = story[feedNumber];
      if (feed.type === "audio" && feed.contentArray) {
        await playMedias(feed.contentArray);
        nextFeed();
      }
      if (feed.type === "text") {
        setPageContent((prev) => ({ ...prev, text: feed.content }));
        nextFeed();
      }
      if (feed.type === "image") {
        setPageContent((prev) => ({ ...prev, image: feed.content }));
        nextFeed();
      }
    }
    precess();
    if (feedNumber >= story.length - 1) {
      setDisabled(true);
    }
  }, [feedNumber, nextFeed, story]);

  async function nextLesson() {
    if (feedNumber >= story.length - 1) {
      onFinish && (await onFinish());
      resetLesson();
    }
  }

  function resetLesson() {
    setFeedNumber(0);
    setPageContent({
      audio: "",
      image: "",
      text: "",
    });
  }

  return (
    <Flex flexDir={"column"} align="center">
      <Subtitle>STUDY THE SPELLING</Subtitle>
      <Text32px mt="16px">Spelling Program</Text32px>

      <Flex align="center" mt="56px" gap="24px" flexDir={["column", "row"]}>
        {disabled && <PlayIcon onClick={repeatText} />}
        <Flex
          minH="80px"
          minW="100px"
          p="16px"
          bg="white"
          borderRadius="16px"
          border="1.5px solid #CBD5E0"
        >
          <Text32px color="primary">{pageContent.text}</Text32px>
        </Flex>
        {disabled && (
          <RegularButton
            rightIcon={<ArrowRight size="16px" />}
            onClick={nextLesson}
          >
            Continue
          </RegularButton>
        )}
      </Flex>

      {pageContent.image && (
        <Image
          mt="56px"
          maxW="600px"
          borderRadius="16px"
          boxShadow="8px 16px 20px rgba(119, 119, 119, 0.137)"
          src={pageContent.image}
        />
      )}
    </Flex>
  );
};

export default LessonSpelling;
