import {
  IAlphabetical,
  IAlphabeticalAnswerPost,
} from "../../../@types/interfaces/api/alphabetical-order.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import {
  IScrambled,
  IScrambledAnswerPost,
} from "../../../@types/interfaces/api/scrambled-sentence.interface";

export enum AlphabeticalOrderActionTypes {
  LOAD_ALPHABETICAL_REQUEST = "@alphabetical/LOAD_ALPHABETICAL_REQUEST",
  LOAD_ALPHABETICAL_SUCCESS = "@alphabetical/LOAD_ALPHABETICAL_SUCCESS",
  LOAD_ALPHABETICAL_ERROR = "@alphabetical/LOAD_ALPHABETICAL_ERROR",

  POST_ALPHABETICAL_REQUEST = "@alphabetical/POST_ALPHABETICAL_REQUEST",
  POST_ALPHABETICAL_SUCCESS = "@alphabetical/POST_ALPHABETICAL_SUCCESS",
  POST_ALPHABETICAL_ERROR = "@alphabetical/POST_ALPHABETICAL_ERROR",

  SET_ANSWER_ALPHABETICAL = "@alphabetical/ SET_ANSWER_ALPHABETICAL",
  CLEAR_LIST_ALPHABETICAL = "@alphabetical/ CLEAR_LIST_ALPHABETICAL",
  SET_TIMER_ALPHABETICAL = "@alphabetical/ SET_TIMER_ALPHABETICAL",
  SET_WRONG_ANSWER_ALPHABETICAL = "@alphabetical/ SET_WRONG_ANSWER_ALPHABETICAL",
}
export interface AlphabeticalOrderState
  extends AlphabeticalOrderStateRequestsStatus {
  alphabeticalData: PaginationBase<IAlphabetical[]> | undefined;
  answerAlphabetical: IAlphabeticalAnswerPost[];
  wrongAnswer: number;
  dateMileseconds: number;
}

interface AlphabeticalOrderStateRequestsStatus {
  isLoading: boolean;
}
