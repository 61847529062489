export function getCaptionText(audiourl: string): string {
  const captions = new Map<string, string>([
    ["jr_html5/audio/ENG/dirs_mp3/TERRIFIC.mp3", "Terrific"],
    ["jr_html5/audio/ENG/dirs_mp3/FANTAST.mp3", "Fantastic"],
    ["jr_html5/vsubt/eng/ogg/in67.ogg", "Fantastic"],
    ["jr_html5/vsubt/eng/ogg/in69.ogg", "Great Job"],
    ["jr_html5/audio/ENG/dirs_mp3/EXCELLNT.mp3", "Excellent"],
    ["jr_html5/audio/ENG/dirs_mp3/PTSORRY.mp3", "Sorry, that was not correct"],
    ["jr_html5/vsubt/eng/ogg/in74.ogg", "Sorry, that was not correct"],
    ["jr_html5/audio/ENG/dirs_mp3/THEPHRSE.mp3", "The phrase"],
    ["jr_html5/vsubt/eng/ogg/in12.ogg", "The phrase"],
    ["ras/sounds/sound_students/great_job.mp3", "Great Job!"],
    [
      "jr_html5/audio/ENG/dirs_mp3/PTWCONT.mp3",
      "Please click on the Forward Arrow to continue with this lesson or click on the Backward Arrow to hear this sentence again.",
    ],
    [
      "jr_html5/audio/ENG/dirs_mp3/PTPHCONT.mp3",
      "Please click on the Forward Arrow to continue with this lesson or click on the Backward Arrow to hear this sentence again.",
    ],
    [
      "jr_html5/audio/ENG/dirs_mp3/QNA4.mp3",
      "You'll see a question. The program will read the question so you can hear it.",
    ],
    [
      "jr_html5/vsubt/eng/ogg/in28.ogg",
      "You'll see a question. The program will read the question so you can hear it.",
    ],
    [
      "jr_html5/audio/ENG/dirs_mp3/PTEACH_INTRO.mp3",
      "Welcome to the Joseph's Readers Pre-Teaching Lesson. The Pre-Teaching Lesson is used to show the words that you will see in this part of the story. We will introduce the words to you, define them and spell them. Becoming familiar with these words will help you improve your language. And improving your language will help you become a better reader.",
    ],
    [
      "jr_html5/vsubt/eng/ogg/in8.ogg",
      "Welcome to the Joseph's Readers Pre-Teaching Lesson. The Pre-Teaching Lesson is used to show the words that you will see in this part of the story. We will introduce the words to you, define them and spell them. Becoming familiar with these words will help you improve your language. And improving your language will help you become a better reader.",
    ],
    [
      "jr_html5/vsubt/eng/ogg/in26.ogg",
      "Welcome to the question and answer section of the Pre Teaching lesson.",
    ],
    [
      "jr_html5/audio/ENG/dirs_mp3/QNA1.mp3",
      "Welcome to the question and answer section of the Pre Teaching lesson.",
    ],
    [
      "jr_html5/audio/ENG/dirs_mp3/QNA2.mp3",
      "If you already know how to use this part of the program click on the Skip button.",
    ],
    [
      "jr_html5/audio/ENG/dirs_mp3/QNA3.mp3",
      "We will ask you some questions about what you've just read. Don't be nervous, just listen carefully and all will be just fine.",
    ],
    [
      "jr_html5/vsubt/eng/ogg/in27.ogg",
      "We will ask you some questions about what you've just read. Don't be nervous, just listen carefully and all will be just fine.",
    ],
    [
      "jr_html5/audio/ENG/dirs_mp3/QNA5.mp3",
      "If you need to hear the question again you can press on the 'Question' button.",
    ],
    [
      "jr_html5/vsubt/eng/ogg/in29.ogg",
      "If you need to hear the question again you can press on the 'Question' button.",
    ],
    [
      "jr_html5/audio/ENG/dirs_mp3/QNA6.mp3",
      "After you hear the question you should select the choice that best answers the question. Click on one of the three answer choices.",
    ],
    [
      "jr_html5/vsubt/eng/ogg/in30.ogg",
      "After you hear the question you should select the choice that best answers the question. Click on one of the three answer choices.",
    ],
    [
      "jr_html5/audio/ENG/dirs_mp3/QNA7.mp3",
      "When you click on answer choice it will appear in the answer box.",
    ],
    [
      "jr_html5/vsubt/eng/ogg/in31.ogg",
      "When you click on answer choice it will appear in the answer box.",
    ],
    [
      "jr_html5/audio/ENG/dirs_mp3/QNA8.mp3",
      "To check your answer click on the 'OK' button.",
    ],
    [
      "jr_html5/vsubt/eng/ogg/in32.ogg",
      "To check your answer click on the 'OK' button.",
    ],
    [
      "jr_html5/audio/ENG/dirs_mp3/QNA9.mp3",
      "If you need to review these directions click on the question mark '?' button.",
    ],
    [
      "jr_html5/vsubt/eng/ogg/in33.ogg",
      "If you need to review these directions click on the question mark '?' button.",
    ],
    [
      "jr_html5/audio/ENG/dirs_mp3/1STWORD.mp3",
      "The first important vocabulary word for this story is",
    ],
    [
      "jr_html5/audio/ENG/dirs_mp3/NEXTWORD.mp3",
      "The next important vocabulary word for this story is",
    ],
    [
      "jr_html5/vsubt/eng/ogg/in9.ogg",
      "The first important vocabulary word for this story is",
    ],
    [
      "jr_html5/vsubt/eng/ogg/in11.ogg",
      "The next important vocabulary word for this story is",
    ],
    [
      "jr_html5/audio/ENG/dirs_mp3/PTWCONT.mp3",
      "Please click on the forward arrow to continue with this lesson or click on the backward arrow to hear this word again.",
    ],
    [
      "jr_html5/vsubt/eng/ogg/in10.ogg",
      "Please click on the forward arrow to continue with this lesson or click on the backward arrow to hear this word again.",
    ],
    ["jr_html5/audio/ENG/dirs_mp3/MADEOF.mp3", "is made of the words"],
    ["jr_html5/vsubt/eng/ogg/in13.ogg", "is made of the words"],
    [
      "jr_html5/audio/ENG/dirs_mp3/PTPHCONT.mp3",
      "Please click on the forward arrow to continue with this lesson or click on the backward arrow to hear this phrase again.",
    ],
    [
      "jr_html5/vsubt/eng/ogg/in14.ogg",
      "Please click on the forward arrow to continue with this lesson or click on the backward arrow to hear this phrase again.",
    ],
    [
      "jr_html5/audio/ENG/dirs_mp3/COMBPHRS.mp3",
      "We are now going to combine or put together the phrase",
    ],
    [
      "jr_html5/vsubt/eng/ogg/in15.ogg",
      "We are now going to combine or put together the phrase",
    ],
    ["jr_html5/audio/ENG/dirs_mp3/WITHPHRS.mp3", "with a new phrase"],
    ["jr_html5/vsubt/eng/ogg/in18.ogg", "with a new phrase"],
    ["jr_html5/audio/ENG/dirs_mp3/NEWPHRSE.mp3", "The new phrase is"],
    ["jr_html5/vsubt/eng/ogg/in16.ogg", "The new phrase is"],
    ["jr_html5/audio/ENG/dirs_mp3/WHENPUT.mp3", "When we put the phrase"],
    ["jr_html5/vsubt/eng/ogg/in17.ogg", "When we put the phrase"],
    ["jr_html5/audio/ENG/dirs_mp3/WITHTHE.mp3", "With the new phrase"],
    ["jr_html5/vsubt/eng/ogg/in18.ogg", "With the new phrase"],
    ["jr_html5/audio/ENG/dirs_mp3/GETSENT.mp3", "we get the sentence"],
    ["jr_html5/vsubt/eng/ogg/in19.ogg", "we get the sentence"],
    ["jr_html5/audio/ENG/dirs_mp3/THESENT.mp3", "The sentence"],
    ["jr_html5/vsubt/eng/ogg/in20.ogg", "The sentence"],
    [
      "jr_html5/audio/ENG/dirs_mp3/PUTSENT.mp3",
      "The person is now going to tell you some more information by combining or putting together the sentence",
    ],
    [
      "jr_html5/vsubt/eng/ogg/in21.ogg",
      "The person is now going to tell you some more information by combining or putting together the sentence",
    ],
    [
      "jr_html5/audio/ENG/dirs_mp3/PLSLSTN.mp3",
      "Please listen as I read this part of the story to you",
    ],
    [
      "jr_html5/vsubt/eng/ogg/in24.ogg",
      "Please listen as I read this part of the story to you",
    ],
    [
      "jr_html5/audio/ENG/dirs_mp3/PLAYPAGE.mp3",
      "If you would like to hear this passage again press the 'Play' button. If you would like to continue please press the forward arrow.",
    ],
    [
      "jr_html5/vsubt/eng/ogg/in25.ogg",
      "If you would like to hear this passage again press the 'Play' button. If you would like to continue please press the forward arrow.",
    ],
    [
      "jr_html5/audio/ENG/dirs_mp3/STRYAGIN.mp3",
      "If you would like to hear this passage again press the Play button. Or if you would like to review any of the phrases or words, click on a word in the passage. If you would like to continue, please press the Forward Arrow.",
    ],
    [
      "jr_html5/audio/ENG/dirs_mp3/PTSCONT.mp3",
      "Please click on the forward arrow to continue with this lesson or click on the backward arrow to hear this sentence again.",
    ],
    [
      "jr_html5/vsubt/eng/ogg/in1.ogg",
      "Welcome to Joseph's Readers Pre and Post Testing.",
    ],
    [
      "jr4_html5/audio/ENG/dirs_mp3/WELCPRETEST.mp3",
      "Welcome to Failure Free Reading's Life Skills Pre and Post Testing.",
    ],
    [
      "jr4_html5/vsubt/eng/ogg/in56.ogg",
      "Welcome to Failure Free Reading's Life Skills Pre and Post Testing.",
    ],
    [
      "jr_html5/audio/ENG/dirs_mp3/FILLIN.mp3",
      "Click on a word to find the best answer. Click on the 'OK' button to record your choice.",
    ],

    [
      "jr4_html5/audio/ENG/dirs_mp3/FILLIN.mp3",
      "Click on a word to find the best answer. Click on the 'OK' button to record your choice.",
    ],
    [
      "jr_html5/vsubt/eng/ogg/in3.ogg",
      "Click on a word to find the best answer. Click on the 'OK' button to record your choice.",
    ],
    [
      "jr_html5/vsubt/eng/ogg/in43.ogg",
      "Click on a word to find the best answer. Click on the 'OK' button to record your choice.",
    ],
    [
      "jr4_html5/vsubt/eng/ogg/in57.ogg",
      "Click on a word to find the best answer. Click on the 'OK' button to record your choice.",
    ],
    [
      "jr_html5/vsubt/eng/ogg/in2.ogg",
      "In a few minutes I am going to ask you to answer a few questions. The questions will help to determine whether this story is suitable for you. Don’t be nervous, you will do just fine.",
    ],
    [
      "jr4_html5/audio/ENG/dirs_mp3/WELTBOOK.mp3",
      "Welcome to Joseph's Readers Talking Book",
    ],
    [
      "jr_html5/audio/ENG/dirs_mp3/WELTBOOK.mp3",
      "Welcome to Joseph's Readers Talking Book",
    ],
    [
      "jr4_html5/vsubt/eng/ogg/in36.ogg",
      "Welcome to Joseph's Readers Talking Book",
    ],
    [
      "jr_html5/vsubt/eng/ogg/in36.ogg",
      "Welcome to Joseph's Readers Talking Book",
    ],
    [
      "jr_html5/audio/ENG/dirs_mp3/WRECDIR.mp3",
      "Click the 'Play' button to have the sentence read to you. Click the arrow to continue this story.",
    ],
    [
      "jr_html5/vsubt/eng/ogg/in37.ogg",
      "Click the 'Play' button to have the sentence read to you. Click the arrow to continue this story.",
    ],
    [
      "jr_html5/audio/ENG/dirs_mp3/TBPLAYPAGE.mp3",
      "Please press the 'Play' button to have the page read to you. Click on any word to hear that word said again. When you are ready to continue please press the 'Forward Arrow'.",
    ],
    [
      "jr_html5/vsubt/eng/ogg/in37.ogg",
      "Please press the 'Play' button to have the page read to you. Click on any word to hear that word said again. When you are ready to continue please press the 'Forward Arrow'.",
    ],
    [
      "jr_html5/audio/ENG/dirs_mp3/WRECFIND.mp3",
      "Click on the word asked for. Click on the 'Repeat' button if you need to hear the word again. Click on the 'Ok' button to record your choice.",
    ],
    [
      "jr_html5/vsubt/eng/ogg/in40.ogg",
      "Click on the word asked for. Click on the 'Repeat' button if you need to hear the word again. Click on the 'Ok' button to record your choice.",
    ],
    ["jr_html5/audio/ENG/dirs_mp3/PLEASLCT.mp3", "Please select the word"],
    ["jr_html5/vsubt/eng/ogg/in6.ogg", "Please select the word"],
    [
      "jr_html5/audio/ENG/dirs_mp3/YOUCHOSE.mp3",
      "I am sorry that was not correct. You chose",
    ],
    [
      "jr_html5/vsubt/eng/ogg/in70.ogg",
      "I am sorry that was not correct. You chose",
    ],
    ["jr_html5/audio/ENG/dirs_mp3/ASKEDFOR.mp3", "But I asked for"],
    ["jr_html5/vsubt/eng/ogg/in_blank.ogg", "But I asked for"],
    [
      "jr_html5/audio/ENG/dirs_mp3/TRYAGAIN.mp3",
      "I am sorry, your answer was not correct. Would you please try again?",
    ],
    [
      "jr_html5/vsubt/eng/ogg/in74.ogg",
      "I am sorry, your answer was not correct. Would you please try again?",
    ],
    [
      "jr_html5/audio/ENG/dirs_mp3/REIN_INTRO.mp3",
      "Welcome to the Joseph's Readers Reinforcement Activities. This is your chance to see how well you've done. Let's see how well you recognize the words that you've read in this lesson.",
    ],
    [
      "jr4_html5/audio/ENG/dirs_mp3/REIN_INTRO.mp3",
      "Welcome to the Joseph's Readers Reinforcement Activities. This is your chance to see how well you've done. Let's see how well you recognize the words that you've read in this lesson.",
    ],
    [
      "jr_html5/vsubt/eng/ogg/in42.ogg",
      "Welcome to the Joseph's Readers Reinforcement Activities. This is your chance to see how well you've done. Let's see how well you recognize the words that you've read in this lesson.",
    ],
    [
      "jr4_html5/vsubt/eng/ogg/in72.ogg",
      "Welcome to the Joseph's Readers Reinforcement Activities. This is your chance to see how well you've done. Let's see how well you recognize the words that you've read in this lesson.",
    ],
    [
      "jr_html5/audio/ENG/dirs_mp3/PRINTBOOK.mp3",
      "Congratulations. You've finished all the activities for this lesson. Take a moment to read this page. If you have a printer, and you'd like to read it to someone else, press the 'Print' button. When you're done with this page press the forward arrow to go back to the Main Menu.",
    ],
    [
      "jr4_html5/audio/ENG/dirs_mp3/SPELLDIR.mp3",
      "Click on the correct spelling of the word then click on the enter box to continue.",
    ],
    [
      "jr4_html5/vsubt/eng/ogg/in58.ogg",
      "Click on the correct spelling of the word then click on the enter box to continue.",
    ],
    [
      "jr4_html5/audio/ENG/dirs_mp3/ALPHADIR.mp3",
      "Find and click on a word that comes first alphabetically then click on the word again in the answer box.",
    ],
    [
      "jr4_html5/vsubt/eng/ogg/in76.ogg",
      "Find and click on a word that comes first alphabetically then click on the word again in the answer box.",
    ],
    [
      "jr_html5/audio/ENG/dirs_mp3/WELCRCMP.mp3",
      "Welcome to Joseph's Readers 1 Reading Comprehension program.",
    ],
    [
      "jr_html5/vsubt/eng/ogg/in52.ogg",
      "Welcome to Joseph's Readers 1 Reading Comprehension program.",
    ],
    [
      "jr_html5/audio/ENG/dirs_mp3/RCOMPDIR.mp3",
      "Please pick the correct answer to the question asked. If you need to hear the question again click the 'Question' button. Click the 'OK' button to record your choice.",
    ],
    [
      "jr_html5/vsubt/eng/ogg/in53.ogg",
      "Please pick the correct answer to the question asked. If you need to hear the question again click the 'Question' button. Click the 'OK' button to record your choice.",
    ],
    [
      "jr4_html5/audio/ENG/dirs_mp3/WELCOME_SPELLING.mp3",
      "Hello! Welcome to Failure Free Reading's Life Skills Spelling Program.",
    ],
    [
      "jr4_html5/vsubt/eng/ogg/in80.ogg",
      "Hello! Welcome to Failure Free Reading's Life Skills Spelling Program.",
    ],
    [
      "jr4_html5/audio/ENG/dirs_mp3/ANYWORD.mp3",
      "Click on any word you would like to spell.",
    ],
    [
      "jr4_html5/vsubt/eng/ogg/in81.ogg",
      "Click on any word you would like to spell.",
    ],
    [
      "jr4_html5/audio/ENG/dirs_mp3/ARROWBOTTOM.mp3",
      "Click the arrow after you have selected the word you would like to spell.",
    ],
    [
      "jr4_html5/vsubt/eng/ogg/in82.ogg",
      "Click the arrow after you have selected the word you would like to spell.",
    ],
    [
      "jr4_html5/audio/ENG/dirs_mp3/SPELLING_DIRECTIONS_1.mp3",
      "Study the spelling. If you need to hear and see the word spelled again click on the word. When you are ready to spell the word click on 'Continue'.",
    ],
    [
      "jr4_html5/vsubt/eng/ogg/in83.ogg",
      "Study the spelling. If you need to hear and see the word spelled again click on the word. When you are ready to spell the word click on 'Continue'.",
    ],
    ["vm_html5/audio/directions/uh05.mp3", "Please spell the word"],
    ["jr4_html5/vsubt/eng/ogg/in46.ogg", "Please spell the word"],
    [
      "vm_html5/audio/directions/uh06.mp3",
      "Click on the 'OK' button to record your answer.",
    ],
    [
      "jr4_html5/vsubt/eng/ogg/in84.ogg",
      "Click on the 'OK' button to record your answer.",
    ],
    ["vm_html5/audio/directions/praise03.mp3", "Great Job!"],
    ["jr4_html5/vsubt/eng/ogg/in67.ogg", "Great Job!"],
    [
      "vm_html5/audio/directions/uh07.mp3",
      "I am sorry. That was not correct. Please watch carefully the correct spelling of the word.",
    ],
    [
      "jr4_html5/vsubt/eng/ogg/in74.ogg",
      "I am sorry. That was not correct. Please watch carefully the correct spelling of the word.",
    ],
    [
      "jr4_html5/audio/ENG/dirs_mp3/VOCABBUILDERDIR.mp3",
      "Click on the word you would like to learn.",
    ],
    [
      "jr4_html5/vsubt/eng/ogg/in87.ogg",
      "Click on the word you would like to learn.",
    ],
    [
      "jr4_html5/audio/ENG/dirs_mp3/CONTINUE_WITH_SYNDEF.mp3",
      "Click the 'Continue' button to go to the next part of the lesson. Click on the synonym or definition to hear them read back to you.",
    ],
    [
      "jr4_html5/vsubt/eng/ogg/CONTINUE_WITH_SYNDEF.ogg",
      "Click the 'Continue' button to go to the next part of the lesson. Click on the synonym or definition to hear them read back to you.",
    ],
    [
      "jr4_html5/audio/ENG/dirs_mp3/CTXHELP.mp3",
      "If you would like to hear the sentence again click the 'Play' button. If you would like to hear the definition for the word click on the word in the sentence.",
    ],
    [
      "jr4_html5/vsubt/eng/ogg/CTXHELP.ogg",
      "If you would like to hear the sentence again click the 'Play' button. If you would like to hear the definition for the word click on the word in the sentence.",
    ],
    [
      "jr4_html5/audio/ENG/dirs_mp3/YNHELP.mp3",
      "If you would like to hear the question read again click on the question. Then to continue click the 'Yes' or 'No' button to answer the question.",
    ],
    [
      "jr4_html5/vsubt/eng/ogg/YNHELP.ogg",
      "If you would like to hear the question read again click on the question. Then to continue click the 'Yes' or 'No' button to answer the question.",
    ],
    [
      "jr4_html5/audio/ENG/dirs_mp3/SPELLING_DIRECTIONS_1.mp3",
      "Study the spelling. If you need to hear and see the word spelled again click on the word. When you are ready to spell the word click on 'Continue'.",
    ],
    [
      "jr4_html5/vsubt/eng/ogg/in83.ogg",
      "Study the spelling. If you need to hear and see the word spelled again click on the word. When you are ready to spell the word click on 'Continue'.",
    ],
    [
      "jr4_html5/audio/ENG/dirs_mp3/WELCOMEVOCABULARY.mp3",
      "Welcome to Failure Free Reading's Life Skills Vocabulary Builder.",
    ],
    [
      "jr4_html5/vsubt/eng/ogg/in86.ogg",
      "Welcome to Failure Free Reading's Life Skills Vocabulary Builder.",
    ],
    ["vm_html5/audio/directions/praise02.mp3", "Fantastic!"],
    ["jr_html5/audio/ENG/dirs_mp3/FANTAST.mp3.mp3", "Fantastic!"],
    ["jr4_html5/vsubt/eng/ogg/in67.ogg", "Fantastic!"],
    [
      "vm_html5/audio/directions/uh03.mp3",
      "I am sorry. I don’t think most people would agree with your answer. Please review the definition of the word once more.",
    ],
    [
      "jr4_html5/vsubt/eng/ogg/QUESTYES.ogg",
      "I am sorry. I don’t think most people would agree with your answer. Please review the definition of the word once more.",
    ],
    ["vm_html5/audio/directions/uh05.mp3", "Please spell the word"],
    ["jr4_html5/vsubt/eng/ogg/in46.ogg", "Please spell the word"],
    [
      "vm_html5/audio/directions/uh06.mp3",
      "Click on the 'OK' button to record your answer.",
    ],
    [
      "jr4_html5/vsubt/eng/ogg/in84.ogg",
      "Click on the 'OK' button to record your answer.",
    ],
    [
      "vm_html5/audio/directions/d22.mp3",
      "Please find and click on the word that means...",
    ],
    [
      "vm_html5/audio/directions/d29.mp3",
      "This exercise is designed to measure your knowledge of the words we are about to teach. Listen as I read the meaning of the word. Then find the word that best matches that meaning on the screen in front of you. Don't be upset if you don't know many of these words. I want you to be challenged by the words. So relax, and give it your best try.",
    ],
    [
      "vm_html5/audio/directions/uh01.mp3",
      'If you would like to hear the sentence again, press the "Play" button. If you would like to hear the definition for the word, press on the word in the sentence.',
    ],
    [
      "vm_html5/audio/directions/uh02.mp3",
      'If you would like the hear the question read again, press on the question. Then, to continue, press the "Yes" or "No" button to answer the question.',
    ],
    [
      "vm_html5/audio/directions/uh04.mp3",
      'Study the spelling. If you need to hear and see the word spelled again, click on the word. When are you are ready to spell the word, click on "Continue".',
    ],
    [
      "vm_html5/audio/directions/d01.mp3",
      "Click and drag any word to reveal the synonym or definition underneath.",
    ],
    [
      "vm_html5/audio/directions/d02.mp3",
      "Click and drag the vocabulary word to it's synonym or definition. Hit the OK button to check your answer.",
    ],
    [
      "vm_html5/audio/directions/d18.mp3",
      "I'm sorry, your answer was not correct. Let's review the definition of the word one more time.",
    ],
    [
      "vm_html5/audio/directions/d13.mp3",
      'If you would like to hear the paragraph read back to you, click on the "Read Paragraph" button. If you would like to review the definition of any of the words, click on the highlighted word.',
    ],
    [
      "vm_html5/audio/directions/d08.mp3",
      "Find and click on the word that best fits the sentence.",
    ],
    [
      "vm_html5/audio/directions/d23.mp3",
      'Please pick the correct answer to the question asked of you. If you need to hear the question again, click the "Question" button. Click the "OK" button to record your choice.',
    ],
  ]);

  return captions.get(audiourl.split("/app-2.0/")[1]) ?? "";
}
