import { Reducer } from "redux";
import { ScrambledSentenceActionTypes, ScrambledSentenceState } from "./type";

const INITIAL_STATE: ScrambledSentenceState = {
  isLoading: false,
  scrambledData: undefined,
  answerScrambled: [],
  dateMileseconds: 0,
  wrongAnswer: 0,
};

const reducer: Reducer<ScrambledSentenceState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case ScrambledSentenceActionTypes.LOAD_SCRAMBLED_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ScrambledSentenceActionTypes.LOAD_SCRAMBLED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        scrambledData: action.payload,
      };
    case ScrambledSentenceActionTypes.LOAD_SCRAMBLED_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ScrambledSentenceActionTypes.POST_SCRAMBLED_REQUEST:
      return {
        ...state,
        answerScrambled: action.payload.contentAnswer,
        isLoading: true,
        wrongAnswer: 0,
      };

    case ScrambledSentenceActionTypes.POST_SCRAMBLED_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case ScrambledSentenceActionTypes.POST_SCRAMBLED_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ScrambledSentenceActionTypes.CLEAR_LIST_SCRAMBLED:
      return INITIAL_STATE;

    case ScrambledSentenceActionTypes.SET_ANSWER_SCRAMBLED:
      return {
        ...state,
        answerScrambled: [...state.answerScrambled, ...action.payload],
      };

    case ScrambledSentenceActionTypes.SET_WRONG_ANSWER_SCRAMBLED:
      return {
        ...state,
        wrongAnswer: state.wrongAnswer + 1,
      };

    case ScrambledSentenceActionTypes.SET_TIMER_SCRAMBLED:
      return {
        ...state,
        dateMileseconds: action.payload,
      };

    default:
      return state;
  }
};
export default reducer;
