import { Button, Flex, Input, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import PlayIcon from "../../../assets/icons/PlayIcon";
import RegularButton from "../../global/RegularButton";
import Subtitle from "../../global/text/Subtitle";
import Text32px from "../../global/text/Text32px";
import { ISpellingQuestion } from "../../../@types/interfaces/api/pre-teaching.interface";
import { useAppSelector } from "../../../hooks/redux";
import { useLanguageContext } from "../../../providers/LanguageProvider";
import { useAccesibiltyContext } from "../../../providers/AccessibilityController";

interface Props {
  data: {
    spellingQuestionData: ISpellingQuestion;
    spellingHelp: boolean;
  };
  onCorrect: () => void;
  onWrong: () => void;
  saveResponse: (answer: string) => void;
}
function SpellingQuestion({ data, onCorrect, onWrong, saveResponse }: Props) {
  const [word, setWord] = useState("");
  const { inAudio } = useAppSelector((i) => i.lesson);
  const { language } = useLanguageContext();
  const { playMedias } = useAccesibiltyContext();

  async function handleOK() {
    if (
      word.trim().toLowerCase() ===
      data.spellingQuestionData.response.toLowerCase()
    ) {
      await playMedias(data.spellingQuestionData.correct);
      onCorrect();
    } else {
      await playMedias(data.spellingQuestionData.wrong);
      onWrong();
    }
  }
  useEffect(() => {
    async function play() {
      await playMedias([...data.spellingQuestionData.audio]);
    }

    play();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex flexDir="column" align="center">
      <Subtitle>SPELL THE WORD</Subtitle>
      <Text32px mt="16px">Spelling Program</Text32px>

      {data.spellingHelp && (
        <Text
          mt="16px"
          color="gray.500"
          borderRadius="16px"
          border="1.5px solid #CBD5E0"
          p="10px"
        >
          {data.spellingQuestionData.response}
        </Text>
      )}

      <Flex gap="24px" mt="56px" align="center" flexDir={["column", "row"]}>
        <PlayIcon
          onClick={async () =>
            await playMedias(data.spellingQuestionData.audio[1])
          }
        />
        <Input
          p="16px"
          bg="white"
          borderRadius="16px"
          border="1.5px solid #CBD5E0"
          placeholder="Basic usage"
          value={word}
          onChange={({ target }) => {
            setWord(target.value);
            saveResponse(target.value);
          }}
        />
        <RegularButton
          onClick={handleOK}
          minW="100px"
          disabled={word === "" || inAudio}
        >
          Save
        </RegularButton>
      </Flex>
    </Flex>
  );
}

export default SpellingQuestion;
