import { createStandaloneToast } from "@chakra-ui/react";
import theme from "../../../styles/theme";
import { AxiosResponse } from "axios";
import { call, put, select } from "redux-saga/effects";
import api from "../../../services/api";
import { AnyAction } from "redux";
import {
  loadWordRecognitionError,
  loadWordRecognitionSuccess,
  postAnswerRecognitionError,
  postAnswerRecognitionSuccess,
} from "./actions";
import {
  HistoryRecognition,
  HistoryRecognitionResponse,
  IProgress,
  WordRecognitionData,
} from "../../../@types/interfaces/api/word-recognition.interface";
import { ApplicationState, getGlobalState } from "..";
import { ActivityIdFilter } from "../../../@types/interfaces/api/talking-book.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import { loadStudent } from "../auth/sagas";

const { toast } = createStandaloneToast({ theme });

export function* loadWordRecognitionId(action: AnyAction) {
  const filter: ActivityIdFilter = action.payload;
  try {
    const response: AxiosResponse<PaginationBase<WordRecognitionData>> =
      yield call(api.get, `/word-recognition`, {
        params: filter,
      });
    if (response.status === 200) {
      yield put(loadWordRecognitionSuccess(response.data));
    }
  } catch (e: any) {
    yield put(loadWordRecognitionError());
  }
}

export function* postHistory() {
  const data: ApplicationState = yield select(getGlobalState);

  const timeLesson = Date.now() - data.wordRecognition.dateMileseconds;

  const dataHitory: HistoryRecognition = {
    time: timeLesson,
    student: data.auth.student?.user,
  };

  try {
    const response: AxiosResponse = yield call(
      api.post,
      "/history",
      dataHitory
    );
    if (response.status == 201) {
      return response.data;
    }
  } catch (e: any) {
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* postProgress(activity: number | undefined) {
  const data: ApplicationState = yield select(getGlobalState);
  const id_student = data.auth.student?.user;

  const dataProgress: IProgress = {
    activity,
    student: id_student,
    finished: true,
  };

  try {
    const response: AxiosResponse = yield call(
      api.patch,
      `/progress/${id_student}`,
      dataProgress
    );
    if (response.status == 200) {
      yield call(loadStudent, response.data.student);

      return response.data;
    }
  } catch (e: any) {
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* postAnswerRecognition(action: AnyAction) {
  const data: ApplicationState = yield select(getGlobalState);
  const setPage = action.payload.setPage;
  const activity = data.wordRecognition.recognitionData?.results[0].activity;

  try {
    const responseHistory: HistoryRecognitionResponse = yield call(postHistory);

    const completeData = data.wordRecognition.answers.map((answer) => {
      return { ...answer, history: responseHistory.id };
    });

    const response: AxiosResponse = yield call(
      api.post,
      "/word-recognition/answer",
      completeData
    );
    if (response.status == 201) {
      yield put(postAnswerRecognitionSuccess());
      yield call(postProgress, activity);
    }
  } catch (e: any) {
    yield put(postAnswerRecognitionError());
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}
