import { ChakraProvider } from "@chakra-ui/react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import CommonPages from "./pages/CommonPages";
import AlphabeticalOrderPage from "./pages/lessons/AlphabeticalOrderPage";
import DiagnosticPrescriptive from "./pages/lessons/DiagnosticPrescriptive";
import DragFillExercise from "./pages/lessons/DragFillExercise";
import SpellingProgramPage from "./pages/lessons/life-skills/SpellingProgramPage";
import VocabularyBuilderPage from "./pages/lessons/life-skills/VocabularyBuilderPage";
import MatchingExercise from "./pages/lessons/MatchingExercise";
import PostTestPage from "./pages/lessons/PostTestPage";
import PreTeachingLesson from "./pages/lessons/PreTeachingLesson";
import PreTest from "./pages/lessons/PreTest";
import ReadingAttitudeSurvey from "./pages/lessons/ReadingAttitudeSurvey";
import ReadingComprehensionPage from "./pages/lessons/ReadingComprehensionPage";
import ReinforcementActivities from "./pages/lessons/ReinforcementActivities";
import ScrambledSentencePage from "./pages/lessons/ScrambledSentencePage";
import ShortAnswerPage from "./pages/lessons/ShortAnswerPage";
import TalkingBook from "./pages/lessons/TalkingBook";
import StoryPage from "./pages/lessons/verbal-master/cluster-story/StoryPage";
import TestComprehensionPage from "./pages/lessons/verbal-master/cluster-test/TestComprehensionPage";
import TestFillTheBlankPage from "./pages/lessons/verbal-master/cluster-test/TestFillTheBlankPage";
import TestSynonymPage from "./pages/lessons/verbal-master/cluster-test/PreTestVm";
import WordPage from "./pages/lessons/verbal-master/unit/WordPage";
import WordRecognition from "./pages/lessons/WordRecognition";
import LoginStudants from "./pages/LoginStudants";
import GlobalStyle from "./styles/GlobalStyle";
import theme from "./styles/theme";
import ControlPainel from "./pages/ControlPainel";
import Dashboard from "./pages/Dashboard";
import { Provider } from "react-redux";
import store from "./stores/ducks";
import { AuthProvider } from "./providers/AuthProvider";
import { useAppSelector } from "./hooks/redux";
import PreTestVm from "./pages/lessons/verbal-master/cluster-test/PreTestVm";
import UnitReviewSynonym from "./pages/lessons/verbal-master/unit/UnitReviewSynonym";
import UnitReviewDefinition from "./pages/lessons/verbal-master/unit/UnitReviewDefinition";
import UnitReviewFillTheBlank from "./pages/lessons/verbal-master/unit/UnitReviewFillTheBlank";
import UnitReviewComprehension from "./pages/lessons/verbal-master/unit/UnitReviewReadingComprehension";
import PostTestVm from "./pages/lessons/verbal-master/cluster-test/PostTestVm";
import LaunchLesson from "./components/lessons/common/LaunchLesson";
import LaunchLessonSubtitles from "./components/lessons/common/LaunchLessonSubtitles";
import { LanguageProvider } from "./providers/LanguageProvider";
import { AccessibilityController } from "./providers/AccessibilityController";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import ErrorFallback from "./pages/ErrorFallback";
import TestPage from "./pages/GamePage";
import GamePage from "./pages/GamePage";

const App: React.FC = () => {
  return (
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <AuthProvider>
          <LanguageProvider>
            <BrowserRouter>
              <AccessibilityController>
                <GlobalStyle />
                <Routes>
                  <Route element={<PublicOutlet />}>
                    <Route path="/" element={<LoginStudants />} />
                  </Route>
                  <Route element={<AuthOutlet />}>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route element={<LessonOutlet />}>
                      <Route path="/lessons" element={<ControlPainel />} />
                      <Route path="/lesson/pre-test" element={<PreTest />} />
                      <Route path="/lesson/minigame" element={<GamePage />} />

                      <Route
                        path="/lesson/reading-attitude-survey"
                        element={<ReadingAttitudeSurvey />}
                      />
                      <Route
                        path="/lesson/diagnostic-prescriptive"
                        element={<DiagnosticPrescriptive />}
                      />
                      <Route
                        path="/lesson/pre-teach"
                        element={<PreTeachingLesson />}
                      />
                      <Route
                        path="/lesson/talking-book"
                        element={<TalkingBook />}
                      />
                      <Route
                        path="/lesson/word-recognition"
                        element={<WordRecognition />}
                      />
                      <Route
                        path="/lesson/reinforcement-activities"
                        element={<ReinforcementActivities />}
                      />
                      <Route
                        path="/lesson/drag-fill-exercise"
                        element={<DragFillExercise />}
                      />
                      <Route
                        path="/lesson/matching"
                        element={<MatchingExercise />}
                      />
                      <Route
                        path="/lesson/scrambled-sentence"
                        element={<ScrambledSentencePage />}
                      />
                      <Route
                        path="/lesson/short-answer"
                        element={<ShortAnswerPage />}
                      />
                      <Route
                        path="/lesson/alphabetical-order"
                        element={<AlphabeticalOrderPage />}
                      />
                      <Route
                        path="/lesson/reading-comprehension"
                        element={<ReadingComprehensionPage />}
                      />
                      <Route
                        path="/lesson/post-test"
                        element={<PostTestPage />}
                      />
                      <Route
                        path="/lesson/spelling-program"
                        element={<SpellingProgramPage />}
                      />

                      <Route
                        path="/lesson/pre-test-verbal-master"
                        element={<PreTestVm />}
                      />
                      <Route
                        path="/lesson/post-test-verbal-master"
                        element={<PostTestVm />}
                      />
                      <Route
                        path="/lesson/unit-review-synonym"
                        element={<UnitReviewSynonym />}
                      />

                      <Route
                        path="/lesson/unit-review-definition"
                        element={<UnitReviewDefinition />}
                      />
                      <Route
                        path="/lesson/unit-review-fill-the-blank"
                        element={<UnitReviewFillTheBlank />}
                      />
                      <Route
                        path="/lesson/unit-review-reading-comprehension"
                        element={<UnitReviewComprehension />}
                      />

                      <Route
                        path="/lesson/word-verbal-master"
                        element={<WordPage />}
                      />
                      <Route
                        path="/lesson/vocabulary-builder"
                        element={<VocabularyBuilderPage />}
                      />

                      <Route path="/common" element={<CommonPages />} />
                    </Route>
                  </Route>
                </Routes>
              </AccessibilityController>
            </BrowserRouter>
          </LanguageProvider>
        </AuthProvider>
      </Provider>
    </ChakraProvider>
  );
};

function Fallback({ error, resetErrorBoundary }: FallbackProps) {
  const navigate = useNavigate();

  const reset = () => {
    navigate("/dashboard");
    resetErrorBoundary();
  };

  return <ErrorFallback error={error.message} reset={reset} />;
}

const AuthOutlet = () => {
  const authenticated = useAppSelector((s) => s.auth.authenticated);

  return (
    <ErrorBoundary FallbackComponent={Fallback}>
      {authenticated ? <Outlet /> : <Navigate to="/" />}
    </ErrorBoundary>
  );
};

const LessonOutlet = () => {
  const { currentLesson } = useAppSelector((s) => s.lesson);
  const { student } = useAppSelector((s) => s.auth);
  return currentLesson ||
    student?.in_ras ||
    student?.in_diagnostic_part_1 ||
    student?.in_diagnostic_part_2 ? (
    <Outlet />
  ) : (
    <Navigate to="/dashboard" />
  );
};

const PublicOutlet = () => {
  const authenticated = useAppSelector((s) => s.auth.authenticated);
  return authenticated ? <Navigate to="/dashboard" /> : <Outlet />;
};

export default App;
