import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import {
  IShortAnswer,
  IShortAnswerPost,
} from "../../../@types/interfaces/api/short-answer.interface";

export enum ShortAnswerActionTypes {
  LOAD_SHORT_ANSWER_REQUEST = "@shortAnswer/LOAD_SHORT_ANSWER_REQUEST",
  LOAD_SHORT_ANSWER_SUCCESS = "@shortAnswer/LOAD_SHORT_ANSWER_SUCCESS",
  LOAD_SHORT_ANSWER_ERROR = "@shortAnswer/LOAD_SHORT_ANSWER_ERROR",

  POST_SHORT_ANSWER_REQUEST = "@shortAnswer/POST_SHORT_ANSWER_REQUEST",
  POST_SHORT_ANSWER_SUCCESS = "@shortAnswer/POST_SHORT_ANSWER_SUCCESS",
  POST_SHORT_ANSWER_ERROR = "@shortAnswer/POST_SHORT_ANSWER_ERROR",

  ON_NEXT = "@shortAnswer/ ON_NEXT",
  ON_PREVIOUS = "@shortAnswer/ ON_PREVIOUS",
  SET_ALTERNATIVE_SA = "@shortAnswer/SET_ALTERNATIVE_SA",
  SET_TIMER_SA = "@shortAnswer/ SET_TIMER_SA",
  CLEAR_LIST_SA = "@shortAnswer/ CLEAR_LIST_SA",
}

export interface ShortAnswerState extends ShortAnswerStateRequestsStatus {
  shortAnswerData: PaginationBase<IShortAnswer[]> | undefined;
  questionIndex: number;
  answersSA: IShortAnswerPost[];
  alternativeSA: string;
  dateMileseconds: number;
}

interface ShortAnswerStateRequestsStatus {
  isLoading: boolean;
}
