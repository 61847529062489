import { Button, Flex, Square, Text, Image } from "@chakra-ui/react";
import React, { useState } from "react";
import { motion } from "framer-motion";
import { select } from "redux-saga/effects";
import { AudioMedia } from "../../hooks/AudioMedia";
import { useAppDispatch } from "../../hooks/redux";
import { setDifficulty } from "../../stores/ducks/minigame/action";
import ovini from "../../assets/image/game/ovini.png";
import bug from "../../assets/image/game/bug.png";
import pteranodon from "../../assets/image/game/Pteranodon.png";

export interface selectedProps {
  game: "ufo" | "butterfly" | "dinosaur" | null;
  difficulty: "easy" | "normal" | "hard" | null;
}

interface StartPageProps {
  handleClick: (data: selectedProps) => void;
}

const StartPage: React.FC<StartPageProps> = ({ handleClick }) => {
  const [gameSelected, setGameSelected] = useState<selectedProps>({
    game: null,
    difficulty: null,
  });
  const dispatch = useAppDispatch();

  return (
    <Flex
      w="full"
      h="100vh"
      flexDir="column"
      align="center"
      p="40px"
      style={{ background: "linear-gradient(45deg, #10111f, #3e2958)" }}
    >
      <Text color="white" fontSize="36px" fontWeight="semibold">
        Failure Free Games
      </Text>
      <Flex flexDir="column" align="center">
        <Text color="white" fontSize="28px" mt="60px">
          Please selecet your favorite
        </Text>
        <Flex mt="40px" gap="40px">
          <motion.div
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{
              delay: 0.1,
              type: "spring",
              stiffness: 260,
              damping: 20,
            }}
          >
            <Square
              size="300px"
              bg="red"
              cursor="pointer"
              borderRadius="16px"
              style={{ background: "linear-gradient(45deg, #1e90ff, #8a2be2)" }}
              border={gameSelected.game === "ufo" ? "2px #fff solid" : "none"}
              boxShadow={
                gameSelected.game === "ufo" ? "2px 0px 30px #cfcfcf71" : "none"
              }
              _hover={{ border: "2px #fff solid" }}
              onClick={() =>
                setGameSelected((prev) => ({ ...prev, game: "ufo" }))
              }
            >
              <Image alt="ovini" src={ovini} />
            </Square>
          </motion.div>
          <motion.div
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{
              delay: 0.1,
              type: "spring",
              stiffness: 260,
              damping: 20,
            }}
          >
            <Square
              size="300px"
              bg="blue"
              cursor="pointer"
              borderRadius="16px"
              style={{ background: "linear-gradient(45deg, #ce6b0e, #dde027)" }}
              border={
                gameSelected.game === "butterfly" ? "2px #fff solid" : "none"
              }
              _hover={{ border: "2px #fff solid" }}
              onClick={() =>
                setGameSelected((prev) => ({ ...prev, game: "butterfly" }))
              }
              boxShadow={
                gameSelected.game === "butterfly"
                  ? "2px 0px 30px #cfcfcf71"
                  : "none"
              }
            >
              <Image alt="bug" src={bug} />
            </Square>
          </motion.div>
          <motion.div
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{
              delay: 0.1,
              type: "spring",
              stiffness: 260,
              damping: 20,
            }}
          >
            <Square
              size="300px"
              bg="blue"
              cursor="pointer"
              borderRadius="16px"
              style={{ background: "linear-gradient(45deg, #1b2ab8, #10af10)" }}
              border={
                gameSelected.game === "dinosaur" ? "2px #fff solid" : "none"
              }
              _hover={{ border: "2px #fff solid" }}
              onClick={() =>
                setGameSelected((prev) => ({ ...prev, game: "dinosaur" }))
              }
              boxShadow={
                gameSelected.game === "dinosaur"
                  ? "2px 0px 30px #cfcfcf71"
                  : "none"
              }
            >
              <Image alt="bug" src={pteranodon} />
            </Square>
          </motion.div>
        </Flex>
        <Flex mt="40px" flexDir="column">
          {gameSelected.game && (
            <Flex gap="40px">
              <Text color="white" fontSize="28px" fontWeight="semibold">
                Difficulty:
              </Text>
              <Text
                color={gameSelected.difficulty === "easy" ? "#61ddff" : "white"}
                fontSize="28px"
                _hover={{ color: "#61ddff" }}
                cursor="pointer"
                onClick={() => {
                  setGameSelected((prev) => ({ ...prev, difficulty: "easy" }));
                  dispatch(setDifficulty(0));
                }}
              >
                Easy
              </Text>
              <Text
                color={
                  gameSelected.difficulty === "normal" ? "#ffad43" : "white"
                }
                fontSize="28px"
                _hover={{ color: "#ffad43" }}
                cursor="pointer"
                onClick={() => {
                  setGameSelected((prev) => ({
                    ...prev,
                    difficulty: "normal",
                  }));
                  dispatch(setDifficulty(1));
                }}
              >
                Normal
              </Text>
              <Text
                color={gameSelected.difficulty === "hard" ? "#f12e2e" : "white"}
                fontSize="28px"
                _hover={{ color: "#f12e2e" }}
                cursor="pointer"
                onClick={() => {
                  setGameSelected((prev) => ({ ...prev, difficulty: "hard" }));
                  dispatch(setDifficulty(2));
                }}
              >
                Hard
              </Text>
            </Flex>
          )}

          <Flex mt="40px" justify="center">
            {gameSelected.difficulty && (
              <Button
                color="white"
                fontWeight="semibold"
                bg="primary"
                w="200px"
                _hover={{ bg: "#4164ff" }}
                onClick={() => handleClick(gameSelected)}
              >
                Start Game
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default StartPage;
