import { Center, Flex, Progress, Text } from "@chakra-ui/react";
import React from "react";

interface LoadingScreenProps {
  progress: number;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ progress }) => {
  return (
    <Flex
      w="full"
      h="100vh"
      position="absolute"
      overflow="hidden"
      top={0}
      left={0}
      bg="#10111f"
      justify="center"
      align="center"
      zIndex={999}
    >
      <Center height="100vh" flexDirection="column">
        <Text color="white" fontSize="20px">
          Loading...
        </Text>
        <Progress value={progress} width="300px" borderRadius="16px" mt={4} />
        <Text color="white" fontSize="20px" mt={4}>
          {progress}%
        </Text>
      </Center>
    </Flex>
  );
};

export default LoadingScreen;
