import {
  Flex,
  Button,
  Text,
  AspectRatio,
  VStack,
  Radio,
  RadioGroup,
  useBreakpointValue,
  FlexProps,
} from "@chakra-ui/react";
import React, { FC, useEffect, useRef, useState } from "react";
import ButtonRadio from "../global/ButtonRadio";
import Subtitle from "../global/text/Subtitle";
import Text32px from "../global/text/Text32px";
import { useLanguageContext } from "../../providers/LanguageProvider";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import useMediaPlayer from "../../hooks/useMediaPlayer";
import SignLanguage from "../global/SignLanguage";
import { useAccesibiltyContext } from "../../providers/AccessibilityController";
import SubtitleComponent from "../global/SubtitleComponent";

interface FillTheGapProps {
  question: string;
  optionsRA?: { id: number; text: string }[];
  options?: string[];
  setAlternativeFn: (alternative: string) => void;
  alternativeData: string;
  audioIntro?: string | string[];
  questionIndex?: number;
  inactiveButton?: boolean;
}

const FillTheGap: FC<FillTheGapProps> = ({
  question,
  options,
  setAlternativeFn,
  alternativeData,
  optionsRA,
  audioIntro,
  questionIndex,
  inactiveButton,
}) => {
  const dispatch = useAppDispatch();
  const { language } = useLanguageContext();
  const { playMedias, accessibility } = useAccesibiltyContext();
  const { student } = useAppSelector((item) => item.auth);
  const { inAudio } = useAppSelector((item) => item.lesson);
  useEffect(() => {
    if (!audioIntro) return;
    if (questionIndex === 0 || student?.in_diagnostic_part_1) {
      playMedias(audioIntro);
    }
  }, []);

  const clickButton = (alternative: string) => {
    setAlternativeFn(alternative);
  };

  const questionArray = question.split(/_+/);

  return (
    <Flex flexDir="column" align="center" w="full" flex={1}>
      <Subtitle>FILL IN THE BLANK</Subtitle>
      <Flex mt="16px" align="center" flexWrap="wrap" gap="8px">
        {questionArray[0].split(" ").map((w, index) => (
          <Text32px key={index}>{w}</Text32px>
        ))}

        <Flex
          w={alternativeData === "" ? "100px" : "initial"}
          h={alternativeData === "" ? "60px" : "initial"}
          bg="white"
          p="8px 16px"
          borderRadius="8px"
          border="1.5px solid #CBD5E0"
          mx="8px"
        >
          <Text32px color="primary">{alternativeData}</Text32px>
        </Flex>

        {questionArray[1].split(" ").map((w, index) => (
          <Text32px key={index}>{w}</Text32px>
        ))}
      </Flex>

      <Flex gap="16px" mt="56px" flexDir={["column", "row"]}>
        {optionsRA
          ? optionsRA?.map((item, index) => {
              return (
                <ButtonRadio
                  key={index}
                  text={item.text}
                  handleClick={() => clickButton(item.text)}
                  isDisabled={inactiveButton}
                  isSelected={
                    optionsRA.findIndex((v) => v.text === alternativeData) ===
                    index
                  }
                />
              );
            })
          : options?.map((item, index) => {
              return (
                <ButtonRadio
                  key={index}
                  text={item}
                  isDisabled={inactiveButton}
                  handleClick={() => clickButton(item)}
                  isSelected={
                    options.findIndex((v) => v === alternativeData) === index
                  }
                />
              );
            })}
      </Flex>

      {/* <Flex flexDirection={"column"} mt={2}>
        {data.introduction?.sl && (
          <AspectRatio>
            <video title="introduction" src={data.introduction.sl} autoPlay />
          </AspectRatio>
        )}
        {data.introduction?.cc && <Text>{data.introduction.cc}</Text>}
      </Flex> */}
    </Flex>
  );
};

export default FillTheGap;
