import { Flex, Spinner } from "@chakra-ui/react";
import { createContext, ReactNode, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { loadTokenRequest } from "../stores/ducks/auth/actions";

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext({});

const AuthProvider = ({ children }: AuthProviderProps) => {
  const { isTokenLoading } = useAppSelector((i) => i.auth);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadTokenRequest());
  }, []);

  return (
    <AuthContext.Provider value={{}}>
      {isTokenLoading ? (
        <Flex w="full" h="full" mt="25%" align="center" justify="center">
          <Spinner thickness="5px" size="xl" color="primary" />
        </Flex>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
