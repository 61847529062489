import createSagaMiddleware from "@redux-saga/core";
import {
  Store,
  legacy_createStore as createStore,
  applyMiddleware,
} from "redux";
import { AuthState } from "./auth/type";
import { DashboardState } from "./dashboard/type";
import { DiagnosticState } from "./diagnostic/type";
import { DragFillState } from "./drag-fill/type";
import { LessonState } from "./lesson/types";
import { MatchingState } from "./matching/type";
import { PreTestState } from "./pre-test/type";
import { RasState } from "./ras/type";
import { ReinforcementActivitiesState } from "./reinforcement-activities/type";
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";
import { ScrambledSentenceState } from "./scrambled-sentence/type";
import { ShortAnswerState } from "./short-answer/type";
import { TalkingBookState } from "./talking-book/type";
import { WordRecognitionState } from "./word-recognition/type";
import { PreTeachingState } from "./pre-teaching/type";
import { AlphabeticalOrderState } from "./alphabetical-order/type";
import { ReadingComprehensionState } from "./reading-comprehension/type";
import { PostTestState } from "./pos-test/type";
import { PreTestVmState } from "./pre-test-vm/type";
import { WordVmState } from "./word-vm/type";
import { UnitReviewSynonymState } from "./unit-review-synonym/type";
import { UnitReviewDefinitionState } from "./unit-review-definition/type";
import { UnitReviewFillTheBlankState } from "./unit-review-fill-the-blank/type";
import { PostTestVmState } from "./pos-test-vm/type";
import { UnitReviewComprehensionState } from "./unit-review-reading-comprehension/type";
import { SpellingProgramState } from "./spelling-program/type";
import { VocabularyBuilderState } from "./vocabulary-builder/type";
import { MinigameState } from "./minigame/types";

export interface ApplicationState {
  lesson: LessonState;
  auth: AuthState;
  ras: RasState;
  diagnostic: DiagnosticState;
  talkingBook: TalkingBookState;
  wordRecognition: WordRecognitionState;
  reinforcementActivities: ReinforcementActivitiesState;
  dragFill: DragFillState;
  matching: MatchingState;
  scrambledSentence: ScrambledSentenceState;
  shortAnswer: ShortAnswerState;
  preTest: PreTestState;
  dashboard: DashboardState;
  preTeaching: PreTeachingState;
  alphabeticalOrder: AlphabeticalOrderState;
  readingComprehension: ReadingComprehensionState;
  postTest: PostTestState;
  preTestVm: PreTestVmState;
  wordVm: WordVmState;
  unitReviewSynonym: UnitReviewSynonymState;
  unitReviewDefinition: UnitReviewDefinitionState;
  unitReviewFillTheBlank: UnitReviewFillTheBlankState;
  unitReviewReadingCcomprehension: UnitReviewComprehensionState;
  postTestVm: PostTestVmState;
  spellingProgram: SpellingProgramState;
  vocabularyBuilder: VocabularyBuilderState;
  minigame: MinigameState;
}

const sagaMiddleware = createSagaMiddleware();

const store: Store<ApplicationState> = createStore(
  rootReducer,
  applyMiddleware(sagaMiddleware)
);
sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;

export const getGlobalState = (state: ApplicationState) => state;

export default store;
