import {
  IMatching,
  IMatchingAnswerPost,
} from "../../../@types/interfaces/api/matching-exercse.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";

export enum MatchingActionTypes {
  LOAD_MATCHING_REQUEST = "@matching/LOAD_MATCHING_REQUEST",
  LOAD_MATCHING_SUCCESS = "@matching/LOAD_MATCHING_SUCCESS",
  LOAD_MATCHING_ERROR = "@matching/LOAD_MATCHING_ERROR",

  POST_MATCHING_REQUEST = "@matching/ POST_MATCHING_REQUEST",
  POST_MATCHING_SUCCESS = "@matching/ POST_MATCHING_SUCCESS",
  POST_MATCHING_ERROR = "@matching/ POST_MATCHING_ERROR",

  SET_ANSWER_MATCHING = "@matching/SET_ANSWER_MATCHING",
  CLEAR_LIST_MATCHING = "@matching/ CLEAR_LIST_MATCHING",
  SET_TIMER_MATCHING = "@matching/ SET_TIMER_MATCHING",
  SET_WRONG_ANSWER_MATCHING = "@matching/ SET_WRONG_ANNSWER_MATCHING",
}

export interface MatchingState extends MatchingStateRequestsStatus {
  matchingData: PaginationBase<IMatching[]> | undefined;
  answerMatching: IMatchingAnswerPost[];
  wrongAnswer: number;
  dateMileseconds: number;
}

interface MatchingStateRequestsStatus {
  isLoading: boolean;
}
