import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const CheckBoxIcon: React.FC<IconProps> = (props) => {
  return (
    <Icon
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="20" height="20" rx="10" fill="#0C4DA2" />
      <path
        d="M8.66668 12.1145L14.7947 5.98584L15.738 6.92851L8.66668 13.9998L4.42401 9.75717L5.36668 8.81451L8.66668 12.1145Z"
        fill="#F6FCFF"
      />
    </Icon>
  );
};

export default CheckBoxIcon;
