import store from "..";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import {
  IShortAnswer,
  IShortAnswerPost,
} from "../../../@types/interfaces/api/short-answer.interface";
import { ActivityIdFilter } from "../../../@types/interfaces/api/talking-book.interface";

import { ShortAnswerActionTypes } from "./type";

export const loadShortAnswerRequest = (filter: ActivityIdFilter) => ({
  type: ShortAnswerActionTypes.LOAD_SHORT_ANSWER_REQUEST,
  payload: filter,
});

export const loadShortAnswerSuccess = (data: PaginationBase<IShortAnswer>) => ({
  type: ShortAnswerActionTypes.LOAD_SHORT_ANSWER_SUCCESS,
  payload: data,
});

export const loadShortAnswerError = () => ({
  type: ShortAnswerActionTypes.LOAD_SHORT_ANSWER_ERROR,
});

export const setAlternativeSA = (alternative: string) => {
  return {
    type: ShortAnswerActionTypes.SET_ALTERNATIVE_SA,
    payload: alternative,
  };
};

export const onNextSA = (setPage: (page: any) => void) => {
  const contentListAnswer = store.getState().shortAnswer;

  const chosenAnswer = contentListAnswer.alternativeSA;
  const questionId =
    contentListAnswer.shortAnswerData?.results[0].questions[
      contentListAnswer.questionIndex
    ].id;

  const contentAnswer = {
    answer: chosenAnswer,
    question: questionId,
    history: 0,
  };

  if (
    contentListAnswer.shortAnswerData &&
    contentListAnswer.questionIndex <
      contentListAnswer?.shortAnswerData.results[0].questions.length - 1
  ) {
    return {
      type: ShortAnswerActionTypes.ON_NEXT,
      payload: { contentAnswer, setPage },
    };
  } else {
    setPage("finish");
    return {
      type: ShortAnswerActionTypes.POST_SHORT_ANSWER_REQUEST,
      payload: { contentAnswer, setPage },
    };
  }
};

export const onPreviousSA = () => {
  const contentListAnswer = store.getState().shortAnswer;
  const answerArray = contentListAnswer.answersSA;

  answerArray.pop();

  return {
    type: ShortAnswerActionTypes.ON_PREVIOUS,
    payload: answerArray,
  };
};
export const postAnswerShortAnswerRequest = (data: IShortAnswerPost[]) => ({
  type: ShortAnswerActionTypes.POST_SHORT_ANSWER_REQUEST,
  payload: data,
});

export const postAnswerShortAnswerSuccess = () => ({
  type: ShortAnswerActionTypes.POST_SHORT_ANSWER_SUCCESS,
});

export const postAnswerShortAnswerError = () => ({
  type: ShortAnswerActionTypes.POST_SHORT_ANSWER_ERROR,
});

export const setTimerShortAnswer = (date: number) => ({
  type: ShortAnswerActionTypes.SET_TIMER_SA,
  payload: date,
});

export const clearListSA = () => ({
  type: ShortAnswerActionTypes.CLEAR_LIST_SA,
});
