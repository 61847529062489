import { Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ContainerLesson from "../../../../components/global/ContainerLesson";
import PageGrid from "../../../../components/global/PageGrid";
import ProgressBar from "../../../../components/global/ProgressBar";
import Finish from "../../../../components/lessons/common/Finish";
import GreatJob from "../../../../components/lessons/common/GreatJob";
import StartLesson from "../../../../components/lessons/common/StartLesson";
import QuestionAndAnswers from "../../../../components/lessons/QuestionAndAnswers";
import { usePages } from "../../../../hooks/usePages";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import {
  clearPreTestVmList,
  loadPreTestVmRequest,
  onNextDefinition,
  onNextSynonym,
  setAlternativeDefinition,
  setAlternativeSynonym,
  setTimerPreTestVm,
} from "../../../../stores/ducks/pre-test-vm/actions";
import SynonymAndDefinition, {
  AnswerPreTestComponent,
} from "../../../../components/lessons/SynonymAndDefinition";
import { QuestionPreTestVm } from "../../../../@types/interfaces/api/pre-test-vm.interface";
import { urlsPaths } from "../../../../utils/Utility";
import { useNavigate } from "react-router-dom";
import DonNotStop from "../../../../components/lessons/common/DoNotStop";
import {
  clearPostTestVmList,
  loadPostTestVmRequest,
  onNextDefinitionPostTest,
  onNextSynonymPostTest,
  setAlternativeDefinitionPostTest,
  setAlternativeSynonymPostTest,
  setTimerPostTestVm,
} from "../../../../stores/ducks/pos-test-vm/actions";
import { assetsBaseUrl } from "../../../../utils/assets-helper";
import { VerifyButtonHelp } from "../../life-skills/SpellingProgramPage";
import { useAccesibiltyContext } from "../../../../providers/AccessibilityController";
import LaunchLessonSubtitles from "../../../../components/lessons/common/LaunchLessonSubtitles";
import SubtitleComponent from "../../../../components/global/SubtitleComponent";

function PostTestVm() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentLesson, activeButtonHelp, inAudio } = useAppSelector(
    (i) => i.lesson
  );
  const {
    questionIndex,
    alternativeSnonym,
    alternativeDefinition,
    postTestVmData,
    typeLesson,
    isLoading,
  } = useAppSelector((i) => i.postTestVm);
  const pageNumberPT = parseInt(localStorage.getItem("pageNumberPT") || "0");
  const { setVideoWindow, captionText, accessibility } =
    useAccesibiltyContext();
  const { student } = useAppSelector((item) => item.auth);

  useEffect(() => {
    dispatch(setTimerPostTestVm(Date.now()));
    if (currentLesson) {
      dispatch(loadPostTestVmRequest({ activity__id: currentLesson?.id }));
    } else {
      dispatch(
        loadPostTestVmRequest({
          activity__lesson__story__level__id: pageNumberPT,
          activity__lesson__story__story_order: 1,
        })
      );
    }
    return () => {
      dispatch(clearPostTestVmList());
    };
  }, []);

  let newArraySynonyms: AnswerPreTestComponent[] = [];

  if (postTestVmData) {
    newArraySynonyms = postTestVmData?.questions.map((obj) => ({
      id: obj.id,
      text: obj.word,
      audio: obj.synonym_audio,
      answer: obj.synonym,
    }));
  }

  let newArrayDefinition: AnswerPreTestComponent[] = [];

  if (postTestVmData) {
    newArrayDefinition = postTestVmData?.questions.map((obj) => ({
      id: obj.id,
      text: obj.word,
      audio: obj.definition_audio,
      answer: obj.definition,
    }));
  }
  const verifyButtonHelp = (): VerifyButtonHelp => {
    if (pageName === "synonym") {
      return {
        inactiveButton: false,
        audios: [
          `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/d22.mp3`,
          `${assetsBaseUrl()}${newArraySynonyms[questionIndex].audio}`,
        ],
      };
    } else if (pageName === "definition") {
      return {
        inactiveButton: false,
        audios: [
          `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/d22.mp3`,
          `${assetsBaseUrl()}${newArrayDefinition[questionIndex].audio}`,
        ],
      };
    } else {
      return { inactiveButton: true, audios: [] };
    }
  };
  const initialPage = student?.progress.finished ? "start" : "accessibility";
  const { page, setPage, pageName } = usePages<
    | "start"
    | "greatJob"
    | "synonym"
    | "definition"
    | "do_not_stop"
    | "accessibility"
  >({
    initialPage,
    pages: {
      accessibility: <LaunchLessonSubtitles onNext={() => setPage("start")} />,

      start: (
        <StartLesson
          title="Cluster Post Test (synonym and definition)"
          onNext={() => setPage("synonym")}
          introduction={[]}
        />
      ),
      synonym: postTestVmData && (
        <SynonymAndDefinition
          setAnswer={(answer) =>
            dispatch(setAlternativeSynonymPostTest(answer))
          }
          data={newArraySynonyms}
          alternative={alternativeSnonym}
          questionIndex={questionIndex}
          title="Select the synonym for the word"
        />
      ),
      do_not_stop: <DonNotStop onNext={() => setPage("definition")} />,
      definition: postTestVmData && (
        <SynonymAndDefinition
          setAnswer={(answer) =>
            dispatch(setAlternativeDefinitionPostTest(answer))
          }
          data={newArrayDefinition}
          alternative={alternativeDefinition}
          questionIndex={questionIndex}
          title="Select the word that corresponds to the definition."
        />
      ),
      greatJob: (
        <GreatJob
          onNext={() => {
            if (currentLesson) {
              navigate(`/lesson/${urlsPaths[currentLesson?.mechanic_type]}`);
            }
          }}
        />
      ),
    },
  });

  return (
    <PageGrid
      activity="Cluster pre/post test (synonym)"
      lesson="Verbal Masters"
      onHelp={() => {}}
      disabledButton={verifyButtonHelp().inactiveButton || inAudio}
      audioHelp={verifyButtonHelp().audios}
    >
      <ContainerLesson>
        {page}
        <Flex flexDir="column" gap="4px">
          <SubtitleComponent
            hide={
              !accessibility.subtitles ||
              !inAudio ||
              captionText === "" ||
              pageName === "greatJob"
            }
          />
          {postTestVmData && (
            <ProgressBar
              data={{
                max: postTestVmData?.questions.length,
                step: questionIndex + 1,
              }}
              hide={pageName !== "synonym" && pageName !== "definition"}
              onConfirm={() =>
                typeLesson === "synonym"
                  ? dispatch(onNextSynonymPostTest(setPage))
                  : dispatch(onNextDefinitionPostTest(setPage))
              }
              hideButtonPrevious
              hideButton={
                alternativeSnonym === undefined &&
                alternativeDefinition === undefined
              }
            />
          )}
        </Flex>
      </ContainerLesson>
    </PageGrid>
  );
}

export default PostTestVm;
