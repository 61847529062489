import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { LanguageType, useLanguageContext } from "./LanguageProvider";
import store from "../stores/ducks";
import { setInAudio } from "../stores/ducks/lesson/action";
import { AudioMedia } from "../hooks/AudioMedia";
import { Circle, Flex, IconButton } from "@chakra-ui/react";
import SignLanguage from "../components/global/SignLanguage";
import {
  GripHorizontal,
  MoreHorizontal,
  MoreVertical,
  Pointer,
} from "lucide-react";
import { useParams, useNavigation, useLocation } from "react-router-dom";
import { sign } from "crypto";
import SubtitleComponent from "../components/global/SubtitleComponent";
import { getCaptionText } from "../utils/captionText";
interface LanguageProviderProps {
  children: ReactNode;
}
interface IAccessibility {
  signLanguage: boolean;
  subtitles: boolean;
}
type accesibiltyContextType = {
  playMedias: (urls: string | string[]) => Promise<void>;
  accessibility: IAccessibility;
  handleSignLanguage: (state: boolean) => void;
  handleSubTitles: (state: boolean) => void;
  handleBothAccessebility: (state: boolean) => void;
  videoWindow: boolean;
  setVideoWindow: React.Dispatch<React.SetStateAction<boolean>>;
  captionText: string;
  onPlayAudio: (urls: string | string[] | undefined) => Promise<void>;
};

export const accesibiltyContext = createContext<accesibiltyContextType>({
  playMedias: async () => {},
  accessibility: {
    signLanguage: JSON.parse(localStorage.getItem("sign") ?? "false"),
    subtitles: JSON.parse(localStorage.getItem("subtitles") ?? "false"),
  },
  handleSignLanguage: () => {},
  handleSubTitles: () => {},
  handleBothAccessebility: () => {},
  videoWindow: false,
  setVideoWindow: () => {},
  captionText: "",
  onPlayAudio: async () => {},
});

export const AccessibilityController = ({
  children,
}: LanguageProviderProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoUrlsRef = useRef<string[]>([]);
  const currentVideoIndexRef = useRef<number>(0);
  const [currentUrls, setCurrentUrls] = useState<string[]>([]);
  const [accessibility, setAccessibility] = useState<IAccessibility>({
    signLanguage: JSON.parse(localStorage.getItem("sign") ?? "false"),
    subtitles: JSON.parse(localStorage.getItem("subtitles") ?? "false"),
  });

  const [videoWindow, setVideoWindow] = useState(false);

  const [captionText, setCaptionText] = useState<string>("");
  const { language } = useLanguageContext();

  const urlsWithSignLanguage = [
    "pre-teach",
    "talking-book",
    "word-recognition",
    "pre-test",
    "post-test",
    "reinforcement-activities",
    "reading-comprehension",
    "dashboard",
    "spelling-program",
    "vocabulary-builder",
  ];

  const location = useLocation();

  const urlPage = location.pathname;
  const hasSignLanguage = urlsWithSignLanguage.some((url) =>
    urlPage.includes(url)
  );

  const handleSignLanguage = (state: boolean) => {
    localStorage.setItem("sign", JSON.stringify(state));
    localStorage.setItem("subtitles", JSON.stringify(!state));

    setAccessibility({ subtitles: !state, signLanguage: state });
  };
  const handleSubTitles = (state: boolean) => {
    localStorage.setItem("sign", JSON.stringify(!state));
    localStorage.setItem("subtitles", JSON.stringify(state));

    setAccessibility({ subtitles: state, signLanguage: !state });
  };
  const handleBothAccessebility = (state: boolean) => {
    localStorage.setItem("sign", JSON.stringify(state));
    localStorage.setItem("subtitles", JSON.stringify(state));

    setAccessibility({ subtitles: state, signLanguage: state });
  };

  const onPlayAudio = async (urls: string | string[] | undefined) => {
    if (Array.isArray(urls)) {
      const urlsFiltred = urls.filter((url) => url.endsWith(".mp3"));
      for (const url of urlsFiltred) {
        setCaptionText(getCaptionText(url));
        await AudioMedia.playSequence(language, url);
      }
    } else {
      setCaptionText(getCaptionText(urls ?? ""));
      await AudioMedia.playSequence(language, urls);
    }
  };

  const playMedias = async (urls: string | string[]) => {
    const videoElement = videoRef.current;

    if (Array.isArray(urls)) {
      videoUrlsRef.current = urls;
      currentVideoIndexRef.current = 0;
    } else {
      videoUrlsRef.current = [urls];
      currentVideoIndexRef.current = 0;
    }

    if (accessibility.signLanguage && hasSignLanguage) {
      if (videoElement) {
        if (Array.isArray(urls)) {
          const oggUrl = urls.filter((url) => url.endsWith(".ogg"));
          setCurrentUrls(oggUrl);

          for (const item of oggUrl) {
            videoElement.src = item;
            videoElement.load();
            store.dispatch(setInAudio(true));
            setCaptionText(getCaptionText(item));

            await videoElement.play();

            await new Promise((resolve, reject) =>
              videoElement.addEventListener("ended", async () => {
                resolve("true");
                if (!urls.length) {
                  return reject();
                }
              })
            ).finally(() => store.dispatch(setInAudio(false)));
          }
        } else if (urls.endsWith(".ogg")) {
          videoElement.src = urls;

          videoElement.load();
          store.dispatch(setInAudio(true));
          setCaptionText(getCaptionText(urls));
          try {
            await videoElement.play();
          } catch (error) {
            store.dispatch(setInAudio(false));
          }
          await new Promise((resolve, reject) =>
            videoElement.addEventListener("ended", async () => {
              resolve("true");
              if (!urls.length) {
                return reject();
              }
            })
          ).finally(() => store.dispatch(setInAudio(false)));
        }
      }
    } else {
      setAccessibility((prev) => ({ ...prev, signLanguage: false }));
      localStorage.setItem("sign", JSON.stringify(false));
      await onPlayAudio(urls);
    }
  };

  useEffect(() => {
    const handleEnded = async () => {
      const videoElement = videoRef.current;
      if (videoElement) {
        currentVideoIndexRef.current++;

        if (currentVideoIndexRef.current < currentUrls.length) {
          videoElement.src = currentUrls[currentVideoIndexRef.current];
          videoElement.load();
          await videoElement.play();
        } else {
          currentVideoIndexRef.current = 0;
        }
      }
    };
    const videoElement = videoRef.current;

    if (videoElement) {
      videoElement.addEventListener("ended", handleEnded);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("ended", handleEnded);
      }
    };
  }, [currentUrls, currentVideoIndexRef, videoUrlsRef]);

  //######################################################################### drag and drop

  const [position, setPosition] = useState({ x: 10, y: 79 });
  const [initialPosition, setinitialPosition] = useState({ x: 10, y: 79 });

  const handleDragStart = (event: any) => {
    const { clientX, clientY } = event;
    setinitialPosition({ x: clientX, y: clientY });
  };

  const handleDrag = (event: any) => {
    const { clientX, clientY } = event;

    if (clientX !== 0 && clientY !== 0) {
      setPosition({
        x: clientX - initialPosition.x,
        y: clientY - initialPosition.y,
      });
    }
  };

  const handleDragEnd = (event: any) => {
    const { clientX, clientY } = event;
    setPosition({ x: 0, y: 0 });
    setinitialPosition({ x: clientX, y: clientY });
  };

  return (
    <accesibiltyContext.Provider
      value={{
        playMedias,
        accessibility,
        handleSignLanguage,
        handleBothAccessebility,
        handleSubTitles,
        setVideoWindow,
        videoWindow,
        captionText,
        onPlayAudio,
      }}
    >
      {accessibility.signLanguage && videoWindow && (
        <Flex
          position="absolute"
          transform={`translate(${position.x}px, ${position.y}px)`}
          // onDragStart={handleDragStart}
          onDrag={handleDrag}
          onDragEnd={handleDragEnd}
          top={initialPosition.y}
          left={initialPosition.x}
          zIndex="99"
        >
          <IconButton
            draggable="true"
            size="30px"
            bg={"gray.100"}
            zIndex="100"
            borderRadius="50%"
            ml="-15px"
            mt="-15px"
            aria-label="dick"
            p="5px"
            icon={<GripHorizontal fontSize="24px" />}
            boxShadow="1px 1px 12px #00000045"
          />
          <SignLanguage ref={videoRef} />
        </Flex>
      )}
      {children}
    </accesibiltyContext.Provider>
  );
};

export const useAccesibiltyContext = () => {
  const context = useContext(accesibiltyContext);
  return context;
};
