import store from "..";
import DiagnosticFilterInterface, {
  AnswerDiagnostic,
  QuestionDiagnostic,
} from "../../../@types/interfaces/api/diagnostic.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";

import { DiagnosticActionTypes } from "./type";

export const loadQuestionDiagnosticRequest = (
  filter: DiagnosticFilterInterface
) => ({
  type: DiagnosticActionTypes.LOAD_QUESTIONS_DIAGNOSTIC_REQUEST,
  payload: filter,
});

export const loadQuestionDiagnosticSuccess = (
  questions: PaginationBase<QuestionDiagnostic[]>
) => {
  const level = questions.results[0].level;

  localStorage.setItem("pageNumberPT", level.toString());

  return {
    type: DiagnosticActionTypes.LOAD_QUESTIONS_DIAGNOSTIC_SUCCESS,
    payload: questions,
  };
};
export const loadQuestionDiagnosticError = () => ({
  type: DiagnosticActionTypes.LOAD_QUESTIONS_DIAGNOSTIC_ERROR,
});

export const clearDiagnosticList = () => {
  return {
    type: DiagnosticActionTypes.CLEAR_LIST,
  };
};

export const setAlternativeDiagnostic = (alternative: string) => {
  return {
    type: DiagnosticActionTypes.SET_ALTERNATIVE_DIAGNOSTIC,
    payload: alternative,
  };
};

export const onNexDiagnostic = (setPage: (page: any) => void) => {
  const contentListAnswer = store.getState().diagnostic;

  const questionId =
    contentListAnswer.questions?.results[contentListAnswer.questionIndex].id;

  const correctAnswer =
    contentListAnswer.questions?.results[contentListAnswer.questionIndex]
      .answer;
  const chosenAnswer = contentListAnswer.alternativeDiagnostic;

  const contentAnswer = {
    answer: chosenAnswer,
    question: questionId,
    diagnostic_history: 0,
  };

  if (correctAnswer !== contentAnswer.answer) {
    contentListAnswer.numberOfWrongAnswers =
      contentListAnswer.numberOfWrongAnswers + 1;
  } else {
    contentListAnswer.numberOfCorrectAnswers =
      contentListAnswer.numberOfCorrectAnswers + 1;
  }

  if (contentListAnswer.numberOfCorrectAnswers === 3) {
    if (
      contentListAnswer.pageNumber === 3 &&
      contentListAnswer.additional === "orangea"
    ) {
      contentListAnswer.pageNumber = 3;
      contentListAnswer.additional = "orangeb";
    } else if (
      contentListAnswer.pageNumber === 3 &&
      contentListAnswer.additional === "orangeb"
    ) {
      contentListAnswer.pageNumber = 4;
      contentListAnswer.additional = "teal1";
    } else if (
      contentListAnswer.pageNumber === 4 &&
      contentListAnswer.additional === "teal1"
    ) {
      contentListAnswer.pageNumber = 4;
      contentListAnswer.additional = "teal2";
    } else if (
      contentListAnswer.pageNumber === 4 &&
      contentListAnswer.additional === "teal2"
    ) {
      contentListAnswer.pageNumber = 4;
      contentListAnswer.additional = "teal3";
    } else if (
      contentListAnswer.pageNumber === 4 &&
      contentListAnswer.additional === "teal3"
    ) {
      contentListAnswer.pageNumber = 9;
      contentListAnswer.additional = "";
    } else {
      contentListAnswer.pageNumber = contentListAnswer.pageNumber + 1;
    }

    setPage("do_not_stop");
    return {
      type: DiagnosticActionTypes.POST_ANSWER_DIAGNOSTIC_REQUEST,
      payload: { contentAnswer, setPage },
    };
  }

  if (
    contentListAnswer.questions &&
    contentListAnswer.numberOfWrongAnswers === 3
  ) {
    setPage("greatJob");
    return {
      type: DiagnosticActionTypes.POST_ANSWER_DIAGNOSTIC_REQUEST,
      payload: { contentAnswer, setPage },
    };
  } else {
    return {
      type: DiagnosticActionTypes.ON_NEXT,
      payload: { contentAnswer, setPage },
    };
  }
};

export const onPreviousDiagnostic = () => {
  const contentListAnswer = store.getState().diagnostic;
  const arrayAnswers = contentListAnswer.answers;
  arrayAnswers.pop();

  return {
    type: DiagnosticActionTypes.ON_PREVIOUS,
    payload: arrayAnswers,
  };
};
export const postAnswerDiagnosticRequest = (data: AnswerDiagnostic[]) => ({
  type: DiagnosticActionTypes.POST_ANSWER_DIAGNOSTIC_REQUEST,
  payload: data,
});

export const postAnswerDiagnosticSuccess = () => ({
  type: DiagnosticActionTypes.POST_ANSWER_DIAGNOSTIC_SUCCESS,
});
export const postAnswerDiagnosticError = () => ({
  type: DiagnosticActionTypes.POST_ANSWER_DIAGNOSTIC_ERROR,
});

export const setTimerDiagnostic = (date: number) => ({
  type: DiagnosticActionTypes.SET_TIMER_DIAGNOSTIC,
  payload: date,
});
export const setDiagnosticKey = (color: string) => ({
  type: DiagnosticActionTypes.SET_DIAGNOSTIC_KEY,
  payload: color,
});
export const setAdditional = (text: string) => ({
  type: DiagnosticActionTypes.SET_ADDITIONAL,
  payload: text,
});

// export const setAudio = (audio: string) => {
//   return {
//     type: RasActionTypes.SET_AUDIO,
//     payload: audio,
//   };
// };
