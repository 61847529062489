import { Reducer } from "redux";
import {
  UnitReviewDefinitionActionTypes,
  UnitReviewDefinitionState,
} from "./type";

const INITIAL_STATE: UnitReviewDefinitionState = {
  isLoading: false,
  alternativeUnitReviewDefinition: "",
  answers: [],
  dateMileseconds: 0,
  questionIndex: 0,
  unitReviewDefinitionData: undefined,
};

const reducer: Reducer<UnitReviewDefinitionState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case UnitReviewDefinitionActionTypes.LOAD_QUESTION_UNIT_REVIEW_DEFINITION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UnitReviewDefinitionActionTypes.LOAD_QUESTION_UNIT_REVIEW_DEFINITION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        unitReviewDefinitionData: action.payload,
        questionIndex: 0,
      };
    case UnitReviewDefinitionActionTypes.LOAD_QUESTION_UNIT_REVIEW_DEFINITION_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case UnitReviewDefinitionActionTypes.CLEAR_LIST:
      return INITIAL_STATE;

    case UnitReviewDefinitionActionTypes.RESET_INDEX:
      return {
        ...state,
        questionIndex: 0,
        alternativeUnitReviewDefinition: "",
      };

    case UnitReviewDefinitionActionTypes.SET_ANSWER_UNIT_REVIEW_DEFINITION:
      return {
        ...state,
        answers: [...state.answers, action.payload],
      };

    case UnitReviewDefinitionActionTypes.ON_NEXT:
      return {
        ...state,
        questionIndex: state.questionIndex + 1,
        alternativeUnitReviewDefinition: "",
      };
    case UnitReviewDefinitionActionTypes.ON_NEXT_2:
      return {
        ...state,
        questionIndex: state.questionIndex + 1,
        alternativeUnitReviewDefinition: "",
      };

    case UnitReviewDefinitionActionTypes.POST_ANSWER_UNIT_REVIEW_DEFINITION_REQUEST:
      return {
        ...state,
        alternativeUnitReviewDefinition: "",
        isLoading: true,
      };
    case UnitReviewDefinitionActionTypes.POST_ANSWER_UNIT_REVIEW_DEFINITION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        answers: [],
      };
    case UnitReviewDefinitionActionTypes.POST_ANSWER_UNIT_REVIEW_DEFINITION_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case UnitReviewDefinitionActionTypes.ON_PREVIOUS:
      return {
        ...state,
        questionIndex: state.questionIndex - 1,
        alternativeUnitReviewDefinition: "",
        answers: action.payload,
      };

    case UnitReviewDefinitionActionTypes.SET_TIMER_UNIT_REVIEW_DEFINITION:
      return { ...state, dateMileseconds: action.payload };

    default:
      return state;
  }
};
export default reducer;
