import store from "..";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import {
  AnswerRA,
  IReinforcementActivities,
} from "../../../@types/interfaces/api/reinforcement-activities.interface";
import { ActivityIdFilter } from "../../../@types/interfaces/api/talking-book.interface";
import { ReinforcementActivitiesActionTypes } from "./type";

export const loadReinforcementRequest = (filter: ActivityIdFilter) => ({
  type: ReinforcementActivitiesActionTypes.LOAD_REINFORCEMENT_REQUEST,
  payload: filter,
});

export const loadReinforcementSuccess = (
  data: PaginationBase<IReinforcementActivities>
) => ({
  type: ReinforcementActivitiesActionTypes.LOAD_REINFORCEMENT_SUCCESS,
  payload: data,
});

export const loadReinforcementError = () => ({
  type: ReinforcementActivitiesActionTypes.LOAD_REINFORCEMENT_ERROR,
});

export const setAlternativeRA = (alternative: string) => {
  return {
    type: ReinforcementActivitiesActionTypes.SET_ALTERNATIVE_RA,
    payload: alternative,
  };
};

export const clearReinforcementList = () => {
  return {
    type: ReinforcementActivitiesActionTypes.CLEAR_LIST,
  };
};

export const onPreviousReinforcement = () => {
  const contentListAnswer = store.getState().reinforcementActivities;
  const answerArray = contentListAnswer.answersRA;

  answerArray.pop();

  return {
    type: ReinforcementActivitiesActionTypes.ON_PREIVIOUS_RA,
    payload: answerArray,
  };
};

export const onNextReinforcement = (setPage: (page: any) => void) => {
  const contentListAnswer = store.getState().reinforcementActivities;
  const isLifeSkills =
    store.getState().dashboard.activityData?.lesson.story.level.program
      .short_name;

  if (
    contentListAnswer.ReinforcementActivities &&
    contentListAnswer.questionIndex <
      contentListAnswer.ReinforcementActivities.results[0].question.length - 1
  ) {
    return {
      type: ReinforcementActivitiesActionTypes.ON_NEXT_RA,
      payload: { setPage },
    };
  } else {
    if (isLifeSkills === "LF") {
      setPage("reinforcement_spelling");
      return {
        type: ReinforcementActivitiesActionTypes.CLEAR_RA_LIFE_SKILLS,
        payload: { setPage },
      };
    } else {
      setPage("greatJob");
      return {
        type: ReinforcementActivitiesActionTypes.POST_REINFORCEMENT_REQUEST,
        payload: { setPage },
      };
    }
  }
};
export const onNextReinforcementLSSpelling = (setPage: (page: any) => void) => {
  const contentListAnswer = store.getState().reinforcementActivities;

  if (
    contentListAnswer.ReinforcementActivities &&
    contentListAnswer.questionIndexLS <
      contentListAnswer.ReinforcementActivities.results[0].spelling.length - 1
  ) {
    return {
      type: ReinforcementActivitiesActionTypes.ON_NEXT_RA_SPELLING,
      payload: { setPage },
    };
  } else {
    setPage("reinforcement_alphabetical");
    return {
      type: ReinforcementActivitiesActionTypes.CLEAR_RA_SPELLING,
      payload: { setPage },
    };
  }
};
export const onNextReinforcementLSAlphabetical = (
  setPage: (page: any) => void
) => {
  const contentListAnswer = store.getState().reinforcementActivities;

  if (
    contentListAnswer.ReinforcementActivities &&
    contentListAnswer.questionIndexLS <
      contentListAnswer.ReinforcementActivities.results[0].alphabetical.length -
        1
  ) {
    return {
      type: ReinforcementActivitiesActionTypes.ON_NEXT_RA_ALPHABETICAL,
      payload: { setPage },
    };
  } else {
    setPage("greatJob");
    return {
      type: ReinforcementActivitiesActionTypes.POST_REINFORCEMENT_LIFE_SKILLS_REQUEST,
      payload: { setPage },
    };
  }
};

export const setAnswerReinforcement = () => {
  const contentListAnswer = store.getState().reinforcementActivities;

  const chosenAnswer = contentListAnswer.alternativeRA;
  const questionId =
    contentListAnswer.ReinforcementActivities?.results[0].question[
      contentListAnswer.questionIndex
    ].id;

  const text =
    contentListAnswer.ReinforcementActivities?.results[0].question[
      contentListAnswer.questionIndex
    ].text;

  const fullAlternative = text?.replace(/_+/, chosenAnswer) ?? "";

  const contentAnswer: AnswerRA = {
    answer: fullAlternative,
    question: questionId,
    history: 0,
  };

  return {
    type: ReinforcementActivitiesActionTypes.SET_ANSWER_RA,
    payload: contentAnswer,
  };
};

export const setAnswerReinforcementSpelling = () => {
  const contentListAnswer = store.getState().reinforcementActivities;

  const questionId =
    contentListAnswer.ReinforcementActivities?.results[0].spelling[
      contentListAnswer.questionIndexLS
    ].id;

  const text = contentListAnswer.alternativeRA;

  const answersRALifeSkills: AnswerRA = {
    history: 0,
    answer: text,
    question: questionId,
  };

  return {
    type: ReinforcementActivitiesActionTypes.SET_ANSWER_RA_SPELLING,
    payload: answersRALifeSkills,
  };
};
export const setAnswerReinforcementAlphabetical = () => {
  const contentListAnswer = store.getState().reinforcementActivities;

  const questionId =
    contentListAnswer.ReinforcementActivities?.results[0].alphabetical[
      contentListAnswer.questionIndexLS
    ].id;

  const text = contentListAnswer.alternativeRA;

  const contentAnswer: AnswerRA = {
    answer: text,
    question: questionId,
    history: 0,
  };

  return {
    type: ReinforcementActivitiesActionTypes.SET_ANSWER_RA_ALPHABETICAL,
    payload: contentAnswer,
  };
};

export const postAnswerReinforcementRequest = (data: AnswerRA[]) => ({
  type: ReinforcementActivitiesActionTypes.POST_REINFORCEMENT_REQUEST,
  payload: data,
});

export const postAnswerReinforcementSuccess = () => ({
  type: ReinforcementActivitiesActionTypes.POST_REINFORCEMENT_SUCCESS,
});
export const postAnswerReinforcementError = () => ({
  type: ReinforcementActivitiesActionTypes.POST_REINFORCEMENT_ERROR,
});

export const setTimerReinforcement = (date: number) => ({
  type: ReinforcementActivitiesActionTypes.SET_TIMER_RA,
  payload: date,
});
