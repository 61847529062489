import { Center, Flex, Progress, Text } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { dataUfo, getRandomWord, ufoDifficulty } from "../../utils/game-data";
import AsteroidElement from "./AsteroidElement";
import { AudioMedia } from "../../hooks/AudioMedia";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useStopwatch } from "react-timer-hook";
import { onNext, shotFired } from "../../stores/ducks/minigame/action";
import laser from "../../assets/audios/laser-shot-5.mp3";
import audioIntro from "../../assets/audios/elevation.mp3";
import { motion, useAnimation } from "framer-motion";
import OviniElement from "./OviniElement";
import LoadingScreen from "./LoadingScreen";
import {
  IMinigameData,
  IMinigmeQuestions,
  IMinigmeQuestionsVM,
} from "../../@types/interfaces/api/minigame.interface";

interface UfoComponentProps {
  setPage: () => void;
  data: IMinigameData | undefined;
}

const UfoComponent: React.FC<UfoComponentProps> = ({ setPage, data }) => {
  const [playPause, setPlayPause] = useState(true);
  const dispatch = useAppDispatch();
  const {
    gameIndex,
    misses,
    otherHits,
    score,
    shotsFired,
    targetsHit,
    difficulty,
  } = useAppSelector((i) => i.minigame);
  const { totalSeconds, seconds, minutes, hours, start, pause } = useStopwatch(
    {}
  );

  const [loadScreen, setLoadScreen] = useState(true);
  const [progress, setProgress] = useState(0);
  const [missShotNext, setMissShotNext] = useState(1);
  const mainControls = useAnimation();

  const dataVerify = useMemo(() => {
    if (data?.drag_fill_exercise) {
      const options = data?.drag_fill_exercise?.questions[
        gameIndex
      ].options.map((i) => i.text);

      return {
        id: data?.drag_fill_exercise?.questions[gameIndex].id,
        sentence: data?.drag_fill_exercise?.questions[gameIndex].text,
        answer: data?.drag_fill_exercise?.questions[gameIndex].answer,
        options,
      };
    } else if (data?.unit_review_drag_fill_exercise) {
      const optionsVm = data?.unit_review_drag_fill_exercise.words;
      const sentence =
        data.unit_review_drag_fill_exercise.questions[gameIndex].type ===
        "definition"
          ? `_____ IS DEFINED AS ${data?.unit_review_drag_fill_exercise.questions[gameIndex].question}`
          : `_____ IS A SYNONYM OF ${data?.unit_review_drag_fill_exercise.questions[gameIndex].question}`;

      return {
        id: data?.unit_review_drag_fill_exercise.questions[gameIndex].id,
        sentence,
        answer: data?.unit_review_drag_fill_exercise.words[gameIndex].id,
        optionsVm,
      };
    }
  }, [gameIndex]);

  const randowWords = useMemo(() => {
    return getRandomWord();
  }, [gameIndex]);

  const nextPage = () => {
    if (gameIndex < dataUfo.length - 1) {
      dispatch(onNext());
    } else {
      pause();
      setPage();
    }
    setMissShotNext(1);
  };

  const missCountForNext = () => {
    setMissShotNext((prev) => prev + 1);
    if (difficulty === 0 && missShotNext === 10) {
      nextPage();
      return;
    }
    if (difficulty === 1 && missShotNext === 5) {
      nextPage();
      return;
    }
    if (difficulty === 2 && missShotNext === 3) {
      nextPage();
      return;
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (progress < 100) {
        setProgress(progress + 1);
      }
    }, 50);

    if (progress === 100) {
      setLoadScreen(false);
      start();
      AudioMedia.playSequence("ENG", audioIntro);
    }

    return () => {
      clearInterval(interval);
      setLoadScreen(true);
    };
  }, [progress]);

  useEffect(() => {
    mainControls.start("hidden").then(() => {
      mainControls.start("visible");
    });
  }, [gameIndex]);

  return (
    <Flex
      w="full"
      h="100vh"
      position="relative"
      overflow="hidden"
      cursor="crosshair"
      display="flex"
      justify="center"
      bg="#10111f"
      onClick={async () => {
        dispatch(shotFired());
      }}
    >
      {loadScreen && <LoadingScreen progress={progress} />}
      <Flex
        bg="rgba(0,0,0,0.5)"
        px="12px"
        py="8px"
        w="full"
        h="56px"
        backdropFilter="auto"
        backdropBlur="5px"
        zIndex={100}
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Text color="white" fontSize="20px">
          Score: {score}
        </Text>
        <Text color="white" fontSize="20px">
          <span>{hours}</span> : {minutes < 10 && <span>0</span>}
          <span>{minutes}</span> : {seconds < 10 && <span>0</span>}
          <span>{seconds}</span>
        </Text>
        <Text color="white" fontSize="20px">
          Hits: {targetsHit}
        </Text>
      </Flex>
      <motion.div
        variants={{
          hidden: { scale: 1.7, opacity: 0 },
          visible: {
            scale: 1,
            opacity: 1,
          },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 1, type: "spring" }}
        style={{
          position: "absolute",
          bottom: "60px",
          backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: "100",
          padding: "8px 12px",
          borderRadius: "12px",
        }}
      >
        <Text color="white" fontSize="26px">
          {dataVerify?.sentence}
        </Text>
      </motion.div>
      {data?.drag_fill_exercise &&
        ufoDifficulty[difficulty].ufo.map(
          (i, index) =>
            dataVerify?.options && (
              <OviniElement
                key={i.id}
                initialX={i.x}
                initialY={i.y}
                answer={dataVerify.answer}
                index={index}
                playPause={playPause}
                option={
                  index < (dataVerify?.options?.length ?? 0)
                    ? dataVerify?.options![index]
                    : randowWords
                }
                sentence={dataVerify?.sentence}
                handleNext={nextPage}
                missCount={missCountForNext}
              />
            )
        )}
      {data?.unit_review_drag_fill_exercise &&
        ufoDifficulty[difficulty].ufo.map(
          (i, index) =>
            dataVerify?.optionsVm && (
              <OviniElement
                key={i.id}
                initialX={i.x}
                initialY={i.y}
                answer={dataVerify.answer}
                index={index}
                playPause={playPause}
                optionVm={dataVerify?.optionsVm[index]}
                sentence={dataVerify?.sentence}
                handleNext={nextPage}
                missCount={missCountForNext}
              />
            )
        )}

      {ufoDifficulty[difficulty].asteroid.map((i, index) => (
        <AsteroidElement
          key={i.id}
          initialX={i.x}
          initialY={i.y}
          id={i.id}
          size={i.size}
          playPause={playPause}
        />
      ))}
      <motion.div
        animate={{
          scale: [1, 1.3, 1],
          transition: {
            duration: 80,
            ease: "easeInOut",
            repeat: Infinity,
            repeatType: "loop",
          },
        }}
        style={{ width: "100%", height: "100%", position: "absolute" }}
      >
        <Flex
          bgImage={dataUfo[gameIndex].bg}
          opacity={0.7}
          bgRepeat="no-repeat"
          bgPosition="center"
          backgroundSize="cover"
          w="full"
          h="100vh"
          position="absolute"
          zIndex={1}
          onClick={async () => {
            await AudioMedia.playSequence("ENG", laser);
          }}
        ></Flex>
      </motion.div>
    </Flex>
  );
};

export default UfoComponent;
