import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Image } from "@chakra-ui/react";
import butterfly from "../../assets/image/game/butterfly.gif";
import net from "../../assets/image/game/net.png";
import { useAppDispatch } from "../../hooks/redux";
import { otherHits } from "../../stores/ducks/minigame/action";
import { AudioMedia } from "../../hooks/AudioMedia";
import crossbowl from "../../assets/audios/CROSSBOW_SHOT.mp3";

interface FlyingElementProps {
  initialX: number;
  initialY: number;
  id: number;
  velocityX?: number;
  velocityY?: number;
  size: number;
  playPause: boolean;
}

const ButterflyElement: React.FC<FlyingElementProps> = ({
  initialX,
  initialY,
  id,
  size = 400,
  velocityX = 6,
  velocityY = 7,
  playPause,
}) => {
  const dispatch = useAppDispatch();
  const [position, setPosition] = useState({
    x: initialX,
    y: initialY,
  });
  const [velocity, setVelocity] = useState({
    x: (Math.random() - 0.5) * velocityX,
    y: (Math.random() - 0.5) * velocityY,
  });
  const [shotAsteroid, setShotAsteroid] = useState(false);

  const handleClickAsteroid = (id: number) => {
    setShotAsteroid(true);
    dispatch(otherHits());
    AudioMedia.playSequence("ENG", crossbowl);
    setTimeout(() => {
      setShotAsteroid(false);
    }, 800);
  };

  useEffect(() => {
    const updatePosition = () => {
      if (playPause) {
        setPosition((prevPosition) => {
          let newX = prevPosition.x + velocity.x;
          let newY = prevPosition.y + velocity.y;

          const isOutsideX = newX + size < 0 || newX > window.innerWidth;
          const isOutsideY = newY + size < 0 || newY > window.innerHeight;

          if (isOutsideX || isOutsideY) {
            // Reiniciar a posição aleatoriamente
            newX = Math.random() * window.innerWidth;
            newY = Math.random() * window.innerHeight;
          }

          return {
            x: newX,
            y: newY,
          };
        });
      }
    };

    const animationId = requestAnimationFrame(updatePosition);

    return () => cancelAnimationFrame(animationId);
  }, [position, velocity]);

  return (
    <>
      <motion.div
        onClick={() => handleClickAsteroid(id)}
        // animate={{ rotate: [0, 360] }}
        // transition={{
        //   duration: 3,
        //   ease: "linear",
        //   repeat: Infinity,
        //   repeatType: "loop",
        // }}
        style={{
          width: `${size}px`,
          overflow: "hidden",
          position: "absolute",
          cursor: "crosshair",
          left: position.x,
          top: position.y,
          userSelect: "none",
          zIndex: "20",
        }}
      >
        <Image src={butterfly} alt="asteroid" w="full" />
      </motion.div>

      <motion.div
        onClick={() => handleClickAsteroid(id)}
        style={{
          overflow: "hidden",
          opacity: shotAsteroid ? 1 : 0,
          position: "absolute",
          cursor: "crosshair",
          left: position.x + 50,
          top: position.y + 50,
          userSelect: "none",
          zIndex: "30",
        }}
      >
        <Image src={net} alt="explosion" w={`${200}px`} />
      </motion.div>
    </>
  );
};

export default ButterflyElement;
