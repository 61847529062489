import { Reducer } from "redux";
import { AlphabeticalOrderActionTypes, AlphabeticalOrderState } from "./type";

const INITIAL_STATE: AlphabeticalOrderState = {
  isLoading: false,
  alphabeticalData: undefined,
  answerAlphabetical: [],
  dateMileseconds: 0,
  wrongAnswer: 0,
};

const reducer: Reducer<AlphabeticalOrderState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case AlphabeticalOrderActionTypes.LOAD_ALPHABETICAL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case AlphabeticalOrderActionTypes.LOAD_ALPHABETICAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        alphabeticalData: action.payload,
      };
    case AlphabeticalOrderActionTypes.LOAD_ALPHABETICAL_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case AlphabeticalOrderActionTypes.POST_ALPHABETICAL_REQUEST:
      return {
        ...state,
        answeralphabetical: action.payload.contentAnswer,
        isLoading: true,
        wrongAnswer: 0,
      };

    case AlphabeticalOrderActionTypes.POST_ALPHABETICAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case AlphabeticalOrderActionTypes.POST_ALPHABETICAL_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case AlphabeticalOrderActionTypes.CLEAR_LIST_ALPHABETICAL:
      return INITIAL_STATE;

    case AlphabeticalOrderActionTypes.SET_ANSWER_ALPHABETICAL:
      return {
        ...state,
        answerAlphabetical: [...state.answerAlphabetical, ...action.payload],
      };

    case AlphabeticalOrderActionTypes.SET_WRONG_ANSWER_ALPHABETICAL:
      return {
        ...state,
        wrongAnswer: state.wrongAnswer + 1,
      };

    case AlphabeticalOrderActionTypes.SET_TIMER_ALPHABETICAL:
      return {
        ...state,
        dateMileseconds: action.payload,
      };

    default:
      return state;
  }
};
export default reducer;
