import { Student } from "../../../@types/interfaces/api/student.interface";
import { User } from "../../../@types/interfaces/api/user.interface";

export enum AuthActionTypes {
  LOG_OUT = "@auth/LOG_OUT",

  SIGN_IN_REQUEST = "@auth/SIGN_IN_REQUEST",
  SIGN_IN_SUCCESS = "@auth/SIGN_IN_SUCCESS",
  SIGN_IN_ERROR = "@auth/SIGN_IN_ERROR",

  LOAD_TOKEN_REQUEST = "@auth/LOAD_TOKEN_REQUEST",
  LOAD_TOKEN_SUCCESS = "@auth/LOAD_TOKEN_SUCCESS",
  LOAD_TOKEN_ERROR = "@auth/LOAD_TOKEN_ERROR",

  LOAD_STUDENT_REQUEST = "@auth/LOAD_STUDENT_REQUEST",
  LOAD_STUDENT_SUCCESS = "@auth/LOAD_STUDENT_SUCCESS",
  LOAD_STUDENT_ERROR = "@auth/LOAD_STUDENT_ERROR",
}

export interface AuthState extends AuthStateRequestsStatus {
  authenticated: boolean;
  user: User | undefined;
  student: Student | undefined;
}

interface AuthStateRequestsStatus {
  isSignInLoading: boolean;
  isTokenLoading: boolean;
}
