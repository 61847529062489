import store from "..";
import AnyAction from "redux";
import { UnitReviewSynonymActionTypes } from "./type";

import UnitReviewSynonymFilterInterface, {
  AnswerUnitReviewSynonym,
  IUnitReviewSynonym,
} from "../../../@types/interfaces/api/unit-review-synonym.interface";

export const loadUnitReviewSynonymRequest = (
  filter: UnitReviewSynonymFilterInterface
) => ({
  type: UnitReviewSynonymActionTypes.LOAD_QUESTION_UNIT_REVIEW_SYNONYM_REQUEST,
  payload: filter,
});

export const loadUnitReviewSynonymSuccess = (data: IUnitReviewSynonym) => {
  return {
    type: UnitReviewSynonymActionTypes.LOAD_QUESTION_UNIT_REVIEW_SYNONYM_SUCCESS,
    payload: data,
  };
};
export const loadUnitReviewSynonymError = () => ({
  type: UnitReviewSynonymActionTypes.LOAD_QUESTION_UNIT_REVIEW_SYNONYM_ERROR,
});

export const clearUnitReviewSynonymList = () => {
  return {
    type: UnitReviewSynonymActionTypes.CLEAR_LIST,
  };
};

export const setAnswerUnitReviewSynonym = (alternative: string) => {
  const contentListAnswer = store.getState().unitReviewSynonym;
  const question =
    contentListAnswer.unitReviewSynonymData?.words[
      contentListAnswer.questionIndex
    ].id;
  const contentAnswer = {
    answer: alternative,
    question,
    history: 0,
  };
  return {
    type: UnitReviewSynonymActionTypes.SET_ANSWER_UNIT_REVIEW_SYNONYM,
    payload: contentAnswer,
  };
};

export const onNextUnitReviewSynonym1 = (setPage: (page: any) => void) => {
  const contentListAnswer = store.getState().unitReviewSynonym;

  if (
    contentListAnswer.unitReviewSynonymData &&
    contentListAnswer.questionIndex <
      contentListAnswer.unitReviewSynonymData.words.length - 1
  ) {
    return {
      type: UnitReviewSynonymActionTypes.ON_NEXT,
      payload: { setPage },
    };
  } else {
    setPage("dragToDefinitionExercise");
    return {
      type: UnitReviewSynonymActionTypes.RESET_INDEX,
    };
  }
};
export const onNextUnitReviewSynonym2 = (setPage: (page: any) => void) => {
  const contentListAnswer = store.getState().unitReviewSynonym;

  if (
    contentListAnswer.unitReviewSynonymData &&
    contentListAnswer.questionIndex <
      contentListAnswer.unitReviewSynonymData.words.length - 1
  ) {
    return {
      type: UnitReviewSynonymActionTypes.ON_NEXT_2,
      payload: { setPage },
    };
  } else {
    return {
      type: UnitReviewSynonymActionTypes.POST_ANSWER_UNIT_REVIEW_SYNONYM_REQUEST,
      payload: { setPage },
    };
  }
};

export const postAnswerUnitReviewSynonymRequest = (
  data: AnswerUnitReviewSynonym[]
) => {
  return {
    type: UnitReviewSynonymActionTypes.POST_ANSWER_UNIT_REVIEW_SYNONYM_REQUEST,
    payload: data,
  };
};

export const postAnswerUnitReviewSynonymSuccess = () => ({
  type: UnitReviewSynonymActionTypes.POST_ANSWER_UNIT_REVIEW_SYNONYM_SUCCESS,
});
export const postAnswerUnitReviewSynonymError = () => ({
  type: UnitReviewSynonymActionTypes.POST_ANSWER_UNIT_REVIEW_SYNONYM_ERROR,
});

export const setTimerUnitReviewSynonym = (date: number) => {
  return {
    type: UnitReviewSynonymActionTypes.SET_TIMER_UNIT_REVIEW_SYNONYM,
    payload: date,
  };
};
// export const onPreviousUnitReviewSynonym = () => {
//   const contentListAnswer = store.getState().wordVm;
//   const answerArray = contentListAnswer.answers;

//   answerArray.pop();

//   return {
//     type: UnitReviewSynonymActionTypes.ON_PREVIOUS,
//     payload: answerArray,
//   };
// };
