import { createStandaloneToast } from "@chakra-ui/react";
import { NavigateFunction } from "react-router-dom";
import { AnyAction } from "redux";
import {
  LoginCredentials,
  LoginResponse,
} from "../../../@types/interfaces/api/login.interface";
import theme from "../../../styles/theme";
import axios, { AxiosResponse } from "axios";
import { call, put, select } from "redux-saga/effects";
import {
  loadStudentByIdError,
  loadStudentByIdRequest,
  loadStudentByIdSuccess,
  loadTokenError,
  loadTokenSuccess,
  signInError,
  signInSuccess,
} from "./actions";
import { User } from "../../../@types/interfaces/api/user.interface";
import api from "../../../services/api";
import { Student } from "../../../@types/interfaces/api/student.interface";
import { loadCurrentLessonRequest, setCurrentLesson } from "../lesson/action";
import {
  loadActivityRequest,
  loadNextActivityLessonRequest,
} from "../dashboard/actions";
import store, { ApplicationState, getGlobalState } from "..";

const { toast } = createStandaloneToast({ theme });

export function* handleSignIn(action: AnyAction) {
  const {
    credentials,
    navigate,
  }: { credentials: LoginCredentials; navigate: NavigateFunction } =
    action.payload;

  try {
    const response: AxiosResponse<LoginResponse> = yield call(
      axios.post,
      `${process.env.REACT_APP_BASE_URL}/auth/login`,
      credentials
    );
    if (response.status === 200) {
      yield put(signInSuccess(response.data));
      yield call(loadAuthUser);
      navigate("/dashboard", { replace: true });
    }
  } catch (e: any) {
    if (e.response.status === 401) {
      toast({
        title: "Erro",
        description:
          "The provided username or password is incorrect, or the user account is not active. Please check your credentials and ensure that your account is active to access this resource.",
        status: "error",
      });
    } else if (e.response.status === 403) {
      toast({
        title: "Erro",
        description: "unauthorized user",
        status: "error",
      });
    } else {
      toast({
        title: "Erro",
        description: e.message,
        status: "error",
      });
    }
    yield put(signInError());
  }
}

export function* loadAuthUser() {
  const data: ApplicationState = yield select(getGlobalState);
  const studentActivity = data.auth.student?.progress.activity;
  try {
    const response: AxiosResponse<User> = yield call(api.get, "/auth/me");
    if (response.status == 200) {
      yield put(loadTokenSuccess(response.data));
      if (response.data.user_type !== "student") {
        toast({
          title: "Erro",
          description: "unauthorized user",
          status: "error",
        });
      }
      yield call(loadStudent, response.data.id);
    }
  } catch (e: any) {
    yield put(loadTokenError());
  }
}
export function* loadStudent(id: number) {
  try {
    const response: AxiosResponse<Student> = yield call(
      api.get,
      `/student/${id}`
    );
    yield put(loadStudentByIdSuccess(response.data));
    yield put(loadActivityRequest(response.data.progress.activity));
  } catch (e: any) {
    yield put(loadStudentByIdError());
  }
}
