import React from "react";
import { useDroppable } from "@dnd-kit/core";
import { Box } from "@chakra-ui/react";

interface Props {
  id: string;
}
export function DroppableBox(props: React.PropsWithChildren<Props>) {
  const { isOver, setNodeRef } = useDroppable({ id: props.id });
  const style = {
    color: isOver ? "green" : undefined,
  };

  return (
    <Box ref={setNodeRef} style={style}>
      {props.children}
    </Box>
  );
}
