import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import {
  AnswerRecognition,
  WordRecognitionData,
} from "../../../@types/interfaces/api/word-recognition.interface";

export enum WordRecognitionActionTypes {
  LOAD_RECOGNITION_REQUEST = "@wordRecognition/LOAD_RECOGNITION_REQUEST",
  LOAD_RECOGNITION_SUCCESS = "@wordRecognition/LOAD_RECOGNITION_SUCCESS",
  LOAD_RECOGNITION_ERROR = "@wordRecognition/LOAD_RECOGNITION_ERROR",

  POST_ANSWER_RECOGNITION_REQUEST = "@wordRecognition/POST_ANSWER_RECOGNITION_REQUEST",
  POST_ANSWER_RECOGNITION_SUCCESS = "@wordRecognition/POST_ANSWER_RECOGNITION_SUCCESS",
  POST_ANSWER_RECOGNITION_ERROR = "@wordRecognition/POST_ANSWER_RECOGNITION_ERROR",

  ON_NEXT = "@wordRecognition/ ON_NEXT",
  SET_ALTERNATIVE_RECOGNITION = "@wordRecognition/SET_ALTERNATIVE_RECOGNITION",
  SET_ANSWER_RECOGNITION = "@wordRecognition/SET_ANSWER_RECOGNITION",
  SET_TIMER_RECOGNITION = "@wordRecognition/ SET_TIMER_RECOGNITION",
}

export interface AlternativeIds {
  word: number;
  question: number;
}

export interface WordRecognitionState
  extends WordRecognitionStateRequestsStatus {
  recognitionData: PaginationBase<WordRecognitionData[]> | undefined;
  questionIndex: number;
  answers: AnswerRecognition[];
  alternativeRecognition: AlternativeIds | undefined;
  dateMileseconds: number;
}

interface WordRecognitionStateRequestsStatus {
  isLoading: boolean;
}
