import { Flex } from "@chakra-ui/react";
import React, { forwardRef, useEffect, useRef, useState } from "react";

const SignLanguage = forwardRef<HTMLVideoElement>((props, ref) => {
  return (
    <Flex
      justify="center"
      bg="gray.700"
      boxShadow="8px 8px 12px #00000045"
      p="10px"
      borderRadius="8px"
      position="absolute"
      w="250px"
    >
      <video
        width="100%"
        style={{ borderRadius: "8px" }}
        autoPlay
        ref={ref}
        {...props}
      />
    </Flex>
  );
});

export default SignLanguage;
