import { Reducer } from "redux";
import {
  ReinforcementActivitiesActionTypes,
  ReinforcementActivitiesState,
} from "./type";

const INITIAL_STATE: ReinforcementActivitiesState = {
  isLoading: false,
  questionIndex: 0,
  questionIndexLS: 0,
  ReinforcementActivities: undefined,
  alternativeRA: "",
  answersRA: [],
  answersRaSpelling: [],
  answersRaAlphabetical: [],
  dateMileseconds: 0,
};

const reducer: Reducer<ReinforcementActivitiesState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case ReinforcementActivitiesActionTypes.LOAD_REINFORCEMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ReinforcementActivitiesActionTypes.LOAD_REINFORCEMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ReinforcementActivities: action.payload,
      };
    case ReinforcementActivitiesActionTypes.LOAD_REINFORCEMENT_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ReinforcementActivitiesActionTypes.ON_NEXT_RA:
      return {
        ...state,
        questionIndex: state.questionIndex + 1,
        alternativeRA: "",
      };

    case ReinforcementActivitiesActionTypes.ON_NEXT_RA_SPELLING:
      return {
        ...state,
        questionIndexLS: state.questionIndexLS + 1,
        alternativeRA: "",
      };
    case ReinforcementActivitiesActionTypes.ON_NEXT_RA_ALPHABETICAL:
      return {
        ...state,
        questionIndexLS: state.questionIndexLS + 1,
        alternativeRA: "",
      };
    case ReinforcementActivitiesActionTypes.CLEAR_RA_LIFE_SKILLS:
      return {
        ...state,
        questionIndex: 0,
        questionIndexLS: 0,
        alternativeRA: "",
      };
    case ReinforcementActivitiesActionTypes.CLEAR_RA_SPELLING:
      return {
        ...state,
        questionIndex: 0,
        questionIndexLS: 0,
        alternativeRA: "",
      };

    case ReinforcementActivitiesActionTypes.SET_ANSWER_RA:
      return {
        ...state,
        alternativeRA: "",
        answersRA: [...state.answersRA, action.payload],
      };
    case ReinforcementActivitiesActionTypes.SET_ANSWER_RA_SPELLING:
      return {
        ...state,
        alternativeRA: "",
        answersRaSpelling: [...state.answersRaSpelling, action.payload],
      };
    case ReinforcementActivitiesActionTypes.SET_ANSWER_RA_ALPHABETICAL:
      return {
        ...state,
        alternativeRA: "",
        answersRaAlphabetical: [...state.answersRaAlphabetical, action.payload],
      };

    case ReinforcementActivitiesActionTypes.SET_ALTERNATIVE_RA:
      return {
        ...state,
        alternativeRA: action.payload,
      };
    case ReinforcementActivitiesActionTypes.ON_PREIVIOUS_RA:
      return {
        ...state,
        questionIndex: state.questionIndex - 1,
        alternativeRA: "",
        answersRA: action.payload,
      };
    case ReinforcementActivitiesActionTypes.POST_REINFORCEMENT_LIFE_SKILLS_REQUEST:
      return {
        ...state,

        isLoading: true,
      };

    case ReinforcementActivitiesActionTypes.POST_REINFORCEMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ReinforcementActivitiesActionTypes.POST_REINFORCEMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        questionIndex: 0,
        questionIndexLS: 0,
        alternativeRA: "",
      };
    case ReinforcementActivitiesActionTypes.POST_REINFORCEMENT_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ReinforcementActivitiesActionTypes.SET_TIMER_RA:
      return {
        ...state,
        dateMileseconds: action.payload,
      };

    case ReinforcementActivitiesActionTypes.CLEAR_LIST:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default reducer;
