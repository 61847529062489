import { Button, Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import PlayIcon from "../../../assets/icons/PlayIcon";
import RegularButton from "../../global/RegularButton";
import Subtitle from "../../global/text/Subtitle";
import Text32px from "../../global/text/Text32px";
import { useLanguageContext } from "../../../providers/LanguageProvider";
import { useAccesibiltyContext } from "../../../providers/AccessibilityController";

interface Props {
  data: {
    sentence: {
      text: string[];
      audio: string;
    };
    word: {
      text: string;
      audio: string;
    };
  };
  onNext: () => void;
}

function WordSentence({ data, onNext }: Props) {
  const [disabled, setDisabled] = useState(true);
  const { language } = useLanguageContext();
  const { onPlayAudio } = useAccesibiltyContext();

  useEffect(() => {
    async function play() {
      await onPlayAudio([data.word.audio, data.sentence.audio]);
      setDisabled(false);
    }

    play();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleContinue() {
    onNext();
  }

  return (
    <Flex flexDirection={"column"} alignItems="center">
      <Subtitle>Press the 'Play' button to HEAR THE SENTENCE</Subtitle>

      <Flex align="center" mt="16px">
        <Text32px>{data.word.text}</Text32px>
        <PlayIcon
          disabled={disabled}
          onClick={() => onPlayAudio(data.word.audio)}
        />
      </Flex>
      <Flex
        align="center"
        mt="60px"
        flexDir={["column", "row"]}
        justify="center"
        textAlign="center"
      >
        <PlayIcon
          disabled={disabled}
          onClick={() => onPlayAudio(data.sentence.audio)}
        />
        <Text ml="16px" fontSize="20px" color="gray.500">
          {data.sentence.text[0]}
        </Text>
        <Text fontWeight="700" ml="10px" color="primary" fontSize="20px">
          {data.sentence.text[1]}
        </Text>
        <Text fontSize="20px" color="gray.500" ml="10px">
          {data.sentence.text[2]}
        </Text>
      </Flex>

      <RegularButton mt="60px" disabled={disabled} onClick={handleContinue}>
        Continue
      </RegularButton>
    </Flex>
  );
}

export default WordSentence;
