import { createStandaloneToast } from "@chakra-ui/react";

import theme from "../../../styles/theme";
import axios, { AxiosResponse } from "axios";
import { call, put, select } from "redux-saga/effects";
import api from "../../../services/api";
import {
  Question,
  RasAnswer,
} from "../../../@types/interfaces/api/ras.interface";
import {
  loadQuestionError,
  loadQuestionSuccess,
  postAnswerError,
  postAnswerSuccess,
} from "./actions";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import { AnyAction } from "redux";
import store, { ApplicationState, getGlobalState } from "..";
import { loadStudent } from "../auth/sagas";

const { toast } = createStandaloneToast({ theme });

export function* loadQuestions() {
  try {
    const response: AxiosResponse<PaginationBase<Question[]>> = yield call(
      api.get,
      "/ras/question"
    );
    if (response.status == 200) {
      yield put(loadQuestionSuccess(response.data));
    }
  } catch (e: any) {
    yield put(loadQuestionError());
  }
}

export function* postAnswerSaga(action: AnyAction) {
  const data: ApplicationState = yield select(getGlobalState);
  const student = data.auth.student?.user;
  const setPage = action.payload.setPage;
  try {
    const response: AxiosResponse = yield call(
      api.post,
      "/ras/answer",
      data.ras.answers
    );
    if (response.status == 201) {
      yield put(postAnswerSuccess());
      if (student) {
        yield call(loadStudent, student);
      }
      setPage("great_job");
    }
  } catch (e: any) {
    yield put(postAnswerError());
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}
