import { createStandaloneToast } from "@chakra-ui/react";
import theme from "../../../styles/theme";
import { AxiosResponse } from "axios";
import { call, put, select } from "redux-saga/effects";
import api from "../../../services/api";
import { AnyAction } from "redux";

import {
  HistoryRecognition,
  HistoryRecognitionResponse,
  IProgress,
  WordRecognitionData,
} from "../../../@types/interfaces/api/word-recognition.interface";
import { ApplicationState, getGlobalState } from "..";
import { ActivityIdFilter } from "../../../@types/interfaces/api/talking-book.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import { loadStudent } from "../auth/sagas";
import { ISpellingProgram } from "../../../@types/interfaces/api/spelling-program.interface";
import {
  loadSpellingProgramError,
  loadSpellingProgramSuccess,
  postAnswerSpellingError,
  postAnswerSpellingRequest,
  postAnswerSpellingSuccess,
} from "./actions";
import { postProgress } from "../word-recognition/sagas";

const { toast } = createStandaloneToast({ theme });

export function* loadSpellingProgram(action: AnyAction) {
  const filter: ActivityIdFilter = action.payload;
  try {
    const response: AxiosResponse<PaginationBase<ISpellingProgram[]>> =
      yield call(api.get, `/spelling-program`, {
        params: filter,
      });
    if (response.status == 200) {
      yield put(loadSpellingProgramSuccess(response.data.results[0]));
    }
  } catch (e: any) {
    yield put(loadSpellingProgramError());
  }
}

export function* postHistory() {
  const data: ApplicationState = yield select(getGlobalState);

  const timeLesson = Date.now() - data.spellingProgram.dateMileseconds;

  const dataHitory: HistoryRecognition = {
    time: timeLesson,
    student: data.auth.student?.user,
  };

  try {
    const response: AxiosResponse = yield call(
      api.post,
      "/history",
      dataHitory
    );
    if (response.status == 201) {
      return response.data;
    }
  } catch (e: any) {
    const errors = e.response.data;
    toast({
      title: Object.keys(errors),
      description: errors[Object.keys(errors)[0]],
      status: "error",
    });
  }
}

export function* postAnswerSpelling() {
  const data: ApplicationState = yield select(getGlobalState);
  const activity = data.spellingProgram.spellingData?.activity;

  if (data.spellingProgram.answers.length === 0) {
    yield call(postProgress, activity);
    yield put(postAnswerSpellingSuccess());
  } else {
    try {
      const responseHistory: HistoryRecognitionResponse = yield call(
        postHistory
      );

      const completeData = data.spellingProgram.answers.map((answer) => {
        return { ...answer, history: responseHistory.id };
      });

      const response: AxiosResponse = yield call(
        api.post,
        "/spelling-program/answer",
        completeData
      );
      if (response.status == 201) {
        yield put(postAnswerSpellingSuccess());
        yield call(postProgress, activity);
      }
    } catch (e: any) {
      yield put(postAnswerSpellingError());
      const errors = e.response.data;
      toast({
        title: Object.keys(errors),
        description: errors[Object.keys(errors)[0]],
        status: "error",
      });
    }
  }
}
