import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import {
  ActivityIdFilter,
  TalkingBook,
} from "../../../@types/interfaces/api/talking-book.interface";
import { TalkingBookActionTypes } from "./type";

export const loadTalkingBookRequest = (filter: ActivityIdFilter) => ({
  type: TalkingBookActionTypes.LOAD_BOOK_REQUEST,
  payload: filter,
});

export const loadTalkingBookSuccess = (data: PaginationBase<TalkingBook>) => ({
  type: TalkingBookActionTypes.LOAD_BOOK_SUCCESS,
  payload: data,
});

export const loadTalkingBookError = () => ({
  type: TalkingBookActionTypes.LOAD_BOOK_ERROR,
});

export const loadTalkingBookIDRequest = (id: number) => ({
  type: TalkingBookActionTypes.LOAD_BOOK_ID_REQUEST,
  payload: id,
});

export const loadTalkingBookIDSuccess = (data: TalkingBook) => ({
  type: TalkingBookActionTypes.LOAD_BOOK_ID_SUCCESS,
  payload: data,
});

export const loadTalkingBookIDError = () => ({
  type: TalkingBookActionTypes.LOAD_BOOK_ID_ERROR,
});
export const postTalkingBookRequest = (setPage: (page: any) => void) => {
  setPage("greatJob");
  return {
    type: TalkingBookActionTypes.POST_TALKING_BOOK_REQUEST,
    payload: { setPage },
  };
};

export const postTalkingBookSuccess = () => ({
  type: TalkingBookActionTypes.POST_TALKING_BOOK_SUCCESS,
});

export const postTalkingBookError = () => ({
  type: TalkingBookActionTypes.POST_TALKING_BOOK_ERROR,
});

export const setTimerTB = (date: number) => ({
  type: TalkingBookActionTypes.SET_TIMER_TB,
  payload: date,
});
