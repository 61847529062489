import {
  DragAlternative,
  IDragAnswerPost,
  IDragFill,
} from "../../../@types/interfaces/api/drag-fill.interface";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";

export enum DragFillActionTypes {
  LOAD_DRAG_FILL_REQUEST = "@dragFill/LOAD_DRAG_FILL_REQUEST",
  LOAD_DRAG_FILL_SUCCESS = "@dragFill/LOAD_DRAG_FILL_SUCCESS",
  LOAD_DRAG_FILL_ERROR = "@dragFill/LOAD_DRAG_FILL_ERROR",

  POST_ANSWER_DRAG_REQUEST = "@dragFill/POST_ANSWER_DRAG_REQUEST",
  POST_ANSWER_DRAG_SUCCESS = "@dragFill/POST_ANSWER_DRAG_SUCCESS",
  POST_ANSWER_DRAG_ERROR = "@dragFill/POST_ANSWER_DRAG_ERROR",

  ON_NEXT_DRAG = "@dragFill/ ON_NEXT_DRAG",
  ON_PREIVIOUS_DRAG = "@dragFill/ ON_PREIVIOUS_DRAG",
  SET_ALTERNATIVE_DRAG = "@dragFill/ SET_ALTERNATIVE_DRAG",
  SET_WORD_DRAG = "@dragFill/ SET_WORD_DRAG",
  SET_ANSWER_DRAG = "@dragFill/ SET_ANSWER_DRAG",
  SET_SKIP_TRUE = "@dragFill/ SET_SKIP_TRUE",
  SET_SKIP_FALSE = "@dragFill/ SET_SKIP_FALSE",
  SKIP_QUESTION = "@dragFill/ SKIP_QUESTION",
  POST_DRAG = "@dragFill/ POST_DRAG",
  SET_TIMER_DRAG = "@dragFill/ SET_TIMER_DRAG",
  CLEAR_LIST_DRAG = "@dragFill/ CLEAR_LIST",
}

export interface DragFillState extends DragFillStateRequestsStatus {
  DragFillData: PaginationBase<IDragFill[]> | undefined;
  questionIndex: number;
  alternativeDrag: DragAlternative;
  dateMileseconds: number;
  answerDrag: IDragAnswerPost[];
  wordSelected: string;
  wrongAnswer: number;
  skip: boolean;
  inactiveDrag: boolean;
}

interface DragFillStateRequestsStatus {
  isLoading: boolean;
}
