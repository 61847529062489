import {
  Button,
  Flex,
  Image,
  Text,
  Menu as ChakraMenu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { ChevronDown, HelpCircle, X } from "lucide-react";
import { useEffect, useState } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { BsBoxArrowRight, BsCircleFill } from "react-icons/bs";
import { HiOutlineChevronDown } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/image/logo.png";
import profileImage from "../../assets/image/profile-image.png";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  loadStudentByIdRequest,
  logOut,
} from "../../stores/ducks/auth/actions";
import {
  clearLessonId,
  loadLessonIDRequest,
} from "../../stores/ducks/lesson/action";
import ModalCloseLesson from "../modals/ModalCloseLesson";
import MenuHeader from "./MenuHeader";
import OutlineButton from "./OutlineButton";
import { mechanicTypeName } from "../../utils/Utility";
import { loadActivityRequest } from "../../stores/ducks/dashboard/actions";
import { useLanguageContext } from "../../providers/LanguageProvider";
import SpainIcon from "../../assets/icons/SpainIcon";
import UsaIcon from "../../assets/icons/UsaIcon";

export interface HeaderProps {
  lesson?: string;
  activity?: string;
  onHelp?: () => void;
  disabledButton?: boolean;
}
function Header({ lesson, activity, onHelp, disabledButton }: HeaderProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { nextLessonFullData, activityData } = useAppSelector(
    (i) => i.dashboard
  );
  const { student } = useAppSelector((i) => i.auth);
  const { lessonIdData, currentLesson } = useAppSelector((i) => i.lesson);
  const [isOpenModal, setisOpenModal] = useState(false);
  const { changeLanguage, language } = useLanguageContext();
  const currentProgram = activityData?.lesson.story.level.program.short_name;

  useEffect(() => {
    if (student) {
      dispatch(loadStudentByIdRequest(student?.user));
      dispatch(loadActivityRequest(student.progress.activity));
    }
    if (currentLesson) {
      dispatch(loadLessonIDRequest(currentLesson?.lesson));
    }
    return () => {
      dispatch(clearLessonId());
    };
  }, []);

  const inPart1 =
    student?.in_diagnostic_part_1 && student?.in_diagnostic_part_2;
  const programVerify = () => {
    if (currentLesson?.mechanic_type === "post_test") {
      return "post_test";
    } else if (student?.in_ras) {
      return "Reading Attitude Survey";
    } else if (student?.in_diagnostic_part_1 || student?.in_diagnostic_part_2) {
      return "In diagnosis";
    } else {
      return `${lessonIdData?.story.name} Lesson ${
        lessonIdData?.lesson_order
      } of ${
        lessonIdData?.total_story_lessons !== undefined
          ? lessonIdData?.total_story_lessons - 1
          : 0
      } ${mechanicTypeName[currentLesson?.mechanic_type ?? ""]}`;
    }
  };

  return (
    <Flex flexDir="column" display={["none", "flex"]}>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        py="16px"
        px="32px"
        borderBottom="1px solid #EDF2F7"
      >
        <Image
          src={logo}
          // onClick={() => navigate("/lessons")}
          // cursor="pointer"
        />
        <MenuHeader />
      </Flex>

      <Flex
        alignItems="center"
        justifyContent="space-between"
        py="16px"
        px="32px"
        borderBottom="1px solid #EDF2F7"
      >
        {lessonIdData &&
        lessonIdData?.lesson_order !== 0 &&
        currentLesson?.mechanic_type !== "pre_test" ? (
          <Flex gap="8px" alignItems="center">
            <BsCircleFill size={12} color={lessonIdData?.story.level.color} />

            <Text fontSize="18px" fontWeight={700}>
              {lessonIdData?.story.level.program.name}
            </Text>

            <Text fontSize="18px" fontWeight={700} color={"gray.400"}>
              ({lessonIdData?.story.name}) Lesson {lessonIdData?.lesson_order}{" "}
              of {lessonIdData?.total_story_lessons - 1} -{" "}
              {mechanicTypeName[currentLesson?.mechanic_type ?? ""]}
            </Text>
          </Flex>
        ) : (
          <Flex gap="8px" alignItems="center">
            <BsCircleFill size={12} color="#0C4DA2" />
            <Text fontSize="18px" fontWeight={700}>
              {lessonIdData?.story.level.program.name}
            </Text>
            <Text fontSize="18px" fontWeight={700} color={"gray.400"}>
              {programVerify()}
            </Text>
          </Flex>
        )}

        <Flex gap="16px">
          {currentProgram !== "VM" && !student?.in_ras && (
            <ChakraMenu>
              <MenuButton
                // disabled
                as={Button}
                rightIcon={<ChevronDown />}
                leftIcon={language === "ENG" ? <UsaIcon /> : <SpainIcon />}
                borderRadius="full"
                color="gray.500"
                fontSize="16px"
                lineHeight="28px"
                fontWeight="600"
                border="1.5px solid #E2E8F0"
                p="8px 24px"
                h="48px"
                bg="bg"
                _hover={{}}
                _expanded={{ bg: "white" }}
              >
                Languages
              </MenuButton>
              <MenuList
                w="250px"
                display="flex"
                flexDir="column"
                mt="-4px"
                fontWeight="600"
                borderRadius="12px"
                border="1.5px solid #E2E5EE"
                borderTop="none"
                p="8px"
                boxShadow="8px 16px 64px rgba(4, 15, 58, 0.236)"
              >
                <MenuItem
                  fontWeight="500"
                  value="1"
                  color="gray.500"
                  borderRadius=" 12px"
                  onClick={() => changeLanguage("ENG")}
                >
                  <Flex gap="8px">
                    <UsaIcon />
                    <Text>English</Text>
                  </Flex>
                </MenuItem>
                <MenuItem
                  fontWeight="500"
                  value="2"
                  color="gray.500"
                  borderTop="1.5px solid #E2E8F0"
                  onClick={() => changeLanguage("SP")}
                >
                  <Flex gap="8px">
                    <SpainIcon />
                    <Text>Spanish</Text>
                  </Flex>
                </MenuItem>
              </MenuList>
            </ChakraMenu>
          )}

          <OutlineButton
            rightIcon={<HelpCircle size="16px" />}
            onClick={onHelp}
            disabled={disabledButton}
          >
            Help
          </OutlineButton>
          <OutlineButton
            rightIcon={<X size="16px" />}
            onClick={() => setisOpenModal(true)}
          >
            Close
          </OutlineButton>
        </Flex>
      </Flex>
      <ModalCloseLesson
        isOpen={isOpenModal}
        onClose={() => setisOpenModal(false)}
      />
    </Flex>
  );
}

export default Header;
