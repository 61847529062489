import { Action } from "@dnd-kit/core/dist/store";
import { Reducer } from "redux";
import { WordRecognitionActionTypes, WordRecognitionState } from "./type";

const INITIAL_STATE: WordRecognitionState = {
  isLoading: false,
  questionIndex: 0,
  recognitionData: undefined,
  answers: [],
  alternativeRecognition: undefined,
  dateMileseconds: 0,
};

const reducer: Reducer<WordRecognitionState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case WordRecognitionActionTypes.LOAD_RECOGNITION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case WordRecognitionActionTypes.LOAD_RECOGNITION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        recognitionData: action.payload,
      };
    case WordRecognitionActionTypes.LOAD_RECOGNITION_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case WordRecognitionActionTypes.SET_ALTERNATIVE_RECOGNITION:
      return { ...state, alternativeRecognition: action.payload };

    case WordRecognitionActionTypes.ON_NEXT:
      return {
        ...state,
        questionIndex: state.questionIndex + 1,
        // answers: [...state.answers, action.payload.contentAnswer],
      };
    case WordRecognitionActionTypes.SET_ANSWER_RECOGNITION:
      return {
        ...state,
        answers: [...state.answers, action.payload],
      };
    case WordRecognitionActionTypes.POST_ANSWER_RECOGNITION_REQUEST:
      return {
        ...state,
        // answers: [...state.answers, action.payload.contentAnswer],
        isLoading: true,
      };

    case WordRecognitionActionTypes.POST_ANSWER_RECOGNITION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case WordRecognitionActionTypes.POST_ANSWER_RECOGNITION_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case WordRecognitionActionTypes.SET_TIMER_RECOGNITION:
      return {
        ...state,
        dateMileseconds: action.payload,
      };
    default:
      return state;
  }
};
export default reducer;
