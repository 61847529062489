import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import {
  clearGame,
  loadMinigameRequest,
  saveMinigameRequest,
  setAlternativeGame,
  setTimerMinigame,
} from "../stores/ducks/minigame/action";
import { useStopwatch, useTimer } from "react-timer-hook";
import { usePages } from "../hooks/usePages";
import StartPage, { selectedProps } from "../components/ufo/StartPage";
import FinishPageGame from "../components/ufo/FinishPageGame";
import UfoComponent from "../components/ufo/UfoComponent";
import ButterflyComponent from "../components/butterfly/ButterflyComponent";
import DinosaurComponent from "../components/dinosaur/DinosaurComponent";
import { urlsPaths } from "../utils/Utility";
import { useNavigate } from "react-router-dom";

const GamePage: React.FC = () => {
  const {
    gameIndex,
    misses,
    otherHits,
    score,
    shotsFired,
    targetsHit,
    minigameData,
    alternativeGame,
  } = useAppSelector((i) => i.minigame);
  const dispatch = useAppDispatch();
  const { currentLesson, currentProgram } = useAppSelector((i) => i.lesson);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentLesson) {
      dispatch(loadMinigameRequest({ activity__id__exact: currentLesson.id }));
    }
    dispatch(setTimerMinigame(Date.now()));
  }, []);

  const startGame = (data: selectedProps) => {
    if (data.game === "ufo") {
      setPage("ufo");
    } else if (data.game === "butterfly") {
      setPage("butterfly");
    } else {
      setPage("dinosaur");
    }
  };

  const { page, setPage, pageName } = usePages<
    "start" | "ufo" | "butterfly" | "dinosaur" | "finish"
  >({
    initialPage: "start",
    pages: {
      start: <StartPage handleClick={(data) => startGame(data)} />,
      ufo: (
        <UfoComponent setPage={() => setPage("finish")} data={minigameData} />
      ),
      butterfly: (
        <ButterflyComponent
          setPage={() => setPage("finish")}
          data={minigameData}
        />
      ),
      dinosaur: (
        <DinosaurComponent
          setPage={() => setPage("finish")}
          data={minigameData}
        />
      ),
      finish: (
        <FinishPageGame
          setPage={() => {
            dispatch(clearGame());
            setPage("start");
            if (currentLesson) {
              dispatch(
                loadMinigameRequest({ activity__id__exact: currentLesson.id })
              );
            }
          }}
          postGame={() => {
            dispatch(saveMinigameRequest(navigate));
          }}
          misses={misses}
          otherHits={otherHits}
          score={score}
          shotsFired={shotsFired}
          targetsHit={targetsHit}
        />
      ),
    },
  });

  return <>{page}</>;
};

export default GamePage;
