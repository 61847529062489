import { Reducer } from "redux";
import { MinigameActionTypes, MinigameState } from "./types";

const INITIAL_STATE: MinigameState = {
  isLoading: false,
  gameIndex: 0,
  misses: 0,
  otherHits: 0,
  score: 0,
  shotsFired: 0,
  targetsHit: 0,
  difficulty: 0,
  dateMileseconds: 0,
  minigameData: undefined,
  alternativeGame: "",
};

const reducer: Reducer<MinigameState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MinigameActionTypes.LOAD_MINIGAME_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case MinigameActionTypes.LOAD_MINIGAME_SUCCESS:
      return {
        ...state,
        minigameData: action.payload,
        isLoading: false,
      };
    case MinigameActionTypes.LOAD_MINIGAME_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case MinigameActionTypes.POST_MINIGAME_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case MinigameActionTypes.POST_MINIGAME_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case MinigameActionTypes.POST_MINIGAME_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case MinigameActionTypes.ON_NEXT:
      return {
        ...state,
        gameIndex: state.gameIndex + 1,
      };
    case MinigameActionTypes.SET_ALTERNATIVE_MINIGAME:
      return {
        ...state,
        alternativeGame: action.payload,
      };

    case MinigameActionTypes.TARGETS_HIT:
      return {
        ...state,
        targetsHit: state.targetsHit + 1,
        score: state.score + 20,
      };

    case MinigameActionTypes.MISSES:
      return {
        ...state,
        misses: state.misses + 1,
      };
    case MinigameActionTypes.SHOT_FIRED:
      return {
        ...state,
        shotsFired: state.shotsFired + 1,
      };

    case MinigameActionTypes.OTHER_HITS:
      return {
        ...state,
        otherHits: state.otherHits + 1,
      };
    case MinigameActionTypes.SET_DIFFICULTY:
      return {
        ...state,
        difficulty: action.payload,
      };
    case MinigameActionTypes.SET_TIMER_MINIGAME:
      return {
        ...state,
        dateMileseconds: action.payload,
      };
    case MinigameActionTypes.CLEAR_GAME:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default reducer;
