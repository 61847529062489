import {
  AnswerPostTestVm,
  IPostTestVm,
} from "../../../@types/interfaces/api/post-test-vm.interface";
import { AnswerPreTestComponent } from "../../../components/lessons/SynonymAndDefinition";

export enum PostTestVmActionTypes {
  LOAD_POST_TEST_VM_REQUEST = "@postTestVm/LOAD_POST_TEST_VM_REQUEST",
  LOAD_POST_TEST_VM_SUCCESS = "@postTestVm/LOAD_POST_TEST_VM_SUCCESS",
  LOAD_POST_TEST_VM_ERROR = "@postTestVm/LOAD_POST_TEST_VM_ERROR",

  POST_POST_TEST_VM_REQUEST = "@postTestVm/POST_POST_TEST_VM_REQUEST",
  POST_POST_TEST_VM_SUCCESS = "@postTestVm/POST_POST_TEST_VM_SUCCESS",
  POST_POST_TEST_VM_ERROR = "@postTestVm/POST_POST_TEST_VM_ERROR",

  SET_ALTERNATIVE_SYNONYM = "@postTestVm/ SET_ALTERNATIVE_SYNONYM",
  SET_ALTERNATIVE_DEFINITION = "@postTestVm/ SET_ALTERNATIVE_DEFINITION",
  ON_NEXT_SYNONYM = "@postTestVm/ ON_NEXT_SYNONYM",
  ON_NEXT_DEFINITION = "@postTestVm/ ON_NEXT_DEFINITION",
  ON_PREVIOUS_POST_TEST_VM = "@postTestVm/ ON_PREVIOUS_POST_TEST_VM",
  SET_TYPE_POST_TEST_VM = "@postTestVm/ SET_TYPE_POST_TEST_VM",
  SET_TYPE_DEFINITION = "@postTestVm/ SET_TYPE_DEFINITION",
  SET_TIMER_POST_TEST_VM = "@postTestVm/ SET_TIMER_POST_TEST_VM",
  CLEAR_LIST = "@postTestVm/ CLEAR_LIST",
}

export interface PostTestVmState extends PostTestVmStateRequestsStatus {
  postTestVmData: IPostTestVm | undefined;
  questionIndex: number;
  alternativeSnonym: AnswerPreTestComponent | undefined;
  alternativeDefinition: AnswerPreTestComponent | undefined;
  dateMileseconds: number;
  answersPostTestVm: AnswerPostTestVm[];
  typeLesson: "synonym" | "definition" | "";
}

interface PostTestVmStateRequestsStatus {
  isLoading: boolean;
}
