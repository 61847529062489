import {
  IUnitReviewComprehension,
  UnitReviewComprehensionAnswer,
} from "../../../@types/interfaces/api/unit-review-reading-comprehension";

export enum UnitReviewComprehensionActionTypes {
  LOAD_QUESTION_UNIT_REVIEW_COMPREHENSION_REQUEST = "@unitReviewComprehension/LOAD_QUESTION_UNIT_REVIEW_COMPREHENSION_REQUEST",
  LOAD_QUESTION_UNIT_REVIEW_COMPREHENSION_SUCCESS = "@unitReviewComprehension/LOAD_QUESTION_UNIT_REVIEW_COMPREHENSION_SUCCESS",
  LOAD_QUESTION_UNIT_REVIEW_COMPREHENSION_ERROR = "@unitReviewComprehension/LOAD_QUESTION_UNIT_REVIEW_COMPREHENSION_ERROR",

  POST_ANSWER_UNIT_REVIEW_COMPREHENSION_REQUEST = "@unitReviewComprehension/POST_ANSWER_UNIT_REVIEW_COMPREHENSION_REQUEST",
  POST_ANSWER_UNIT_REVIEW_COMPREHENSION_SUCCESS = "@unitReviewComprehension/POST_ANSWER_UNIT_REVIEW_COMPREHENSION_SUCCESS",
  POST_ANSWER_UNIT_REVIEW_COMPREHENSION_ERROR = "@unitReviewComprehension/POST_ANSWER_UNIT_REVIEW_COMPREHENSION_ERROR",

  SET_ANSWER_UNIT_REVIEW_COMPREHENSION = "@unitReviewComprehension/ SET_ANSWER_UNIT_REVIEW_COMPREHENSION",
  SET_ALTERNATIVE_UNIT_REVIEW_COMPREHENSION = "@unitReviewComprehension/ SET_ALTERNATIVE_UNIT_REVIEW_COMPREHENSION",
  SET_TIMER_UNIT_REVIEW_COMPREHENSION = "@unitReviewComprehension/ SET_TIMER_UNIT_REVIEW_COMPREHENSION",
  ON_NEXT = "@unitReviewComprehension/ ON_NEXT",
  ON_PREVIOUS = "@unitReviewComprehension/ PREVIOUS",
  CLEAR_LIST = "@unitReviewComprehension/CLEAR_LIST",
}

export interface UnitReviewComprehensionState
  extends UnitReviewComprehensionStateRequestsStatus {
  unitReviewComprehensionData: IUnitReviewComprehension | undefined;
  answers: UnitReviewComprehensionAnswer[];
  alternativeUnitReviewComprehension: string;
  questionIndex: number;
  dateMileseconds: number;
}

interface UnitReviewComprehensionStateRequestsStatus {
  isLoading: boolean;
}
