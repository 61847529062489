import store from "..";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import {
  ActivityIdFilter,
  TalkingBook,
} from "../../../@types/interfaces/api/talking-book.interface";
import {
  AnswerRecognition,
  WordRecognitionData,
} from "../../../@types/interfaces/api/word-recognition.interface";
import { AlternativeIds, WordRecognitionActionTypes } from "./type";

export const loadWordRecognitionRequest = (filter: ActivityIdFilter) => ({
  type: WordRecognitionActionTypes.LOAD_RECOGNITION_REQUEST,
  payload: filter,
});

export const loadWordRecognitionSuccess = (
  data: PaginationBase<WordRecognitionData>
) => ({
  type: WordRecognitionActionTypes.LOAD_RECOGNITION_SUCCESS,
  payload: data,
});

export const loadWordRecognitionError = () => ({
  type: WordRecognitionActionTypes.LOAD_RECOGNITION_ERROR,
});

export const setAlternativeRecognition = (alternativeIds: AlternativeIds) => {
  return {
    type: WordRecognitionActionTypes.SET_ALTERNATIVE_RECOGNITION,
    payload: alternativeIds,
  };
};

export const onNextRecognition = (setPage: (page: any) => void) => {
  const contentListAnswer = store.getState().wordRecognition;

  if (
    contentListAnswer.recognitionData &&
    contentListAnswer.questionIndex <
      contentListAnswer?.recognitionData.results[0].select.length - 1
  ) {
    return {
      type: WordRecognitionActionTypes.ON_NEXT,
      payload: { setPage },
    };
  } else {
    setPage("greatJob");
    return {
      type: WordRecognitionActionTypes.POST_ANSWER_RECOGNITION_REQUEST,
      payload: { setPage },
    };
  }
};
export const setAnswerRecognition = () => {
  const contentListAnswer = store.getState().wordRecognition;
  const chosenAnswer = contentListAnswer.alternativeRecognition;

  const contentAnswer = {
    word: chosenAnswer?.word,
    question: chosenAnswer?.question,
    history: 0,
  };
  return {
    type: WordRecognitionActionTypes.SET_ANSWER_RECOGNITION,
    payload: contentAnswer,
  };
};

export const postAnswerRecognitionRequest = (data: AnswerRecognition[]) => ({
  type: WordRecognitionActionTypes.POST_ANSWER_RECOGNITION_REQUEST,
  payload: data,
});

export const postAnswerRecognitionSuccess = () => ({
  type: WordRecognitionActionTypes.POST_ANSWER_RECOGNITION_SUCCESS,
});
export const postAnswerRecognitionError = () => ({
  type: WordRecognitionActionTypes.POST_ANSWER_RECOGNITION_ERROR,
});

export const setTimerRecognition = (date: number) => ({
  type: WordRecognitionActionTypes.SET_TIMER_RECOGNITION,
  payload: date,
});
