import store from "..";
import { LessonsActionTypes } from "./types";
import {
  FullResponseActivity,
  IActivityData,
  ICurrentLessonData,
  IlessonById,
} from "../../../@types/interfaces/api/dashboard.interface";

export const loadCurrentLessonRequest = () => {
  return {
    type: LessonsActionTypes.LOAD_CURRENT_LESSON_REQUEST,
  };
};

export const loadCurrentLessonSuccess = (lessonList: ICurrentLessonData[]) => {
  return {
    type: LessonsActionTypes.LOAD_CURRENT_LESSON_SUCCESS,
    payload: lessonList,
  };
};
export const loadCurrentLessonError = () => {
  return {
    type: LessonsActionTypes.LOAD_CURRENT_LESSON_ERROR,
  };
};

export const loadLessonIDRequest = (id: number) => {
  return {
    type: LessonsActionTypes.LOAD_LESSON_REQUEST,
    payload: id,
  };
};

export const loadLessonIDSuccess = (lessonId: IlessonById) => {
  const currentProgram = lessonId.story.level.program.short_name;
  return {
    type: LessonsActionTypes.LOAD_LESSON_SUCCESS,
    payload: { lessonId, currentProgram },
  };
};
export const loadLessonIDError = () => {
  return {
    type: LessonsActionTypes.LOAD_LESSON_ERROR,
  };
};

export const setInAudio = (inAudio: boolean) => {
  return {
    type: LessonsActionTypes.SET_IN_AUDIO,
    payload: inAudio,
  };
};
export const setCurrentLesson = (fullResponse: FullResponseActivity) => {
  const { student } = store.getState().auth;
  const { currentLesson } = store.getState().lesson;

  let currentLessonPT: ICurrentLessonData | undefined = undefined;

  if (fullResponse.nextActivity && student?.progress.finished === true) {
    currentLessonPT = {
      activity_order: fullResponse.nextActivity.activity_order,
      id: fullResponse.nextActivity.id,
      lesson: fullResponse.nextActivity.lesson.id,
      mechanic_type: fullResponse.nextActivity.mechanic_type,
    };
  } else if (
    fullResponse.currentActivity &&
    student?.progress.finished === false
  ) {
    currentLessonPT = {
      activity_order: fullResponse.currentActivity.activity_order,
      id: fullResponse.currentActivity.id,
      lesson: fullResponse.currentActivity.lesson.id,
      mechanic_type: fullResponse.currentActivity.mechanic_type,
    };
  } else if (
    fullResponse.nextActivity &&
    student?.in_diagnostic_part_2 &&
    student?.progress.finished === false
  ) {
    currentLessonPT = {
      activity_order: fullResponse.nextActivity.activity_order,
      id: fullResponse.nextActivity.id,
      lesson: fullResponse.nextActivity.lesson.id,
      mechanic_type: fullResponse.nextActivity.mechanic_type,
    };
  } else if (
    fullResponse.nextActivity &&
    student?.progress.finished === false &&
    currentLesson?.mechanic_type === "pre_test"
  ) {
    currentLessonPT = {
      activity_order: fullResponse.nextActivity.activity_order,
      id: fullResponse.nextActivity.id,
      lesson: fullResponse.nextActivity.lesson.id,
      mechanic_type: fullResponse.nextActivity.mechanic_type,
    };
  } else {
    currentLessonPT = undefined;
  }

  return {
    type: LessonsActionTypes.SET_CURRENT_LESSON,
    payload: currentLessonPT,
  };
};

// export const setCurrentLesson = (lessonList: ICurrentLessonData[]) => {
//   const { student } = store.getState().auth;
//   const { currentLesson } = store.getState().lesson;

//   const filtredActivity = lessonList.findIndex(
//     (d) => d.id === student?.progress.activity
//   );

//   let currentLessonPT = null;

//   if (filtredActivity === lessonList.length - 1 && student?.progress.finished) {
//     currentLessonPT = lessonList[filtredActivity];
//     return {
//       type: LessonsActionTypes.LOAD_CURRENT_LESSON_REQUEST,
//     };
//   } else {
//     if (student?.progress.finished === true) {
//       currentLessonPT = lessonList[filtredActivity + 1];
//     } else if (
//       student?.progress.finished === false &&
//       student.in_diagnostic_part_2
//     ) {
//       currentLessonPT = lessonList[filtredActivity + 1];
//     } else if (
//       student?.progress.finished === false &&
//       currentLesson?.mechanic_type === "pre_test"
//     ) {
//       currentLessonPT = lessonList[filtredActivity + 1];
//     } else {
//       currentLessonPT = lessonList[filtredActivity];
//     }
//     return {
//       type: LessonsActionTypes.SET_CURRENT_LESSON,
//       payload: currentLessonPT,
//     };
//   }
// };

export const setActiveButtonHelp = (active: boolean) => {
  return {
    type: LessonsActionTypes.SET_ACTIVE_BUTTON_HELP,
    payload: active,
  };
};

export const clearLessonId = () => {
  return {
    type: LessonsActionTypes.CLEAR_LESSON,
  };
};
