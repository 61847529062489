import { Flex, Heading, Text } from "@chakra-ui/react";
import { ArrowRight } from "lucide-react";
import React, { useEffect } from "react";
import GreatJobIcon from "../../../assets/icons/GreatJobIcon";
import RegularButton from "../../global/RegularButton";
import Text32px from "../../global/text/Text32px";
import { chakra } from "@chakra-ui/react";
import { motion, isValidMotionProp } from "framer-motion";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import {
  loadStudentByIdRequest,
  loadTokenRequest,
} from "../../../stores/ducks/auth/actions";
import {
  loadActivityRequest,
  loadNextActivityLessonRequest,
} from "../../../stores/ducks/dashboard/actions";
import { loadCurrentLessonRequest } from "../../../stores/ducks/lesson/action";
import { assetsBaseUrl } from "../../../utils/assets-helper";
import { useLanguageContext } from "../../../providers/LanguageProvider";
import { useAccesibiltyContext } from "../../../providers/AccessibilityController";

interface GreatJobProps {
  onNext: () => void;
}

const GreatJob: React.FC<GreatJobProps> = ({ onNext }) => {
  const dispatch = useAppDispatch();
  const { student } = useAppSelector((i) => i.auth);
  const { isLoading } = useAppSelector((i) => i.dashboard);
  const { language } = useLanguageContext();
  const { setVideoWindow, onPlayAudio } = useAccesibiltyContext();

  useEffect(() => {
    setVideoWindow(false);
    if (student?.progress.activity) {
      dispatch(loadActivityRequest(student.progress.activity));
    }
  }, [student]);

  useEffect(() => {
    onPlayAudio(
      `${assetsBaseUrl()}/app-2.0/ras/sounds/sound_students/great_job.mp3`
    );
    if (student) {
      dispatch(loadStudentByIdRequest(student?.user));
    }
  }, []);

  return (
    <Flex flexDir="column" align="center" mt="80px">
      <motion.div
        animate={{
          rotate: [0, -80, 20, -44, 10, 0],
        }}
        // @ts-ignore no problem in operation, although type error appears.
        transition={{
          duration: 3,
          ease: "easeInOut",
          repeat: Infinity,
          repeatType: "loop",
        }}
      >
        <GreatJobIcon />
      </motion.div>
      <Text32px mt="32px">Great Job!</Text32px>
      <Text
        color="gray.500"
        fontSize="18px"
        fontWeight="500"
        textAlign="center"
      >
        Your score has been recorded.
      </Text>
      <Text fontSize="16px" fontWeight="600" mt="32px" textAlign="center">
        Please click the “Next” button to Continue.
      </Text>
      <RegularButton
        rightIcon={<ArrowRight />}
        mt="56px"
        onClick={onNext}
        isLoading={isLoading}
      >
        Next
      </RegularButton>
    </Flex>
  );
};

export default GreatJob;
