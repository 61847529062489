import store from "..";
import PaginationBase from "../../../@types/interfaces/api/pagination-base.interface";
import {
  IScrambled,
  IScrambledAnswerPost,
} from "../../../@types/interfaces/api/scrambled-sentence.interface";
import { ActivityIdFilter } from "../../../@types/interfaces/api/talking-book.interface";
import { ScrambledSentenceActionTypes } from "./type";

export const loadScrambledRequest = (filter: ActivityIdFilter) => ({
  type: ScrambledSentenceActionTypes.LOAD_SCRAMBLED_REQUEST,
  payload: filter,
});

export const loadScrambledSuccess = (data: PaginationBase<IScrambled>) => ({
  type: ScrambledSentenceActionTypes.LOAD_SCRAMBLED_SUCCESS,
  payload: data,
});

export const loadScrambledError = () => ({
  type: ScrambledSentenceActionTypes.LOAD_SCRAMBLED_ERROR,
});

export const clearScrambledList = () => {
  return {
    type: ScrambledSentenceActionTypes.CLEAR_LIST_SCRAMBLED,
  };
};

export const setTimerScrambled = (date: number) => {
  return {
    type: ScrambledSentenceActionTypes.SET_TIMER_SCRAMBLED,
    payload: date,
  };
};

export const setWrongScrambled = () => {
  return {
    type: ScrambledSentenceActionTypes.SET_WRONG_ANSWER_SCRAMBLED,
  };
};

export const setAnswerScrambled = (data: IScrambledAnswerPost[]) => {
  return {
    type: ScrambledSentenceActionTypes.SET_ANSWER_SCRAMBLED,
    payload: data,
  };
};

export const onNextScrambled = (setPage: (page: any) => void) => {
  const contentListAnswer = store.getState().scrambledSentence;

  const contentAnswer = contentListAnswer.answerScrambled;
  setPage("greatJob");
  return {
    type: ScrambledSentenceActionTypes.POST_SCRAMBLED_REQUEST,
    payload: { contentAnswer, setPage },
  };
};
export const postScrambledSuccess = () => {
  return {
    type: ScrambledSentenceActionTypes.POST_SCRAMBLED_SUCCESS,
  };
};

export const postScrambledError = () => {
  return {
    type: ScrambledSentenceActionTypes.POST_SCRAMBLED_ERROR,
  };
};
