import {
  IMinigameData,
  IMinigameJR,
  IMinigmeQuestions,
  IMinigmeQuestionsVM,
} from "../../../@types/interfaces/api/minigame.interface";

export enum MinigameActionTypes {
  ON_NEXT = "@minigame/ON_NEXT",
  SCORE = "@minigame/SCORE",
  SHOT_FIRED = "@minigame/SHOT_FIRED",
  MISSES = "@minigame/MISSES",
  TARGETS_HIT = "@minigame/TARGETS_HIT",
  OTHER_HITS = "@minigame/OTHER_HITS",
  SET_DIFFICULTY = "@minigame/SET_DIFFICULTY",
  CLEAR_GAME = "@minigame/CLEAR_GAME",

  LOAD_MINIGAME_REQUEST = "@minigame/LOAD_MINIGAME_REQUEST",
  LOAD_MINIGAME_SUCCESS = "@minigame/LOAD_MINIGAME_SUCCESS",
  LOAD_MINIGAME_ERROR = "@minigame/LOAD_MINIGAME_ERROR",

  POST_MINIGAME_REQUEST = "@minigame/ POST_MINIGAME_REQUEST",
  POST_MINIGAME_SUCCESS = "@minigame/ POST_MINIGAME_SUCCESS",
  POST_MINIGAME_ERROR = "@minigame/ POST_MINIGAME_ERROR",

  SET_ANSWER_MINIGAME = "@minigame/SET_ANSWER_MINIGAME",
  SET_ALTERNATIVE_MINIGAME = "@minigame/SET_ALTERNATIVE_MINIGAME",
  SET_TIMER_MINIGAME = "@minigame/ SET_TIMER_MINIGAME",
}

export interface MinigameState extends MinigameStateRequestsStatus {
  gameIndex: number;
  score: number;
  shotsFired: number;
  targetsHit: number;
  misses: number;
  otherHits: number;
  difficulty: 0 | 1 | 2;
  alternativeGame: string;
  minigameData: IMinigameData | undefined;
  dateMileseconds: number;
}

interface MinigameStateRequestsStatus {
  isLoading: boolean;
}
