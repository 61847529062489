import { Box, Button, Flex, Text, useDimensions } from "@chakra-ui/react";
import { DndContext } from "@dnd-kit/core";
import {
  PropsWithChildren,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { DraggableButton } from "../global/DraggableButton";
import RegularButton from "../global/RegularButton";
import Subtitle from "../global/text/Subtitle";
import Text32px from "../global/text/Text32px";
import { useSize } from "@chakra-ui/react-use-size";
import { useLanguageContext } from "../../providers/LanguageProvider";
import { useAccesibiltyContext } from "../../providers/AccessibilityController";

type CardContent = {
  text: string;
  audio: string;
};
export type DragToUncoverData = {
  introduction?: {
    audio: string;
  };
  frontCard: CardContent;
  backCard: CardContent;
};
interface Props {
  data: DragToUncoverData;
  questionIndex: number;
}
function DragToUncover({ data, questionIndex }: Props) {
  const { language } = useLanguageContext();
  const [hideCard, setHideCard] = useState(false);
  const [process, setProcess] = useState(false);
  const [realDimension, setRealDimension] = useState({
    w: "auto",
    h: "auto",
  });
  const initialPosition = { x: 0, y: 0 };
  const [position, setPosition] = useState(initialPosition);
  const { onPlayAudio } = useAccesibiltyContext();

  const elementRef = useRef<any>();

  async function handleResponse() {
    if (!process) {
      setProcess(true);
      await onPlayAudio(data.frontCard.audio);
      setHideCard(true);
      await onPlayAudio(data.backCard.audio);

      setProcess(false);
      setHideCard(false);
      setPosition(initialPosition);
    }
  }

  const dimensions = useSize(elementRef);

  useEffect(() => {
    async function explanation() {
      await onPlayAudio(data.introduction?.audio);
    }
    if (questionIndex === 0) {
      explanation();
    }
    if (dimensions) {
      setRealDimension({
        w: String(dimensions.width) + "px",
        h: String(dimensions.height) + "px",
      });
    }
  }, [dimensions]);

  return (
    <Flex flexDir="column" align="center">
      <Subtitle>SYNONYM AND DEFINITION</Subtitle>
      <Text32px mt="16px">Click and drag</Text32px>
      <DndContext
        onDragEnd={({ delta }) => {
          if (delta.x > 30 || delta.y > 30) {
            setPosition(({ x, y }) => {
              return {
                x: x + delta.x,
                y: y + delta.y,
              };
            });

            handleResponse();
          }
        }}
        autoScroll={false}
      >
        <Flex alignItems={"center"} justifyContent="center" flexDir={"column"}>
          <Flex
            alignItems={"center"}
            justifyContent="center"
            position="relative"
            h="200px"
            maxW="700px"
          >
            <Box
              bg="#4D9A39"
              pos="absolute"
              borderRadius="16px"
              w="max-content"
              maxW={["300px", "600px"]}
            >
              <Flex
                ref={elementRef}
                p="24px"
                borderRadius="16px"
                border="1.5px solid #CBD5E0"
                align="center"
                justify="center"
              >
                <Text fontSize="24px" fontWeight="700" color="white">
                  {data.backCard.text}
                </Text>
              </Flex>
            </Box>

            <DraggableButton
              id="card"
              position={{ x: position.x, y: position.y }}
            >
              <Box
                bg="white"
                zIndex={1}
                borderRadius="16px"
                hidden={hideCard}
                boxShadow="0px 3px 6px rgba(119, 119, 119, 0.196)"
              >
                <Flex
                  h={realDimension.h}
                  minW={realDimension.w}
                  p="24px"
                  borderRadius="16px"
                  border="1.5px solid #CBD5E0"
                  align="center"
                  justify="center"
                >
                  <Text fontSize="24px" fontWeight="700">
                    {data.frontCard.text}
                  </Text>
                </Flex>
              </Box>
            </DraggableButton>
          </Flex>
        </Flex>
      </DndContext>
    </Flex>
  );
}

export default DragToUncover;
