import { type } from "os";
import {
  ICurrentLessonData,
  IlessonById,
} from "../../../@types/interfaces/api/dashboard.interface";
import { Question } from "../../../@types/interfaces/api/ras.interface";

// import { FillTheGapResponse } from "../../../components/lessons/FillTheGapOld";

export enum LessonsActionTypes {
  LOAD_LESSON_REQUEST = "@lesson/LOAD_LESSON_REQUEST",
  LOAD_LESSON_SUCCESS = "@lesson/LOAD_LESSON_SUCCESS",
  LOAD_LESSON_ERROR = "@lesson/LOAD_LESSON_ERROR",

  LOAD_CURRENT_LESSON_REQUEST = "@lesson/LOAD_CURRENT_LESSON_REQUEST",
  LOAD_CURRENT_LESSON_SUCCESS = "@lesson/LOAD_CURRENT_LESSON_SUCCESS",
  LOAD_CURRENT_LESSON_ERROR = "@lesson/LOAD_CURRENT_LESSON_ERROR",

  SET_CURRENT_LESSON = "@lesson/SET_CURRENT_LESSON",
  SET_IN_AUDIO = "@lesson/SET_IN_AUDIO",
  CLEAR_LESSON = "@lesson/CLEAR_LESSON",

  SET_ACTIVE_BUTTON_HELP = "@lesson/SET_ACTIVE_BUTTON_HELP",
}

export interface LessonState extends LessonStateRequestsStatus {
  currentLesson: ICurrentLessonData | undefined;
  currentLessonList: ICurrentLessonData[];
  lessonIdData: IlessonById | undefined;
  activeButtonHelp: boolean;
  inAudio: boolean;
  currentProgram: "JR" | "LF" | "VM" | null;
}

interface LessonStateRequestsStatus {
  isLoading: boolean;
}
