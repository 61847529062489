import { Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { StoryFeed } from "../../../@types/interfaces/api/pre-teaching.interface";
import ContainerLesson from "../../../components/global/ContainerLesson";
import PageGrid from "../../../components/global/PageGrid";
import ProgressBar from "../../../components/global/ProgressBar";
import GreatJob from "../../../components/lessons/common/GreatJob";
import StartLesson from "../../../components/lessons/common/StartLesson";
import LessonSpelling from "../../../components/lessons/LessonSpelling";

import StoryLesson from "../../../components/lessons/StoryLesson";
import SpellingQuestion from "../../../components/lessons/word/SpellingQuestion";
import { usePages } from "../../../hooks/usePages";
import { assetsBaseUrl } from "../../../utils/assets-helper";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import {
  clearSpelling,
  learnedWordSpelling,
  loadSpellingProgramRequest,
  onNextSpelling,
  setAlternativeSpelling,
  setAnswerSpelling,
  setTextSpelling,
  setTimerSpelling,
  setWordFeedSpelling,
} from "../../../stores/ducks/spelling-program/actions";
import SpellingWords from "../../../components/lessons/SpellingWords";
import { SpellingWordsType } from "../../../@types/interfaces/api/spelling-program.interface";
import { urlsPaths } from "../../../utils/Utility";
import { useNavigate } from "react-router-dom";
import { useAccesibiltyContext } from "../../../providers/AccessibilityController";
import LaunchLesson from "../../../components/lessons/common/LaunchLesson";
import SubtitleComponent from "../../../components/global/SubtitleComponent";

export type VerifyButtonHelp = {
  audios: string[];
  inactiveButton: boolean;
};

function SpellingProgramPage() {
  const [spellingQuestionHelp, setSpellingQuestionHelp] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    spellingData,
    spellingQuestionData,
    alternativeSpelling,
    wordFeed,
    answers,
    learnedWords,
    isLoading,
  } = useAppSelector((i) => i.spellingProgram);
  const { currentLesson, inAudio } = useAppSelector((i) => i.lesson);
  const { setVideoWindow, captionText, accessibility } =
    useAccesibiltyContext();
  const { student } = useAppSelector((item) => item.auth);

  useEffect(() => {
    setVideoWindow(true);
    dispatch(setTimerSpelling(Date.now()));
    if (!currentLesson) return;
    dispatch(loadSpellingProgramRequest({ activity__id: currentLesson.id }));
    return () => {
      setVideoWindow(false);
      dispatch(clearSpelling());
    };
  }, []);

  const verifyButtonHelp = (): VerifyButtonHelp => {
    if (pageName === "words") {
      return {
        inactiveButton: false,
        audios: [
          `${assetsBaseUrl()}/app-2.0/jr4_html5/audio/ENG/dirs_mp3/ANYWORD.mp3`,
          `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/in81.ogg`,
          `${assetsBaseUrl()}/app-2.0/jr4_html5/audio/ENG/dirs_mp3/ARROWBOTTOM.mp3`,
          `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/in82.ogg`,
        ],
      };
    } else if (pageName === "spelling") {
      return {
        inactiveButton: false,
        audios: [
          `${assetsBaseUrl()}/app-2.0/jr4_html5/audio/ENG/dirs_mp3/SPELLING_DIRECTIONS_1.mp3`,
          `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/in83.ogg`,
        ],
      };
    } else if (pageName === "spellingQuestion" && spellingQuestionData) {
      return {
        inactiveButton: false,
        audios: spellingQuestionData.audio,
      };
    } else {
      return { inactiveButton: true, audios: [] };
    }
  };

  const initialPage = student?.progress.finished ? "start" : "accessibility";

  const { page, setPage, pageName } = usePages<
    | "start"
    | "greatJob"
    | "words"
    | "spelling"
    | "spellingQuestion"
    | "accessibility"
  >({
    initialPage,
    pages: {
      accessibility: <LaunchLesson onNext={() => setPage("start")} />,
      start: (
        <StartLesson
          title="Spelling program"
          onNext={() => setPage("words")}
          introduction={[
            `${assetsBaseUrl()}/app-2.0/jr4_html5/audio/ENG/dirs_mp3/WELCOME_SPELLING.mp3`,
            `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/in80.ogg`,
          ]}
          inAudio={inAudio}
        />
      ),
      words: spellingData && (
        <SpellingWords
          audiosIntro={[
            `${assetsBaseUrl()}/app-2.0/jr4_html5/audio/ENG/dirs_mp3/ANYWORD.mp3`,
            `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/in81.ogg`,
            `${assetsBaseUrl()}/app-2.0/jr4_html5/audio/ENG/dirs_mp3/ARROWBOTTOM.mp3`,
            `${assetsBaseUrl()}/app-2.0/jr4_html5/vsubt/eng/ogg/in82.ogg`,
          ]}
          learnedWords={learnedWords}
          data={spellingData?.words}
          setAlternative={(word) => {
            dispatch(setAlternativeSpelling(word));
            dispatch(setWordFeedSpelling());
          }}
          onNext={(word) => {
            setPage("spelling");
          }}
          postActivity={() => dispatch(onNextSpelling(setPage))}
        />
      ),
      spelling: (
        <LessonSpelling
          story={wordFeed}
          onFinish={async () => {
            setPage("spellingQuestion");
          }}
        />
      ),
      spellingQuestion: spellingQuestionData && (
        <SpellingQuestion
          data={{ spellingQuestionData, spellingHelp: spellingQuestionHelp }}
          onWrong={() => {
            setPage("spelling");
            setSpellingQuestionHelp(true);
            dispatch(setAnswerSpelling());
          }}
          onCorrect={() => {
            setPage("words");
            setSpellingQuestionHelp(false);
            dispatch(setAnswerSpelling());
            dispatch(learnedWordSpelling());
          }}
          saveResponse={(answer) => dispatch(setTextSpelling(answer))}
        />
      ),
      greatJob: (
        <GreatJob
          onNext={() => {
            if (currentLesson) {
              navigate(`/lesson/${urlsPaths[currentLesson?.mechanic_type]}`);
            }
          }}
        />
      ),
    },
  });

  return (
    <PageGrid
      activity="Spelling program"
      lesson="Life Skills"
      onHelp={() => {}}
      audioHelp={verifyButtonHelp().audios}
      disabledButton={
        verifyButtonHelp().inactiveButton ||
        pageName === "accessibility" ||
        inAudio
      }
    >
      <ContainerLesson>
        {page}
        <SubtitleComponent
          hide={
            !accessibility.subtitles ||
            !inAudio ||
            captionText === "" ||
            pageName === "greatJob"
          }
        />

        {/* <ProgressBar data={{ max: 12, step: 1 }} hide={pageName === "start"} /> */}
      </ContainerLesson>
    </PageGrid>
  );
}

export default SpellingProgramPage;
