import { Button, Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import PlayIcon from "../../assets/icons/PlayIcon";
import ButtonRadio from "../global/ButtonRadio";
import RegularButton from "../global/RegularButton";
import Subtitle from "../global/text/Subtitle";
import Text32px from "../global/text/Text32px";
import { BASE_URL_MIDIA } from "../../utils/Utility";
import { QuestionPreTestVm } from "../../@types/interfaces/api/pre-test-vm.interface";
import AudioWave from "../global/AudioWave";
import { assetsBaseUrl } from "../../utils/assets-helper";
import { useAppDispatch } from "../../hooks/redux";
import { setActiveButtonHelp } from "../../stores/ducks/lesson/action";
import { useLanguageContext } from "../../providers/LanguageProvider";
import { useAccesibiltyContext } from "../../providers/AccessibilityController";

export type AnswerPreTestComponent = {
  id: number;
  text: string;
  audio?: string;
  answer: string;
};

interface Props {
  setAnswer: (answer: AnswerPreTestComponent) => void;
  data: AnswerPreTestComponent[];
  alternative: AnswerPreTestComponent | undefined;
  questionIndex: number;
  title: string;
}

function SynonymAndDefinition({
  data,
  questionIndex,
  alternative,
  setAnswer,
  title,
}: Props) {
  const [isPlaying, setIsPlaying] = useState(true);
  const dispatch = useAppDispatch();
  const { language } = useLanguageContext();
  const { onPlayAudio } = useAccesibiltyContext();

  useEffect(() => {
    const play = async () => {
      setIsPlaying(true);
      dispatch(setActiveButtonHelp(false));
      await onPlayAudio([
        `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/d29.mp3`,
        `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/d22.mp3`,
        `${assetsBaseUrl()}${data[questionIndex].audio}`,
        `${assetsBaseUrl()}/app-2.0/vm_html5/audio/directions/uh06.mp3`,
      ]);
      setIsPlaying(false);
      dispatch(setActiveButtonHelp(true));
    };
    if (questionIndex === 0) {
      play();
    } else {
      onPlayAudio(`${BASE_URL_MIDIA}${data[questionIndex].audio}`);
    }
  }, [questionIndex]);
  return (
    <Flex flexDirection={"column"} align="center">
      <Flex align="center" pos="relative">
        <Subtitle>{title}</Subtitle>
        <Flex position="absolute" hidden={!isPlaying} right={-70}>
          <AudioWave />
        </Flex>
      </Flex>

      <Flex align="center" mt="20px" flexDir={["column", "row"]}>
        {/* <Text32px>synonym</Text32px> */}
        <PlayIcon
          onClick={() =>
            onPlayAudio(`${BASE_URL_MIDIA}${data[questionIndex].audio}`)
          }
          disabled={isPlaying}
        />
        <Text fontWeight="500" fontSize="18px" color={"gray.500"} ml="20px">
          Repeat Audio
        </Text>
      </Flex>

      <Flex gap="16px" mt="56px" flexWrap="wrap" justify="center">
        {data.map((data) => {
          return (
            <ButtonRadio
              key={data.id}
              isSelected={data.text === alternative?.text}
              text={data.text}
              disabled={isPlaying}
              inPlaying={isPlaying}
              handleClick={() => {
                setAnswer(data);
              }}
            />
          );
        })}
      </Flex>
      {alternative?.text && (
        <Flex
          py="20px"
          px={["14px", "24px"]}
          bg="white"
          borderRadius="16px"
          border="1.5px solid #CBD5E0"
          gap="24px"
          mt="40px"
        >
          <Text
            fontWeight={700}
            fontSize={["18px", "24px"]}
            whiteSpace="pre-wrap"
            color={"primary"}
          >
            {alternative?.text}
          </Text>
        </Flex>
      )}
    </Flex>
  );
}

export default SynonymAndDefinition;
